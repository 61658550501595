import React from 'react';

import {
  cx,
  css,
  Text,
  Select,
  px2grid,
  px2rem,
  ColorNeutralLighter,
  ColorBrandLighter,
  ColorBrandBase,
  ColorNeutralDark,
  getSpaceSize,
} from '@ampli/ui';
import { arrayOf, object, oneOfType, string, number, func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

const BoxItems = ({ items, value, onSelectValue, label }) => (
  <div
    className={cx(
      'items-wrap',
      css`
        width: fit-content;
      `
    )}
  >
    <Text size="x-large" color={ColorNeutralDark}>
      {label}
    </Text>
    <div
      className={cx(
        'select-items-wrap',
        css`
          border: 1px solid ${ColorNeutralLighter};
          border-radius: ${px2rem(8)};
          margin-top: ${getSpaceSize('small')};
          margin-right: ${getSpaceSize('xx-small')};
        `
      )}
    >
      {items.map((item, index) => (
        <button
          key={`select_item_${index}`}
          className={cx(
            'select-button',
            css`
              border: none;
              background-color: transparent;
              border-right: 1px solid ${ColorNeutralLighter};
              padding: ${px2grid(12)};

              &.last {
                border: none;
              }

              &.selected {
                background-color: ${ColorBrandLighter};
              }

              &.disabled {
                opacity: 0.4;
                background-color: ${ColorNeutralLighter};
              }
            `,
            index === items.length - 1 && 'last',
            item?.value === value && 'selected',
            item?.disabled && 'disabled'
          )}
          disabled={item?.disabled}
          onClick={() => !!onSelectValue && onSelectValue(item?.value)}
        >
          <Text
            color={item?.value === value ? ColorBrandBase : ColorNeutralDark}
          >
            {item?.label}
          </Text>
        </button>
      ))}
    </div>
  </div>
);

const SelectValue = ({ items, value, onSelectValue, label }) => {
  const filteredItems = React.useMemo(() => {
    if (!items?.length) return [];

    return items.filter((item) => !item.disabled);
  }, [items]);

  return (
    <div
      className={cx(
        'select-value-wrap',
        css`
          width: ${px2grid(200)};
        `
      )}
    >
      <Text color="white">{label}</Text>
      <Select
        value={value}
        onChange={(event) =>
          !!onSelectValue && onSelectValue(event.target.value)
        }
        className={cx(
          'select-value',
          css`
            color: ${ColorBrandBase};
          `
        )}
      >
        {filteredItems.map((item, index) => (
          <option key={`select_value_${index}`} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
    </div>
  );
};

const SelectItems = (props) => {
  const breakpoint = useBreakpoint();

  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  return isDesktop ? <BoxItems {...props} /> : <SelectValue {...props} />;
};

SelectItems.propTypes = {
  items: arrayOf(object),
  value: oneOfType([string, number]),
  label: string,
  onSelectValue: func,
};

export default SelectItems;
