import React from 'react';

import {
  cx,
  css,
  GradientButton,
  px2grid,
  getSpaceSize,
  ColorNeutralLighter,
  GradientLinearBrand,
} from '@ampli/ui';
import { func, number, bool } from '@ampli/utils';

import PriceInfoFilter from './price-info-filter';
import { useDetectIdScroll } from '../../hooks';

const FilterMobileWrap = ({ onClick, minPrice, fixedPrice }) => {
  const { isReachedPoint } = useDetectIdScroll('subscribe-banner', 600);

  return (
    <div
      className={cx(
        'mobile-filter-wrap',
        css`
          position: fixed;
          background-color: white;
          width: 100%;
          z-index: 10;
          bottom: 0;
          display: flex;
          border-radius: ${px2grid(8)} ${px2grid(8)} 0 0;
          justify-content: center;
          align-items: center;
          border: 1px solid ${ColorNeutralLighter};
          padding: ${getSpaceSize('xx-small')} ${getSpaceSize('small')};
          visibility: visible;
          opacity: 1;
          transition: opacity 200ms, visibility 200ms;

          &.isReachedPoint {
            visibility: hidden;
            opacity: 0;
          }
        `,
        isReachedPoint && 'isReachedPoint'
      )}
    >
      <div
        className={cx(
          'mobile-filter-info',
          css`
            width: ${px2grid(150)};
            margin-bottom: ${px2grid(20)};
          `
        )}
      >
        <PriceInfoFilter
          price={minPrice}
          marginBottom="xxx-small"
          fixedPrice={fixedPrice}
        />
      </div>

      <GradientButton
        className={cx(
          'info-filter-button',
          css`
            height: ${px2grid(60)};
            width: ${px2grid(200)};
            margin-left: ${px2grid(20)};
          `
        )}
        onClick={!!onClick && onClick}
        color={GradientLinearBrand}
      >
        {'Filtrar cursos'}
      </GradientButton>
    </div>
  );
};

FilterMobileWrap.propTypes = {
  onClick: func,
  minPrice: number,
  fixedPrice: bool,
};

export default FilterMobileWrap;
