import React from 'react';

import {
  css,
  cx,
  PrimaryButton,
  TextButton,
  px2rem,
  px2grid,
  Text,
  fontSize,
  GradientLinearBrand,
  IconButton,
  ColorBrandBase,
  getSpaceSize,
  useDialogState,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { string, shape, arrayOf, func } from '@ampli/utils';

import AmpliLogo from './header-logo';
import HeaderContainer from './header-container';
import MenuDesktop from './menu-desktop';
import DialogMenu from './dialog-menu';

import { HEADER_LINKS } from '../../constants';

export const MIN_WIDTH_DESKTOP_HEADER = 1084;

const BaseHeader = ({
  items,
  onLoginClick,
  onSubscribeClick,
  onFollowSubscriptionClick,
  visibleSection,
}) => {
  const breakpoint = useBreakpoint(true);
  const dialogState = useDialogState();
  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width >= MIN_WIDTH_DESKTOP_HEADER,
    [breakpoint]
  );

  const handleLoginClick = () => {
    if (!onLoginClick) return window?.open(HEADER_LINKS.login, '_blank');

    onLoginClick();
  };

  const handleSubscriptionClick = () => {
    if (!onFollowSubscriptionClick)
      return window?.open(HEADER_LINKS.followSubscription, '_blank');

    onFollowSubscriptionClick();
  };

  return (
    <HeaderContainer>
      {isDesktop ? (
        <>
          <div
            className={cx(
              'wrap-content',
              css`
                display: flex;
                flex-direction: row;
              `
            )}
          >
            <AmpliLogo />
            <MenuDesktop items={items} visibleSection={visibleSection} />
          </div>

          <div>
            <IconButton
              onClick={handleSubscriptionClick}
              icon="Document"
              iconColor={ColorBrandBase}
              className={cx(
                'text-button-primary',
                css`
                  color: ${ColorBrandBase};
                  font-weight: 400;
                  cursor: pointer;
                  margin-right: ${getSpaceSize('small')};
                  svg {
                    width: ${px2rem(16)};
                    height: ${px2rem(16)};
                  }
                  path {
                    fill: ${ColorBrandBase};
                  }
                `
              )}
            >
              {'acompanhar inscrição'}
            </IconButton>

            <IconButton
              onClick={handleLoginClick}
              icon="HatGraduation"
              iconSize="x-large"
              iconColor={ColorBrandBase}
              className={cx(
                'text-button-primary',
                css`
                  color: ${ColorBrandBase};
                  font-weight: 400;
                  cursor: pointer;
                  margin-right: ${getSpaceSize('small')};
                  path {
                    fill: ${ColorBrandBase};
                  }
                `
              )}
            >
              {'sou aluno'}
            </IconButton>

            <PrimaryButton
              onClick={onSubscribeClick}
              as="strong"
              className={cx(
                'primary-button-navbar',
                css`
                  font-weight: 400;
                  cursor: pointer;
                  background: ${GradientLinearBrand};
                  border-color: transparent;
                `
              )}
              size="micro"
            >
              <Text
                color="white"
                size="base"
                className={cx(
                  'button-navbar-text',
                  css`
                    padding: 0 ${px2grid(12)};
                  `
                )}
              >
                {'Experimente de graça'}
              </Text>
            </PrimaryButton>
          </div>
        </>
      ) : (
        <>
          <IconButton
            className={cx(
              'icon-button-menu',
              fontSize('x-small'),
              css`
                letter-spacing: ${px2rem(5)};
              `
            )}
            icon="Menu"
            iconColor="text-light"
            onClick={() => dialogState[dialogState.visible ? 'hide' : 'show']()}
          >
            {'menu'}
          </IconButton>
          <AmpliLogo />

          <TextButton
            className={cx(
              'subscribe-button-menu',
              fontSize('x-small'),
              css`
                letter-spacing: ${px2rem(5)};
              `
            )}
            onClick={onSubscribeClick}
            underline
          >
            {'inscreva-se'}
          </TextButton>
        </>
      )}
      <DialogMenu
        items={items}
        onLoginClick={handleLoginClick}
        onSubscribeClick={onSubscribeClick}
        dialogState={dialogState}
      />
    </HeaderContainer>
  );
};

BaseHeader.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      onClick: func,
    })
  ),
  onLoginClick: func,
  onFollowSubscriptionClick: func,
  onSubscribeClick: func,
  visibleSection: string,
};

export default BaseHeader;
