import React from 'react';

import {
  cx,
  css,
  px2grid,
  px2rem,
  getSpaceSize,
  mediaQuery,
  OutlinedButton,
  IconButton,
  Text,
  Input,
  ColorNeutralLighter,
  ColorNeutralDark,
} from '@ampli/ui';

import { func, string } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

const SearchInputList = ({ onSearch, label, value }) => {
  const [search, setSearch] = React.useState('');
  const breakpoint = useBreakpoint();

  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  React.useEffect(() => {
    if (value && search !== value) setSearch(value);
  }, [value]);

  return (
    <div
      className={cx(
        'input-search-wrap',
        css`
          width: auto;
          flex: 1;
          margin-left: ${getSpaceSize('small')};

          ${mediaQuery('desktop')} {
            margin-right: ${getSpaceSize('small')};
          }
        `
      )}
    >
      <Text
        size={isDesktop ? 'x-large' : 'base'}
        color={isDesktop ? ColorNeutralDark : 'white'}
      >
        {label}
      </Text>
      <div
        className={cx(
          'search-input-wrap',
          css`
            display: flex;
            background-color: white;
            border: 1px solid ${ColorNeutralLighter};
            border-radius: ${px2rem(8)};
            padding: ${px2grid(7)} 0;

            ${mediaQuery('desktop')} {
              padding: ${px2grid(5)} 0;
              margin-top: ${getSpaceSize('small')};
            }
          `
        )}
      >
        <Input
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Palavra-chave"
          className={cx(
            'search-input',
            css`
              border: none;
              padding: 0 ${px2grid(10)};
              height: ${px2grid(30)};
              align-self: center;
              border-radius: 0;
              box-shadow: none;

              ${mediaQuery('mobile')} {
                min-width: ${px2grid(130)};
              }
            `
          )}
          onKeyDown={(event) =>
            event.key === 'Enter' && onSearch && onSearch(search)
          }
        />

        {isDesktop ? (
          <OutlinedButton
            className={cx(
              'search-button',
              css`
                margin-right: ${px2grid(8)};
                border: 1px solid ${ColorNeutralLighter};
              `
            )}
            size="micro"
            onClick={() => !!onSearch && onSearch(search)}
          >
            {'Pesquisar'}
          </OutlinedButton>
        ) : (
          <IconButton
            className={cx(
              'search-button-icon',
              css`
                margin-right: ${px2grid(15)};
              `
            )}
            onClick={() => !!onSearch && onSearch(search)}
            icon="Search"
          />
        )}
      </div>
    </div>
  );
};

SearchInputList.propTypes = {
  onSearch: func,
  label: string,
};

export default SearchInputList;
