import { useEffect, useState } from 'react';
import { formatMonthsInYearlyString } from '@ampli/utils';

function useCourseTime(duration) {
  const [courseTime, setCourseTime] = useState('');

  useEffect(() => {
    setCourseTime(formatMonthsInYearlyString(duration));
  }, [duration]);

  return { courseTime };
}

export default useCourseTime;
