import React from 'react';

import {
  css,
  cx,
  px2grid,
  px2rem,
  mediaQuery,
  getColor,
  fontSize,
  Text,
  Flex,
} from '@ampli/ui';
import { string, func, node } from '@ampli/utils';

const Step = (props) => {
  return (
    <Flex direction="column" alignItems="center" justifyContent="flex-start">
      <div
        {...props}
        className={cx(
          'step',
          css`
            white-space: normal;
            text-align: center;
            background-color: transparent;
            position: relative;
            width: fit-content;
            height: ${px2grid(345)};

            ${mediaQuery('desktop')} {
              padding: 0;
              width: auto;
              height: ${px2grid(352)};
            }
          `,
          props.className
        )}
      />
    </Flex>
  );
};
Step.propTypes = {
  className: string,
};

const Title = (props) => (
  <Text
    {...props}
    as="h3"
    className={cx(
      'step-title',
      css`
        color ${getColor('neutral-darker')};
        margin: 0 0 ${px2grid(16)} 0;
        max-width: ${px2grid(500)};  /* fallback for browser support */
        max-width: 20ch;
        width: 100%;
        display: inline-grid;

        ${mediaQuery('desktop')} {
          ${fontSize('small')}
        }
      `,
      props.className
    )}
  />
);
Title.propTypes = {
  className: string,
};

const Description = (props) => (
  <Text
    {...props}
    as="h3"
    className={cx(
      'step-description',
      css`
        color ${getColor('neutral-dark')};
        width: 100%; /* fallback for browser support */
        width: 20ch;
        max-width: ${px2grid(500)};
        margin: 0 0 ${px2grid(8)} 0;
        display: inline-grid;

        & > a {
          color: ${getColor('neutral-dark')};
          text-decoration: underline;
        }

        ${mediaQuery('desktop')} {
          ${fontSize('small')}
        }
      `,
      props.className
    )}
  />
);
Description.propTypes = {
  className: string,
};

const IllustrationWrapper = ({ Illustration, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'illustration-wrapper',
        css`
          width: ${px2rem(155)};
          height: ${px2rem(158)};
          margin: 0 auto;

          svg {
            width: 100%;
            height: 100%;
          }
        `,
        props.className
      )}
    >
      <Illustration />
    </div>
  );
};
IllustrationWrapper.propTypes = {
  Illustration: func,
  className: string,
};

const StepAction = ({ children, ...props }) => {
  return (
    <Flex justifyContent="center">
      <div
        {...props}
        className={cx(
          'step-wrap-title',
          css`
            position: absolute;
            bottom: ${px2grid(16)};
            width: 100%;
            left: 0;
            height: ${px2grid(42)};
          `,
          props.className
        )}
      >
        <Text
          {...props}
          className={cx(
            'step-action',
            css`
              color: ${getColor('brand-dark')};
              ${fontSize('small')}
              font-weight: bold;
              text-decoration: underline;
              cursor: pointer;
              display: inline-grid;
              padding: 0;

              ${mediaQuery('desktop')} {
                margin-bottom: ${px2grid(8)};
              }
            `,
            props.className
          )}
        >
          {children}
        </Text>
      </div>
    </Flex>
  );
};
StepAction.propTypes = {
  className: string,
  children: node.isRequired,
};

const StepCard = ({
  title,
  description,
  dialogText,
  onActionClick,
  Illustration,
}) => (
  <Step>
    <IllustrationWrapper Illustration={Illustration} />
    <Title>{title}</Title>
    <Description>{description}</Description>
    <StepAction onClick={onActionClick}>{dialogText}</StepAction>
  </Step>
);

StepCard.propTypes = {
  title: string,
  description: string,
  dialogText: string,
  onActionClick: func,
  Illustration: func,
};

StepCard.displayName = 'StepCard';

export default StepCard;
