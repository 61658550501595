import React from 'react';

import {
  cx,
  css,
  px2grid,
  Text,
  ColorNeutralBackground,
  ColorNeutralLighter,
  paddingSize,
  getSpaceSize,
} from '@ampli/ui';
import { string } from '@ampli/utils';

const Separator = () => (
  <div
    className={cx(
      'alert-separator',
      css`
        width: 80%;
        border-top: 1px solid ${ColorNeutralLighter};
        margin-top: ${getSpaceSize('x-small')};
        margin-bottom: ${getSpaceSize('x-small')};
      `
    )}
  />
);

const UpcomingAlert = ({ alertText, ...props }) => (
  <div
    className={cx(
      'wrap-alert',
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: ${getSpaceSize('large')};
      `
    )}
  >
    <Separator />
    <div
      className={cx(
        'alert-text-wrap',
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: ${ColorNeutralBackground};
          width: 100%;
          text-align: center;
        `,
        paddingSize()
      )}
      {...props}
    >
      <Text>{alertText}</Text>
    </div>
    <Separator />
  </div>
);

UpcomingAlert.propTypes = {
  alertText: string,
  className: string,
};

export default UpcomingAlert;
