import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  cx,
  css,
  px2grid,
  getSpaceSize,
  marginSize,
  Text,
  Icon,
  PrimaryButton,
  GradientLinearWarning,
  mediaQuery,
} from '@ampli/ui';
import { number, string } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';
import CountStudentsIllustration from './count-student-illustration';
import CountStudentsTitle from './count-students-title';

export const MIN_WIDTH_DESKTOP = 920;

const CountStudents = ({ count, redirectURL, className, ...props }) => {
  const history = useHistory();
  const breakpoint = useBreakpoint(true);
  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width >= MIN_WIDTH_DESKTOP,
    [breakpoint]
  );

  return (
    <div
      {...props}
      className={cx(
        'count-students-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: ${px2grid(30)};

          ${mediaQuery('mobile')} {
            padding: 0 ${px2grid(20)};
          }
        `,
        className
      )}
    >
      <CountStudentsTitle count={count} />
      <div
        className={cx(
          'count-students-description-wrap',
          css`
            display: flex;
            margin-top: ${px2grid(20)};
          `
        )}
      >
        {isDesktop && (
          <CountStudentsIllustration
            className={cx(
              'count-students-illustration',
              css`
                width: ${px2grid(230)};
                height: ${px2grid(220)};
                margin-right: ${px2grid(40)};
              `
            )}
          />
        )}

        <div
          className={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: ${px2grid(320)};

            ${mediaQuery('desktop')} {
              max-width: ${px2grid(465)};
              align-items: flex-start;
            }
          `}
        >
          <Text as="p">
            Existimos para possibilitar que qualquer pessoa que deseja fazer um
            curso de graduação ou pós-graduação tenha essa oportunidade. Por
            isso, somos um centro de educação com nota máxima no MEC e
            desenvolvemos cursos que apoiam no desenvolvimento pessoal,
            educacional e profissional de cada pessoa.
          </Text>
          <PrimaryButton
            as="a"
            href={redirectURL}
            className={cx(
              'banner-button',
              css`
                margin-top: ${getSpaceSize('small')};
                background: ${GradientLinearWarning};
                width: fit-content;
              `
            )}
          >
            <div
              className={css`
                padding: 0 ${getSpaceSize('small')};
                svg {
                  width: ${px2grid(25)};
                  height: ${px2grid(25)};
                }
              `}
            >
              <Text color="white" bold>
                {'Conheça nossos cursos'}
              </Text>
              <Icon.ArrowRight
                className={cx(
                  'count-students-icon-arrow-right',
                  marginSize('x-small', 'left')
                )}
                color="white"
              />
            </div>
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

CountStudents.propTypes = {
  count: number,
  redirectURL: string.isRequired,
  className: string,
};

export default CountStudents;
