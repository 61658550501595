import React, { useState } from 'react';

import {
  css,
  cx,
  px2grid,
  px2rem,
  mediaQuery,
  getColor,
  Flex,
  Input as _Input,
  Button as _Button,
  Text,
} from '@ampli/ui';
import { string, node, func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

const Container = ({ children, className, ...props }) => (
  <Flex alignItems="center" justifyContent="center" direction="row">
    <form
      {...props}
      className={cx(
        'button-input-container',
        css`
          border: ${px2rem(1)} solid ${getColor('neutral-lighter')};
          width: 100%;
          border-radius: ${px2rem(8)};
          padding: ${px2grid(16)};
          background: transparent;
          max-height: ${px2grid(56)};
        `,
        className
      )}
    >
      {children}
    </form>
  </Flex>
);

Container.propTypes = {
  children: node.isRequired,
  className: string,
};

const Input = ({ onChange, onSubmit, value, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  return (
    <_Input
      {...props}
      onChange={onChange}
      className={css`
        border: none;
        background-color: transparent;
        box-shadow: none;
        font-size: ${px2rem(14)};
        padding: 0;
        height: 100%;

        ${mediaQuery('desktop')} {
          font-size: ${px2rem(16)};
        }
      `}
      placeholder={
        isDesktop
          ? 'Digite uma palavra-chave, nome do curso ou área de atuação'
          : 'Digite uma palavra-chave'
      }
      onKeyDown={(event) =>
        event.key === 'Enter' && onSubmit && onSubmit(value)
      }
    />
  );
};

Input.propTypes = {
  onChange: func,
  onSubmit: func,
  value: string,
};

const Button = ({ onClick, children, ...props }) => (
  <_Button
    {...props}
    className={css`
      background: ${getColor('gradient-linear-brand-alt')};
      color: ${getColor('text-white')};
      margin-left: ${px2grid(16)};
    `}
    size="micro"
    onClick={onClick}
  >
    <Text color="white" size="base">
      {children}
    </Text>
  </_Button>
);

Button.propTypes = {
  children: node.isRequired,
  onClick: func,
};

const ButtonInput = ({ onSearchClick, ...props }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const search = () => onSearchClick && onSearchClick(searchTerm);

  return (
    <Container {...props}>
      <Input
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        onSubmit={onSearchClick}
      />
      <Button onClick={search}>Pesquisar</Button>
    </Container>
  );
};

ButtonInput.propTypes = {
  onSearchClick: func,
};

export default ButtonInput;
