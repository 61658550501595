import React from 'react';

import {
  css,
  cx,
  px2grid,
  getColor,
  fontSize,
  mediaQuery,
  Flex,
  Text,
} from '@ampli/ui';
import { string, node } from '@ampli/utils';

import IconSelector from '../icon-selector/icon-selector';

const ContentWrapper = ({ className, children, ...props }) => (
  <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
    <div
      {...props}
      className={cx(
        'content-wrapper',
        css`
          margin-bottom: ${px2grid(12)};

          ${mediaQuery('desktop')} {
            margin-bottom: ${px2grid(96)};
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

ContentWrapper.propTypes = {
  className: string,
  children: node.isRequired,
};

const ContentHeader = ({ className, children, ...props }) => (
  <Flex alignItems="center" justifyContent="flex-start" direction="row">
    <div
      {...props}
      className={cx(
        'content-header',
        css`
          margin-bottom: ${px2grid(12)};

          ${mediaQuery('desktop')} {
            margin-bottom: ${px2grid(24)};
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

ContentHeader.propTypes = {
  className: string,
  children: node.isRequired,
};

const HeaderIcon = ({ icon, className, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'header-icon',
        css`
          width: ${px2grid(24)};
          height: ${px2grid(24)};
        `,
        className
      )}
    >
      <IconSelector icon={icon} color="brand-dark" />
    </div>
  );
};
HeaderIcon.propTypes = {
  icon: string,
  className: string,
};

const Title = ({ children, className, ...props }) => (
  <Text
    {...props}
    as="h2"
    color="brand-dark"
    size="base"
    className={cx(
      'title',
      css`
        font-weight: 400;
        margin-left: ${px2grid(12)};

        ${mediaQuery('desktop')} {
          ${fontSize('x-large')}
          margin-left: ${px2grid(24)};
        }
      `,
      className
    )}
  >
    {children}
  </Text>
);

Title.propTypes = {
  className: string,
  children: node.isRequired,
};

const ContentBody = ({ className, children, ...props }) => (
  <div
    {...props}
    className={cx(
      'content-body',
      css`
        color: ${getColor('neutral-dark')};
        ${fontSize('small')}
        font-weight: 400;
        white-space: pre-line;
        width: 100%;

        ${mediaQuery('desktop')} {
          ${fontSize('base')}
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

ContentBody.propTypes = {
  className: string,
  children: node.isRequired,
};

const CourseContent = ({ icon, title, children, ...props }) => (
  <ContentWrapper {...props}>
    <ContentHeader>
      <HeaderIcon icon={icon} />
      <Title>{title}</Title>
    </ContentHeader>
    <ContentBody>{children}</ContentBody>
  </ContentWrapper>
);

CourseContent.propTypes = {
  icon: string,
  title: string,
  children: node.isRequired,
};

CourseContent.displayName = 'CourseContent';

export default CourseContent;
