import * as React from 'react';

function SvgParaQuemGostaDeGerir(props) {
  return (
    <svg
      width="100%"
      viewBox="0 -10 110 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.165 12.65s18.807 11.753 19.8 26.21c.993 14.455-18.874 3.834-21.026-3.582-2.153-7.419 1.226-22.629 1.226-22.629z"
        fill="#AD263D"
      />
      <path
        d="M55.887 24.996c-5.829-7.407-13.723-12.344-13.723-12.344s-3.376 15.21-1.224 22.626c.152.526.403 1.066.718 1.615 6.393-1.943 12.188-9.134 14.229-11.897z"
        fill="#FCC"
      />
      <path
        d="M42.24 117.726c.895-1.344 1.144-2.825 1.253-4.042h4.422s-.302 1.545.443 2.752c.324.527 1.273.918 2.582 1.479 2.07 1.082 4.627 2.479 4.715 3.199.147 1.2-12.05 1.164-14.077 0-1.06-.609-.32-1.916.662-3.388zM23.51 117.726c.895-1.344 1.144-2.825 1.253-4.042h4.422s-.302 1.545.443 2.752c.324.527 1.273.918 2.582 1.479 2.07 1.082 4.627 2.479 4.715 3.199.147 1.2-12.05 1.164-14.077 0-1.06-.609-.318-1.916.663-3.388z"
        fill="#311E69"
      />
      <path
        d="M52.238 78.008c-1.302-16.837-2.886-31.092-5.518-36.48-1.118-3.031-24.454 5.146-24.87 6.33-4.104 11.684.351 31.182-.528 38.07-1.569 12.268 0 23.774 1.009 29.21.577 3.105 6.903 1.943 8.46 1.011.094-.057 5.775-51.078 5.775-51.078s2.878 12.244 3.303 18.33c.353 5.06-.526 24.872 1.74 31.974.49 1.533 4.975 1.05 6.225 1.043 3.162-.016 4.89-32.138 4.404-38.41z"
        fill="#614CD4"
      />
      <path
        d="M17.769 17.281c1.588-3.465 4.978-5.292 8.684-6.121l.004.013c5.294-1.437 11.503-.663 14.097.397 4.727 1.934 6.826 12.651 7.862 22.582.345 3.306 1.7 6.516 1.495 9.298-.123 1.647-.35 3.142-.687 4.409.186.483-4.278 5.128-10.604 6.017C29.467 55.16 22 56.22 20.371 50.677c-.141-.471-3.806-30.767-2.602-33.396z"
        fill="#FFE6E6"
      />
      <path
        d="M49.221 47.86c.338-1.266.564-2.76.687-4.408.208-2.782-1.148-5.992-1.494-9.298-1.036-9.93-3.135-20.65-7.863-22.583-1.544-.63-4.372-1.157-7.523-1.18 1.55 2.832 4.527 8.633 5.55 13.171 1.047 4.641 1.727 21.549 2.006 29.903 5.26-1.435 8.803-5.174 8.637-5.604z"
        fill="#FCC"
      />
      <path
        d="M35.217 4.767s1.228-1.365.535-2.673C35.06.786 32.942-.663 31.496.333c-1.445.996-1.224 2.217-1.224 2.217s-1.027.433-1.21 1.64c-.184 1.208.454 3.944.454 3.944s5.182-1.342 5.701-3.367z"
        fill="#311E69"
      />
      <path
        d="M30.304 6.261l-2.406 5.732c-.07.83 4.135 3.472 5.5 2.525.4-.278.356-1.08.067-1.969l-1.75-4.853-1.411-1.435z"
        fill="#FF6161"
      />
      <path
        d="M32.062 8.672c-.884-.233-2.092-.565-2.092-.565s-.031 1.46 1.134 2.654c.618.631 1.37.97 2.099 1.08l-1.141-3.17z"
        fill="#311E69"
      />
      <path
        d="M31.612 4.411l-1.501 3.105c-.414 1.062.074 2.272 1.105 2.723.826.36 1.38.442 2.498.465.93.02 1.523-.807 1.693-2.026.222-1.583-.018-3.105-.19-3.912-.394-1.84-2.48-2.305-3.605-.355z"
        fill="#FF6161"
      />
      <path
        d="M33.197 7.06s.73.301 1.817.322c0 0-.07.713-.526.897-.456.185-1.157-.334-1.29-1.218z"
        fill="#fff"
      />
      <path
        d="M34.166 9.533c-.994-.358-1.992 1.107-3.6-.65-.3-.328-.386-1.023-.421-1.435l-.034.068c-.414 1.062.074 2.272 1.105 2.723.826.36 1.38.442 2.498.465.568.011 1.009-.292 1.306-.807-.188.002-.55-.255-.855-.364z"
        fill="#AD263D"
      />
      <path
        d="M31.517 5.74a3.075 3.075 0 01.03-.641c.057-.403.048-1.016-.58-.806-.916.305-1.2 2.264-.245 2.57.712.227.833-.584.794-1.124z"
        fill="#FF6161"
      />
      <path
        d="M36.97 49.688c4.507-.394 10.3-3.646 12.842-5.197-.132 1.24-.327 2.374-.59 3.37.185.483-4.279 5.128-10.604 6.017-9.154 1.285-16.62 2.344-18.25-3.2-.033-.113-.273-1.968-.6-4.747 4.769 1.893 11.89 4.222 17.203 3.757z"
        fill="#311E69"
      />
      <path
        d="M52.238 78.008c-1.302-16.837-2.886-31.092-5.518-36.48-.338-.914-2.703-.805-5.846-.178.765 2.9 3.463 7.201 3.418 11.122-.082 7.334.58 5.986 2.484 15.172s.331 16.604.414 22.59c.083 5.985.083 13.148-2.98 16.857-1.497 1.811-2.672 3.032-3.486 3.816.223 1.754.51 3.29.888 4.47.49 1.533 4.976 1.05 6.225 1.044 3.16-.019 4.887-32.14 4.401-38.413z"
        fill="#311E69"
      />
      <path
        d="M29.089 43.632s.564-1.057 1.15-1.447c.586-.39 1.15-1.492 1.354-.895.203.597.022 1.814-.294 2.342-.315.529-.586 1.057-1.465 1.057-.882-.002-.745-1.057-.745-1.057z"
        fill="#FF6161"
      />
      <path
        d="M48.927 15.26v39.646L53.48 26.26s1.076.169 1.2-.63c.125-.805-5.753-10.37-5.753-10.37z"
        fill="#2E88BF"
      />
      <path
        d="M45.905 16.602l-.823 11.41 7.25-2.606-6.427-8.804z"
        fill="#FFE6E6"
      />
      <path
        d="M32.349 24.531s2.289-.631 2.759-2.324L50.5 24.171l-4.594-7.57 3.229-.121s4.931 8.56 4.967 9.145c.036.585-1.116 1.305-1.862 1.39-.747.086-19.891-2.484-19.891-2.484z"
        fill="#fff"
      />
      <path
        d="M30.847 55.505l.467-31.608 22.165 2.36-.398 29.712-22.234-.465z"
        fill="#4CB4D4"
      />
      <path
        d="M53.48 26.258l-.399 29.711c1.468-.08 1.598-.883 1.598-.883v-29.46c-.123.8-1.2.632-1.2.632z"
        fill="#4CB4D4"
      />
      <path
        d="M22.66 14.782C18.285 10.448 3.997 28.087 1.144 36.688c-4.61 13.904 5.347 18.965 18.509 15.55 11.357-2.946 15.577-9.291 5.582-10.43-8.88-1.012-14.593-1.518-14.593-1.518S27.446 19.523 22.66 14.782z"
        fill="#FF6161"
      />
      <path
        d="M29.474 45.77c-2.98.926-6 2.022-10.595 2.865-4.593.843-10.968.884-12.912-.72-1.945-1.603-1.614-1.344-3.766-3.228C1.234 43.84.594 42.22.18 40.693-1.376 51.538 7.845 55.302 19.653 52.24c6.379-1.654 10.507-4.382 11.127-6.658a4.033 4.033 0 00-1.307.187z"
        fill="#AD263D"
      />
      <path
        d="M29.088 43.632s2.645-.045 3.506-.597c.86-.55 1.663-1.378 1.683-.781.02.597-.555 1.217-.555 1.217s.517.46.21 1.47c-.306 1.008-.648 1.63-1.069 2.066-.329.341-4.242 1.537-4.435.483-.192-1.058-.22-3.582.66-3.858z"
        fill="#FF6161"
      />
      <path
        d="M13.6 38.647c4.33-5.76 12.786-20.176 9.06-23.865-3.586-3.552-13.828 7.655-18.978 16.552 1.058 1.763 4.788 6.589 9.919 7.313z"
        fill="#FFE6E6"
      />
      <path
        d="M18.661 35.763C21.35 31.245 22.8 21.749 22.8 21.749l-4.992 4.281c.21 2.991.521 6.411.854 9.733z"
        fill="#311E69"
      />
      <path
        d="M13.6 38.647c4.33-5.76 12.786-20.176 9.06-23.865-3.586-3.552-13.828 7.655-18.978 16.552 1.058 1.763 4.788 6.589 9.919 7.313z"
        fill="#FFE6E6"
      />
      <path
        d="M8.103 36.19c1.541 1.177 3.4 2.161 5.497 2.46 4.33-5.76 12.786-20.177 9.06-23.866-.078-.077-.163-.139-.248-.203-.31 1.174-1.345 3.685-4.692 8.729-4.001 6.033-8.004 10.054-9.617 12.88z"
        fill="#FCC"
      />
      <path
        d="M50.643 35.082l-15.851-1.803v-2.323l15.85 1.802v2.324zM48.707 38.144l-11.98-1.362v-1.757l11.98 1.362v1.757z"
        fill="#2E88BF"
      />
      <path
        d="M36.566 65.072s-8.046 16.56-7.487 23.895c.56 7.334-.268 17.194-2.173 19.807-1.011 1.388-3.23 2.509-5.095 3.261.177 1.173.354 2.226.517 3.103.578 3.105 6.903 1.943 8.46 1.011.097-.057 5.778-51.078 5.778-51.078z"
        fill="#311E69"
      />
    </svg>
  );
}

export default SvgParaQuemGostaDeGerir;
