import React from 'react';

import {
  cx,
  css,
  px2grid,
  fontSize,
  Text,
  paddingSize,
  GradientLinearWarning,
  PrimaryButton,
  marginSize,
  boxShadow,
} from '@ampli/ui';
import { string, func } from '@ampli/utils';

import BannerWrap from './banner-wrap';
import Clock from './clock';
import Hourglass from './hourglass';

const VacationBanner = ({ onProsClick, ...props }) => (
  <BannerWrap {...props}>
    <Hourglass
      className={cx(
        'hourglass-illustration',
        css`
          position: relative;
          right: ${px2grid(50)};
          z-index: 5;
        `
      )}
    />

    <div
      className={cx(
        'vacation-wrap-text',
        css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: ${px2grid(700)};
          z-index: 5;
        `,
        paddingSize('large')
      )}
    >
      <Text
        className={cx(
          'title-vacation',
          css`
            font-size: ${px2grid(80)};
            line-height: 0.7;
            font-weight: 200;
          `
        )}
        color="white"
      >
        {'Estude sem pausas'}
      </Text>

      <Text
        className={cx(
          'subtitle-vacation',
          css`
            font-size: ${px2grid(45)};
          `
        )}
        color="white"
      >
        {'e se forme mais rápido '}
      </Text>
      <div
        className={cx(
          'vacation-text-separator',
          marginSize('medium', 'bottom')
        )}
      />

      <Text color="white">
        Aqui na Ampli, é possível concluir uma graduação a partir de 1 ano e
        meio dependendo do curso escolhido, pois não temos pausa para férias.
      </Text>
      <Text color="white">
        Dessa forma, a quantidade de semestres do curso diminui. A carga horária
        total não é afetada, sendo a mesma duração exigida pelo MEC.
      </Text>

      <div
        className={cx('vacation-text-separator', marginSize('small', 'bottom'))}
      />

      <Text color="white" as="strong">
        Ah! Caso queira tirar um período de férias, basta efetuar o trancamento
        do curso, sem custos. 
      </Text>

      <div
        className={cx(
          'vacation-text-separator',
          marginSize('x-large', 'bottom')
        )}
      />

      {!!onProsClick && (
        <PrimaryButton
          onClick={onProsClick}
          className={cx(
            'button-pros',
            css`
              align-self: center;
              width: ${px2grid(250)};
              background: ${GradientLinearWarning};
            `,
            fontSize('large'),
            boxShadow('card')
          )}
        >
          {'Nossos diferenciais'}
        </PrimaryButton>
      )}
    </div>

    <Clock
      className={cx(
        'clock-illustration',
        css`
          position: relative;
          z-index: 5;
        `
      )}
    />
  </BannerWrap>
);

VacationBanner.propTypes = {
  className: string,
  onProsClick: func,
};

export default VacationBanner;
