import React from 'react';
import { useHistory } from 'react-router-dom';

import { css, cx, Logo, px2grid } from '@ampli/ui';
import { string } from '@ampli/utils';

const AmpliLogo = (props) => {
  const history = useHistory();
  return (
    <div
      {...props}
      className={cx(
        'logo',
        css`
          align-self: center;
          cursor: pointer;
        `,
        props.className
      )}
      onClick={() => history.push('/')}
    >
      <Logo.SimpleColor
        className={cx(
          'logo',
          css`
            padding: ${px2grid(16)} 0;
          `,
          props.className
        )}
      />
    </div>
  );
};

AmpliLogo.propTypes = {
  className: string,
};

export default AmpliLogo;
