import React from 'react';

import {
  cx,
  css,
  Text,
  getSpaceSize,
  px2rem,
  ColorBrandBase,
  mediaQuery,
  ColorNeutralBackground,
  ColorNeutralLighter,
  px2grid,
} from '@ampli/ui';
import { arrayOf, node, object, bool, func } from '@ampli/utils';

const TabItem = ({ tab, selected, onClick }) => (
  <button
    className={cx(
      'tab-item-wrap',
      css`
        margin-right: ${getSpaceSize('small')};
        margin-bottom: ${getSpaceSize('small')};
        border: none;
        background-color: ${ColorNeutralBackground};
        border: ${px2rem(2)} solid ${ColorNeutralLighter};
        border-radius: ${px2grid(8)};
        padding: ${getSpaceSize('xx-small')};
        &.selected {
          ${mediaQuery('desktop')} {
            border-bottom: ${px2rem(2)} solid ${ColorBrandBase};
          }
        }
        ${mediaQuery('desktop')} {
          border: ${px2rem(2)} solid transparent;
          background-color: transparent;
          border-radius: 0;
        }
      `,
      selected && 'selected'
    )}
    onClick={onClick}
  >
    <Text color={selected ? 'brand-base' : 'neutral-dark'}>{tab?.label}</Text>
  </button>
);

TabItem.propTypes = {
  tab: object,
  selected: bool,
  onClick: func,
};

const TabCourse = ({ tabs, children }) => {
  const [selected, setSelected] = React.useState(tabs[0]);
  return (
    <>
      <div
        className={cx(
          'tab-course-wrap',
          css`
            display: flex;
            flex-wrap: wrap;
          `
        )}
      >
        {!!tabs.length &&
          tabs.map((tab, index) => (
            <TabItem
              key={`tab_index_${index}`}
              selected={selected?.label === tab?.label}
              onClick={() => setSelected(tab)}
              tab={tab}
            />
          ))}
      </div>
      {children(selected)}
    </>
  );
};

TabCourse.propTypes = {
  tabs: arrayOf(object),
  children: node,
};

export default TabCourse;
