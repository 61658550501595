import React from 'react';

import {
  cx,
  css,
  Text,
  Icon,
  Disclosure,
  DisclosureContent,
  useDisclosureState,
  getSpaceSize,
  ColorNeutralTitle,
  ColorNeutralDark,
  ColorNeutralBackground,
  px2rem,
} from '@ampli/ui';

import { func, object, node } from '@ampli/utils';

const CollapsibleItem = ({ item, selected, setSelected, children }) => {
  const disclosure = useDisclosureState({ animated: 100 });

  const handleToggle = () => {
    if (disclosure.visible) {
      !!setSelected && setSelected(null);
      disclosure.hide();
      return;
    }
    !!setSelected && setSelected(item);
    disclosure.show();
  };

  const color = React.useMemo(
    () =>
      selected?.label === item?.label ? ColorNeutralTitle : ColorNeutralDark,
    [selected, item]
  );

  React.useEffect(() => {
    if (selected?.label !== item?.label && disclosure.visible)
      disclosure.hide();
  }, [selected]);

  return (
    <>
      <Disclosure
        {...disclosure}
        toggle={handleToggle}
        className={cx(
          'collapsible-wrap',
          css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            cursor: pointer;
            background-color: ${ColorNeutralBackground};
            border-radius: ${px2rem(8)};
            padding: ${getSpaceSize('xx-small')};
            padding-right: ${getSpaceSize('base')};
            padding-left: ${getSpaceSize('base')};
            margin-bottom: ${getSpaceSize('x-small')};
            width: 100%;
            border: none;
          `
        )}
      >
        <Text color={color}>{item?.label}</Text>

        <Icon.ArrowDown
          className={cx(
            'collapsible-icon',
            css`
              transform: rotate(0deg);
              &.visible {
                transform: rotate(180deg);
              }
            `,
            disclosure.visible && 'visible'
          )}
          color={color}
        />
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        className={cx(
          'collapsible-content',
          css`
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: ${getSpaceSize('x-small')};
            padding-bottom: ${getSpaceSize('x-small')};
            transition: opacity 250ms ease-out;
            opacity: 0;
            &[data-enter] {
              transition: opacity 250ms ease-in;
              opacity: 1;
            }
          `
        )}
      >
        {children}
      </DisclosureContent>
    </>
  );
};

CollapsibleItem.propTypes = {
  item: object,
  selected: object,
  setSelected: func,
  children: node,
};

export default CollapsibleItem;
