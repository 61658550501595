import React from 'react';

import { cx, css, px2grid, Text, mediaQuery } from '@ampli/ui';
import { string, object, imageURLFormatter } from '@ampli/utils';

const PartnerLogo = ({ partner, className, ...props }) => {
  const logoImage = imageURLFormatter(partner.logo, 'original');

  return (
    <div
      className={cx(
        'partner-information',
        css`
          display: flex;
          justify-content: flex-start;
          align-items: center;
          align-self: flex-start;
          background-color: white;
          min-height: ${px2grid(91)};
          padding-left: 10%;
          padding-right: ${px2grid(16)};
          margin: ${px2grid(50)} 0;

          ${mediaQuery('tablet-max')} {
            padding: 0;
            margin: 0;
            width: 95%;
            min-height: ${px2grid(113)};
            align-self: flex-start;
            justify-content: center;
            flex-direction: column;
          }

          ${mediaQuery('tablet')} {
            width: 100%;
          }
        `,
        className
      )}
      {...props}
    >
      <Text size="large">Curso oferecido por: </Text>
      {logoImage ? (
        <img
          src={logoImage}
          alt={partner.name || 'logomarca-parceiro'}
          width={150}
          className={cx(
            'partner-logo',
            css`
              margin-top: ${px2grid(8)};

              ${mediaQuery('desktop')} {
                margin: 0 ${px2grid(32)};
              }
            `
          )}
        />
      ) : (
        <Text size="large">{partner.name}</Text>
      )}
    </div>
  );
};

PartnerLogo.propTypes = {
  partner: object,
  className: string,
};

export default PartnerLogo;
