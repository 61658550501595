import React from 'react';

import { css, cx, px2grid, mediaQuery, Logo } from '@ampli/ui';
import { string } from '@ampli/utils';

import CognaLogoHW from './cogna-horizontal-white';
import PitagorasLogoHW from './pitagoras-horizontal-white';

const CognaLogo = (props) => (
  <CognaLogoHW
    {...props}
    className={cx(
      'logo-cogna',
      css`
        align-self: center;
        box-sizing: content-box;
        display: block;
        height: auto;
        margin: 0 auto;
        width: ${px2grid(100)};

        ${mediaQuery('desktop')} {
          margin: 0;
        }
      `,
      props.className
    )}
  />
);
CognaLogo.propTypes = {
  className: string,
};

const AmpliLogo = (props) => (
  <Logo.HorizontalWhite
    {...props}
    className={cx(
      'logo',
      css`
        align-self: center;
        box-sizing: content-box;
        display: block;
        height: auto;
        margin: 0 auto;
        width: ${px2grid(120)};

        ${mediaQuery('desktop')} {
          margin: 0;
        }
      `,
      props.className
    )}
  />
);
AmpliLogo.propTypes = {
  className: string,
};

export { CognaLogo, AmpliLogo };
