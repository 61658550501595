import React from 'react';

import {
  cx,
  css,
  px2grid,
  px2rem,
  getSpaceSize,
  mediaQuery,
  Text,
  Input,
  PrimaryButton,
  ColorNeutralDark,
  GradientLinearBrand,
  ColorNeutralLighter,
  ColorNeutralTitle,
} from '@ampli/ui';
import { createEmailLeadSchema } from '@ampli/services';
import { string, func, bool, useFormik } from '@ampli/utils';

import EmptyStateIllustration from './empty-state-illustration';

const FormInputText = ({ onSubmit, label, placeholder, loading }) => {
  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: { email: '' },
    validationSchema: createEmailLeadSchema(),
    onSubmit: () => onSubmit && onSubmit(values?.email),
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div
        className={cx(
          'form-input-text-wrap',
          css`
            margin-top: ${getSpaceSize('small')};
          `
        )}
      >
        <Text color={ColorNeutralDark}>{label}</Text>
        <div
          className={cx(
            'empty-state-input-wrap',
            css`
              display: flex;
              background-color: white;
              border: 1px solid ${ColorNeutralLighter};
              border-radius: ${px2rem(8)};
              padding: ${px2grid(7)} 0;

              ${mediaQuery('desktop')} {
                padding: ${px2grid(8)} 0;
                margin-top: ${getSpaceSize('xx-small')};
              }
            `
          )}
        >
          <Input
            value={values.email}
            onChange={handleChange('email')}
            placeholder={placeholder}
            className={cx(
              'empty-state-input',
              css`
                border: none;
                padding: 0 ${px2grid(10)};
                height: ${px2grid(30)};
                align-self: center;
                border-radius: 0;
                box-shadow: none;

                ${mediaQuery('mobile')} {
                  min-width: ${px2grid(130)};
                }
              `
            )}
          />

          <PrimaryButton
            className={cx(
              'empty-state-button',
              css`
                margin-right: ${px2grid(8)};
                background: ${GradientLinearBrand};
                border-color: transparent;
                width: ${px2grid(150)};
              `
            )}
            loading={loading}
            size="micro"
            type="submit"
          >
            {'Enviar'}
          </PrimaryButton>
        </div>
      </div>
      {!!errors && <Text color="semantic-error">{errors?.email}</Text>}
    </form>
  );
};

const EmptyState = ({ searchText, onSubmit, leadLoading }) => (
  <>
    <div
      className={cx(
        'empty-state-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          ${mediaQuery('desktop')} {
            flex-direction: row;
            align-items: flex-start;
          }
        `
      )}
    >
      <EmptyStateIllustration
        className={cx(
          'empty-state-illustration',
          css`
            width: ${px2grid(310)};
            height: ${px2grid(170)};
            margin-bottom: ${getSpaceSize('large')};
            ${mediaQuery('desktop')} {
              margin-bottom: 0;
              margin-right: ${getSpaceSize('small')};
            }
          `
        )}
      />

      <div
        className={cx(
          'empty-state-form',
          css`
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
            max-width: ${px2grid(400)};
          `
        )}
      >
        <Text
          color={ColorNeutralTitle}
          size="x-large"
          className={cx(
            'empty-state-text',
            css`
              height: ${px2grid(50)};
            `
          )}
          ellipsis
        >{`Sem resultados para a pesquisa${
          searchText ? ` "${searchText}"` : ''
        }`}</Text>

        <FormInputText
          onSubmit={onSubmit}
          label="Não achou o que queria?"
          placeholder="Deixe aqui o seu e-mail"
          loading={leadLoading}
        />
      </div>
    </div>
    <div
      className={cx(
        'empty-state-space-mobile',
        css`
          margin: ${getSpaceSize('large')} 0;
          border-bottom: 1px solid ${ColorNeutralLighter};
          width: 90%;
          align-self: center;
          ${mediaQuery('desktop')} {
            display: none;
          }
        `
      )}
    />
    <div
      className={cx(
        'empty-state-space',
        css`
          margin: ${getSpaceSize('large')} 0;
          ${mediaQuery('mobile')} {
            display: none;
          }
        `
      )}
    >
      <Text size="x-large">
        {'Mas separamos alguns cursos que você pode gostar'}
      </Text>
    </div>
  </>
);

EmptyState.propTypes = {
  searchText: string,
  onSubmit: func,
  leadLoading: bool,
};

export default EmptyState;
