import * as React from 'react';

function SvgPersonStudyingFromLaptop(props) {
  return (
    <svg
      width={117}
      height={199}
      viewBox="0 0 117 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#PersonStudyingFromLaptop_svg__clip0)">
        <path
          d="M15.964 190.64l.52-1.768-4.918-.486-.63 3.569s-3.169 3.46-2.239 5.073c1.32 2.269 19.843 1.682 20.243-.838.81-4.947-12.976-5.55-12.976-5.55z"
          fill="#311E69"
        />
        <path
          d="M110.632 14.034l-23.252-3.46c-4.029-.602-7.907 1.472-8.677 4.626l-7.378 30.378c-.77 3.154 1.88 6.191 5.908 6.794l23.252 3.46c4.029.602 7.908-1.472 8.677-4.626l7.378-30.378c.76-3.146-1.88-6.191-5.908-6.794z"
          fill="#2A214F"
        />
        <path
          d="M112.971 15.811a4.776 4.776 0 00-.74-.172l-27.08-4.031c-.99-.149-1.99.015-2.82.462-.83.446-1.389 1.111-1.579 1.886l-8.097 33.375c-.33 1.362.62 2.74 2.25 3.272.24.078.489.133.739.172l27.081 4.031c.989.15 1.989-.015 2.819-.461.829-.447 1.389-1.112 1.579-1.887l8.097-33.375c.33-1.37-.609-2.74-2.249-3.272zm.66-1.237c2.339.76 3.769 2.724 3.269 4.76l-8.097 33.375c-.56 2.324-3.429 3.85-6.398 3.404l-27.081-4.03a6.57 6.57 0 01-1.08-.251c-2.349-.76-3.768-2.724-3.278-4.759l8.097-33.375c.56-2.325 3.429-3.851 6.398-3.405l27.09 4.03c.38.056.74.142 1.08.251z"
          fill="#fff"
        />
        <path
          d="M96.727 13.33l12.616 29.962-1.7 7.03-17.914-38.033 6.998 1.04z"
          fill="url(#PersonStudyingFromLaptop_svg__paint0_linear)"
        />
        <path
          d="M30.21 6.77l-1.37 5.05c-.28 1.69 1.2 3.255 3.359 3.521 1.719.212 2.799.126 4.908-.266 1.76-.32 2.489-1.737 2.23-3.561-.33-2.372-1.49-4.485-2.19-5.581-1.6-2.513-5.738-2.403-6.938.837z"
          fill="#FF6161"
        />
        <path
          d="M28.85 11.819l.05-.172c.45 1.095 1.65 1.957 3.189 2.144 1.719.212 2.799.126 4.908-.266 1.41-.258 2.16-1.22 2.27-2.528l.09.509c.249 1.824-.48 3.24-2.23 3.561-2.11.384-3.19.478-4.909.266-2.169-.258-3.648-1.823-3.368-3.514z"
          fill="#AD263D"
        />
        <path
          d="M29.75 10.222l-3.01 8.704c3.03 3.42 11.727.086 10.767-1.088l-5.568-6.356-2.19-1.26z"
          fill="#FF6161"
        />
        <path
          d="M30.98 14.379s-2.92.25-4.07-1.879c-1.15-2.121-2.809-5.534-2.119-7.13.62-1.44 2.16-4.486 5.738-5.104C42.495-1.8 37.957 8.68 37.027 9.244c-.92.556-2.2 4.9-6.048 5.135z"
          fill="#311E69"
        />
        <path
          d="M34.418.054c.7 1.636 1.9 4.986.53 6.02-1.41 1.064-7.098 2.402-10.227 1.134.32 1.683 1.38 3.804 2.18 5.291 1.15 2.122 4.068 1.88 4.068 1.88 3.849-.228 5.128-4.58 6.058-5.136.81-.493 4.338-8.469-2.61-9.189z"
          fill="#1E1240"
        />
        <path
          d="M37.177 9.706s-.14-1.871-1.19-1.175c-1.05.69-.14 2.912.44 3.045.57.125.8-.94.75-1.87z"
          fill="#FF6161"
        />
        <path
          d="M59.19 190.852c.13-.415-.27-.814-.81-.814l-6.548-.016c-.62 0-1.03.509-.76.947 0 0-2.19 5.644-1.05 6.692 1.88 1.738 18.154 1.73 22.403.306.37-.126.58-.446.48-.76-1.28-4.226-10.057-5.306-12.996-5.549-.51-.039-.85-.423-.72-.806z"
          fill="#311E69"
        />
        <path
          d="M62.518 72.26s-29.35-1.972-29.34-2.848c-11.226 0-14.405-1.824-18.853-2.122-.49 1.331-3.36 2.192-4.609 10.497C7.956 89.497 6.187 162.68.04 189.818c-.87 3.836 11.866 4.939 19.063 1.511 12.996-6.184 17.844-67.189 18.694-85.309 0 0 11.406 16.077 13.275 28.828 2.1 14.323-7.047 43.676-10.946 55.056-.93 2.709 3.539 4.024 14.575 2.943a33.232 33.232 0 005.438-.994c2.46-.665 4.298-2.27 4.958-4.242 3.29-9.862 13.996-42.878 13.996-55.151.01-19.983-16.575-60.2-16.575-60.2z"
          fill="#614CD4"
        />
        <path
          d="M66.047 81.356c.72 1.918 1.5 4.07 2.32 6.387-5.009 3.327-15.605 7.867-32.87 3.57-20.552-5.104 15.115-12.485 30.55-9.957z"
          fill="#311E69"
        />
        <path
          d="M20.313 42.204c-6.098-3.835-2.32-11.56 5.488-11.177 6.538.329 12.926.313 16.384-.587 9.887-2.567 35.058-11.06 38.167-6.966C86.74 31.88 58.53 45.64 41.306 48.224c-7.138 1.072-14.645-2.028-20.993-6.02z"
          fill="#D95F76"
        />
        <path
          d="M.05 189.811C6.187 162.673 7.967 89.489 9.726 77.779c.21-1.37.46-2.536.73-3.538 6.018 1.933 12.026 5.111 12.466 10.176.91 10.347-.65 17.572 2.279 18.675 8.517 3.217 5.018 3.272 2.28 12.195-2.74 8.923-9.468 28.03-8.718 40.866.6 10.325-.03 28.938-6.918 37.055-6.227.657-12.415-.681-11.795-3.397z"
          fill="#311E69"
        />
        <path
          d="M64.927 86.021c1.63-1.025 2.26-2.833 1.58-4.469-9.447-22.848 6.428-46.11-.67-53.366-15.285-15.631-45.194-12.054-53.182-4.58C2.26 33.337.66 59.645 7.017 83.807c.45 1.73 1.62 3.272 3.29 4.329 12.235 7.717 43.654 4.79 54.62-2.114z"
          fill="#FF8F8F"
        />
        <path
          d="M64.438 74.946c-3.729-1.777-8.547-5.855-6.658-14.903.91-4.344 3.559-5.503 6.408-4.728-.9 6.152-1.24 12.837.25 19.63z"
          fill="#311E69"
        />
        <path
          d="M55.21 24.984c-4.298 4.571-2.598 30.268 3.46 41.352 7.907 14.488 41.116 1.393 50.702-15.608 9.587-17-32.149 3.444-32.149 3.444s-2.229-15.6-8.477-24.366c-4.458-6.239-11.466-7.029-13.535-4.822z"
          fill="#FF8F8F"
        />
        <path
          d="M109.372 50.728c.35-.618.62-1.174.84-1.698-1.079-.767-2.649-1.151-4.638-1.542-4.869-.955-21.663 7.976-28.2 10.715-6.538 2.74-9.297 11.185-11.377 10.927-2.069-.258-1.38-2.16-2.499-6.332-.82-3.045-7.157-14.645-10.516-20.68.55 8.665 2.529 18.418 5.688 24.202 7.907 14.504 41.106 1.409 50.702-15.592z"
          fill="#D95F76"
        />
        <path
          d="M77.673 22.636s2.49-3.874 4.599-5.04c1.51-.83 5.188-1.98 5.768-1.535 1.36 1.041-.59 1.77-1.3 2.075-.92.391-1.67.962-2.11 1.55-.439.579-2.018 2.363-.599 2.019 1.42-.345 1.56-2.685 2.949-1.95 1.39.737-1.51 4.235-3.369 5.895-1.87 1.66-7.097-.305-5.938-3.014z"
          fill="#FF6161"
        />
        <path
          d="M55.58 104.048c5.779-2.497 15.175 8.445 12.147 13.799-3.03 5.354-5.459 17.009-3.03 24.742 2.43 7.733-.15 16.179-5.318 26.526-4.068 8.149-4.178 18.582-6.337 23.866-9.797.79-13.776-.525-12.896-3.084 3.899-11.381 13.045-40.733 10.946-55.057-1.87-12.743-13.275-28.828-13.275-28.828s11.996.532 17.764-1.964z"
          fill="#311E69"
        />
        <path
          d="M12.656 23.615c2.579-2.41 7.447-4.415 13.365-5.503-2.13 3.076-4.618 8.579-5.988 18.253-2.739 19.271 6.068 32.945 9.877 37.884 2.419 3.147 8.107 12 11.916 18.019-11.886 1.166-24.682.172-31.51-4.133-1.669-1.057-2.839-2.599-3.288-4.329-6.368-24.154-4.759-50.47 5.628-60.191z"
          fill="#D95F76"
        />
        <path
          d="M103.534 45.875s1.08-2.019 2.06-3.28c1.239-1.596 1.989-2.042 2.479-2.504.949-.892 1.679-.65 2.059-.485.38.172.62.79.62.79s1.419-1.69 2.289-1.4c.87.281 2.699 2.567 2.059 4.617-.629 2.059-6.627 5.08-8.067 4.634-1.449-.438-3.499-2.372-3.499-2.372z"
          fill="#FF6161"
        />
      </g>
      <defs>
        <linearGradient
          id="PersonStudyingFromLaptop_svg__paint0_linear"
          x1={82.733}
          y1={25.515}
          x2={112.445}
          y2={41.243}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" />
          <stop offset={0.093} stopColor="#6F6F6F" />
          <stop offset={0.388} stopColor="#404040" />
          <stop offset={0.647} stopColor="#1D1D1D" />
          <stop offset={0.861} stopColor="#080808" />
          <stop offset={1} />
        </linearGradient>
        <clipPath id="PersonStudyingFromLaptop_svg__clip0">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 117 0)"
            d="M0 0h117v199H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgPersonStudyingFromLaptop;
