import React from 'react';

import { cx, css, px2grid, Text, getSpaceSize, mediaQuery } from '@ampli/ui';

import BannerWrap from './banner-wrap';
import Hourglass from './hourglass-mobile';
import Clock from './clock-mobile';

const VacationBannerMobile = () => (
  <BannerWrap>
    <div
      className={cx(
        'vacation-banner-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 85%;
          margin: 0 auto;
          padding: ${px2grid(16)};

          ${mediaQuery('tablet-min')} {
            max-width: ${px2grid(500)};
          }
        `
      )}
    >
      <div
        className={cx(
          'header-vacation',
          css`
            display: flex;
            width: 100%;
            margin-bottom: ${px2grid(20)};
            justify-content: space-between;
          `
        )}
      >
        <div
          className={cx(
            'hourglass-illustration',
            css`
              flex: 0.45;
              height: ${px2grid(180)};
            `
          )}
        >
          <Hourglass
            className={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
        <div
          className={cx(
            'header-vacation-text-wrap',
            css`
              flex: 0.55;
              display: flex;
              flex-direction: column;
              align-self: center;
            `
          )}
        >
          <Text
            className={cx(
              'title-vacation',
              css`
                font-size: ${px2grid(36)};
                line-height: 100%;
                font-weight: 200;
                text-align: center;
                margin-bottom: ${px2grid(6)};
              `
            )}
            color="white"
          >
            {'Estude sem pausas'}
          </Text>

          <Text
            className={css`
              text-align: center;
            `}
            color="white"
            size="xxx-large"
          >
            {'e se forme mais rápido '}
          </Text>
        </div>
      </div>

      <div
        className={cx(
          'description-vacation-wrap',
          css`
            display: flex;
            flex-direction: column;
            max-width: ${px2grid(600)};
          `
        )}
      >
        <Text color="white">
          Aqui na Ampli, é possível concluir uma graduação a partir de 1 ano e
          meio dependendo do curso escolhido, pois não temos pausa para férias.
        </Text>
        <Text color="white">
          Dessa forma, a quantidade de semestres do curso diminui. A carga
          horária total não é afetada, sendo a mesma duração exigida pelo MEC.
        </Text>
      </div>

      <div
        className={cx(
          'vacation-footer-text',
          css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: ${getSpaceSize('base')};
          `
        )}
      >
        <Text
          color="white"
          className={css`
            width: 90%;
            font-weight: bold;
          `}
        >
          Ah! Caso queira tirar um período de férias, basta efetuar o
          trancamento do curso, sem custos. 
        </Text>
        <div
          className={cx(
            'clock-illustration',
            css`
              width: ${px2grid(180)};
              height: ${px2grid(145)};
              margin-left: ${px2grid(16)};
            `
          )}
        >
          <Clock
            className={css`
              width: 100%;
              height: 100%;
            `}
          />
        </div>
      </div>
    </div>
  </BannerWrap>
);

export default VacationBannerMobile;
