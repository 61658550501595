import React from 'react';

import {
  cx,
  css,
  TextButton,
  Button,
  marginSize,
  getSpaceSize,
  GradientLinearBrand,
  mediaQuery,
  px2grid,
} from '@ampli/ui';
import {
  arrayOf,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  string,
  shape,
} from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import GroupButtons from './group-buttons';
import CheckboxList from './checkbox-list';

const loadFilters = (props) => {
  switch (props.type) {
    case 'groupButtons':
      return <GroupButtons {...props} />;
    default:
      return <CheckboxList {...props} />;
  }
};

const CourseListFilter = ({
  filters,
  values,
  onChange,
  clearFilters,
  onSubmit,
}) => {
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  return (
    <div
      className={cx(
        'filters-container',
        css`
          width: 100%;
          ${mediaQuery('desktop')} {
            width: ${px2grid(290)};
            padding: 0 ${getSpaceSize('base')};
          }
        `
      )}
    >
      {!!filters?.length &&
        filters.map((filter, index) => (
          <div key={`filter_${index}`}>
            {loadFilters({
              ...filter,
              onChange,
              value: values[filter?.name] || filter?.initialValue,
            })}
            <div
              className={cx('filter-separator', marginSize('base', 'bottom'))}
            />
          </div>
        ))}
      {!isDesktop && (
        <div
          className={cx(
            'footer-filter',
            css`
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: ${getSpaceSize('small')};
            `
          )}
        >
          <TextButton onClick={clearFilters} underline>
            {'Limpar'}
          </TextButton>

          <Button
            className={cx(
              'submit-filter',
              css`
                background: ${GradientLinearBrand};
                color: white;
                padding: ${getSpaceSize('xx-small')} ${getSpaceSize('xx-large')};
              `
            )}
            onClick={onSubmit}
          >
            {'Salvar'}
          </Button>
        </div>
      )}
    </div>
  );
};

CourseListFilter.propTypes = {
  filters: arrayOf(
    shape({
      key: string,
      label: string,
      extraInfo: string,
      type: oneOf(['groupButtons', 'checkbox', 'inputText']),
      placeholder: string,
      items: arrayOf(object),
      initialValue: oneOfType([string, number]),
    })
  ),
  values: object,
  onChange: func,
  clearFilters: func,
  onSubmit: func,
};

export default CourseListFilter;
