import React, { forwardRef } from 'react';
import ButtonInput from './button-input';

import {
  css,
  cx,
  px2grid,
  px2rem,
  mediaQuery,
  fontSize,
  getColor,
  boxShadow,
  Flex,
} from '@ampli/ui';
import { string, node, func } from '@ampli/utils';

const BoxContainer = forwardRef(({ children, className, ...props }, ref) => (
  <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
    <div
      {...props}
      ref={ref}
      className={cx(
        'box-container',
        css`
          background-color: ${getColor('white')};
          padding: ${px2grid(24)};
          margin: 0 ${px2grid(14)};
          width: calc(100% - ${px2grid(28)});
          height: ${px2rem(132)};
          border-radius: ${px2rem(8)};
          ${boxShadow('highlight')}

          ${mediaQuery('desktop')} {
            height: ${px2rem(136)};
            max-width: ${px2grid(1000)};
            margin-right: auto;
            margin-left: auto;
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
));
BoxContainer.propTypes = {
  children: node.isRequired,
  className: string,
};
BoxContainer.displayName = 'BoxContainer';

const InputLabel = ({ children, className, ...props }) => (
  <h3
    {...props}
    className={cx(
      'input-label',
      css`
        color: ${getColor('neutral-dark')};
        ${fontSize('small')}
        font-weight: 400;
        padding-bottom: ${px2grid(8)};

        ${mediaQuery('desktop')} {
          ${fontSize('large')}
        }
      `,
      className
    )}
  >
    {children}
  </h3>
);

InputLabel.propTypes = {
  className: string,
  children: node.isRequired,
};

const SearchBox = ({ onSearchClick, ...props }) => (
  <BoxContainer {...props}>
    <InputLabel>Pesquise cursos por palavra-chave:</InputLabel>
    <ButtonInput onSearchClick={onSearchClick} />
  </BoxContainer>
);

SearchBox.propTypes = {
  onSearchClick: func,
};

SearchBox.displayName = 'SearchBox';

export default SearchBox;
