import React from 'react';

import {
  cx,
  css,
  Text,
  Checkbox,
  getSpaceSize,
  ColorNeutralDark,
  ColorNeutralTitle,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import {
  func,
  string,
  arrayOf,
  shape,
  oneOfType,
  number,
  bool,
} from '@ampli/utils';

const CheckboxList = ({ label, items, onChange, value, wrap, name }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  const handleChange = (valueParam) => () => {
    const newValue = value;
    if (newValue?.includes(valueParam)) {
      onChange &&
        onChange(
          name,
          newValue.filter((item) => item !== valueParam)
        );
      return;
    }

    onChange && onChange(name, [...(value || []), valueParam]);
  };

  return (
    <div
      className={cx(
        'checkbox-list-wrap',
        css`
          display: flex;
          flex-direction: column;

          &.wrap {
            flex-wrap: wrap;
          }
        `,
        wrap && !isDesktop && 'wrap'
      )}
    >
      <Text
        className={cx(
          'label-checkbox-list',
          css`
            margin-top: ${getSpaceSize('base')};
            margin-bottom: ${getSpaceSize('x-small')};
          `
        )}
        color={ColorNeutralTitle}
      >
        {label}
      </Text>

      {!!items?.length &&
        items.map((item, index) => (
          <Checkbox
            key={`checkbox_item_${label.replace(/\s/g, '_')}_${index}}`}
            className={cx(
              'checkbox-item',
              css`
                color: ${ColorNeutralDark};
                &.wrap {
                  margin-right: ${getSpaceSize('xx-small')};
                }
              `,
              wrap && !isDesktop && 'wrap'
            )}
            {...item}
            onChange={handleChange(item?.value)}
            inline={false}
            checked={value?.includes(item?.value)}
          />
        ))}
    </div>
  );
};

CheckboxList.propTypes = {
  value: arrayOf(string),
  name: string,
  onChange: func,
  label: string,
  wrap: bool,
  items: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string]),
    })
  ),
};

export default CheckboxList;
