import React from 'react';

import UnitItem from './unit-item';
import CollapsibleItem from './collapsible-item';

import { arrayOf, object, func } from '@ampli/utils';

const CourseCurriculumList = ({ items, onClickClass }) => {
  const [selected, setSelected] = React.useState(null);
  return (
    <div>
      {items.map((item, index) => (
        <CollapsibleItem
          key={`collapsible_item_${index}`}
          item={item}
          selected={selected}
          setSelected={setSelected}
        >
          {!!item.units?.length &&
            item.units?.map((unit, unitIndex) => (
              <UnitItem
                unit={unit}
                key={`item_${index}_unit_${unitIndex}`}
                onClickClass={onClickClass}
              />
            ))}
        </CollapsibleItem>
      ))}
    </div>
  );
};

CourseCurriculumList.propTypes = {
  items: arrayOf(object),
  onClickClass: func,
};

export default CourseCurriculumList;
