import React from 'react';

import { cx, css, px2rem,px2grid, boxShadow } from '@ampli/ui';

import { node, string } from '@ampli/utils';

const CourseInfoCard = ({ children, ...props }) => (
  <div
    className={cx(
      'course-info-card',
      css`
        background-color: white;
        border-radius: ${px2rem(16)};
        display: flex;
        flex-direction: column;
        width: ${px2grid(360)};
        padding: ${px2grid(25)};
        ${boxShadow('card')};
      `,
      props.className
    )}
  >
    {children}
  </div>
);

CourseInfoCard.propTypes = {
  children: node,
  className: string,
};

export default CourseInfoCard;
