import React from 'react';
import { string, node } from '@ampli/utils';
import {
  Carousel as _Carousel,
  css,
  cx,
  px2grid,
  mediaQuery,
  getColor,
  fontSize,
  Flex,
  Text,
  RichText,
} from '@ampli/ui';

const TestimonialHeader = ({ children, className, ...props }) => (
  <Flex justifyContent="flex-start" alignItems="center" direction="row">
    <div
      {...props}
      className={cx(
        'testimonial-header',
        css`
          margin-bottom: ${px2grid(20)};

          ${mediaQuery('mobile')} {
            margin-bottom: ${px2grid(8)};
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

TestimonialHeader.propTypes = {
  children: node.isRequired,
  className: string,
};

const HeaderTextContainer = ({ children, className, ...props }) => (
  <Flex alignItems="flex-start" justifyContent="center" direction="column">
    <div
      {...props}
      className={cx(
        'header-text-container',
        css`
          margin-left: ${px2grid(16)};
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

HeaderTextContainer.propTypes = {
  children: node.isRequired,
  className: string,
};

const StudentName = ({ className, ...props }) => (
  <Text
    {...props}
    as="h3"
    color="accent-base"
    size="xxx-large"
    className={cx(
      'student-name',
      css`
        text-align: left;
        font-weight: bold;
        margin-bottom: ${px2grid(8)};

        ${mediaQuery('mobile')} {
          margin-bottom: ${px2grid(4)};
          ${fontSize('x-large')};
        }
      `,
      className
    )}
  />
);

StudentName.propTypes = {
  className: string,
};

const CourseName = ({ className, ...props }) => (
  <Text
    as="h4"
    color="white"
    size="base"
    {...props}
    className={cx(
      'course-name',
      css`
        text-align: left;
        margin-bottom: ${px2grid(4)};

        ${mediaQuery('mobile')} {
          ${fontSize('small')};
        }
      `,
      className
    )}
  />
);

CourseName.propTypes = {
  className: string,
};

const StudentImage = ({ children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      'student-image',
      css`
        margin-bottom: ${px2grid(8)};
        img {
          border-radius: 50%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          height: ${px2grid(110)};
          width: ${px2grid(110)};

          ${mediaQuery('mobile')} {
            height: ${px2grid(80)};
            width: ${px2grid(80)};
          }
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

StudentImage.propTypes = {
  className: string,
  children: node.isRequired,
};

const Divider = ({ className, ...props }) => (
  <div
    {...props}
    className={cx(
      'header-divider',
      css`
        border-bottom: 1px solid white;
        width: 100%;
        height: ${px2grid(4)};
      `,
      className
    )}
  />
);
Divider.propTypes = {
  className: string,
};

const TestimonialText = ({ children, className, ...props }) => (
  <Text
    {...props}
    color="white"
    size="small"
    block
    className={cx(
      'testimonial-item',
      css`
        text-align: left;
        ${mediaQuery('desktop')} {
          ${fontSize('base')};
          width: ${px2grid(352)};
          padding: 0;
          display: inline;
        }
      `,
      className
    )}
  >
    <RichText>{children}</RichText>
  </Text>
);

TestimonialText.propTypes = {
  className: string,
  children: node.isRequired,
};

const HomeStudentTestimonial = ({ imgUrl, name, course, testimonial }) => {
  return (
    <div
      className={css`
        padding-right: ${px2grid(20)};
        padding-left: ${px2grid(40)};

        ${mediaQuery('desktop')} {
          padding: 0;
        }
      `}
    >
      <TestimonialHeader>
        <StudentImage>
          <img alt="Estudante" src={imgUrl} width={100} height={100} />
        </StudentImage>
        <HeaderTextContainer>
          <StudentName>{name}</StudentName>
          <CourseName>{course}</CourseName>
          <Divider />
        </HeaderTextContainer>
      </TestimonialHeader>
      <TestimonialText>{testimonial}</TestimonialText>
    </div>
  );
};

HomeStudentTestimonial.propTypes = {
  imgUrl: string,
  name: string,
  course: string,
  testimonial: string,
};

export default HomeStudentTestimonial;
