import React from 'react';

import { css, cx, getColor, px2grid, fontSize } from '@ampli/ui';
import { string, node } from '@ampli/utils';

const Paragraph = ({ children, ...props }) => (
  <div
    {...props}
    className={cx(
      'courses-and-price-paragraph',
      css`
        ${fontSize('small')};
        text-align: left;
        color: ${getColor('neutral-dark')};
        margin-top: ${px2grid(10)};
      `,
      props.className
    )}
  >
    {children}
  </div>
);
Paragraph.propTypes = {
  className: string,
  children: node.isRequired,
};

export default Paragraph;
