import React from 'react';

import { cx, css, getSpaceSize, px2grid, Text, mediaQuery } from '@ampli/ui';
import { string, number, splitPrice, bool } from '@ampli/utils';

const PriceInfoFilter = ({
  price: _price = 0,
  marginBottom,
  fixedPrice = true,
}) => {
  const price = splitPrice(_price);

  return (
    <div
      className={cx(
        'price-wrap',
        css`
          display: flex;
          flex-direction: column;
          margin-top: ${getSpaceSize('small')};
          margin-bottom: ${getSpaceSize(marginBottom || 'small')};
        `
      )}
    >
      {!fixedPrice && (
        <Text
          size="x-small"
          as="h5"
          className={css`
            ${mediaQuery('desktop')} {
              display: none;
            }
          `}
        >
          a partir de
        </Text>
      )}
      <div
        className={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <Text
          size={px2grid(50)}
          as="h4"
          className={css`
            font-weight: bold;
          `}
          color="brand-base"
        >
          {price.integer}
        </Text>

        <div
          className={cx(
            'price-text-wrap',
            css`
              display: flex;
              flex-direction: column;
              margin-top: ${px2grid(4)};
            `
          )}
        >
          <Text
            size="large"
            as="h4"
            className={css`
              font-weight: bold;
            `}
            color="brand-base"
          >
            {`,${price.decimal}`}
          </Text>
        </div>
      </div>
    </div>
  );
};

PriceInfoFilter.propTypes = {
  marginBottom: string,
  price: number,
  fixedPrice: bool,
};

export default PriceInfoFilter;
