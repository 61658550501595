import { useState, useMemo } from 'react';
import { useGeolocation, useCurentPositionInformation } from '@ampli/hooks';
import { useFindAffiliatesByGeolocationNear } from '@ampli/services';

function parseAffiliateListNearMe(affiliateList) {
  function parseAddress(address) {
    return `${address.street}${address.number ? ' ' + address.number : ''}, ${
      address.neighbourhood
    }`;
  }

  return affiliateList?.length
    ? affiliateList.map((spot) => ({
        address: `${parseAddress(spot.affiliate.address)}`,
        city: spot.affiliate.address.city.name,
        distance: parseInt(spot.distance.toFixed(2)),
        coordinates: spot.affiliate.geolocation.coordinates,
      }))
    : [];
}

export function useNearbySpots(googleAPIKey) {
  const { geolocation } = useGeolocation();
  const { currentLocation } = useCurentPositionInformation(
    geolocation,
    googleAPIKey
  );
  const { data: affiliateList } = useFindAffiliatesByGeolocationNear(
    geolocation?.latitude,
    geolocation?.longitude
  );

  const [nearbySpots, setNearbySpots] = useState([]);

  useMemo(() => {
    setNearbySpots(parseAffiliateListNearMe(affiliateList));
  }, [affiliateList]);

  return { nearbySpots, currentLocation, geolocation };
}
