import React from 'react';

import { css, cx, px2grid, boxShadow, mediaQuery } from '@ampli/ui';
import { string, node } from '@ampli/utils';
import { MIN_WIDTH_DESKTOP_HEADER } from './base-header';

const Wrap = (props) => (
  <div
    {...props}
    className={cx(
      'wrap',
      css`
        display: flex;
        background-color: white;
        justify-content: space-between;
        margin: 0 auto;
        height: 100%;
        flex-direction: row;
        align-items: center;
        padding: 0 ${px2grid(32)} 0;

        @media (min-width: ${MIN_WIDTH_DESKTOP_HEADER}px) {
          flex-direction: row;
          padding: 0 ${px2grid(36)} 0;
        }
      `,
      props.className
    )}
  />
);

Wrap.propTypes = {
  className: string,
};

const HeaderContainer = ({ children, ...props }) => (
  <header
    {...props}
    className={cx(
      'header',
      css`
        background: white;
        position: sticky;
        width: 100%;
        top: 0;
        z-index: 10000;
        height: ${px2grid(64)};
        ${boxShadow('highlight')};

        @media (min-width: ${MIN_WIDTH_DESKTOP_HEADER}px) {
          height: auto;
        }
        ${mediaQuery('desktop')} {
          z-index: 10;
        }
      `,
      props.className
    )}
  >
    <Wrap>{children}</Wrap>
  </header>
);

HeaderContainer.propTypes = {
  children: node.isRequired,
  className: string,
};

export default HeaderContainer;
