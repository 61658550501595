import React from 'react';

import {
  css,
  cx,
  DialogTitle as _DialogTitle,
  px2grid,
  fontSize,
} from '@ampli/ui';
import { string, node } from '@ampli/utils';

const DialogTitle = ({ children, ...props }) => (
  <_DialogTitle
    {...props}
    className={cx(
      'dialog-title',
      css`
        color: black;
        ${fontSize('large')};
        text-align: center;
        margin-bottom: ${px2grid(10)};
      `,
      props.className
    )}
  >
    {children}
  </_DialogTitle>
);
DialogTitle.propTypes = {
  className: string,
  children: node.isRequired,
};

export default DialogTitle;
