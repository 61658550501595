import React from 'react';

import {
  cx,
  css,
  px2grid,
  Text,
  getSpaceSize,
  ColorNeutralDark,
  mediaQuery,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { number, splitPrice } from '@ampli/utils';

const PriceInfo = ({ price: _price = 0.0 }) => {
  const breakpoint = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoint !== 'desktop', [breakpoint]);

  const price = splitPrice(_price);

  return (
    <div
      className={cx(
        'info-price-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          width: ${px2grid(180)};
          ${mediaQuery('desktop')} {
            width: auto;
          }
        `
      )}
    >
      <div
        className={cx(
          'price-wrap',
          css`
            display: flex;
            margin-top: ${getSpaceSize('small')};
            margin-bottom: ${getSpaceSize('small')};
            align-items: flex-end;
          `
        )}
      >
        <Text
          className={cx(
            'prefix-price',
            css`
              margin-bottom: ${px2grid(4)};
            `
          )}
          size="xx-large"
          color="brand-base"
        >
          {'R$'}
        </Text>
        <Text size={px2grid(50)} as="strong" color="brand-base">
          {price.integer}
        </Text>

        <div
          className={cx(
            'price-text-wrap',
            css`
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              margin-bottom: ${px2grid(4)};
            `
          )}
        >
          <Text size="large" as="strong" color="brand-base">
            {`,${price.decimal}`}
          </Text>
          <Text size="small" color="brand-base">
            {'mês'}
          </Text>
        </div>
      </div>

      <Text
        as="h4"
        size={isMobile ? 'xx-small' : 'small'}
        color={isMobile ? ColorNeutralDark : 'brand-base'}
      >
        {'Mensalidades fixas que cabem '}
      </Text>
      <Text
        as="h4"
        marginSize="small"
        size={isMobile ? 'xx-small' : 'small'}
        color={isMobile ? ColorNeutralDark : 'brand-base'}
      >
        {'no seu bolso'}
      </Text>
    </div>
  );
};

PriceInfo.propTypes = {
  price: number,
};

export default PriceInfo;
