import React from 'react';

import {
  cx,
  css,
  Text,
  PrimaryButton,
  Icon,
  px2grid,
  px2rem,
  boxShadow,
  fontSize,
  getSpaceSize,
  ColorBrandBase,
  mediaQuery,
} from '@ampli/ui';
import PriceInfo from './price-info';
import { useBreakpoint } from '@ampli/hooks';
import {
  string,
  isString,
  func,
  oneOfType,
  bool,
  number,
  shape,
  arrayOf,
  moneyFormatter,
} from '@ampli/utils';
import { useCourseTime } from '../../hooks';

const InfoItem = ({ label, icon, hasFill }) => {
  const resolvedIcon = React.useMemo(
    () => (isString(icon) ? React.createElement(Icon[icon]) : icon),
    [icon]
  );

  return (
    <div
      className={cx(
        'info-item-wrap',
        css`
          display: flex;
          align-items: center;
          width: 100%;
        `
      )}
    >
      <Text
        className={cx(
          'icon-info-item',
          css`
            height: ${px2grid(30)};
            svg {
              width: ${px2grid(15)};
              height: ${px2grid(15)};
            }
            path {
              ${hasFill ? 'fill' : 'stroke'}: ${ColorBrandBase};
            }
            margin-right: ${getSpaceSize('xx-small')};
          `
        )}
      >
        {resolvedIcon}
      </Text>
      <Text as="h5" color="neutral-dark" size="x-small">
        {label}
      </Text>
    </div>
  );
};

InfoItem.propTypes = {
  icon: string,
  label: string,
  hasFill: oneOfType([string, bool]),
};

const CourseDetailCard = ({
  className,
  onClick,
  duration,
  image,
  price,
  recordedClasses = false,
}) => {
  const breakpoint = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoint !== 'desktop', [breakpoint]);

  const { courseTime } = useCourseTime(duration);

  return (
    <div
      className={cx(
        'detail-card-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: ${px2rem(16)};

          width: var(--detail-card-width);
          padding: ${getSpaceSize('large')};
          padding-top: 0;
          background-color: white;
        `,
        boxShadow(),
        className
      )}
    >
      {isMobile ? (
        <div
          className={cx(
            'info-wrap',
            css`
              display: flex;
              justify-content: space-between;
            `
          )}
        >
          <PriceInfo price={price} />
        </div>
      ) : (
        <>
          <div
            className={cx(
              'img-info-wrap',
              css`
                position: relative;
              `
            )}
          >
            <div
              className={cx(
                'img-info-course',
                css`
                  background: url(${require('../../assets/horizontal-background-card.png')})
                      center center no-repeat,
                    url(${image?.baseUrl && image?.formats?.includes('mobile')
                        ? `${image?.baseUrl}/mobile`
                        : require('../../assets/horizontal-image-card.webp')})
                      center center no-repeat;
                  height: ${px2grid(150)};
                  width: ${px2grid(325)};
                  border-top-right-radius: ${px2rem(16)};
                  border-top-left-radius: ${px2rem(16)};
                  ${mediaQuery('desktop')} {
                    background: url(${require('../../assets/horizontal-background-card.png')})
                        center center no-repeat,
                      url(${image?.baseUrl &&
                        image?.formats?.includes('thumbnail')
                          ? `${image?.baseUrl}/thumbnail`
                          : require('../../assets/horizontal-image-card.webp')})
                        center center no-repeat;
                  }
                `
              )}
            />
          </div>

          <PriceInfo price={price} />
        </>
      )}

      <InfoItem label="Inicio toda segunda-feira" icon="CalendarSimple" />
      {recordedClasses && (
        <InfoItem label="Aulas gravadas, curso 100% online" icon="PlayCircle" />
      )}
      <InfoItem
        label="Estude pelo celular ou computador"
        icon="Iphone"
        hasFill
      />
      <InfoItem
        label="Baixe as aulas e estude sem internet"
        icon="DownloadCloud"
      />
      <InfoItem label={`Se forme em ${courseTime}`} icon="Clock" />

      <PrimaryButton
        onClick={onClick}
        className={cx(
          'info-card-button',
          css`
            height: ${px2grid(50)};
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: ${getSpaceSize('small')};
            margin-bottom: ${getSpaceSize('x-small')};
          `,
          fontSize('small')
        )}
        fluid
      >
        {'Experimente 1 mês grátis'}
      </PrimaryButton>

      <Text size="x-small" color="black">
        {`Depois pague apenas ${moneyFormatter(price)} por mês`}
      </Text>
    </div>
  );
};

CourseDetailCard.propTypes = {
  className: string,
  onClick: func,
  duration: number,
  image: shape({ formats: arrayOf(string), baseUrl: string }),
  price: number,
  recordedClasses: bool,
};

export default CourseDetailCard;
