import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useFindCoursesByCourseDegree,
  useCreateEmailLead,
  LIST_COURSES_BY_COURSE_DEGREE,
} from '@ampli/services';
import {
  cx,
  css,
  mediaQuery,
  getSpaceSize,
  px2grid,
  useDialogState,
  ColorNeutralLighter,
} from '@ampli/ui';
import { omit } from '@ampli/utils';
import { useBreakpoint, useScroller } from '@ampli/hooks';
import { Modal, useSubscriptionModal } from '@ampli/registration';
import {
  Helmet,
  Header,
  BannerListCourse,
  SelectItems,
  SearchInputList,
  CourseListFilter,
  FilterListModal,
  FilterInfoCourse,
  MecInfo,
  CourseListWrap,
  HowToSubscribe,
  SocialProof,
  AmpliBenefits,
  CourseSuggestions,
  CTAWhatsapp,
  Footer,
  FilterMobileWrap,
} from '../components';
// TODO: Get MIN_PRICES from API
import {
  CONTACT,
  SCROLLER_SETTINGS,
  MIN_PRICES,
  PATHNAME_BY_DEGREE_TYPE,
} from '../constants';
import { useCourseFilter, useMetaTextFilter } from '../hooks';
import {
  ampli,
  entranceExamUrl,
  recaptchaSiteKey,
  googleAPIKey,
  baseUrl,
} from '../config';
import { onFilterCourse } from '../lib/filter-courses';
import { loadFilters } from '../hooks/use-course-filter';

import mockedTestimonialList from '../components/social-proof/testimonialList';

const selectItems = [
  { label: 'Supletivo', value: 'OPEN', disabled: true },
  { label: 'Graduação', value: 'UNDERGRADUATE' },
  { label: 'Pós-graduação', value: 'GRADUATE' },
];

const degreeTypeByPathnameMap = {
  '/graduacao': 'UNDERGRADUATE',
  '/pos-graduacao': 'GRADUATE',
};

export const normalizeValue = (value) =>
  value
    ? value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : '';

const CourseList = () => {
  const { pathname, search } = useLocation();
  const canonicalUrl = baseUrl + pathname + search;
  const history = useHistory();
  const listRef = React.useRef();
  const subscriptionModal = useSubscriptionModal();
  const dialogFilter = useDialogState();
  const [courseDegree] = React.useState(
    degreeTypeByPathnameMap[pathname] || 'UNDERGRADUATE'
  );
  const breakpoint = useBreakpoint(true);
  const isDesktop = React.useMemo(
    () => breakpoint?.isDesktop && breakpoint?.currentSize?.width > 900,
    [breakpoint]
  );

  const { courses, loading } = useFindCoursesByCourseDegree(courseDegree, {
    page: 1,
    perPage: 1000,
  });

  function changeDegreeType(degreeType) {
    const degreePathname = Object.keys(degreeTypeByPathnameMap).find(
      (key) => degreeTypeByPathnameMap[key] === degreeType
    );
    return degreePathname && history.push(degreePathname);
  }

  const [createLead, { loading: leadLoading }] = useCreateEmailLead({
    onCompleted: () => alert('Seus dados foram cadastrados com sucesso!'),
    onError: () =>
      alert(
        'Ocorreu um problema ao enviar seus dados, por favor tente novamente.'
      ),
  });

  const handleCreateLead = (email) => {
    const grecaptcha = window?.grecaptcha;
    if (grecaptcha) {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(recaptchaSiteKey, {
            action: 'createEmailLead',
          })
          .then((token) => {
            createLead({
              variables: {
                lead: { email, sourceTag: 'course-not-found' },
                captcha: { token, origin: 'web' },
              },
            });
            return token;
          })
          .catch(console.error);
      });
      return;
    }
    alert(
      'Ocorreu um problema ao enviar seus dados, por favor tente novamente.'
    );
  };

  const {
    filterValues,
    onChangeFilter,
    filteredCourses,
    filtersList,
    clearFilters,
  } = useCourseFilter(courses, courseDegree);

  const { metaTitle, metaDescription, doNotIndex } = useMetaTextFilter(
    filterValues,
    filtersList,
    courseDegree
  );

  const currentPage = filterValues?.page;

  const { ref: contactRef, scroller: contactScroller } = useScroller(
    SCROLLER_SETTINGS
  );

  const paginateCourses = React.useMemo(() => {
    if (!filteredCourses?.length) return [];

    const pageSize = isDesktop ? 16 : 8;
    return filteredCourses.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [filteredCourses, currentPage, isDesktop]);

  const backupPaginateCourses = React.useMemo(() => {
    if (!courses?.length) return [];

    const pageSize = isDesktop ? 16 : 8;
    return courses.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [courses, currentPage, isDesktop]);

  const popularCourses = React.useMemo(() => {
    if (!courses?.length) return [];

    const newCourses = [...courses];
    return newCourses
      .sort((a, b) => a?.candidateCount > b?.candidateCount)
      .slice(0, 5);
  }, [courses]);

  const [selectedCourseCode, setSelectedCourseCode] = React.useState('');

  const handleSubscribeCourseClick = (course) => {
    if (course && course.urlCode) setSelectedCourseCode(course.urlCode);
    subscriptionModal.show();
  };

  const onChangePage = (newPage) => {
    onChangeFilter('page', newPage);
    listRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <Helmet
        title={metaTitle}
        description={metaDescription}
        robots="robots"
        canonical={canonicalUrl}
        metaValues={
          doNotIndex
            ? [{ name: 'robots', content: 'noindex, nofollow' }]
            : undefined
        }
      />
      <Header
        contactScroller={contactScroller}
        onSubscribeClick={subscriptionModal.show}
      />
      <BannerListCourse
        countCourses={courses?.length || 0}
        degreeType={courseDegree}
      >
        <SelectItems
          items={selectItems}
          value={courseDegree}
          onSelectValue={changeDegreeType}
          label="Nível do curso"
        />

        <SearchInputList
          label="Pesquisar"
          value={filterValues?.name}
          onSearch={(value) => onChangeFilter('name', value)}
        />
      </BannerListCourse>
      {!isDesktop && (
        <FilterMobileWrap
          minPrice={MIN_PRICES[courseDegree]}
          onClick={() => dialogFilter.show()}
          fixedPrice={courseDegree === 'UNDERGRADUATE'}
        />
      )}
      <div
        className={cx(
          'course-content-wrap',
          css`
            width: 100%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: ${px2grid(20)};

            &.loading {
              min-height: ${px2grid(700)};
            }

            ${mediaQuery('desktop')} {
              flex-direction: row;
              margin-bottom: ${px2grid(80)};
            }
          `,
          loading && 'loading'
        )}
      >
        <div
          className={cx(
            'course-list-container',
            css`
              display: flex;
              justify-content: center;
              align-items: center;
              ${mediaQuery('desktop')} {
                align-items: flex-start;
                flex: 1;
                max-width: ${px2grid(1200)};
              }
            `
          )}
        >
          {isDesktop && (
            <div
              className={cx(
                'filter-wrap',
                css`
                  border: 1px solid ${ColorNeutralLighter};
                  border-radius: ${px2grid(8)};
                  margin-left: ${px2grid(4)};
                  margin-right: ${getSpaceSize('x-large')};
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                `
              )}
            >
              <FilterInfoCourse
                minPrice={MIN_PRICES[courseDegree]}
                fixedPrice={courseDegree === 'UNDERGRADUATE'}
              />
              <MecInfo hasBottomBorder />
              <CourseListFilter
                filters={filtersList}
                onChange={onChangeFilter}
                values={filterValues}
              />
            </div>
          )}
          <CourseListWrap
            ref={listRef}
            items={paginateCourses}
            backupItems={backupPaginateCourses}
            loading={loading}
            itemsLength={filteredCourses?.length || courses?.length || 0}
            onChangePage={onChangePage}
            limitPerPage={isDesktop ? 16 : 8}
            currentPage={currentPage}
            searchText={filterValues?.name}
            onEmptyStateSubmit={handleCreateLead}
            onSubscribeClick={handleSubscribeCourseClick}
            leadLoading={leadLoading}
            degreeType={courseDegree}
          />
        </div>
      </div>

      <HowToSubscribe onClick={subscriptionModal.show} />

      <SocialProof testimonials={mockedTestimonialList} />

      {isDesktop ? (
        <div
          className={cx(
            'ampli-benefits-wrap',
            css`
              display: flex;
              justify-content: center;
            `
          )}
        >
          <div
            className={cx(
              'ampli-benefits-content',
              css`
                width: ${px2grid(1150)};
              `
            )}
          >
            <AmpliBenefits />
          </div>
        </div>
      ) : (
        <AmpliBenefits />
      )}

      <div
        className={cx(
          'suggestions-wrap',
          css`
            margin-top: ${getSpaceSize('xxx-large')};
            overflow: auto;
            ${mediaQuery('desktop')} {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          `
        )}
      >
        <CourseSuggestions
          items={popularCourses}
          degreeType={courseDegree}
          loading={loading}
        />
      </div>

      <div ref={contactRef}>
        <CTAWhatsapp onClick={() => window?.open(CONTACT.WHATSAPP, '_blank')} />
      </div>

      <Footer />

      <FilterListModal dialog={dialogFilter}>
        <CourseListFilter
          filters={filtersList}
          onChange={onChangeFilter}
          values={filterValues}
          onSubmit={() => dialogFilter.hide()}
          clearFilters={clearFilters}
        />
      </FilterListModal>

      <Modal
        {...subscriptionModal}
        isModal
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        selectedCourseCode={selectedCourseCode}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </div>
  );
};

CourseList.getInitialProps = async ({ client, req }) => {
  try {
    if (!req) return {};

    const degreeType = Object.keys(PATHNAME_BY_DEGREE_TYPE).find(
      (key) => PATHNAME_BY_DEGREE_TYPE[key].pathname === `/${req?.params[0]}`
    );

    const { data } = await client.query({
      query: LIST_COURSES_BY_COURSE_DEGREE,
      variables: {
        degree: degreeType,
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'name', order: 'ASC' },
      },
    });
    const courses = data?.list?.data;

    const hasNoQueryParams = !Object.keys(omit(req?.query || {}, ['pagina']))
      .length;

    if (hasNoQueryParams) return { courses };
    else {
      const filters = loadFilters(req?._parsedUrl?.search);
      const filteredCourses =
        Object.keys(filters || {})?.length &&
        onFilterCourse(courses, filters, degreeType, true);

      return filteredCourses?.length ? { courses } : { statusCode: 404 };
    }
  } catch (error) {
    if (error?.response?.status === 404) return { statusCode: 404 };
    return { error };
  }
};

export default CourseList;
