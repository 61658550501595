import * as React from "react";

function SvgNecessaryDocuments(props) {
  return (
    <svg
      width={160}
      height={157}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.072 64.783H9.78c1.29 0 2.244-.322 2.866-.967.62-.644.931-1.5.931-2.566 0-1.031-.31-1.834-.931-2.408-.61-.574-1.454-.861-2.532-.861-.972 0-1.787.269-2.443.808-.656.527-.984 1.219-.984 2.074h-5.08c0-1.336.357-2.531 1.072-3.586.726-1.066 1.734-1.898 3.023-2.496 1.301-.597 2.73-.896 4.29-.896 2.706 0 4.827.65 6.363 1.95 1.535 1.29 2.302 3.071 2.302 5.345 0 1.172-.357 2.25-1.072 3.234-.715.984-1.652 1.74-2.813 2.268 1.442.515 2.514 1.289 3.217 2.32.715 1.031 1.073 2.25 1.073 3.656 0 2.274-.832 4.096-2.497 5.467-1.652 1.371-3.843 2.057-6.574 2.057-2.554 0-4.646-.674-6.275-2.022-1.617-1.347-2.426-3.129-2.426-5.344h5.08c0 .961.358 1.746 1.072 2.356.727.61 1.618.914 2.672.914 1.207 0 2.15-.317 2.83-.95.692-.644 1.037-1.493 1.037-2.548 0-2.555-1.406-3.832-4.218-3.832h-2.69v-3.973z"
        fill="#009A98"
      />
      <path
        d="M65 29.29c0 .626-.076 1.225-.215 1.786H19c0-3.842 2.259-6.958 5.043-6.958.974 0 1.885.382 2.655 1.042.82-3.829 3.388-6.622 6.428-6.622 1.13 0 2.195.386 3.129 1.068 1.28-4.97 4.722-8.53 8.765-8.53 3.767 0 7.013 3.09 8.474 7.536.27-.048.547-.072.828-.072 2.689 0 5.008 2.184 6.076 5.337.204-.046.415-.068.628-.068 2.195 0 3.974 2.455 3.974 5.482z"
        fill="#E3ECFF"
      />
      <g opacity={0.2} fill="#3F3D56">
        <path
          opacity={0.2}
          d="M50.713 116.726c.066.448.169.888.309 1.315a266.532 266.532 0 018.623-2.504c-.014-.119-.028-.241-.045-.37a7.103 7.103 0 00-1.448-3.445c-1.014-1.261-2.416-1.94-3.849-1.69-1.432.25-2.573 1.371-3.183 2.908a7.409 7.409 0 00-.407 3.786z"
        />
        <path
          opacity={0.2}
          d="M54.911 114.574c2.51-.42 4.154-3.362 3.672-6.57-.482-3.208-2.907-5.468-5.417-5.048-2.51.42-4.154 3.361-3.672 6.57.482 3.208 2.907 5.468 5.417 5.048z"
        />
        <path
          opacity={0.2}
          d="M53.762 107.417c2.51-.42 4.154-3.361 3.672-6.569-.482-3.208-2.908-5.468-5.418-5.048-2.51.42-4.154 3.361-3.672 6.569.483 3.208 2.908 5.468 5.418 5.048z"
        />
        <path
          opacity={0.2}
          d="M52.614 100.261c2.51-.42 4.154-3.36 3.672-6.569-.482-3.208-2.907-5.468-5.417-5.048-2.51.42-4.154 3.361-3.672 6.57.482 3.207 2.907 5.467 5.417 5.047z"
        />
        <path
          opacity={0.2}
          d="M51.469 93.107c2.51-.42 4.154-3.361 3.672-6.57-.482-3.207-2.908-5.467-5.418-5.047-2.51.42-4.154 3.36-3.672 6.569.482 3.208 2.908 5.468 5.418 5.048z"
        />
      </g>
      <path
        opacity={0.9}
        d="M118.365 40.446l-1.051-.077c-.214-.016-.404.156-.425.384l-1.336 15.193c-.02.228.137.426.352.442l1.05.077c.215.016.405-.156.425-.384l1.337-15.193c.02-.228-.138-.426-.352-.442zM53.938 22.627l-.254-.019a.414.414 0 00-.432.385l-.399 4.452c-.02.228.14.426.359.442l.253.018a.414.414 0 00.433-.384l.398-4.452a.398.398 0 00-.358-.442zM53.263 32.274l-.381-.028c-.216-.016-.407.156-.427.384l-.738 8.353a.396.396 0 00.354.442l.382.028c.215.016.406-.156.427-.384l.737-8.354a.396.396 0 00-.354-.441zM52.222 44.644l-.325-.024c-.215-.016-.405.157-.426.385l-.742 8.431c-.02.228.138.426.353.442l.325.024c.215.016.405-.157.426-.385l.742-8.431c.02-.228-.138-.426-.353-.442z"
        fill="#E5E7EB"
      />
      <path
        opacity={0.9}
        d="M118.532 9.666L65.875 5.909c-4.013-.286-7.524 2.291-7.84 5.757L48.39 117.039c-.317 3.466 2.679 6.508 6.692 6.794l52.657 3.757c4.013.286 7.523-2.292 7.841-5.757l9.643-105.374c.317-3.465-2.679-6.507-6.692-6.793z"
        fill="#444"
      />
      <path
        opacity={0.9}
        d="M99.017 12.616L82.84 11.413c-.812-.06-1.5.225-1.535.637l-.042.478c-.036.412.593.795 1.404.855l16.179 1.203c.811.06 1.498-.225 1.535-.637l.041-.478c.036-.412-.592-.795-1.404-.855z"
        fill="#6E6E6E"
      />
      <path
        d="M103.616 17.792L76.99 15.636l-14.413-1.167a4 4 0 00-4.311 3.664l-7.892 97.465c-.098 1.209.419 2.418 1.436 3.361 1.018.942 2.452 1.542 3.989 1.666l52.357 4.239c1.537.125 3.049-.236 4.205-1.003 1.156-.767 1.86-1.876 1.958-3.085l7.891-97.466a4.003 4.003 0 00-3.668-4.31l-14.926-1.208z"
        fill="#F5F7FB"
      />
      <g opacity={0.4} fill="#6C63FF">
        <path
          opacity={0.4}
          d="M133.765 136.211c3.737 0 6.766-.512 6.766-1.143s-3.029-1.143-6.766-1.143c-3.736 0-6.765.512-6.765 1.143s3.029 1.143 6.765 1.143z"
        />
        <path
          opacity={0.4}
          d="M136.562 133.199c.308-.251.534-.59.646-.971.083-.387-.083-.851-.451-.992-.415-.157-.857.129-1.192.414-.336.286-.72.621-1.159.559.227-.205.397-.465.493-.755.095-.291.113-.601.052-.901a.677.677 0 00-.151-.333c-.23-.246-.647-.141-.92.052-.875.621-1.12 1.803-1.126 2.871-.087-.385-.012-.787-.014-1.182-.003-.396-.112-.828-.446-1.048a1.365 1.365 0 00-.678-.157c-.394-.014-.829.025-1.1.311-.334.358-.247.956.043 1.349.29.394.732.64 1.14.911.325.195.603.459.813.774a.712.712 0 01.06.139h2.466a6.865 6.865 0 001.524-1.041z"
        />
      </g>
      <path
        opacity={0.4}
        d="M35.66 132.343c3.737 0 6.766-.511 6.766-1.142 0-.631-3.029-1.143-6.765-1.143-3.737 0-6.766.512-6.766 1.143s3.03 1.142 6.766 1.142z"
        fill="#B1CBF2"
      />
      <path
        opacity={0.4}
        d="M38.46 129.332c.308-.251.533-.591.644-.973.083-.385-.081-.849-.452-.99-.415-.157-.856.129-1.19.414-.334.286-.721.621-1.159.559a1.766 1.766 0 00.546-1.656.698.698 0 00-.152-.335c-.23-.244-.646-.139-.922.054-.875.621-1.12 1.801-1.123 2.869-.09-.385-.015-.787-.017-1.182-.002-.396-.11-.828-.444-1.035a1.352 1.352 0 00-.68-.16c-.391-.014-.829.025-1.098.313-.334.358-.247.956.044 1.35.29.393.731.637 1.137.91.326.194.604.458.813.775.025.044.046.09.062.138h2.465a6.897 6.897 0 001.525-1.051z"
        fill="#B1CBF2"
      />
      <path
        d="M71.82 19.619l-9.214-.746-.745 9.2 9.214.747.745-9.201zM97.868 22.39l-19.303-1.564-.142 1.753 19.303 1.563.142-1.753zM110.122 26.469l-31.806-2.576-.142 1.753 31.806 2.575.142-1.752zM109.874 29.536L78.068 26.96l-.141 1.753 31.805 2.575.142-1.752zM68.665 60.327l-9.214-.746-.745 9.201 9.213.746.746-9.201zM94.715 63.096l-19.303-1.563-.142 1.752 19.303 1.563.142-1.752zM106.97 67.175L75.164 64.6l-.142 1.753 31.806 2.575.142-1.753zM106.722 70.242l-31.806-2.575-.142 1.752 31.806 2.576.142-1.753zM66.64 87.112l-9.214-.745-.745 9.2 9.213.747.745-9.202zM92.688 89.883l-19.303-1.562-.142 1.752 19.303 1.563.142-1.752zM104.942 93.963l-31.805-2.575-.142 1.752 31.806 2.575.141-1.752zM104.694 97.03l-31.805-2.575-.142 1.752 31.806 2.576.141-1.753zM65.344 103.986l-9.213-.746-.745 9.202 9.213.746.745-9.202zM91.393 106.757l-19.303-1.563-.142 1.753 19.303 1.563.142-1.753zM103.647 110.837l-31.806-2.575-.142 1.752 31.806 2.575.142-1.752zM103.399 113.904l-31.806-2.575-.142 1.752 31.806 2.575.142-1.752z"
        fill="#D6D8E0"
      />
      <path
        d="M125.071 35.359l-78.527-6.358-1.579 19.496 78.527 6.358 1.579-19.496z"
        fill="url(#necessary-documents_svg__paint0_linear)"
      />
      <path
        d="M121.243 35.19L47.29 29.202l-1.55 19.141 73.953 5.988 1.55-19.14z"
        fill="#fff"
      />
      <path
        d="M51.429 35.052c.064-.633.609-1.115 1.215-1.076l14.946.963c.607.04 1.046.584.981 1.218l-.885 8.653c-.065.634-.609 1.116-1.216 1.076l-14.945-.963c-.607-.039-1.046-.584-.981-1.218l.885-8.653z"
        fill="#009A98"
      />
      <path
        d="M51.737 39.913c.023-.243.217-.427.433-.412l6.524.447c.216.015.372.223.35.465-.023.243-.217.427-.433.413l-6.524-.447c-.217-.015-.373-.224-.35-.466zM51.578 41.873c.023-.241.217-.425.433-.41l6.132.419c.216.015.373.222.35.464-.023.241-.217.425-.433.41l-6.132-.419c-.216-.014-.372-.222-.35-.464z"
        fill="#fff"
        fillOpacity={0.4}
      />
      <path
        d="M66.525 40.513c-.128 1.388-1.331 2.434-2.688 2.337-1.357-.096-2.353-1.299-2.226-2.686.128-1.388 1.33-2.434 2.688-2.337 1.356.096 2.353 1.299 2.226 2.686z"
        fill="#000"
        fillOpacity={0.1}
      />
      <path
        d="M96.854 38.792l-19.28-1.436-.165 1.88 19.281 1.436.164-1.88zM109.109 42.834l-31.784-2.411-.164 1.917 31.784 2.41.164-1.916zM108.86 45.9l-31.784-2.41-.164 1.917 31.784 2.41.164-1.916z"
        fill="#CCF0F6"
      />
      <path
        d="M62.25 152.221c.384.011.756.132 1.072.349.233.217.39.503.448.815a2.169 2.169 0 01-.295 1.824 2.048 2.048 0 01-.831.619c-1.138.49-2.438.135-3.623-.221l-4.64-1.397a1.558 1.558 0 01-.755-.391 1.018 1.018 0 01-.02-1.19c.25-.346.59-.617.983-.783 1.445-.702 3.073-.864 4.67-.894.59-.012 1.17-.14 1.571.314.211.246.175.506.492.715.287.15.605.232.929.24z"
        fill="#444"
      />
      <path
        d="M53.484 149.671c.383.008.756.128 1.072.346.23.218.387.503.447.815a2.165 2.165 0 01-.295 1.824 2.041 2.041 0 01-.83.621c-1.139.491-2.439.132-3.624-.224l-4.64-1.396a1.537 1.537 0 01-.755-.391 1.018 1.018 0 01-.02-1.19c.25-.347.59-.618.983-.783 1.446-.703 3.07-.865 4.658-.894.588 0 1.17-.14 1.571.314.212.246.175.508.492.715.291.151.613.234.941.243z"
        fill="#444"
      />
      <path
        d="M57.514 99.374a57.232 57.232 0 00-8.229-.231 1.619 1.619 0 00-.533.084c-.605.245-.714-.501-.773.147-2.659 4.513-3.416 15.779-2.659 25.034.927 5.771-.488 14.399-1.733 20.255-.25 1.169-.336 5.263.976 5.855 2.667 1.2 6.106.353 7.905-1.952-1.275-.202-.779-4.241-.848-5.504-.357-4.093-1.229-8.026.244-11.989 1.451-6.433 2.224-14.142 4.077-19.776 0 5.634-.047 11.547.228 16.609.343 6.861-1.534 13.485-2.08 20.314-.121 1.522-.173 3.169-1.122 4.365 3.239 2.104 6.358 1.382 8.903-1.63-1.817-.62 1.082-14.282 1-16.237-.163-4.344 1.307-9.176 2.422-13.356 1.889-7.147 2.254-21.626.475-22.812-2.554.806-5.584 1.053-8.252.824z"
        fill="#8E9294"
      />
      <path
        d="M66.28 70.459a32.09 32.09 0 013.764-5.936c.306-.36.585-.74.836-1.14.528-.913.685-1.99 1.02-2.992.934-2.808 3.264-5.064 3.832-7.968.206-1.053.88-1.964 1.34-2.946.314-.658.658-1.35 1.282-1.718.804-.491 1.915-.263 2.584.392a3.058 3.058 0 01.789 2.59c-.04.427-.206.832-.477 1.164-.356.4-.89.582-1.371.822a5.378 5.378 0 00-2.643 3.017c-.82 2.372-.924 4.88-1.966 7.166-1.042 2.285-2.267 4.482-3.169 6.822-.508 1.328-.919 2.7-1.666 3.928-.748 1.227-1.922 2.256-3.333 2.44a8.352 8.352 0 00-.413-2.612c-.298-.975-.856-2.126-.408-3.03zM51.06 72.29a13.595 13.595 0 00-.797-5.101c-.226-.626-.504-1.262-.47-1.927.03-.617.328-1.186.593-1.743.927-1.927 1.576-3.972 2.212-6.01l1.23-3.839c.399-.295.761-.637 1.078-1.019a5.431 5.431 0 001.252-2.666 3.755 3.755 0 00-.691-2.82 1.633 1.633 0 00-1.155-.7 1.487 1.487 0 00-1.08.491c-.407.46-.72.996-.924 1.576-.403.943-.81 1.925-.772 2.946.032.85.352 1.797-.147 2.487-1.635 2.26-2.869 4.86-4.164 7.332-.327.59-.608 1.205-.84 1.839a10.179 10.179 0 00-.44 3.176c-.013 2.013.265 4.014.54 6.01.172 1.247.403 2.607 1.35 3.436.985-1.249 1.974-2.481 3.225-3.468z"
        fill="#B89397"
      />
      <path
        d="M57.315 73.25c2.817 0 5.1-2.28 5.1-5.094a5.097 5.097 0 00-5.1-5.094c-2.817 0-5.1 2.28-5.1 5.094a5.097 5.097 0 005.1 5.094z"
        fill="#B89397"
      />
      <path
        d="M54.837 73.52c-.122.922-.616 1.745-1.098 2.54-.293.491-.661 1.014-1.23 1.068 1.477.776 3.029 1.4 4.632 1.86a5.272 5.272 0 001.693.273 4.141 4.141 0 001.409-.36 6.994 6.994 0 003.967-4.665 9.215 9.215 0 01-1.893-.145c-.349-.063-.725-.166-.929-.454a1.494 1.494 0 01-.192-.822 43.998 43.998 0 010-2.548 10.09 10.09 0 00-3.706.454c-.492.16-.983.353-1.487.479-.31.076-1.163 0-1.392.179-.346.272.318 1.514.227 2.14z"
        fill="#B89397"
      />
      <path
        d="M57.087 76.615a3.2 3.2 0 00-2.438-2.843c-.526-.115-1.094-.103-1.566-.363-.806-.45-1.05-1.544-1.821-2.047-.772-.503-1.873-.224-2.547.415-.673.638-1.015 1.541-1.29 2.425-.49 1.425-.58-.148.041 3.595 1.699 1.186 2.292 9.487 1.699 12.452-.746 3.728-1.738 5.56-1.699 9.441 4.41 2.968 14.611 2.308 18.894-.547 1.59-11.266-4.15-11.859 1.59-20.022.116-.618.594-2.139 1.209-2.54.03-2.22-.176-3.25-.615-5.425-.165-.774-.506-1.687-1.286-1.82-.703-.117-1.295.474-1.858.911a5.082 5.082 0 01-1.966.923 1.851 1.851 0 00-.806.292c-.34.28-.386.786-.662 1.127-.491.611-1.59.557-1.94 1.262a4.42 4.42 0 00-.186.645 2.36 2.36 0 01-.809 1.044 4.564 4.564 0 01-1.944 1.075z"
        fill="#009A98"
      />
      <path
        d="M60.005 61.768c-4.852-2.405-8.204.929-9.749 3.308-2.185 4.452-.257 11.534 3.372 13.412 2.827 1.462 5.78.067 7.12-2.732.583-1.277 1.866-4.782.141-6.621-1.478-1.421.367-6.747-.884-7.367z"
        fill="#444"
      />
      <g opacity={0.1} fill="#000">
        <path
          opacity={0.1}
          d="M65.328 57.838c-.066-.005-.091-.09-.105-.154l-.087-.425c-.026-.117-.085-.261-.206-.259a.149.149 0 01-.084-.006c-.026-.017-.026-.052-.03-.084-.025-.186-.24-.28-.429-.304a.855.855 0 00-.238 0c-.247.036-.473.218-.718.178a.51.51 0 00-.19-.014c-.094.027-.136.131-.166.223-.089.28-.176.56-.257.84-.02.057-.054.127-.115.126-.01-.185-.052-.4-.227-.454a.364.364 0 01-.098-.031.112.112 0 01-.047-.064c-.002.025-.015.052-.021.078a.176.176 0 00-.004.102.168.168 0 00.053.086.435.435 0 00.099.03c.175.054.216.275.227.455.06.004.094-.069.114-.127.089-.28.176-.56.257-.84.03-.09.073-.197.167-.222a.443.443 0 01.19.014c.245.04.47-.142.718-.18.08-.01.159-.008.238.002.19.014.404.12.428.304-.002.03.005.067.03.084.027.01.056.012.085.006.12-.004.18.14.205.257l.087.425c.015.064.04.142.106.154.03.002.06-.007.085-.026a.133.133 0 00.048-.077.375.375 0 00-.004-.148.116.116 0 01-.049.042c-.02.009-.041.012-.062.009zM62.344 57.081c-.005-.084-.065-.153-.093-.232a.47.47 0 01-.026-.165l-.008.09a.584.584 0 00.018.267c.022.063.066.121.085.188a.417.417 0 00.024-.148zM65.618 56.764a2.47 2.47 0 00-.162.714c.038-.176.086-.35.145-.52.04-.13.062-.265.065-.401-.012.07-.028.139-.048.207z"
        />
      </g>
      <path
        d="M156.129 0l-24.333 10.571 8.143 1.78 3.021 7.666.4-6.694 10.047 3.202L156.129 0z"
        fill="#C7F0FF"
      />
      <path
        d="M155.194.947L140.49 12.03l2.183 6.264-.144-5.348 12.665-12z"
        fill="#B3D8E6"
      />
      <defs>
        <linearGradient
          id="necessary-documents_svg__paint0_linear"
          x1={28781}
          y1={9015.1}
          x2={28951.9}
          y2={6904.48}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="gray" stopOpacity={0.25} />
          <stop offset={0.54} stopColor="gray" stopOpacity={0.12} />
          <stop offset={1} stopColor="gray" stopOpacity={0.1} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgNecessaryDocuments;
