import * as React from 'react';

const SvgPlantVase = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M100.686 135.274H48.315s-1.802 52.109 26.186 52.109c27.988-.002 26.185-52.109 26.185-52.109z"
      fill="#D16547"
    />
    <path
      d="M100.688 135.274H84.193c-.063 3.523-.278 6.665-.799 7.906-1.46 3.476 1.326 7.285 2.52 17.051 1.193 9.769-3.713 13.907-9.152 15.397-5.436 1.491-2.652 3.477-4.112 8.61-.394 1.386-1.156 2.249-2.06 2.778 1.236.24 2.534.37 3.91.37 27.99-.005 26.188-52.112 26.188-52.112z"
      fill="#9B543E"
    />
    <path
      d="M74.503 140.224c14.462 0 26.185-2.216 26.185-4.95 0-2.733-11.723-4.949-26.185-4.949-14.462 0-26.186 2.216-26.186 4.949 0 2.734 11.724 4.95 26.186 4.95z"
      fill="#543131"
    />
    <path
      d="M73.806 136.819a.43.43 0 01-.182-.041c-.239-.11-.353-.411-.253-.671.199-.513 19.902-51.64 33.854-74.212 7.466-12.079 15.554-21.493 24.045-27.981 9.417-7.199 19.404-10.846 29.688-10.846.259 0 .469.23.469.513 0 .283-.21.513-.469.513-19.4 0-37.217 12.91-52.955 38.375-13.9 22.489-33.57 73.523-33.765 74.036a.467.467 0 01-.432.314z"
      fill="#614CD4"
    />
    <path
      d="M75.66 131.933s15.542-14.149 35.313-15.839c19.771-1.689 21.476.695 21.476.695s-18.865 25.053-56.789 15.144zM79.446 123.185s-11.977-17.819-12.32-39.493c-.343-21.674 1.936-23.39 1.936-23.39s21.732 22.103 10.384 62.883z"
      fill="#614CD4"
    />
    <path
      d="M88.185 101.787s9.695-15.04 25.251-21.064c15.554-6.024 17.396-4.51 17.396-4.51s-10.213 24.583-42.647 25.574z"
      fill="#311E69"
    />
    <path
      d="M95.3 85.91s-6.16-17.123-2.211-34.636c3.95-17.513 6.118-18.363 6.118-18.363s13.17 22.84-3.907 52.999zM106.417 64.42s12.57-6.847 26.174-4.534c13.604 2.313 14.407 4.22 14.407 4.22s-16.406 13.63-40.581.314zM123.109 42.143s13.119-1.376 24.327 5.649c11.208 7.027 11.325 8.993 11.325 8.993s-18.568 5.914-35.652-14.642z"
      fill="#614CD4"
    />
    <path
      d="M139.743 28.763s9.768-1.024 18.111 4.206 8.432 6.695 8.432 6.695-13.826 4.401-26.543-10.901z"
      fill="#311E69"
    />
    <path
      d="M153.51 24.137s9.067-4.1 18.444-1.764c9.378 2.339 9.854 3.71 9.854 3.71s-12.044 8.627-28.298-1.946z"
      fill="#614CD4"
    />
    <path
      d="M116.4 12.365s4.785 13.424 1.653 27.126c-3.131 13.703-4.831 14.361-4.831 14.361s-10.26-17.92 3.178-41.487z"
      fill="#311E69"
    />
    <path
      d="M144.298 0s1.186 12.12-4.15 22.552c-5.335 10.43-6.856 10.558-6.856 10.558S128.545 15.99 144.298 0z"
      fill="#614CD4"
    />
    <path
      d="M71.928 134.902c-2.244-10.397-4.724-20.385-7.255-27.864-4.628-13.67-10.443-24.913-17.28-33.421-7.584-9.435-16.495-15.574-26.484-18.241-.252-.066-.507.102-.568.378-.06.276.094.554.346.62 18.844 5.034 33.347 22.202 43.105 51.024 2.506 7.4 4.96 17.27 7.186 27.562.315-.028.632-.045.95-.058z"
      fill="#311E69"
    />
    <path
      d="M64.907 109.7S54.185 99.788 40.47 98.502c-13.717-1.287-14.912.36-14.912.36s12.976 17.5 39.35 10.838zM53.532 83.73s-12.444-4.743-24.857-.825c-12.413 3.916-12.955 5.797-12.955 5.797s16.754 10.56 37.812-4.972zM40.282 66.413s-9.265-3.53-18.507-.615-9.644 4.317-9.644 4.317 12.474 7.862 28.151-3.702zM27.911 58.347s-7.916-6.335-17.534-6.499C.76 51.685 0 52.895 0 52.895s9.826 11.504 27.911 5.452zM42.103 37.293s-3.785 11.467-.866 22.984c2.917 11.517 4.367 12.035 4.367 12.035s8.329-15.4-3.5-35.019z"
      fill="#311E69"
    />
  </svg>
);

export default SvgPlantVase;
