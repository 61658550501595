import React, { useRef, useEffect, useState } from 'react';

import {
  css,
  cx,
  px2grid,
  mediaQuery,
  getColor,
  useDialogState,
  Flex,
} from '@ampli/ui';
import { string, func, node, bool, oneOf } from '@ampli/utils';

import { CourseContent } from '../course-content';
import StepCard from './step-card';

import { Separator, InverseSeparator } from './assets/icons';
import {
  OnlineTest,
  SubscribeAtHome,
  NecessaryDocuments,
} from './assets/illustrations';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

import { TestAndEdictModal, RegistrationModal, DocumentModal } from './dialogs';

const requiredDegreeLevel = {
  UNDERGRADUATE: 'ensino médio',
  GRADUATE: 'ensino superior',
};

const Steps = (props) => (
  <Flex alignItems="center" justifyContent="space-between" direction="column">
    <div
      {...props}
      className={cx(
        'steps',
        css`
          position: relative;

          ${mediaQuery('desktop')} {
            align-items: start;
            flex-direction: row;
            text-align: center;
          }
        `,
        props.className
      )}
    />
  </Flex>
);
Steps.propTypes = {
  className: string,
};

const StepSeparator = ({ inverse, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'step-separator',
        css`
          width: ${px2grid(80)};
          height: ${px2grid(20)};
          margin-top: ${inverse ? px2grid(100) : px2grid(120)};
        `,
        props.className
      )}
    >
      {inverse ? (
        <Separator color={getColor('brand-base')} />
      ) : (
        <InverseSeparator color={getColor('brand-base')} />
      )}
    </div>
  );
};
StepSeparator.propTypes = {
  className: string,
  inverse: bool,
};

const Container = ({ children, className, ...props }) => {
  const scrollableContainer = useRef(null);
  const nonScrollableContainer = useRef(null);
  const [cursor, setCursor] = useState('auto');

  const isOverflow = () =>
    nonScrollableContainer.current?.clientWidth >
    scrollableContainer.current?.clientWidth;

  useEffect(() => {
    setCursor(isOverflow() ? 'grab' : 'auto');
  }, [scrollableContainer.current?.clientWidth]);

  return (
    <div
      ref={scrollableContainer}
      className={cx(
        'carousel-container',
        css`
          margin-top: ${px2grid(8)};
          overflow-x: auto;
          overflow-y: hidden;
          flex-wrap: nowrap;
          white-space: nowrap;
          cursor: ${cursor};

          ${mediaQuery('tablet-max')} {
            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: -ms-autohiding-scrollbar; /* Edge */
            ::-webkit-scrollbar {
              display: none; /* Safari and Chrome */
            }
          }
        `,
        className
      )}
      {...props}
    >
      <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
        <div
          ref={nonScrollableContainer}
          className={css`
            width: fit-content;
          `}
        >
          {children}
        </div>
      </Flex>
    </div>
  );
};

Container.propTypes = {
  children: node,
  className: string,
};

const SubscriptionProcess = ({ onCallToActionClick, degreeType, ...props }) => {
  const testandEdict = useDialogState();
  const registration = useDialogState();
  const documentation = useDialogState();

  const testInformation = {
    UNDERGRADUATE: {
      title: 'Inscreva-se e participe do vestibular online',
      description:
        'Utilize sua nota do ENEM ou faça nossa prova online e gratuita',
      dialogText: 'Como é a prova e o edital',
      onActionClick: testandEdict.show,
    },
    GRADUATE: {
      title: 'Inscreva-se gratuitamente',
      description: 'Escolha seu curso favorito e informe seus dados de contato',
      dialogText: 'Quero me inscrever',
      onActionClick: onCallToActionClick,
    },
  };

  return (
    <div {...props}>
      <CourseContent title="Como é o processo de matrícula?" icon="FileText">
        <Container>
          <StepCard
            title={testInformation[degreeType].title}
            description={testInformation[degreeType].description}
            dialogText={testInformation[degreeType].dialogText}
            onActionClick={testInformation[degreeType].onActionClick}
            Illustration={OnlineTest}
          />
          <StepSeparator inverse />
          <StepCard
            title="Matricule-se sem sair de casa"
            description="Matrícula gratuita, você paga apenas a partir da 2ª mensalidade"
            dialogText="Como é a matrícula"
            onActionClick={registration.show}
            Illustration={SubscribeAtHome}
          />
          <StepSeparator />
          <StepCard
            title="Envie os documentos necessários"
            description="Tire fotos de seus documentos e envie por nossa plataforma online"
            dialogText="Ver documentos"
            onActionClick={documentation.show}
            Illustration={NecessaryDocuments}
          />
        </Container>
      </CourseContent>
      <TestAndEdictModal
        dialog={testandEdict}
        onCallToActionClick={onCallToActionClick}
      />
      <RegistrationModal
        dialog={registration}
        onCallToActionClick={onCallToActionClick}
      />
      <DocumentModal
        dialog={documentation}
        onCallToActionClick={onCallToActionClick}
        requiredDegreeLevel={requiredDegreeLevel[degreeType]}
      />
    </div>
  );
};
SubscriptionProcess.propTypes = {
  onCallToActionClick: func,
  degreeType: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)).isRequired,
};
SubscriptionProcess.displayName = 'SubscriptionProcess';

export default SubscriptionProcess;
