import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  cx,
  css,
  Text,
  px2grid,
  boxShadow,
  px2rem,
  getSpaceSize,
  mediaQuery,
} from '@ampli/ui';
import { node, number, oneOf } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

const degreeTypeDescriptionMap = {
  UNDERGRADUATE: 'Graduação',
  GRADUATE: 'Pós-Graduação',
};

const BannerListCourse = ({ countCourses, degreeType, children }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  const [degreeTypeDescription] = useState(
    degreeTypeDescriptionMap[degreeType]
  );

  return (
    <div
      className={cx(
        'banner-list',
        css`
          width: 100%;
          height: ${px2grid(300)};
          background: url(${require('../../assets/banner-lista-cursos-mobile.jpg')});
          background-position: 50% 0%;
          background-repeat: no-repeat;
          background-size: cover;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-bottom: ${px2grid(70)};

          ${mediaQuery('mobile')} {
            padding: 0 ${px2grid(20)};
            min-height: ${px2grid(300)};
            height: auto;
          }

          ${mediaQuery('desktop')} {
            background: url(${require('../../assets/banner-lista-cursos.jpg')});
            background-position: 100% 0%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-bottom: ${px2grid(150)};
          }
        `
      )}
    >
      {isDesktop && (
        <div
          className={cx(
            'filter-list-breadcrumb',
            css`
              position: absolute;
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              height: fit-content;
              margin: ${getSpaceSize('small')} ${getSpaceSize('large')};
            `
          )}
        >
          <Link
            className={css`
              &:link,
              &:active {
                text-decoration: none;
              }
            `}
            to="/"
          >
            <Text size="small" color="neutral-background">
              {'Página Inicial'}
            </Text>
          </Link>
          <Link
            className={css`
              &:link,
              &:active {
                text-decoration: none;
              }
            `}
            to="/cursos"
          >
            <Text size="small" color="neutral-background">
              {' > Todos os cursos'}
            </Text>
          </Link>

          <Text size="small" color="neutral-background">
            {` > ${degreeTypeDescription}`}
          </Text>
        </div>
      )}
      <Text
        color="white"
        className={css`
          text-align: center;
        `}
        as="h1"
        size={isDesktop ? 'larger' : 'xx-large'}
      >
        {`Conheça os nossos cursos de ${degreeTypeDescription.toLowerCase()}`}
      </Text>
      <Text
        color="white"
        as="h4"
        size={isDesktop ? 'base' : 'small'}
        className={cx(
          'description-banner-list',
          css`
            ${mediaQuery('mobile')} {
              margin-top: ${getSpaceSize('x-small')};
              padding: 0 ${getSpaceSize('large')};
              text-align: center;
            }
          `
        )}
      >{`São mais de ${
        countCourses || 0
      } opções de cursos disponíveis em todo Brasil`}</Text>
      <div
        className={cx(
          'search-wrap',
          css`
            position: absolute;
            height: ${px2grid(150)};
            width: 100%;
            top: ${px2grid(210)};
            display: flex;
            justify-content: center;
            align-items: center;

            ${mediaQuery('mobile')} {
              position: initial;
              margin-top: ${px2grid(16)};
            }

            ${mediaQuery('desktop')} {
              top: ${px2grid(250)};
            }
          `
        )}
      >
        <div
          className={cx(
            'search-content',
            css`
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 ${getSpaceSize('large')};
              width: 100%;
              margin: 0 5%;
              max-width: ${px2grid(1200)};

              ${mediaQuery('mobile')} {
                padding: 0;
                margin: 5% 0;
                flex-direction: column;

                & > .select-value-wrap,
                .input-search-wrap {
                  width: 100%;
                }

                & > .input-search-wrap {
                  margin-left: 0;
                }
              }

              ${mediaQuery('desktop')} {
                background-color: white;
                border-radius: ${px2rem(8)};
                justify-content: flex-start;
                ${boxShadow()}
              }
            `
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

BannerListCourse.propTypes = {
  countCourses: number,
  degreeType: oneOf(Object.keys(degreeTypeDescriptionMap)).isRequired,
  children: node,
};

export default BannerListCourse;
