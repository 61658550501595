import * as React from 'react';

function SvgStudentIllustration(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 358 386"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M185.795 45.797s-36.65 40.475-34.121 50.789c2.529 10.314 4.667 42.186 16.909 40.421 20.396-2.937 25.974-92.389 17.212-91.21z"
        fill="#AD263D"
      />
      <path
        d="M124.297 156.422l-11.324-5.969-44.13 101.7-44.13-101.7-11.318 5.969L61.889 268.18 13.395 379.943l5.045 5.969h6.272l44.13-101.699 37.859 101.699h6.272l11.324-5.969-48.5-111.763 48.5-111.758z"
        fill="#311E69"
      />
      <path
        d="M118.018 156.422l-11.317-5.969-44.13 101.7-44.131-101.7-11.324 5.969 48.5 111.758-48.5 111.763 11.324 5.969 44.13-101.699 44.131 101.699 11.317-5.969L69.524 268.18l48.494-111.758zM185.006 66.769c-1.362-.404-2.131-1.725-1.706-2.944l17.536-50.15c.425-1.212 1.875-1.852 3.231-1.42l25.184 8.043a25.67 25.67 0 016.468 3.14l-21.913 52.446c-3.136-2.102-28.8-9.115-28.8-9.115z"
        fill="#614CD4"
      />
      <path
        d="M240.124 89.31c-.527 2.223-3.521 3.045-5.423 1.489 0 0-19.971-14.296-20.895-14.915l21.907-52.453c1.638 1.091 20.375 17.057 20.375 17.057.661.721.924 1.678.708 2.574L240.124 89.31z"
        fill="#8572ED"
      />
      <path
        d="M209.348 16.478l-4.735.04-18.177 52.225 26.392 7.612 21.556-49.738-25.036-10.139z"
        fill="#F2B6AE"
      />
      <path
        d="M234.384 26.617l-21.556 49.738 16.437 14.195 4.431 1.091 18.952-48.128-18.264-16.896z"
        fill="#fff"
      />
      <path d="M239.22 143.589H0v14.881h239.22v-14.881z" fill="#AD263D" />
      <path
        d="M358 143.589H118.78v14.881H358v-14.881zM159.073 15.178l-3.46 6.73c-2.745 5.336-.992 12.106 4.296 14.956 4.877 2.634 10.873 1.3 13.807-4.985 2.603-5.592.134-11.972-.493-15.098-1.43-7.114-9.604-9.047-14.15-1.603z"
        fill="#FF6161"
      />
      <path
        d="M157.016 22.07a9.24 9.24 0 01.135-1.98c.195-1.247.202-3.154-1.794-2.534-2.907.896-3.602 7.606-.587 8.603 2.226.741 2.34-2.418 2.246-4.09zM172.67 24.818c.297-.593.499-1.226.668-1.873.317-1.226 1.066-2.97 2.657-1.61 2.314 1.981.277 8.408-2.88 8.132-2.347-.209-1.193-3.153-.445-4.649z"
        fill="#FF6161"
      />
      <path
        d="M161.299 34.769c2.111.768 6.171 1.179 8.161.397 1.76-.694 3.912-3.058 4.377-3.59-.047.1-.081.208-.128.31-2.934 6.285-8.923 7.619-13.806 4.985-3.258-1.759-5.167-5.006-5.518-8.482 2.004 3.678 5.018 5.686 6.914 6.38z"
        fill="#AD263D"
      />
      <path
        d="M159.107 27.358l-8.371 17.59c-1.369 2.904 2.368 13.69 8.667 12.113 1.842-.458 1.923-5.275 2.044-8.886l3.534-16.795-5.874-4.022z"
        fill="#FF6161"
      />
      <path
        d="M155.728 134.191c9.395-27.129 43.307-47.475 26.735-71.282-7.871-11.305-26.648-30.074-59.036-13.986-8.774 4.359-17.765 17.94-23.45 29.804-18.704 38.986 42.666 93.244 55.751 55.464z"
        fill="#FF6161"
      />
      <path
        d="M158.736 236.698l22.547 25.836s-50.437 89.088-39.605 96.499c19.479 13.318 82.548-66.197 78.63-93.225-3.926-27.035-33.616-47.124-61.572-29.11z"
        fill="#311E69"
      />
      <path
        d="M163.497.384c-10.569 2.526-9.847 6.043-9.847 6.043-2.421.06-4.404 1.927-5.854 4.608-1.855 3.22-10.846 15.831-13.834 16.963 0 0 1.478 2.446 4.621 5.262.505.451 2.853 2.014 3.277 2.573 2.489 3.281 4.803 3.584 6.941 4.487.816.343 2.273-1.711 2.273-1.711s.431 3.287 2.185 3.678c20.578 4.534 23.728-4.73 23.728-4.73s-1.916-9.694-2.071-16.02c1.835-2.202 3.143-4.223 3.399-5.719 1.262-7.491-4.249-17.96-14.818-15.434z"
        fill="#1E1240"
      />
      <path
        d="M78.34 169.182s-15.129 27.493-2.732 48.835c22.73 39.121 117.242 24.677 117.242 24.677s32.55 119.127 48.238 122.826c15.688 3.699 12.154-123.217 0-139.668-12.154-16.451-83.971-55.1-83.971-55.1l-78.778-1.57z"
        fill="#614CD4"
      />
      <path
        d="M153.643 176.242c11.304-3.671 7.453-12.726 9.962-25.788 1.848-9.627 11.736-33.038 14.393-40.455-5.058-8.131-11.648-20.049-12.315-27.91-.776-9.136 4.815-22.124 8.161-28.962-2.901-2.574-6.307-4.979-10.252-6.832-3.386 2.426-8.121 5.228-10.171 3.679-2.206-1.671-2.617-5.02-2.637-7.128-4.472-.39-9.389-.04-14.771 1.327-4.816 3.349-10.879 7.02-17.226 14.154-6.475 7.283-12.788 12.403-18.81 20.4-17.038 39.417-20.822 79.716-24.356 91.87 6.947 12.207 49.068 10.725 78.022 5.645z"
        fill="#311E69"
      />
      <path
        d="M247.232 360.858s10.913-1.698 17.644 3.395c3.164 2.392 4.317 8.947-.978 9.856-5.139.89-14.966.977-20.652.391-5.685-.586-12.154-1.273-6.373-5.093 5.787-3.82 10.359-8.549 10.359-8.549z"
        fill="#614CD4"
      />
      <path
        d="M242.39 228.116c-1.066 3.894-3.71 13.655-4.634 18.297-1.173 5.874-8.249-.195-9.517 18.216-1.268 18.412 10.104 88.132 16.383 97.145.25.357.493.654.742.93 10.151-17.886 6.92-113.751-2.974-134.588z"
        fill="#311E69"
      />
      <path
        d="M246.241 357.847c-1.255-1.529-5.645-2.931-7.918.135-2.273 3.065-1.066 7.733-2.401 11.6-1.336 3.867 9.611 4.669 15.485 3.867 5.875-.801 5.983-5.174 4.378-7.498-1.599-2.317-4.964-2.519-9.544-8.104zM182.463 62.908l32.483 49.22s27.127-50.466 32.583-43.972c5.457 6.495-3.311 73.223-27.484 77.791-31.113 5.874-49.176-41.378-51.267-53.134-2.09-11.755 13.685-29.905 13.685-29.905z"
        fill="#FF6161"
      />
      <path
        d="M252.426 55.202s-.668 1.852-1.059 2.944c-.202.565-.938 2.62-2.226 4.507.479-1.382.708-3.483-.047-4.38-.87-1.03-1.019 1.725-3.575 3.544s-4.094 9.256-4.094 9.256c.445 1.078 2.287 1.146 3.838.694.121.587.425.998.971 1.065 2.003.256 5.355-4.278 6.172-8.111.822-3.84.02-9.52.02-9.52z"
        fill="#FF6161"
      />
      <path
        d="M193.916 33.462c.755.195.438 1.462-.607 3.227-.31.518-1.201 3.132-2.125 5.22.924-1.138 3.285-2.943 4.445-2.775 1.335.196-.722 2.318-1.868 4.985-1.241 2.877-2.9 7.195-5.652 7.782-1.14.242-3.561-.129-4.034-3.153-.31-1.988.891-6.724 3.649-9.52 4.02-4.075 3.663-6.427 6.192-5.766z"
        fill="#AD263D"
      />
      <path
        d="M140.599 370.68s-11.371.883-13.334 2.251c-1.963 1.374 1.572 3.523 9.51 4.311 7.938.782 28.53-.391 31.862-5.679 3.332-5.288-9.51-9.203-14.609-9.007-5.099.195-11.763 5.484-13.429 8.124z"
        fill="#614CD4"
      />
      <path
        d="M177.998 226.634c-8.194-2.352-48.473-11.163-76.315-18.021-17.995-4.433-26.237-14.336-29.825-20.797-1.815 9.169-1.97 20.339 3.756 30.194 22.73 39.121 117.243 24.678 117.243 24.678s-6.671-13.703-14.859-16.054z"
        fill="#311E69"
      />
      <path
        d="M144.228 349.628s-5.227-.526-8.626 2.742c-3.4 3.267.782 15.798 2.616 18.412 1.828 2.613 10.981 1.435 13.335-2.87 2.354-4.312-5.619-7.182-4.836-10.705.775-3.53 3.959-8.798-2.489-7.579z"
        fill="#FF6161"
      />
      <path
        d="M46.76 386c-.478 0-.957-.094-1.43-.296-2.009-.869-3-3.369-2.211-5.585l40.67-114.021c.79-2.216 3.062-3.308 5.072-2.439 2.01.869 3.001 3.369 2.212 5.585l-40.67 114.021c-.6 1.698-2.078 2.735-3.642 2.735zM113.992 382.544a3.64 3.64 0 01-1.39.485c-2.145.296-4.101-1.381-4.364-3.746L94.54 257.724c-.27-2.365 1.254-4.514 3.4-4.81 2.144-.296 4.1 1.381 4.363 3.746l13.705 121.566c.203 1.812-.647 3.496-2.016 4.318zM191.339 382.544a3.63 3.63 0 01-1.389.485c-2.145.296-4.101-1.381-4.364-3.746l-13.698-121.559c-.27-2.365 1.254-4.514 3.399-4.81 2.145-.296 4.101 1.381 4.364 3.746l13.698 121.559c.216 1.819-.634 3.503-2.01 4.325z"
        fill="#311E69"
      />
      <path
        d="M96.428 141.453s-15.08 3.038-14.21 24.542H69.894s-4.35-19.934 6.812-24.542c11.17-4.601 19.722 0 19.722 0z"
        fill="#AD263D"
      />
      <path
        d="M75.587 268.146c23.775 22.663 130.429 20.379 135.966-18.526 3.548-24.92-61.815-34.459-67.73-67.982-5.908-33.515-9.098-51.712-35.49-55.545-26.385-3.833-46.977-1.28-49.877 16.283-2.894 17.556-10.265 99.658 17.131 125.77zm-1.922-104.859s0-22.049 15.115-22.036c12.626.014 22.183 6.555 22.905 22.036h-38.02z"
        fill="#FF6161"
      />
      <path
        d="M60 183.06c2.32-14.922 39.147-14.505 51.692-3.254 13.732 12.322 26.243 24.178 39.362 36.311 13.118 12.133 47.489 25.856 55.319 33.179 2.32 2.17 3.258 4.312 3.44 6.252-14.987 33.145-111.712 34.055-134.219 12.598-8.936-8.515-14.17-22.993-17.07-39.242-1.35-17.974.067-36.79 1.477-45.844z"
        fill="#AD263D"
      />
      <path
        d="M221.158 386c.478 0 .957-.094 1.429-.296 2.01-.869 3.002-3.369 2.213-5.585l-40.671-114.028c-.789-2.216-3.062-3.308-5.072-2.438-2.01.869-3.001 3.368-2.212 5.584l40.67 114.021c.601 1.705 2.078 2.742 3.643 2.742zM349.347 156.422l-11.318-5.969-44.13 101.7-44.131-101.7-11.317 5.969 48.494 111.758-48.494 111.763 5.045 5.969h6.272l44.131-101.699 37.858 101.699h6.272l11.318-5.969-48.495-111.763 48.495-111.758z"
        fill="#311E69"
      />
      <path
        d="M343.074 156.422l-11.317-5.969-44.131 101.7-44.13-101.7-11.324 5.969 48.494 111.758-48.494 111.763 11.324 5.969 44.13-101.699 44.131 101.699 11.317-5.969L294.58 268.18l48.494-111.758z"
        fill="#614CD4"
      />
      <path d="M358 143.589H180.319v14.881H358v-14.881z" fill="#FF6161" />
    </svg>
  );
}

export default SvgStudentIllustration;
