import React from 'react';

import {
  cx,
  css,
  getSpaceSize,
  px2grid,
  Text,
  ColorNeutralBackground,
  ColorNeutralLighter,
} from '@ampli/ui';
import { number, bool } from '@ampli/utils';

import Coins from './coins';
import PriceInfoFilter from './price-info-filter';

const PriceFlavorText = ({ fixedPrice }) => {
  const fixedPriceText = (
    <>
      {'Mensalidades '}
      <strong>{'fixas'}</strong>
      {' que '}
      <strong>{'cabem no seu bolso!'}</strong>
    </>
  );
  const variablePriceText = (
    <>
      {'Investimento mensal '}
      <strong>{'fixo, '}</strong>
      {'a partir de: '}
    </>
  );

  return (
    <Text
      color="black"
      size="small"
      as="h4"
      className={cx(
        'filter-info-title',
        css`
          text-align: center;
          width: ${px2grid(200)};
        `
      )}
    >
      {fixedPrice ? fixedPriceText : variablePriceText}
    </Text>
  );
};

PriceFlavorText.propTypes = {
  fixedPrice: bool,
};

const FilterInfoCourse = ({ minPrice, fixedPrice }) => {
  return (
    <div
      className={cx(
        'filter-info-wrap',
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: ${getSpaceSize('large')};
          background-color: ${ColorNeutralBackground};
          border-bottom: 1px solid ${ColorNeutralLighter};
          border-radius: ${px2grid(8)} ${px2grid(8)} 0 0;
        `
      )}
    >
      <PriceFlavorText fixedPrice={fixedPrice} />
      <div
        className={cx(
          'price-info-wrap',
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: ${px2grid(160)};
          `
        )}
      >
        <Coins
          className={cx(
            'coins-icon',
            css`
              width: ${px2grid(50)};
              height: ${px2grid(30)};
              margin-top: ${getSpaceSize('x-small')};
            `
          )}
        />
        <PriceInfoFilter price={minPrice} />
      </div>
      <Text as="h4" color="brand-base" size="small">
        {'Experimente por '}
        <strong>{'30 dias grátis!'}</strong>
      </Text>
    </div>
  );
};

FilterInfoCourse.propTypes = {
  minPrice: number,
  fixedPrice: bool,
};

export default FilterInfoCourse;
