import * as React from 'react';

const SvgIllustration = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M310.315 216.975l127.213-1.968c2.88-.015 5.19 1.538 5.666 4.747.285 1.92-1.924 5.753-5.112 6.035l-117.875 12.35-9.892-21.164z"
      fill="#68564E"
    />
    <path
      d="M440.248 218.915l32.629 158.088c.523 3.822-7.328 5.222-8.081 2.385L418.108 220.69l22.14-1.775z"
      fill="#68564E"
    />
    <path
      d="M449.742 281.377c0 4.512-3.754 10.132-8.491 10.295l-405.882.517v-12.283l414.373 1.471z"
      fill="#68564E"
    />
    <path
      d="M69.606 202.887l23.17 58.151-21.559 114.97c-.57 3.046 1.889 5.846 5.143 5.846 2.306 0 4.339-1.441 5.002-3.543l35.278-111.94c1.167-3.699.59-7.696-1.58-10.961l-37.432-56.337c-.827-1.244-2.263-1.998-3.808-1.998-3.153 0-5.33 3.005-4.214 5.812z"
      fill="#7F6D65"
    />
    <path
      d="M186.231 281.377c0 4.513-3.754 8.216-8.491 8.375l-142.376 4.859v-13.234h150.867z"
      fill="#7F6D65"
    />
    <path
      d="M48.214 255.645h332.708l-35.438-77.68c-2.45-5.1-7.804-8.372-13.696-8.372H11.098c-7.905 0-13.157 7.785-9.868 14.627l26.655 58.707c3.508 7.722 11.495 12.718 20.33 12.718z"
      fill="#B8D2D9"
    />
    <path
      d="M67.194 255.645H391.61l-35.438-77.68c-2.451-5.1-7.804-8.372-13.696-8.372H28.236c-7.905 0-13.157 7.785-9.868 14.627l25.925 57.099c3.949 8.695 12.95 14.326 22.9 14.326zM92.826 284.319h371.509c8.284 0 15.062-6.452 15.062-14.337 0-7.886-6.778-14.337-15.062-14.337H92.826c-8.284 0-8.284 28.674 0 28.674z"
      fill="#D8E7EB"
    />
    <path
      d="M38.454 284.319H184.17c8.284 0 15.062-6.452 15.062-14.337 0-7.886-6.778-14.337-15.062-14.337H38.454c-8.284 0-8.284 28.674 0 28.674z"
      fill="#B8D2D9"
    />
    <path
      d="M3.958 202.887l23.17 58.151-21.559 114.97c-.57 3.046 1.889 5.846 5.143 5.846 2.306 0 4.34-1.441 5.003-3.543l35.278-111.94c1.166-3.699.589-7.696-1.58-10.961L11.98 199.073c-.827-1.244-2.263-1.998-3.808-1.998-3.157 0-5.334 3.005-4.214 5.812z"
      fill="#7F6D65"
    />
    <path
      d="M331.418 209.165s-68.746-17.821-82.606-2.764c-13.86 15.058 9.279 39.267 43.359 46.77 34.076 7.503 80.362-7.989 71.204-21.788-9.154-13.794-31.957-22.218-31.957-22.218z"
      fill="#311E69"
    />
    <path
      d="M259.918 210.089c3.012-.304 6.052.186 8.861 1.274 15.323 5.943 88.31 35.037 86.718 49.556-1.779 16.22-100.114-8.068-106.244-27.939-1.697-5.489-3.437-21.475 10.665-22.891z"
      fill="#614CD4"
    />
    <path
      d="M353.507 263.924c-3.484-3.74-14.625-5.609-22.038-8.279-11.858-4.275-41.334-7.796-58.881-17.506-9.314-5.151-17.914-12.435-23.931-18.129-1.116 4.858-.258 10.226.589 12.974 5.669 18.363 90.105 40.5 104.261 30.94z"
      fill="#311E69"
    />
    <path
      d="M174.565 183.351s-54.757 28.785-28.309 53.147c30.041 27.675 69.549-44.107 72.421-40.938 2.762 3.049 10.2 27.489 24.458 49.377 3.184 4.888 14.351 2.637 14.792-2.519 1.354-15.885-8.815-58.547-13.056-70.577-7.328-20.782-33.062-18.884-70.306 11.51z"
      fill="#AD263D"
    />
    <path
      d="M190.239 171.77c-4.963 3.257-10.192 7.109-15.674 11.581 0 0-54.757 28.785-28.309 53.147 23.849 21.969 53.664-18.735 66.377-34.869-6.618-13.015-16.81-24.239-22.394-29.859z"
      fill="#1E1240"
    />
    <path
      d="M124.455 209.636s49.145 4.246 75.109 15.399c25.964 11.154 30.069 49.567 32.254 57.267 2.185 7.699 6.353 60.542.726 77.088-3.102 9.119-11.265 8.677-15.339.621-4.077-8.056-27.4-81.843-35.715-92.006-5.174-6.325-61.725-4.995-82.031-18.615-20.306-13.617-10.84-32.901-10.84-32.901l35.836-6.853z"
      fill="#FF6161"
    />
    <path
      d="M229.618 364.271c-1.066-4.186-2.517-8.632-3.821-12.576-4.069-12.346-20.091-79.154-30.517-93.439-10.426-14.282-14.496-7.02-44.428-15.396-22.37-6.258-49.692-19.574-62.284-26.259-.722 1.574-8.526 19.77 10.89 32.789 20.307 13.62 76.858 12.29 82.032 18.616 8.315 10.162 31.638 83.949 35.715 92.005 3.114 6.151 8.604 7.855 12.413 4.26zM166.441 128.707s36.808 55.799 29.858 65.159c-6.949 9.36-23.943 5.486-28.859-1.776-4.917-7.261-24.263-61.608-.999-63.383z"
      fill="#AD263D"
    />
    <path
      d="M184.414 158.573c-8.444-15.421-17.973-29.866-17.973-29.866-17.746 1.356-10.695 33.302-4.171 51.602 5.74-2.34 17.574-8.602 22.144-21.736z"
      fill="#FFD0CF"
    />
    <path
      d="M162.913 190.605c-2.969-12.874-2.427-24.819-2.15-26.082 5.248-23.774 15.093-31.177-1.674-42.81-9.825-6.815-50.39-12.706-65.667 4.595-12.295 13.924-9.864 52.192-10.121 75.751-.051 4.736-.04 26.42 3.332 28.403 25.016 14.701 55.26-8.353 76.28-39.857z"
      fill="#fff"
    />
    <path
      d="M162.429 263.392c1.846-7.51 9.396-34.631 23.81-43.011-26.56-7.703-61.784-10.745-61.784-10.745l-35.832 6.849s-9.47 19.285 10.84 32.901c13.532 9.081 43.176 11.518 62.966 14.006z"
      fill="#1E1240"
    />
    <path
      d="M254.56 241.025s10.77 9.279 15.378 10.319c5.564 1.255 3.773 2.73 5.857 4.182 4.393 3.068.027 3.904-1.319 3.919-1.346.014-10.091.78-12.373-.658-5.042-3.172-15.577 2.515-15.893-7.362-.312-9.876 4.507-11.707 8.35-10.4zM229.099 356.033s12.989 16.421 17.547 18.066c4.557 1.645-.211 3.978-4.124 5.322-2.502.858-7.094 1.932-12.03-2.83-4.371-4.212-15.882-3.305-12.19-12.847 3.691-9.542 7.656-10.367 10.797-7.711zM388.071 359.907s-12.99 16.421-17.547 18.066c-4.558 1.645.21 3.978 4.124 5.322 2.501.858 7.094 1.932 12.03-2.83 4.37-4.212 15.881-3.305 12.19-12.847-3.692-9.538-7.656-10.367-10.797-7.711z"
      fill="#444"
    />
    <path
      d="M22.462 216.975l127.213-1.968c2.88-.015 5.19 1.538 5.666 4.747.284 1.92-1.924 5.753-5.112 6.035l-117.876 12.35-9.891-21.164z"
      fill="#7F6D65"
    />
    <path
      d="M152.395 218.915l32.628 158.088c.523 3.822-7.328 5.222-8.081 2.385L130.255 220.69l22.14-1.775z"
      fill="#7F6D65"
    />
    <path
      d="M246.198 249.665s-6.876-8.848-7.535-12.231c-.659-3.384 15.67-6.166 19.459-3.867l.863 14.085s-10.189 3.342-12.787 2.013z"
      fill="#444"
    />
    <path
      d="M157.681 164.735l.054.242.129.546.176.713c.043.171.082.341.125.512l.28 1.111.141.531.176.657c.054.201.105.398.159.595l.164.594.164.591c.063.226.129.453.195.676.09.319.184.635.277.947l.164.549.188.624.152.494.199.639c.047.145.09.286.136.431.129.412.262.817.391 1.222l.117.36.218.665.129.383.222.653c.039.112.074.22.113.331.172.501.344.992.516 1.475.003.011.007.018.011.029a112.181 112.181 0 002.681 6.853c.882-2.206.148-7.714-.437-12.183-.765-5.816 3.028-33.443 3.028-33.443-5.225 3.373-8.218 14.126-9.868 23.203z"
      fill="#311E69"
    />
    <path
      d="M210.943 343.297c2.954 8.39 5.225 14.671 6.259 16.714 4.078 8.056 12.237 8.498 15.339-.62 1.409-4.145 2.205-10.567 2.571-17.988-14.71-3.392-24.165 1.89-24.169 1.894z"
      fill="#444"
    />
    <path
      d="M145.148 101.827s4.998-3.187 1.779-7.11c-4.792-5.838-13.38.532-14.652 2.883-1.896 3.503-1.182 9.869-1.182 9.869s12.283-1.434 14.055-5.642z"
      fill="#311E69"
    />
    <path
      d="M131.098 107.469s-.714-6.366 1.182-9.869c0 0 0-.004.004-.004 2.599 1.683 8.217 3.93 12.069 5.382-3.255 3.321-13.255 4.491-13.255 4.491z"
      fill="#614CD4"
    />
    <path
      d="M133.434 103.669l-7.172 11.637c-.398 1.757 8.499 8.602 11.901 6.949.999-.482 1.124-2.217.718-4.204l-2.626-10.905-2.821-3.477z"
      fill="#FF6161"
    />
    <path
      d="M136.786 109.337c-1.967-.746-4.64-1.797-4.64-1.797s-.495 3.127 1.846 6.013c1.237 1.526 2.868 2.462 4.511 2.904l-1.717-7.12z"
      fill="#311E69"
    />
    <path
      d="M136.977 100.063l-4.342 6.247c-1.257 2.165-.484 4.895 1.755 6.151 1.791 1.003 3.044 1.333 5.6 1.694 2.13.3 3.73-1.304 4.472-3.878.963-3.336.85-6.671.686-8.45-.37-4.06-5.029-5.638-8.171-1.764z"
      fill="#FF6161"
    />
    <path
      d="M141.367 111.77c-2.181-1.044-4.889 1.824-8.081-2.399-.597-.788-.593-2.307-.554-3.198l-.094.137c-1.256 2.166-.483 4.895 1.756 6.151 1.791 1.003 3.044 1.333 5.6 1.694 1.299.182 2.399-.346 3.231-1.367-.433-.045-1.194-.702-1.858-1.018z"
      fill="#AD263D"
    />
    <path
      d="M134.597 110.522a.254.254 0 01-.125-.033c-.117-.064-1.175-.728-1.62-3.915-.351-2.526.453-4.773 1.429-5.694.409-.383.854-.557 1.291-.494.187.026.761.104.648 4.104a.251.251 0 01-.262.234.246.246 0 01-.245-.249c.043-1.526-.043-3.305-.258-3.617-.328-.023-.624.182-.815.364-.867.813-1.608 2.941-1.28 5.285.413 2.953 1.362 3.554 1.373 3.562.117.07.156.219.086.33a.275.275 0 01-.222.123z"
      fill="#fff"
    />
    <path
      d="M111.462 141.581s1.849 14.121-2.81 31.307c-4.663 17.186-4.409 24.447-3.73 26.304.679 1.857-7.168-1.285-7.882-5.809-2.384-15.087.764-27.274 3.898-34.535 3.133-7.261 10.524-17.267 10.524-17.267z"
      fill="#311E69"
    />
    <path
      d="M128.279 205.907l65.566-7.09 38.743 5.486-63.556 6.871-41.525-4.773c.148-.263.429-.456.772-.494z"
      fill="#614CD4"
    />
    <path
      d="M169.032 211.178l-1.588 5.051c-.012 0-.105-.015-.269-.037-3.176-.468-33.44-5.047-38.638-5.835a.98.98 0 01-.843-.839l-.304-2.544a.922.922 0 01.117-.569l41.525 4.773zM171.018 209.336l-33.62-3.091 36.277-3.922-2.657 7.013z"
      fill="#311E69"
    />
    <path
      d="M169.031 211.178l63.556-6.872 10.094-50.543c.227-1.133-.764-2.154-1.97-2.02l-59.814 6.463c-.784.085-1.416.646-1.564 1.381l-10.302 51.591z"
      fill="#614CD4"
    />
    <path
      d="M204.138 180.064c-.429 2.236.839 4.327 2.837 4.676 1.998.349 3.964-1.181 4.394-3.417.429-2.236-.839-4.327-2.837-4.676-1.998-.35-3.965 1.181-4.394 3.417zM232.584 204.307l-2.536 3.963c-.609.954-1.651 1.586-2.813 1.712l-57.629 6.232c-.718.078-1.444.082-2.162.019l1.588-5.051 63.552-6.875z"
      fill="#311E69"
    />
    <path
      d="M159.09 121.709c-1.932-1.34-5.053-2.644-8.932-3.74.402 6.192 1.498 25.019.698 36.541-.944 13.624-6.563 39.501-.702 52.798 4.515-5.137 8.803-10.772 12.76-16.703-2.97-12.874-2.427-24.819-2.15-26.082 5.248-23.778 15.092-31.177-1.674-42.814z"
      fill="#FFD0CF"
    />
    <path
      d="M102.561 125.836c20.279 5.107-7.414 63.06-.527 70.723 6.887 7.666 54.25-9.13 56.946 2.094 2.653 11.058-67.555 38.892-81.017 11.633-10.559-21.375 4.577-89.49 24.598-84.45z"
      fill="#FF6161"
    />
    <path
      d="M77.16 165.233c5.27 1.645 18.721 5.36 28.121 3.788a671.86 671.86 0 001.319-5.578l-27.76-5.457a132.465 132.465 0 00-1.68 7.247z"
      fill="#311E69"
    />
    <path
      d="M107.295 167.844c4.351-18.222 8.331-39.378-4.729-42.491-11.929-2.841-19.982 15.949-24.723 36.715 3.492 2.467 12.986 6.493 29.452 5.776z"
      fill="#fff"
    />
    <path
      d="M156.517 203.742c-5.462.093-14.113 3.484-21.215 4.487-11.323 1.597-22.752 8.713-42.032 5.163-11.203-2.062-16.385-14.423-18.76-24.418-.09 8.654.967 16.28 3.456 21.316 11.683 23.664 66.127 5.809 78.551-6.548z"
      fill="#AD263D"
    />
    <path
      d="M154.283 196.685s6.664-.468 9.278.423c2.615.892 6.466 9.067 5.233 9.256-1.233.189-2.864-3.384-2.864-3.384s1.334 4.651.64 5.074c-.691.423-1.136.189-1.136.189s.149 1.13-1.084.892c-1.233-.234-3.013-4.743-3.013-4.743s.519 3.428-.148 3.617c-.437.123-.89-1.407-.89-1.407l-1.186-2.537s-7.047 1.786-8.018-1.196c-.785-2.437.323-5.62 3.188-6.184z"
      fill="#FF6161"
    />
    <path
      d="M94.653 151.022c-1.308 3.74-3.262 9.783-5.1 15.555 4.624.981 10.512 1.583 17.742 1.271 4.351-18.222 8.331-39.379-4.729-42.491a10.068 10.068 0 00-1.694-.26c-1.556 7.748-4.17 20.045-6.22 25.925z"
      fill="#FFD0CF"
    />
    <path
      d="M340.237 180.056s-32.172 16.506-51.245 20.302c-19.073 3.796-.8 8.395 19.373 7.789 20.174-.605 39.247-7.142 40.772-12.346 1.526-5.2.512-17.813-8.9-15.745z"
      fill="#AD263D"
    />
    <path
      d="M396.819 200.421c-9.919-25.557-16.908-43.267-33.823-53.756-12.712-7.881-20.914-4.267-26.346-.241-8.764 6.496-12.619 21.427-3.988 29.049 15.015 13.256 6.755 26.627 2.248 30.497-11.277 9.694 70.872 15.548 65.592 2.181-1.034-2.623-2.649-5.059-3.683-7.73z"
      fill="#FF6161"
    />
    <path
      d="M396.819 200.422c-9.919-25.558-16.908-43.268-33.823-53.757-4.464-2.767-8.37-4.115-11.796-4.527-.226 5.913.356 15.833 5.565 24.254 8.011 12.951 22.253 31.073 21.106 43.921a55.524 55.524 0 01-.804 5.627c14.012-.238 25.515-2.519 23.435-7.789-1.034-2.622-2.649-5.059-3.683-7.729z"
      fill="#AD263D"
    />
    <path
      d="M328.881 208.537s2.848.134 5.322-1.79c0 0 13.357 6.496 23.904 7.792 10.547 1.297-3.692 7.484-11.991 6.99-8.3-.49-17.235-12.992-17.235-12.992z"
      fill="#311E69"
    />
    <path
      d="M400.502 208.151s-12.365 2.006-24.017 3.45c-9.201 1.141-17.957 1.564-19.822 2.946-1.881 1.393-62.455 27.734-66.525 61.14-3.687 30.275 71.399 84.829 94.475 90.534 4.897 1.211 9.209-2.225 9.853-3.146 8.202-11.74-60.524-83.165-60.524-83.165s54.39-17.015 63.513-24.559c15.003-11.402 3.047-47.2 3.047-47.2z"
      fill="#614CD4"
    />
    <path
      d="M370.695 152.534c-1.896.516-5.943 1.534-9.018 1.716-3.941.23-8.795-8.892-8.775-11.834-7.207-1.467-12.424 1.166-16.256 4.008-1.475 1.092-2.805 2.429-3.968 3.926-.765 1.972-1.253 4.082-1.939 6.158-.535 1.612-1.912 3.447-2.833 5.705-.472 4.944.878 9.839 4.752 13.26 1.737 1.534 3.157 3.068 4.312 4.587 6.672-1.623 35.828-8.71 46.114-11.169-3.539-6.373-7.484-11.819-12.389-16.357zM329.935 134.939s-2.029-5.493.573-8.383c2.599-2.889 8.475-2.8 12.206.754 3.73 3.559 2.622 11.637.203 12.577-2.416.94-13.259-.91-12.982-4.948z"
      fill="#311E69"
    />
    <path
      d="M342.183 134.969l3.703 9.821c.601 1.619-2.084 7.168-5.522 5.998-1.006-.342-.78-2.912-.647-4.843l-1.042-9.137 3.508-1.839z"
      fill="#FF6161"
    />
    <path
      d="M339.016 139.797l.448 3.949c2.084-1.419 2.533-5.854 2.533-5.854s-1.51.977-2.981 1.905z"
      fill="#311E69"
    />
    <path
      d="M340.048 131.861l.902 1.474c1.908 2.652 1.408 6.366-1.339 8.238-2.536 1.727-5.97 1.423-8.049-1.723-1.85-2.797-.921-6.355-.792-8.056.292-3.878 9.278.067 9.278.067z"
      fill="#FF6161"
    />
    <path
      d="M338.686 140.548c-1.123.55-3.359 1.04-4.522.758-1.03-.249-2.396-1.363-2.692-1.616.031.052.058.108.093.156 2.08 3.146 5.514 3.447 8.05 1.723 1.694-1.151 2.529-3.004 2.478-4.88-.859 2.095-2.396 3.365-3.407 3.859z"
      fill="#AD263D"
    />
    <path
      d="M335.83 126.274s-2.946-.397-1.1-4.138c1.846-3.74 5.829-2.778 8.167-1.192 2.337 1.586 1.717 7.808-.589 7.741-2.31-.063-6.478-2.411-6.478-2.411z"
      fill="#311E69"
    />
    <path
      d="M370.695 152.534c-1.896.516-5.943 1.534-9.017 1.716-3.941.23-8.795-8.892-8.776-11.834a20.599 20.599 0 00-3.855-.42c.511 6.092 2.31 22.412 7.332 28.752a52.272 52.272 0 003.297 3.793 8938.613 8938.613 0 0123.404-5.654c-3.535-6.369-7.48-11.815-12.385-16.353z"
      fill="#1E1240"
    />
    <path
      d="M335.222 209.974l-39.703-7.298-23.943 1.597 38.486 7.076 25.608-1.037a.622.622 0 00-.448-.338z"
      fill="#FFCACA"
    />
    <path
      d="M310.058 211.349l.718 3.157.167-.011c1.963-.142 20.681-1.572 23.896-1.817a.61.61 0 00.558-.475l.312-1.542a.564.564 0 00-.043-.352l-25.608 1.04z"
      fill="#F9B2B2"
    />
    <path
      d="M308.938 210.13l20.692-.364-21.968-4.037 1.276 4.401z"
      fill="#FFE6E6"
    />
    <path
      d="M310.058 211.349l-38.486-7.076-3.64-31.337c-.082-.702.577-1.281 1.307-1.148l36.218 6.66c.476.085.835.457.886.914l3.715 31.987z"
      fill="#fff"
    />
    <path
      d="M290.166 190.75c.152 1.385-.73 2.607-1.967 2.726-1.237.122-2.361-.903-2.513-2.288-.152-1.386.73-2.608 1.967-2.726 1.237-.123 2.36.902 2.513 2.288z"
      fill="#311E69"
    />
    <path
      d="M271.572 204.273l1.354 2.537a2.322 2.322 0 001.635 1.174l34.9 6.414a6.55 6.55 0 001.319.108l-.718-3.157-38.49-7.076z"
      fill="#FFCACA"
    />
    <path
      d="M396.818 200.421s-3.941 6.92-12.759 12.733c-8.815 5.809-34.069 10.786-47.597 4.275-4.308-2.073 5.042-1.861 13.251-4.275 17.575-5.17 47.105-12.733 47.105-12.733z"
      fill="#311E69"
    />
    <path
      d="M355.516 144.366c3.536-4.709 54.008 29.647 42.317 53.86-10.648 22.052-68.714 25.298-68.952 13.728-.156-7.618 42.282-11.885 50.773-21.476 9.771-11.038-32.039-35.582-24.138-46.112z"
      fill="#FF6161"
    />
    <path
      d="M397.833 198.222c10.465-21.672-28.875-51.471-39.777-54.105 5.826 4.55 15.998 12.955 20.829 19.73 6.992 9.802 16.783 18.861 13.731 27.708-3.051 8.844-15.108 15.682-22.112 17.832-7.004 2.151-22.008 2.886-30.272 3.729-5.315.543-8.842-.256-11.187-.208 3.375 10.408 58.448 6.734 68.788-14.686z"
      fill="#AD263D"
    />
    <path
      d="M329.353 208.719c-2.26-.955-4.882.152-7.481-.527-2.594-.68-2.684 1.11-.92 2.135.694.401 1.373.531 1.986.587-1.245 1.326-7.055 3.239-7.055 3.239s1.038 1.148 4.897 1.441c3.859.293 7.589.709 9.337-.969 1.101-1.055 1.495-4.951-.764-5.906z"
      fill="#FF6161"
    />
    <path
      d="M400.502 208.151s-3.703.598-8.959 1.378c1.83 7.915 2.931 19.254-2.825 26.943-9.665 12.911-36.074 19.173-54.249 26.95-14.106 6.032-23.241 8.068-4.839 29.855 18.402 21.788 46.883 48.415 52.478 56.642a3112.256 3112.256 0 0010.2 14.898c1.135-.683 1.912-1.396 2.154-1.746 8.202-11.74-60.524-83.165-60.524-83.165s54.39-17.015 63.513-24.558c15.007-11.399 3.051-47.197 3.051-47.197z"
      fill="#311E69"
    />
    <path
      d="M145.144 106.607s-.936.398-3.563-.208c-3.148-.724-5.735-2.533-5.735-2.533s-2.627 3.569-1.831 6.314c.301 1.037 4.324 4.008 8.733 3.247 1.662-.286 2.65-4.261 2.396-6.82z"
      fill="#fff"
    />
    <path
      d="M142.748 113.427c.854-.149 1.529-1.274 1.951-2.678-1.343.096-3.801.107-6.201-.736-2.376-.839-3.691-2.117-4.304-2.871-.312.988-.464 2.065-.179 3.042.3 1.036 4.323 4.008 8.733 3.243z"
      fill="#E8E8E8"
    />
    <path
      d="M135.626 102.889a6.27 6.27 0 01.257-1.363c.246-.85.402-2.169-1.104-1.894-2.189.401-3.406 4.528-1.295 5.449 1.564.683 2.075-1.025 2.142-2.192z"
      fill="#FF6161"
    />
    <path
      d="M340.946 140.31a.184.184 0 01-.156-.078.174.174 0 01.051-.252c.249-.164.737-1.653.905-3.377.144-1.493-.098-3.632-.617-4.056-.085-.066-.148-.063-.195-.052-.569.138-.807 1.731-.827 2.704 0 .101-.074.194-.195.179-.105-.004-.191-.086-.187-.186.004-.286.078-2.797 1.112-3.049a.597.597 0 01.538.13c.757.616.863 3.186.749 4.364-.148 1.519-.612 3.343-1.069 3.643a.223.223 0 01-.109.03z"
      fill="#fff"
    />
    <path
      d="M340.443 133.305c.093-.345.132-.698.152-1.054.031-.676.246-1.676 1.276-1.137 1.498.784 1.123 4.379-.648 4.58-1.315.148-1.018-1.52-.78-2.389z"
      fill="#FF6161"
    />
    <path
      d="M330.527 136.908s.98.167 3.625-.275c1.803-.301 5.939-1.664 5.939-1.664s1.448 4.294 1.139 5.204c-.308.906-6.738 2.433-8.264 1.976-.8-.242-1.518-1.07-1.935-2.121-.383-.958-.504-1.891-.504-3.12z"
      fill="#7BDBD2"
    />
    <path
      d="M341.07 138.416c.168.773.262 1.452.16 1.757-.308.906-6.738 2.433-8.264 1.976-.71-.212-1.35-.892-1.779-1.776a27.713 27.713 0 002.641-.196c3.516-.409 6.501-1.482 7.242-1.761z"
      fill="#47BFB1"
    />
    <path
      d="M335.689 136.392c-.889-.327-1.572-1.17-1.72-2.281-.254-1.887 1.252-2.232 2.466-2.511 1.213-.278 2.606-.509 2.86 1.375 0 0 .234 1.047-.414 2.213-.386.695-1.073 1.122-1.787 1.285a2.437 2.437 0 01-1.405-.081zm2.896-3.726a.863.863 0 00-.839-.531c-.324.019-.722.093-1.21.204-1.529.353-2.146.658-2.013 1.642.168 1.241 1.268 1.995 2.47 1.749 1.073-.215 2.177-1.585 1.592-3.064zM330.622 137.625c-.367-.097-.722-.312-1.038-.628-.511-.516-.855-1.244-.972-2.05-.117-.821.051-1.408.511-1.746.371-.275.882-.356 1.464-.427.581-.071 1.092-.119 1.533.056.535.215.87.739.987 1.56.238 1.656-.573 3.127-1.81 3.283a1.73 1.73 0 01-.675-.048zm1.287-4.112c-.331-.089-.761-.044-1.213.011-.507.063-.983.138-1.276.353-.195.145-.367.368-.273 1.01.086.598.343 1.141.726 1.527.386.39.85.576 1.314.516.945-.115 1.569-1.225 1.39-2.47-.094-.642-.32-.813-.551-.906-.039-.019-.078-.03-.117-.041z"
      fill="#fff"
    />
    <path
      d="M333.021 134.36l-.472-.446c.172-.379.726-.951 1.627-.393l-.172.679c-.71-.438-.979.153-.983.16z"
      fill="#fff"
    />
    <path
      d="M304.756 7.261V53.11H145.628V7.26h159.128zM312.384 0H138v60.375h174.388V0h-.004z"
      fill="#311E69"
      fillOpacity={0.5}
    />
    <path d="M294.583 43.43H155.801V16.946h138.782V43.43z" fill="#FFD0CF" />
    <path
      d="M180.902 32.537c.019.624.57 1.114 1.229 1.096l50.952-1.564c.656-.019 1.171-.543 1.151-1.17-.019-.628-.569-1.115-1.229-1.096l-50.952 1.564c-.656.022-1.171.546-1.151 1.17z"
      fill="#FF8B85"
    />
    <path
      d="M230.578 31.014s6.056 5.482 15.085 3.343c9.029-2.14 13.31-6.4 13.31-6.4s-17.902-8.602-28.395 3.057zM221.634 31.289s8.346-.405 12.931-8.112c4.585-7.707 4.289-13.594 4.289-13.594s-18.874 6.433-17.22 21.706z"
      fill="#FF8B85"
    />
    <path
      d="M240.221 51.38s-8.354.107-13.454-7.303c-5.1-7.41-5.198-13.3-5.198-13.3s19.272 5.262 18.652 20.602z"
      fill="#FF8B85"
    />
  </svg>
);

export default SvgIllustration;
