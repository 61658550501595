/**
 * Splits query string into an array of query params
 * @param {string} queryString
 * @returns {string[]}
 */
export function splitQueryString(queryString) {
  // slice(1) to remove leading "?" from query string;
  return queryString?.length ? queryString.slice(1).split('&') : [];
}

/**
 * Parse query param into a key-value object
 * @param {string} queryParam
 * @returns {{key: string, value: string}}
 */
export function transformQueryParamIntoObject(queryParam) {
  if (!queryParam) return null;

  const splitQueryParam = queryParam.split('=');
  return (
    splitQueryParam?.length && {
      key: splitQueryParam[0],
      value: splitQueryParam[1],
    }
  );
}

/**
 * Gets a query string and a list of allowed query params, and filters
 * out an object with the rejected query params.
 * @param {string} queryString
 * @param {string[]} allowedQueryParams
 * @returns {Object.<string, string>}
 */
export function filterQueryStringIgnoredParams(
  queryString,
  allowedQueryParams
) {
  const objectQueryParamsArray = splitQueryString(
    queryString
  ).map((queryParam) => transformQueryParamIntoObject(queryParam));

  let ignoredQueryParams = {};

  for (const queryParam of objectQueryParamsArray) {
    if (
      !allowedQueryParams.find(
        (allowedFilter) => allowedFilter === queryParam.key
      )
    )
      ignoredQueryParams = {
        ...ignoredQueryParams,
        ...{ [queryParam.key]: queryParam.value },
      };
  }

  return ignoredQueryParams;
}
