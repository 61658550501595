import React from 'react';

import {
  css,
  cx,
  px2grid,
  getColor,
  mediaQuery,
  Flex,
  Text,
  Button,
  Icon,
} from '@ampli/ui';
import { string, number, node, func } from '@ampli/utils';

const SpotWrapper = ({ onViewOnMap, className, children, ...props }) => (
  <Flex alignItems="flex-start" justifyContent="space-between" direction="row">
    <div
      {...props}
      className={cx(
        'spot-wrapper',
        css`
          width: 100%;
          margin-top: ${px2grid(16)};
          margin-bottom: ${px2grid(16)};
          padding-left: ${px2grid(20)};
          border-left: 1px solid ${getColor('brand-base')};
        `,
        className
      )}
    >
      <div>{children}</div>
      <ViewOnMapButton onClick={onViewOnMap}>Ver no mapa</ViewOnMapButton>
    </div>
  </Flex>
);

SpotWrapper.propTypes = {
  onViewOnMap: func,
  className: string,
  children: node.isRequired,
};

const ViewOnMapButton = ({ onClick, children, className, ...props }) => (
  <Button
    size="small"
    onClick={onClick}
    className={cx(
      'view-on-map-button',
      css`
        border: 1px solid ${getColor('undergraduate-brand-base')};
        border-radius: 3rem;
        color: ${getColor('brand-light')};
        font-weight: 600;
        margin-left: ${px2grid(24)};

        ${mediaQuery('tablet-max')} {
          display: none;
        }
      `,
      className
    )}
    {...props}
  >
    {children}
  </Button>
);

ViewOnMapButton.propTypes = {
  onClick: func,
  children: node.isRequired,
  className: string,
};

const SpotInformationWrapper = ({ className, children, ...props }) => (
  <Flex alignItems="flex-start" justifyContent="flex-start" direction="column">
    <div
      {...props}
      className={cx(
        'spot-information-wrapper',
        css`
          width: 100%;
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

SpotInformationWrapper.propTypes = {
  className: string,
  children: node.isRequired,
};

const ContentHeader = ({ className, children, ...props }) => (
  <Flex alignItems="center" justifyContent="flex-start" direction="row">
    <div
      {...props}
      className={cx(
        'content-header',
        css`
          margin-bottom: ${px2grid(12)};

          ${mediaQuery('desktop')} {
            margin-bottom: ${px2grid(24)};
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

ContentHeader.propTypes = {
  className: string,
  children: node.isRequired,
};

const CityInformation = ({ city, distance, ...props }) => (
  <Flex alignItems="center" justifyContent="flex-start" direction="row">
    <div {...props}>
      <Icon.MapPin
        className={css`
          color: black;
          margin-right: ${px2grid(10)};
        `}
      />
      <Text color={getColor('black')}>{city} - </Text>
      <Text color={getColor('brand-base')}>{distance}km de distância</Text>
    </div>
  </Flex>
);

CityInformation.propTypes = {
  city: string,
  distance: number,
};

const SpotItem = ({ city, distance, address, onViewOnMap, ...props }) => (
  <SpotWrapper onViewOnMap={onViewOnMap} {...props}>
    <SpotInformationWrapper>
      <CityInformation city={city} distance={distance} />
      <Text>{address}</Text>
    </SpotInformationWrapper>
  </SpotWrapper>
);

SpotItem.propTypes = {
  city: string,
  distance: number,
  address: string,
  onViewOnMap: func,
};

SpotItem.displayName = 'SpotItem';

export default SpotItem;
