import React from 'react';

import {
  cx,
  css,
  px2rem,
  px2grid,
  Text,
  Icon,
  fontSize,
  getSpaceSize,
  getColor,
} from '@ampli/ui';

import { string, deburr } from '@ampli/utils';

const degreeInfo = {
  tecnologo: {
    color: getColor('accent-base'),
    fillType: 'stroke',
    icon: 'Award',
    contentText:
      'Indicados para quem pretender se tornar especialista na profissão, por meio de um curso superior de menor duração.',
  },
  bacharelado: {
    color: getColor('semantic-success'),
    fillType: 'fill',
    icon: 'HatGraduation',
    contentText:
      'Indicada para quem pretende focar sua carreira no mercado de trabalho, exercendo ativamente a profissão.',
  },
  licenciatura: {
    color: getColor('brand-base'),
    fillType: 'stroke',
    icon: 'PaperCertificate',
    contentText:
      'Indicada para quem pretende ser docente, exercendo a profissão de professor no curso escolhido e áreas relacionadas.',
  },
  'pos-graduacao': {
    color: getColor('accent-base'),
    fillType: 'stroke',
    icon: 'Award',
    contentText:
      'Indicado para profissionais que desejam aprofundar ou adquirir novo conhecimento sobre um tópico específico, para agregar no dia a dia do trabalho.',
  },
  mba: {
    color: getColor('semantic-success'),
    fillType: 'fill',
    icon: 'HatGraduation',
    contentText:
      'Indicado para profissionais que já possuem experiência na área e desejam desenvolver competências gerenciais e de negociação.',
  },
};

const DegreeCard = ({ degree = '', ...props }) => {
  const degreeKey = React.useMemo(() => {
    if (!degree) return '';

    return deburr(degree.toLowerCase());
  }, [degree]);

  const resolvedIcon = degreeInfo[degreeKey]?.icon
    ? React.createElement(Icon[degreeInfo[degreeKey]?.icon], {
        width: '21',
        height: '16',
      })
    : '';

  const resolvedDegree = React.useMemo(() => {
    if (!degree) return '';
    const type = `${degree[0]?.toUpperCase()}${degree
      .substring(1)
      .toLowerCase()}`;
    switch (type) {
      case 'Tecnologo':
        return 'Tecnólogo';
      case 'Pos-graduacao':
        return 'Especialização';
      case 'Mba':
        return 'MBA';
      default:
        return type;
    }
  }, [degree]);

  return (
    <div
      className={cx(
        'degree-card',
        css`
          border-radius: ${px2rem(8)};
          width: ${px2grid(270)};
          background-color: white;
          padding: ${getSpaceSize('x-small')};
        `,
        fontSize('x-small'),
        props.className
      )}
    >
      <div
        className={cx(
          'title-degree-wrap',
          css`
            margin-bottom: ${getSpaceSize('x-small')};
          `
        )}
      >
        {resolvedIcon && (
          <Text
            size="x-large"
            className={cx(
              'title-icon-degree',
              css`
                margin-right: ${getSpaceSize('xx-small')};
                path {
                  ${degreeInfo[degreeKey]?.fillType}: ${degreeInfo[degreeKey]
                    ?.color};
                }
              `
            )}
          >
            {resolvedIcon}
          </Text>
        )}

        <Text>{resolvedDegree}</Text>
      </div>
      <Text>{degreeInfo[degreeKey]?.contentText || ''}</Text>
    </div>
  );
};

DegreeCard.propTypes = {
  degree: string,
  className: string,
};

export default DegreeCard;
