import React from 'react';

const useDetectIdScroll = (id = '', padding = 0) => {
  const [isReachedPoint, setIsReachedPoint] = React.useState(false);
  const [position, setPosition] = React.useState(0);

  const handleScroll = () => {
    if (id) {
      const componentId = document.getElementById(id);
      const bodyRect = document.body.getBoundingClientRect();
      const positionValue =
        componentId.getBoundingClientRect().top - bodyRect.top;
      setIsReachedPoint(window.pageYOffset > positionValue - padding);
      setPosition(positionValue);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return id ? { isReachedPoint, position } : null;
};

export default useDetectIdScroll;
