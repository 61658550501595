import React from 'react';

import { css, cx, getColor, PrimaryButton } from '@ampli/ui';

const CtaButton = (props) => {
  return (
    <PrimaryButton
      {...props}
      className={cx(
        'cta-button',
        css`
          background: ${getColor('accent-base')};
          border-color: ${getColor('accent-base')};
          color: white;
        `
      )}
    />
  );
};

export default CtaButton;
