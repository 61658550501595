import { useLocation } from 'react-router-dom';

const formatQueryParam = (value = '') => `${value ? `${value}&` : '?'}`;

const parseArrayValue = (value = [], key = '') =>
  value.reduce(
    (memo, cur, index) =>
      `${memo}${key}=${cur}${index === value.length - 1 ? '' : '&'}`,
    ''
  );

const parseQueryObject = (queryObject, queryParamsToTranslate = {}) => {
  if (!queryObject) return '';

  return Object.keys(queryObject).reduce((memo, key) => {
    if (
      !queryObject[key] ||
      (Array.isArray(queryObject[key]) && !queryObject[key]?.length)
    )
      return memo;

    if (Array.isArray(queryObject[key]) && queryObject[key]?.length) {
      return `${formatQueryParam(memo)}${parseArrayValue(
        queryObject[key],
        queryParamsToTranslate[key] || key
      )}`;
    }

    return `${formatQueryParam(memo)}${queryParamsToTranslate[key] || key}=${
      queryObject[key]
    }`;
  }, '');
};

export const useQueryParams = () => {
  const queryString = useLocation().search;
  const queryParams = new URLSearchParams(queryString);

  return { parseQueryObject, queryString, queryParams };
};
