import React from 'react';
import { Link } from 'react-router-dom';

import {
  css,
  cx,
  px2grid,
  px2rem,
  getColor,
  fontSize,
  boxShadow,
  Flex,
  FlexItem,
  Text,
} from '@ampli/ui';
import { string, node, func, bool } from '@ampli/utils';

const Container = ({ children, className, ...props }) => (
  <Flex alignItems="center" justifyContent="center" direction="row">
    <div
      {...props}
      className={cx(
        'recommendation-container',
        css`
          flex: 0 1 ${px2grid(286)};
          height: ${px2grid(148)};
          width: ${px2grid(286)};
          margin-bottom: ${px2grid(32)};
          border: ${px2rem(1)} solid ${getColor('neutral-lighter')};
          border-radius: ${px2rem(8)};
          padding: ${px2grid(24)};
          ${boxShadow()}
          cursor: pointer;
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
);

Container.propTypes = {
  children: node.isRequired,
  className: string,
};

const Title = ({ children, className, ...props }) => (
  <Text
    {...props}
    as="h3"
    className={css`
      color: ${getColor('brand-base')};
      font-weight: 300;
      margin-bottom: ${px2grid(8)};
    `}
  >
    {children}
  </Text>
);

Title.propTypes = {
  children: node.isRequired,
  className: string,
};

const Description = ({ children, className, ...props }) => (
  <Text
    {...props}
    className={css`
      color: ${getColor('neutral-dark')};
      ${fontSize('x-small')}
      font-weight: 400;
    `}
  >
    {children}
  </Text>
);

Description.propTypes = {
  children: node.isRequired,
};

const DescriptionWrapper = ({ children, ...props }) => (
  <FlexItem flex={2}>
    <div>
      <Flex alignItems="flex-start" justifyContent="center" direction="column">
        <div {...props}>{children}</div>
      </Flex>
    </div>
  </FlexItem>
);

DescriptionWrapper.propTypes = {
  children: node.isRequired,
};

const RecommendationImage = ({ children, className, ...props }) => (
  <FlexItem flex={1.5}>
    <div
      {...props}
      className={cx(
        css`
          svg: {
            display: block;
            margin: auto;
          }
        `,
        className
      )}
    >
      {children()}
    </div>
  </FlexItem>
);

RecommendationImage.propTypes = {
  children: func,
  className: string,
};

const RecommendationCard = ({
  image,
  title,
  description,
  urlLink,
  ...props
}) => (
  <Link to={urlLink}>
    <Container {...props}>
      <RecommendationImage>{image}</RecommendationImage>
      <DescriptionWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </DescriptionWrapper>
    </Container>
  </Link>
);

RecommendationCard.propTypes = {
  image: func,
  title: string,
  description: string,
  urlLink: string,
  noMargin: bool,
};

export default RecommendationCard;
