import React from 'react';

import { cx, css } from '@ampli/ui';
import { CollapsibleInfo } from '../collapsible-info';

import { string, arrayOf, shape } from '@ampli/utils';

const CollapsibleItems = ({ items, degree }) => {
  const [selected, setSelected] = React.useState();
  return (
    <div
      className={cx(
        'collapsible-items-wrap',
        css`
          display: flex;
          flex-direction: column;
        `
      )}
    >
      {!!items?.length &&
        items.map((item, index) => (
          <CollapsibleInfo
            key={`collapsible_item_${index}`}
            selected={selected}
            setSelected={setSelected}
            degree={degree}
            hasSpace={index !== items.length - 1}
            {...(item || {})}
          />
        ))}
    </div>
  );
};

CollapsibleItems.propTypes = {
  items: arrayOf(
    shape({
      label: string,
      icon: string,
      contentText: string,
    })
  ),
  degree: string,
};

export default CollapsibleItems;
