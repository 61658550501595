import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  css,
  cx,
  px2grid,
  getColor,
  fontSize,
  mediaQuery,
  Icon,
  Text,
} from '@ampli/ui';
import { string, array, object, bool, func, node } from '@ampli/utils';

import SpotItem from './spot-item';
import CourseContent from '../course-content/course-content';

const MobileToggleHeader = ({ isVisible, className, ...props }) => (
  <div
    className={cx(
      'mobile-toggle-header',
      css`
        background: ${getColor('neutral-background')};
        color: ${getColor('brand-dark')};
        ${fontSize('x-large')};
        padding: ${px2grid(10)} ${px2grid(24)};
        margin: ${px2grid(24)} 0;

        .indicator-arrow {
          ${fontSize('xx-small')}
          margin-left: ${px2grid(24)}
        }
      `,
      className
    )}
    {...props}
  >
    Lista de polos
    {isVisible ? (
      <Icon.ArrowUp className="indicator-arrow" />
    ) : (
      <Icon.ArrowDown className="indicator-arrow" />
    )}
  </div>
);

MobileToggleHeader.propTypes = {
  isVisible: bool,
  className: string,
};

const MobileSpotsDescription = ({ className, ...props }) => (
  <Text
    className={cx(
      'mobile-spots-description',
      css`
        display: block;
        margin: ${px2grid(12)} 0;

        ${mediaQuery('tablet-min')} {
          display: none;
        }
      `,
      className
    )}
    {...props}
  >
    São centenas de polos presenciais espalhados por todo o Brasil. Confira
    abaixo o endereço do polo EaD mais próximo de você:
  </Text>
);

MobileSpotsDescription.propTypes = {
  className: string,
};

const SpotsWrapper = ({ className, children, ...props }) => (
  <div
    {...props}
    className={cx(
      'spots-wrapper',
      css`
        margin-left: 0;
        margin-top: ${px2grid(16)};

        ${mediaQuery('tablet-min')} {
          margin-left: ${px2grid(44)};
          width: ${px2grid(585)};
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

SpotsWrapper.propTypes = {
  className: string,
  children: node.isRequired,
};

const SpotListLoader = ({ spotList, maxNumberOfSpots }) => (
  <>
    {spotList
      .map(({ city, distance, address, coordinates }, index) => {
        return (
          <SpotItem
            key={index}
            city={city}
            distance={distance}
            address={address}
            onViewOnMap={() =>
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${coordinates[1]},${coordinates[0]}`
              )
            } // TODO: change to use the map dialog view from the subscriptions page
          />
        );
      })
      .slice(0, maxNumberOfSpots)}
  </>
);

SpotListLoader.propTypes = {
  spotList: array,
};

const LocationNotFoundMessage = () => (
  <Text>
    Não conseguimos definir sua localização{' '}
    {/* <ViewMoreLink to="/polos">
      confira aqui nossa lista completa de polos
    </ViewMoreLink> */}
  </Text>
);

const ViewMoreLink = ({ to, children, onClick, ...props }) => (
  <Link
    {...props}
    to={to}
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }}
    className={cx(
      'view-more',
      css`
        color: ${getColor('brand-base')};
        margin-top: ${px2grid(16)};
        text-decoration: underline;
      `
    )}
  >
    {children}
  </Link>
);

ViewMoreLink.propTypes = {
  to: string,
  children: node.isRequired,
  onClick: func,
};

const NearbySpots = ({ currentLocation, spotList, ...props }) => {
  const [maxNumberOfSpots, setMaxNumberOfSpots] = useState(5);

  const handleViewMoreClick = () =>
    maxNumberOfSpots < spotList.length
      ? setMaxNumberOfSpots(maxNumberOfSpots + 5)
      : null;

  return (
    <CourseContent
      icon="MapPin"
      title="Nossos polos na região"
      id="nearby-spots"
      {...props}
    >
      <MobileSpotsDescription />
      <div>
        {(currentLocation?.city && currentLocation?.state && (
          <>
            {spotList?.length ? (
              <>
                <Text>
                  Sua localização atual:{' '}
                  <u>
                    {currentLocation.city}, {currentLocation.state}
                  </u>
                </Text>
                <SpotsWrapper>
                  <SpotListLoader
                    spotList={spotList}
                    maxNumberOfSpots={maxNumberOfSpots}
                  />
                  {maxNumberOfSpots < spotList.length && (
                    <ViewMoreLink to="/" onClick={handleViewMoreClick}>
                      Ver mais polos
                    </ViewMoreLink>
                  )}
                </SpotsWrapper>
              </>
            ) : (
              <Text>Infelizmente ainda não chegamos em sua região.</Text>
            )}
          </>
        )) || <LocationNotFoundMessage />}
      </div>
    </CourseContent>
  );
};

NearbySpots.propTypes = {
  currentLocation: object,
  spotList: array,
};

NearbySpots.displayName = 'NearbySpots';

export default NearbySpots;
