import React from 'react';

import { cx, css, px2grid, getSpaceSize, Text, mediaQuery } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import ProposalIllustration from './proposal-illustration';

const ProposalTitleDesktop = () => (
  <Text
    as="h2"
    className={cx(
      'ampli-proposal-title-wrap',
      css`
        display: flex;
        flex-direction: column;
        position: absolute;
        margin-right: ${px2grid(50)};
      `
    )}
  >
    <Text
      className={css`
        margin-left: ${px2grid(130)};
        font-weight: 100;
      `}
      color="brand-base"
      size={px2grid(96)}
    >
      {'Conheça '}
    </Text>
    <Text
      className={css`
        margin-right: ${px2grid(100)};
      `}
      size="larger"
      color="semantic-alert"
    >
      <strong>{'a proposta Ampli'}</strong>
    </Text>
  </Text>
);

const ProposalTitleMobile = () => (
  <div
    className={cx(
      'proposal-title-wrap',
      css`
        display: flex;
        position: relative;
        width: 100%;

        ${mediaQuery('tablet')} {
          width: auto;
        }
      `
    )}
  >
    <Text
      as="h2"
      className={cx(
        'proposal-title-content',
        css`
          display: grid;
          position: absolute;
        `
      )}
    >
      <Text
        className={css`
          margin-left: ${px2grid(30)};
          font-weight: 100;
        `}
        color="brand-base"
        size={px2grid(57)}
      >
        {'Conheça '}
      </Text>
      <Text
        className={css`
          width: ${px2grid(150)};
          margin-top: ${px2grid(20)};
          margin-left: ${px2grid(30)};
          text-align: right;
        `}
        size="large"
        color="semantic-alert"
      >
        <strong>{'a proposta Ampli'}</strong>
      </Text>
    </Text>
    <ProposalIllustration
      className={cx(
        'proposal-illustration',
        css`
          width: ${px2grid(150)};
          height: ${px2grid(150)};
          margin-left: ${px2grid(200)};
        `
      )}
    />
  </div>
);

const ProposalTitle = () => {
  const { isDesktop } = useBreakpoint(true);
  if (isDesktop) return <ProposalTitleDesktop />;

  return <ProposalTitleMobile />;
};

export default ProposalTitle;
