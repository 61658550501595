import React from 'react';

import { cx, css, px2rem, Text, px2grid } from '@ampli/ui';

import { string } from '@ampli/utils';

const LabelTypeCourse = ({ label }) => (
  <div
    className={cx(
      'label-type-course',
      css`
        position: absolute;
        top: ${px2grid(-7)};
        background-color: white;
        border-radius: ${px2rem(8)};
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 ${px2grid(16)};
        max-width: ${px2grid(130)};
      `
    )}
  >
    <Text
      className={css`
        font-weight: 500;
      `}
      size="small"
      ellipsis
    >
      {label}
    </Text>
  </div>
);

LabelTypeCourse.propTypes = {
  label: string,
};

export default LabelTypeCourse;
