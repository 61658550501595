import React from 'react';
import { cx, Text, Flex, GradientButton, BrandedCard } from '@ampli/ui';
import { string, node, bool } from '@ampli/utils';
import styles from './styles';

import Illustrations from './illustrations';

const DegreeCard = ({
  illustration,
  title,
  subtitle,
  children,
  link,
  openLinkInNewTab = false,
  className,
  ...props
}) => {
  const Illustration = Illustrations[illustration];

  return (
    <BrandedCard
      maxWidth={340}
      height={325}
      className={cx('degree-card', styles.degreeCard, className)}
      {...props}
    >
      <Flex
        direction="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <div className="card-content">
          {illustration && <Illustration className="illustration" />}
          <div className="title-wrapper">
            <Text
              as="h2"
              color="semantic-alert"
              size="huge"
              className="title"
              block
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                as="h3"
                color="semantic-alert"
                size="x-large"
                className="subtitle"
              >
                {subtitle}
              </Text>
            )}
          </div>
          <Text color="neutral-dark" className="description">
            {children}
          </Text>
          <GradientButton
            as="a"
            href={link}
            target={openLinkInNewTab && '_'}
            size="micro"
          >
            Conheça
          </GradientButton>
        </div>
      </Flex>
    </BrandedCard>
  );
};

DegreeCard.propTypes = {
  illustration: string,
  title: string,
  subtitle: string,
  children: node,
  link: string,
  openLinkInNewTab: bool,
  className: string,
};

export default DegreeCard;
