import React from 'react';

import { css, cx, px2rem } from '@ampli/ui';
import { func, string } from '@ampli/utils';

const IllustrationWrap = ({ Illustration, ...props }) => {
  return (
    <div
      {...props}
      className={cx(
        'illustration-wrap',
        css`
          width: ${px2rem(155)};
          height: ${px2rem(158)};
          margin: 0 auto;

          svg {
            width: 100%;
            height: 100%;
          }
        `,
        props.className
      )}
    >
      <Illustration />
    </div>
  );
};

IllustrationWrap.propTypes = {
  Illustration: func,
  className: string,
};

export default IllustrationWrap;
