import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { shape, arrayOf, string } from '@ampli/utils';

const Helmet = ({
  description,
  robots,
  title = '',
  metaValues,
  canonical,
  linkValues,
  ...props
}) => {
  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'robots',
      content: robots,
    },
    ...(metaValues || []),
  ];

  const link = [
    {
      rel: 'canonical',
      href: canonical,
    },
    ...(linkValues || []),
  ];

  return <ReactHelmet meta={meta} title={title} link={link} {...props} />;
};

Helmet.propTypes = {
  description: string,
  robots: string,
  title: string,
  metaValues: arrayOf(shape({ name: string, content: string })),
  canonical: string,
  linkValues: arrayOf(shape({ rel: string, href: string })),
};

export default Helmet;
