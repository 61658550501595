import React from 'react';
import {
  cx,
  css,
  px2grid,
  Flex,
  Text,
  getColor,
  mediaQuery,
  fontSize,
} from '@ampli/ui';
import { string } from '@ampli/utils';

import { IconSelector } from '../';
import TooltipCard from './tooltip-card';

const BenefitsItem = ({ icon, header, text, className, ...props }) => {
  return (
    <Flex alignItems="center" justifyContent="flex-start" direction="column">
      <div
        {...props}
        className={cx(
          'benefits-item',
          css`
            text-align: left;
            box-sizing: border-box;
            flex: 1;

            ${mediaQuery('desktop')} {
              margin: 0 ${px2grid(12)};

              &:first-child {
                margin-left: 0;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            ${mediaQuery('tablet-min')} {
              text-align: center;
              flex-basis: calc(50% - ${px2grid(12)});
            }

            ${mediaQuery('mobile')} {
              min-width: ${px2grid(130)};
            }
          `,
          className
        )}
      >
        <Flex direction="column" alignItems="center" justifyContent="center">
          <div
            className={cx(
              'benefit-header',
              css`
                ${mediaQuery('mobile')} {
                  flex-direction: row;
                }
              `,
              className
            )}
          >
            <div
              className={css`
                width: ${px2grid(40)};
                height: ${px2grid(40)};
              `}
            >
              <IconSelector
                icon={icon}
                color={getColor('#009A98')}
                size={40}
                className={cx('benefit-icon')}
              />
            </div>
            {header && <Header>{header}</Header>}
          </div>
        </Flex>

        <Text
          color="neutral-title"
          size="base"
          className={css`
            // fallback in case there's no browser support for the ch unit
            max-width: ${px2grid(180)};
            max-width: 22ch;

            ${mediaQuery('tablet-max')} {
              ${fontSize('small')}
            }
          `}
        >
          {text}
        </Text>
      </div>
    </Flex>
  );
};

BenefitsItem.propTypes = {
  icon: string.isRequired,
  header: string.isRequired,
  text: string.isRequired,
  className: string,
};

BenefitsItem.displayName = 'BenefitsItem';

const Header = ({ children, className, ...props }) => (
  <Flex justifyContent="flex-start" alignItems="center" direction="row">
    <div
      className={cx(
        'header',
        css`
          margin: ${px2grid(24)} 0 ${px2grid(16)};
        `,
        className
      )}
      {...props}
    >
      <Text
        as="h2"
        color="neutral-title"
        size="x-large"
        className={css`
          font-weight: 700;
          margin-right: ${px2grid(10)};

          ${mediaQuery('mobile')} {
            margin-left: ${px2grid(10)};
          }

          ${mediaQuery('tablet-max')} {
            ${fontSize('base')};
          }
        `}
        inlineBlock
      >
        {children}{' '}
      </Text>
      <div
        className={css`
          width: ${px2grid(20)};
          height: ${px2grid(20)};
        `}
      >
        <TooltipCard type={children}>
          <Text>
            <IconSelector
              icon="Help"
              color={getColor('accent-base')}
              className={css`
                display: inline-block;
              `}
            />
          </Text>
        </TooltipCard>
      </div>
    </div>
  </Flex>
);

Header.propTypes = {
  children: string.isRequired,
  className: string,
};

const AmpliBenefits = ({ className, ...props }) => {
  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      direction="row"
    >
      <div
        {...props}
        className={cx(
          'ampli-benefits',
          css`
            flex: 1;
            padding: ${px2grid(40)} 9%;

            ${mediaQuery('tablet-max')} {
              flex-direction: column;
              flex-wrap: wrap;
            }

            ${mediaQuery('mobile')} {
              margin-bottom: ${px2grid(20)};
            }
          `,
          className
        )}
      >
        <div
          className={css`
            width: 100%;
            margin-bottom: ${px2grid(16)};
            display: flex;
            flex-basis: calc(50% - ${px2grid(12)});
            flex-wrap: wrap;
            justify-content: space-between;

            ${mediaQuery('desktop')} {
              margin-bottom: 0;
              flex-basis: 100%;
            }
          `}
        >
          <BenefitsItem
            icon="Smile"
            header="1º mês grátis"
            text="Você não paga nada se cancelar dentro desse período"
            className={css`
              ${mediaQuery('mobile')} {
                margin-right: ${px2grid(8)};
              }
            `}
          />
          <BenefitsItem
            icon="ThumbsUp"
            header="Forme mais rápido"
            text="Não temos pausas para férias"
          />
        </div>
        <div
          className={css`
            width: 100%;
            flex-basis: calc(50% - ${px2grid(12)});
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            ${mediaQuery('desktop')} {
              flex-basis: 100%;
            }
          `}
        >
          <BenefitsItem
            icon="Tasks"
            header="Valor único"
            text="Sem reajustes abusivos! Valores ajustados apenas pela inflação"
            className={css`
              ${mediaQuery('mobile')} {
                margin-right: ${px2grid(8)};
              }

              & > .benefit-header > .benefit-icon {
                width: ${px2grid(32)};
                height: ${px2grid(32)};
              }
            `}
          />
          <BenefitsItem
            icon="PhoneStar"
            header="Aula pelo celular"
            text="Baixe as aulas e assista sem internet"
          />
        </div>
      </div>
    </Flex>
  );
};

AmpliBenefits.propTypes = {
  className: string,
};

AmpliBenefits.displayName = 'AmpliBenefits';

export default AmpliBenefits;
