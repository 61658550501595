import Student1Url from './assets/junior.webp';
import Student2Url from './assets/thiago.webp';
import Student3Url from './assets/michele.webp';
import Student4Url from './assets/maria-julia.webp';
import Student5Url from './assets/eduardo.webp';
import Student6Url from './assets/luan.webp';

export default [
  {
    imgUrl: Student1Url,
    name: 'Junior Gonçalves Barbosa Dias',
    course: 'Administração',
    testimonial: `Fazer parte desse projeto tem sido um máximo, plataforma de
                estudo moderna e de fácil entendimento. Com conteúdos da nossa
                atualidade que tornam mais fácil o aprendizado, enfim é a
                oportunidade que esperava pra minha primeira graduação.`,
  },
  {
    imgUrl: Student2Url,
    name: 'Thiago Augusto Campos Ferreira',
    course: 'Marketing',
    testimonial: `Tenho somado experiências excelentes com a Ampli!
                Além do atendimento próximo do aluno, a democratização de
                ensino, com mensalidades acessíveis, e a estratégia própria de
                estudo à distância, obedecendo as normativas do MEC, fazem da
                Ampli uma proposta única e extremamente condizente com a
                atualidade.`,
  },
  {
    imgUrl: Student3Url,
    name: 'Michele Martines',
    course: 'Pedagogia',
    testimonial: `Estudar na Ampli é prazeroso e livre. Livre, pois,
                estudo nos dias e horas que posso, tenho um bebê de 2 meses e
                posso estudar nas horas em que ele dorme. Prazeroso, pois o
                estudo no sistema de Situações-Problema nos coloca a pensar em
                uma hipótese real de uso dos conhecimentos e o estudo pelo
                celular é cômodo, pois a qualquer momento você tem acesso ao
                conteúdo, podendo estudar até mesmo em sala de espera do
                pediatra. Estou na terceira disciplina do curso, até agora muito
                proveitoso todos os conteúdos.`,
  },
  {
    imgUrl: Student4Url,
    name: 'Maria Julia Tosi Peres',
    course: 'Administração',
    testimonial: `A minha experiência com a Ampli está sendo ótima, os conteúdos
                são bem cheios e explicativos, o atendimento é sem duvidas 100%,
                tudo oq eu preciso me ajudam, e sempre estão dispostos a ajudar
                mais.`,
  },
  {
    imgUrl: Student5Url,
    name: 'Eduardo Menezes Alves Silva',
    course: 'Administração',
    testimonial: `Procurar e não achar uma plataforma de estudos adequada te
                frustra, mas quando menos se espera a oportunidade bate a sua
                porta. A Ampli é esta oportunidade, que veio para
                revolucionar com uma plataforma de estudos incríveis,
                professores bem atenciosos e que são bons instrutores, respostas
                rápidas pela janelas de apoio. Me sinto contente por estar
                fazendo parte dessa unidade de ensino. Serão três anos, mas
                acreditando ser, três anos de muito conteúdo e aprendizado, que
                levarei pra vida toda. Obrigado Ampli.`,
  },
  {
    imgUrl: Student6Url,
    name: 'Luan Araújo do Amaral',
    course: 'Administração',
    testimonial: `A ampli me deu a liberdade que eu precisava para estudar no
                melhor horário para mim. Tem um atendimento excelente e
                dedicação total ao aluno, toda a equipe é prestativa, e sempre
                estão prontos para auxiliar em qualquer problema ou dúvida que
                tenho. Faculdade que pensa no aluno e caminha na velocidade que
                você precisa.`,
  },
];
