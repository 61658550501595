import React, { forwardRef } from 'react';
import { useHistory } from 'react-router-dom';

import { css, cx, px2grid, mediaQuery, Flex } from '@ampli/ui';
import { string, node } from '@ampli/utils';

import RecommendationCard from './recommendation-card';
import HeaderText from '../cta-header-text/header-text';

import { SvgImage1, SvgImage2, SvgImage3, SvgImage4 } from './assets';

const RecommendationContainer = forwardRef(
  ({ children, className, ...props }, ref) => (
    <Flex alignItems="flex-start" justifyContent="center" direction="column">
      <div
        {...props}
        ref={ref}
        className={cx(
          'recommendation-container',
          css`
            display: flex;
            padding: 0 ${px2grid(12)};
            margin-bottom: ${px2grid(40)};
            width: 100%;

            ${mediaQuery('desktop')} {
              padding: 10% 5% 0;
              max-width: ${px2grid(1500)};
              margin: 0 auto ${px2grid(108)};
            }
          `,
          className
        )}
      >
        {children}
      </div>
    </Flex>
  )
);
RecommendationContainer.propTypes = {
  children: node.isRequired,
  className: string,
};
RecommendationContainer.displayName = 'RecommendationContainer';

const CardListWrapper = forwardRef(({ children, className, ...props }, ref) => (
  <Flex alignItems="center" justifyContent="center" direction="row" wrap="wrap">
    <div
      {...props}
      ref={ref}
      className={cx(
        'card-list-wrapper',
        css`
          width: 100%;
          flex-direction: row;

          ${mediaQuery('mobile')} {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
));
CardListWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

const coursesFilters = [
  'area=financas&area=matematica-e-tecnologia',
  'area=educacao',
  'area=industria-gestao-e-negocios',
  'area=comunicacao-marketing-e-artes',
];

const CourseRecommendation = ({ ...props }) => (
  <RecommendationContainer {...props}>
    <HeaderText as="h2">
      Encontre o curso que combina melhor com você:
    </HeaderText>
    <CardListWrapper>
      <Flex alignItems="center" justifyContent="space-around" wrap="wrap">
        <div
          className={css`
            flex: 1;
          `}
        >
          <RecommendationCard
            image={SvgImage1}
            title="Ideal para quem gosta de números"
            description="Matemática, Ciências Contábeis e mais!"
            urlLink={`/graduacao?${coursesFilters[0]}`}
          />
          <RecommendationCard
            image={SvgImage2}
            title="Ideal para quem quer lecionar"
            description="Pedagogia, Letras e mais!"
            urlLink={`/graduacao?${coursesFilters[1]}`}
          />
        </div>
      </Flex>
      <Flex alignItems="center" justifyContent="space-around" wrap="wrap">
        <div
          className={css`
            flex: 1;
          `}
        >
          <RecommendationCard
            image={SvgImage3}
            title="Ideal para quem gosta de gestão e negócios"
            description="Administração, Gastronomia e mais!"
            urlLink={`/graduacao?${coursesFilters[2]}`}
          />
          <RecommendationCard
            image={SvgImage4}
            title="Ideal para quem gosta de comunicação"
            description="Marketing, Publicidade e mais!"
            urlLink={`/graduacao?${coursesFilters[3]}`}
            noMargin
          />
        </div>
      </Flex>
    </CardListWrapper>
  </RecommendationContainer>
);

CourseRecommendation.displayName = 'CourseRecommendation';

export default CourseRecommendation;
