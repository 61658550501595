import * as React from 'react';

function SvgBanner(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1021.36 0H19.838C4.784 85.687-2.078 177.91.548 277.401c2.27 85.593 12.1 165.651 28.464 240.599H1037.69c-13.22-80.548-21.7-171.247-23.85-274.28-1.92-92.09.93-172.672 7.52-243.72z"
        fill="url(#banner_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="banner_svg__paint0_linear"
          x1={-123}
          y1={678}
          x2={1149.92}
          y2={240.994}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#23B8B5" />
          <stop offset={1} stopColor="#18A5C2" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBanner;
