import React from 'react';

import {
  cx,
  css,
  px2rem,
  Text,
  getSpaceSize,
  px2grid,
  getColor,
} from '@ampli/ui';

import { string } from '@ampli/utils';

const cancelInfo =
  'Você só paga se decidir ficar com a gente após seu período de 30 dias de teste.';

const CourseInfoContent = ({ degree, title, subTitle, priceLabel, price }) => {
  const color =
    degree == 'GRADUATE'
      ? getColor('semantic-success-dark')
      : getColor('brand-base');

  const priceSplited = React.useMemo(() => {
    if (!price) return ['0', ',00'];

    if (!price.includes(',')) return [price];

    const splited = price.split(',');
    return [splited[0], `,${splited[1]}`];
  }, [price]);

  return (
    <>
      <div
        className={cx(
          'title-degree-wrap',
          css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          `
        )}
      >
        <Text
          size="xx-large"
          as="h2"
          color="neutral-title"
          marginSize="x-small"
          className={css`
            font-weight: bold;
            width: 60%;
          `}
        >
          {title}
        </Text>
        <div
          className={cx(
            'free-trial-card',
            css`
              display: flex;
              align-items: center;
              padding: ${px2grid(2)} ${px2grid(8)};
              border-radius: ${px2rem(4)};
              background: ${getColor('gradient-linear-brand')};
              height: ${px2grid(35)};
            `
          )}
        >
          <Text
            className={cx(
              css`
                text-align: center;
              `
            )}
            size="small"
            color="white"
          >
            {'1 mês grátis'}
          </Text>
        </div>
      </div>
      <Text marginSize="small" as="h3">
        {subTitle}
      </Text>

      <div
        className={cx(
          'separator',
          css`
            width: 100%;
            border-bottom: 1px solid ${getColor('accent-base')};
            margin-bottom: ${getSpaceSize('x-small')};
          `
        )}
      />

      <Text marginSize="x-small" size="small" color={color}>
        {priceLabel}
      </Text>

      <div
        className={cx(
          'wrap-price',
          css`
            display: flex;
            flex-direction: row;
          `
        )}
      >
        <Text size={px2rem(64)} as="strong" color={color}>
          {priceSplited[0]}
        </Text>
        {priceSplited[1] && (
          <Text size="x-large" as="strong" color={color}>
            {priceSplited[1]}
          </Text>
        )}
        <div
          className={cx(
            'wrap-cancel-info-text',
            css`
              margin-left: ${px2grid(10)};
            `
          )}
        >
          <Text size="x-small" lineHeight={1}>
            {cancelInfo}
          </Text>
        </div>
      </div>
    </>
  );
};

CourseInfoContent.propTypes = {
  title: string,
  subTitle: string,
  priceLabel: string,
  price: string,
  degree: string,
};

export default CourseInfoContent;
