import * as React from 'react';

function SvgRunningMan(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={176}
      height="130pt"
      viewBox="0 0 132 214"
      {...props}
    >
      <path
        d="M106.535 206.309c1.672-2.47 1.422-7.684 2.703-9.528 1.278-1.828 6.922-3.297 8.86-1.73 1.726 1.402-1.426 6.605.004 8.797.62.957 13.714 7.109 13.894 8.425.3 2.192-22.8 2.325-26.656.23-2.02-1.089-.637-3.487 1.195-6.194zm0 0"
        fill="#311e69"
      />
      <path
        d="M97.523 131.305C95.207 115.332 67.707 86.094 81.914 75.8c14.203-10.29 29.727-1.188 29.727-1.188s11.355 41.227 12.195 58.059c.605 12.238-4.184 66.441-4.324 67.516-.477 3.632-18.446 4.738-20.707 2.82-5.817-4.938-.637-67.29-1.282-71.703zm0 0"
        fill="#614cd4"
      />
      <path
        d="M79.305 91.742c6.015-.531 18.047.176 21.57 13.492 4.754 17.985.07 22.864 4.895 33.317 4.828 10.457 5.82 19.656 3.976 31.367-1.316 8.371 6.063 13.89 10.953 16.613-.648 7.805-1.14 13.324-1.187 13.66-.477 3.633-18.446 4.739-20.703 2.82-5.817-4.937-.637-67.284-1.282-71.702C96 120.805 83.586 104.563 79.305 91.742zm0 0M13.71 111.668c1.915 2.293 7.067 3.582 8.485 5.328 1.407 1.73 1.184 7.465-.914 8.824-1.867 1.215-6.008-3.27-8.562-2.57-1.114.309-10.938 10.8-12.274 10.582-2.218-.36 4.418-22.094 7.582-25.105 1.657-1.57 3.586.43 5.684 2.941zm0 0"
        fill="#311e69"
      />
      <path
        d="M95.043 76.793s-7.664 38.059-13.059 50.328c-5.394 12.266-13.336 31.617-48.144 14.418-24.242-11.98-18.778-4.777-19.774-7.703-.992-2.93-4.117-18.207 1.848-20.574 5.961-2.367 33.426 10.093 35.984 7.304 2.555-2.789-.43-31.504 9.649-39.312 10.078-7.805 33.496-4.461 33.496-4.461zm0 0"
        fill="#614cd4"
      />
      <path
        d="M15.91 113.266c5.961-2.368 33.43 10.09 35.985 7.304 2.554-2.789-.426-31.504 9.648-39.312 3.121-2.418 7.516-3.762 12.102-4.473-1.887 2.867 11.543-.816 10.222 2.465-1.148 2.848-17.156 12.633-17.418 15.586-1.422 15.973-3.636 35.844-8.062 37.336-4.426 1.496-8.969-4.024-15.215 1.664-2.84 2.586-5.203 5.941-6.945 8.844a99.595 99.595 0 01-2.391-1.14c-16.73-8.27-19.313-7.403-19.633-7.032.008-.11-.015-.313-.14-.672-.989-2.926-4.114-18.203 1.847-20.57zm0 0M14.203 134.512c-.004.164-.086.101 0 0zm0 0"
        fill="#311e69"
      />
      <path
        d="M59.668 81.059c-4.344-19.137-10.383-62.063 28.43-68.375 13.07-2.125 42.507 16.406 34.32 34.777-2.145 4.805-8.05 9.11-11.035 14.469-1.817 3.273.004 11.27-.39 15.386-.735 7.618-7.278-2.843-27.122 1.934-14.625 3.52-22.465 9.48-24.203 1.809zm0 0"
        fill="#d8e7eb"
      />
      <path
        d="M76.488 81.336c-9.918 3.16-15.406 5.98-16.824-.281-4.344-19.137-10.383-62.063 28.43-68.371 2.625-.43 5.918-.02 9.43 1.054-1.305 3.055-3.465 7.457-5.829 9.516-3.82 3.32-14.246 6.871-18.203 13.133-3.953 6.261-3.164 15.168-2.375 23.883.613 6.777 3.887 14.39 5.371 21.066zm0 0"
        fill="#b8d2d9"
      />
      <path
        d="M126.168 74.668c6.707-12.758 2.238-31.676-2.945-38.148-5.184-6.47-15.102 24.14-14.852 36.582.25 12.464 14.043 8.699 17.797 1.566zm0 0"
        fill="#d8e7eb"
      />
      <path
        d="M62.34 29.27l21.008-2.31s-3.11 7.087-14.2 10.267c-4.714 1.351-8.246 1.793-10.757 1.863.91-3.453 2.199-6.77 3.949-9.82zm0 0M99.121 14.035a.672.672 0 01.29.969c-2.266 3.578-13.485 21.613-17.095 32.7-3.136 9.636-2.918 33-3.605 33.386-.688.39-3.629 1.715-4.82 1.097-1.192-.613.386-29.71 3.937-39.218 9.043-24.219 16.723-28.91 17.527-29.723.586-.59 2.668.27 3.766.79zm0 0M118.637 27.93s-12.996 43.742-14.758 50.863a.922.922 0 01-1.082.68l-3.242-.625a.908.908 0 01-.73-1.082c1.448-6.536 10.456-46.418 16.218-52.887a.66.66 0 01.883-.086c.785.57 2.328 1.828 2.71 3.137zm0 0"
        fill="#311e69"
      />
      <path
        d="M116.902 9.89s1.735-1.562 1.489-3.363c-.25-1.8-1.707-3.168-2.965-3.523-1.254-.36-.86 6.558 1.476 6.887zm0 0"
        fill="#b5b5b5"
      />
      <path
        d="M117.43 8.52s1.93-5.063-3.168-7.391c-3.864-1.77-5.211-.914-6.692.078-1.484.996-2.922 4.738-3.007 5.695-.137 1.528-.446 3.258.152 5.004.512 1.504 12.394-2.219 12.715-3.386zm0 0"
        fill="#ff6161"
      />
      <path
        d="M105.266 8.09l-4.461 9.976c-.172 1.497 5.785 6.82 8.648 5.196.84-.477.348-2.23.54-3.91.433-3.844-1.13-8.809-1.13-8.809zm0 0"
        fill="#ff6161"
      />
      <path
        d="M109.945 20.566c-.004-.398 0-.808.047-1.214.16-1.442.043-3.043-.168-4.48l-4.105-1.731s.797 5.793 4.226 7.425zm0 0"
        fill="#311e69"
      />
      <path
        d="M110.598 4.633l-4.34 5.226c-2.469 4.18 2.508 7.38 4.707 8.102 1.836.598 3.515-.625 4.59-2.86 1.398-2.902 1.843-5.96 1.988-7.613.332-3.761-3.535-5.914-6.945-2.855zm0 0"
        fill="#ff6161"
      />
      <path
        d="M111.934 11.645s1.418.59 3.562 1.285c0 0-.57 1.324-1.586 1.402-1.02.082-2.242-.914-1.976-2.687zm0 0"
        fill="#fff"
      />
      <path
        d="M108.676.016c.449-.114.824.351.605.75-.37.664-.855 1.77-.52 2.468.5 1.063.637 1.532.395 3.133-.242 1.606-.144 2.774-1.226 2.418-1.086-.351-2.993-.418-3.664-1.406-1.332-1.957.816-5.106 1.937-5.934.742-.55 1.719-1.234 2.473-1.43zm0 0M114.422 10.953s1.25.195 1.816 1c.567.805.664 1.64.242 1.672-.418.031-2.261-.387-2.593-1.117 0 0-1.559.203-3.078-.727-1.516-.93.75-1.988 3.613-.828zm0 0"
        fill="#b5b5b5"
      />
      <path
        d="M111.633 16.918c.992.289 2.531-.117 3.715-1.434-1.078 1.98-2.657 3.04-4.38 2.473-1.906-.625-5.878-3.098-5.327-6.457.086-.527.402.371.597.848.68 1.664 2.192 3.64 5.395 4.57zm0 0"
        fill="#ad263d"
      />
      <path
        d="M107.785 7.05c-.015-.82-.41-2.148-1.633-1.527-1.777.907-1.367 4.391.696 4.68 1.535.215.949-2.723.937-3.152zm0 0M112.352 56.195c10.39 4.649 4.847 22.133-1.024 22.45-10.05.535-25.324-14.383-35.73-21.438-4.16-2.816-6.758-6.906-5.828-8.691 1.597-3.063 5.203-2.149 9.98-2.078 11.922.18 29.105 8.19 32.602 9.757zm0 0M82.805 13.852C73.34 8.988 40.84 9.715 24.832 22.934c-9.566 7.894 2.527 44.882 19.719 39.699 6.511-1.965-3.356-27.692-3.356-27.692s30.512 1.223 39.57-5.629c6.442-4.859 5.044-13.917 2.04-15.46zm0 0"
        fill="#ff6161"
      />
      <path
        d="M24.832 22.934c3.664-3.024 8.191-5.391 13.11-7.188-.352 2.52-1.473 6.723-5 8.707-5.215 2.934-4.82 7.235-4.43 10.57.394 3.34 10.52 18.961 11.703 23.997.336 1.44-.23 2.738-1.313 3.843-14.011-3.097-22.543-32.937-14.07-39.93zm0 0"
        fill="#ad263d"
      />
      <path
        d="M29.105 24.805a26.156 26.156 0 01-1.378-4.004c2.304-1.531 4.847-2.867 7.546-4.012l4.68 14.277s-9.168-2.453-10.848-6.261zm0 0"
        fill="#311e69"
      />
      <path
        d="M31.684 18.477c17.078-8.848 42.312-10.97 55.523-4.622 3.047 1.461 0 10.598-6.437 15.461-9.06 6.844-39.57 5.625-39.57 5.625s-3.747-5.488-7.165-8.972c-1.906-1.946-2.336-5.09-2.351-7.492zm0 0"
        fill="#d8e7eb"
      />
      <path
        d="M60.043 20.195c11.344-3.898 7.383.801 16.883 2.41 5.054.86 8.699-1.238 11-3.386-1.004 3.304-3.543 7.37-7.153 10.093-9.062 6.848-39.574 5.63-39.574 5.63s-3.12-4.57-6.265-8.02c4.28-1.555 15.558-3.45 25.109-6.727zm0 0"
        fill="#b8d2d9"
      />
      <path
        d="M43.871 57.418s2.36-.348 4.977 2.23c2.62 2.579 5.554 5.02 4.547 6.758-1.008 1.746-4.836 1.047-7.036 1.606-2.203.558-4.87-1.532-6.41-3.903-1.543-2.37.235-6.62 3.922-6.691zm0 0M75.031 46.258s-2.875-3.27-5.449-3.41c-2.574-.141-4.187.574-5.977-.078-2.484-.899-.917 3.484-.78 5.882.128 2.301 4.183 3.465 8.73 3.114 4.543-.344 3.476-5.508 3.476-5.508zm0 0"
        fill="#ff6161"
      />
      <path
        d="M69.77 48.516c.234-.45.52-.801.835-1.094 1.145 1.02 2.508 2.71 4.536 3.86 4.425 2.507 6.785 4.179 18.738 8.921 11.95 4.738 6.5 4.602 12.59 8.781 4.547 3.125 9.265 1.582 11.367.602-.93 4.77-3.676 8.906-6.504 9.059-10.05.535-25.324-14.383-35.73-21.438-4.16-2.816-6.762-6.906-5.832-8.691zm0 0"
        fill="#ad263d"
      />
    </svg>
  );
}

export default SvgRunningMan;
