import React, { forwardRef } from 'react';

import {
  css,
  cx,
  px2grid,
  px2rem,
  mediaQuery,
  Icon,
  Paragraph,
  Text,
  Flex,
  Separator,
  ColorBrandBase,
} from '@ampli/ui';
import { string, node } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import { AmpliLogo, CognaLogo } from './logos';

import { STORES } from '../../constants';

const Wrap = (props) => (
  <div
    {...props}
    className={cx(
      'wrap',
      css`
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: ${px2grid(960)};
        padding: 0 ${px2grid(14)};
        width: 100%;

        ${mediaQuery('tablet')} {
          flex-direction: row;
          max-width: calc(100% - ${px2grid(80)} * 2);
        }

        ${mediaQuery('desktop')} {
          padding: 0 ${px2grid(140)};
        }
      `,
      props.className
    )}
  />
);
Wrap.propTypes = {
  className: string,
};

const Footer = forwardRef(({ children, ...props }, ref) => (
  <footer
    {...props}
    ref={ref}
    className={cx(
      'footer',
      css`
        background: ${ColorBrandBase};
        display: flex;
        width: 100%;
        color: white;
        padding-top: ${px2grid(56)};
        position: relative;
      `,
      props.className
    )}
  >
    <Wrap>{children}</Wrap>
  </footer>
));
Footer.propTypes = {
  className: string,
  children: node,
};
Footer.displayName = 'Footer';

const LogoList = (props) => (
  <ul
    {...props}
    className={cx(
      'logo-list',
      css`
        display: flex;
        align-items: center;
        flex-direction: column;
        list-style: none;
        margin-bottom: ${px2grid(24)};
        padding: 0;
        text-align: center;

        ${mediaQuery('desktop')} {
          margin-bottom: 0;
          text-align: left;
          flex-direction: row;
        }
      `,
      props.className
    )}
  />
);
LogoList.propTypes = {
  className: string,
};

const LogoItem = (props) => (
  <li {...props} className={cx('logo-item', css``, props.className)} />
);
LogoItem.propTypes = {
  className: string,
};

const SocialList = (props) => (
  <ul
    {...props}
    className={cx(
      'social-list',
      css`
        list-style: none;
        padding: 0;
        text-align: center;
        display: flex;
        justify-content: space-between;
        width: 95%;
      `,
      props.className
    )}
  />
);
SocialList.propTypes = {
  className: string,
};

const StoresList = (props) => (
  <ul
    {...props}
    className={cx(
      'stores-list',
      css`
        padding: ${px2grid(8)} 0;
        text-align: center;
        display: flex;
        margin-bottom: 0;
      `,
      props.className
    )}
  />
);
StoresList.propTypes = {
  className: string,
};

const SocialItem = (props) => (
  <li
    {...props}
    className={cx(
      'social-item',
      css`
        display: inline-block;
        padding: 0;

        a {
          width: 26px;
          height: 26px;
          display: block;
        }
      `,
      props.className
    )}
  />
);
SocialItem.propTypes = {
  className: string,
};

const SocialIcon = ({ media, ...props }) => {
  const MediaIcon = Icon[media];
  return (
    <MediaIcon
      {...props}
      title={`Siga a Ampli no ${media}`}
      className={cx(
        `${media}-icon`,
        css`
          box-sizing: content-box;
          cursor: pointer;
          height: 100%;
          width: 100%;
        `,
        props.className
      )}
    />
  );
};
SocialIcon.propTypes = {
  className: string,
  media: string.isRequired,
};

const FooterSection = (props) => (
  <div
    {...props}
    className={cx(
      'footer-section',
      css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      `,
      props.className
    )}
  />
);
FooterSection.propTypes = {
  className: string,
};

const MediaSection = (props) => (
  <div
    {...props}
    className={cx(
      'media-section',
      css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: ${px2grid(16)};
        align-items: center;
        width: 100%;

        ${mediaQuery('desktop')} {
          flex-direction: row;
          padding: ${px2grid(12)};
        }
      `,
      props.className
    )}
  />
);
MediaSection.propTypes = {
  className: string,
};

const FooterSeparator = (props) => (
  <hr
    {...props}
    className={cx(
      'footer-separator',
      css`
        background-color: rgba(0, 0, 0, 0.1);
        border: 0;
        height: ${px2rem(1)};
        margin: ${px2grid(1)} 0;
        padding: 0;
        width: 100%;

        ${mediaQuery('desktop')} {
          display: none;
        }
      `,
      props.className
    )}
  />
);
FooterSeparator.propTypes = {
  className: string,
};

const SecurityItem = (props) => (
  <div
    {...props}
    className={cx(
      'security-item',
      css`
        display: none;
        padding: ${px2grid(20)};
        margin: 0 ${px2grid(14)};

        ${mediaQuery('desktop')} {
          display: block;
        }
      `,
      props.className
    )}
  />
);
SecurityItem.propTypes = {
  className: string,
};

const StoresItem = (props) => (
  <li
    {...props}
    className={cx(
      'stores-item',
      css`
        display: inline-block;
        padding: 0 ${px2grid(10)};
      `,
      props.className
    )}
  />
);
StoresItem.propTypes = {
  className: string,
};

const SecuritySiteIcon = (props) => (
  <Icon.SecuritySite
    {...props}
    className={cx('security-site', css``, props.className)}
  />
);

SecuritySiteIcon.propTypes = {
  className: string,
};

const SocialWrapper = (props) => (
  <div
    {...props}
    className={cx(
      'social-wrapper',
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      `,
      props.className
    )}
  />
);

SocialWrapper.propTypes = {
  className: string,
};

const LogoSeparator = () => (
  <Separator
    orientation="vertical"
    color="background-translucent"
    className={cx(
      'logo-separator',
      css`
        height: ${px2grid(48)};
        visibility: hidden;

        ${mediaQuery('desktop')} {
          visibility: visible;
        }
      `
    )}
  />
);

const MainFooter = forwardRef((props, ref) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === 'desktop';
  return (
    <Footer ref={ref}>
      <FooterSection>
        <LogoList>
          <LogoItem>
            <a href="/">
              <AmpliLogo />
            </a>
          </LogoItem>
          <LogoSeparator />
          <LogoItem>
            <a href="https://www.cognaedu.com.br/">
              <CognaLogo />
            </a>
          </LogoItem>
        </LogoList>
        <FooterSeparator />
        <MediaSection>
          <SocialWrapper>
            <Paragraph marginSize="x-small">
              <Text color="text-white" size="small">
                Fale com a gente
              </Text>
            </Paragraph>
            <SocialList>
              <SocialItem>
                <a href="https://www.instagram.com/ampli_oficial/">
                  <SocialIcon media="Instagram" />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="https://www.facebook.com/OficialAmpli">
                  <SocialIcon media="Facebook" />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="https://www.linkedin.com/school/amplioficial">
                  <SocialIcon media="LinkedinOutline" />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="https://www.youtube.com/channel/UCQuBgGO1XDvn9HmDwW9c-Hw">
                  <SocialIcon media="YoutubeOutline" />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="mailto:contato@ampli.com.br">
                  <SocialIcon media="Mail" />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="https://twitter.com/AmpliOficial">
                  <SocialIcon media="TwitterOutline" />
                </a>
              </SocialItem>
            </SocialList>
          </SocialWrapper>
          <SecurityItem>
            <SecuritySiteIcon />
          </SecurityItem>
          {!isDesktop && <FooterSeparator />}
          <StoresList>
            <StoresItem>
              <Paragraph
                marginSize="x-small"
                textAlign={isDesktop ? 'left' : 'center'}
              >
                <Text color="text-white" size="small">
                  Disponível na
                </Text>
              </Paragraph>
              <Flex direction="row">
                <div>
                  <a
                    href={STORES.GOOGLEPLAY.URL}
                    className={css`
                      margin-right: ${px2grid(12)};
                    `}
                  >
                    <Icon.GooglePlay />
                  </a>
                  <a href={STORES.APPLESTORE.URL}>
                    <Icon.AppleStore />
                  </a>
                </div>
              </Flex>
            </StoresItem>
          </StoresList>
        </MediaSection>
      </FooterSection>
    </Footer>
  );
});
MainFooter.displayName = 'MainFooter';

export default MainFooter;
