import React from 'react';

import {
  cx,
  css,
  Text,
  Icon,
  getSpaceSize,
  px2grid,
  useDisclosureState,
  Disclosure,
  DisclosureContent,
  marginSize,
  getColor,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { string, bool, func, isString, oneOfType, node } from '@ampli/utils';

const CollapsibleInfo = ({
  label,
  contentText,
  icon,
  hasSpace,
  selected,
  setSelected,
  hasStroke,
  hasPathColor,
  degree,
}) => {
  const breakpoint = useBreakpoint(true);
  const color =
    degree == 'GRADUATE'
      ? getColor('semantic-success-dark')
      : getColor('brand-base');
  const resolvedIcon = isString(icon)
    ? React.createElement(Icon[icon], {
        color: color,
      })
    : icon;
  const disclosure = useDisclosureState({ animated: 100 });

  const handleToggle = () => {
    if (disclosure.visible) {
      !!setSelected && setSelected('');
      disclosure.hide();
      return;
    }
    !!setSelected && setSelected(label);
    if (selected) {
      setTimeout(() => disclosure.show(), 250);
      return;
    }
    disclosure.show();
  };

  React.useEffect(() => {
    if (selected !== label && disclosure.visible) disclosure.hide();
  }, [selected]);

  return (
    <>
      <Disclosure
        {...disclosure}
        toggle={handleToggle}
        className={cx(
          'collapsible-wrap',
          css`
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: transparent;
            border: none;
          `
        )}
      >
        {resolvedIcon && (
          <Text
            size="x-large"
            className={cx(
              'icon-collapsible',
              css`
                width: ${px2grid(25)};

                &.hasStroke {
                  path {
                    stroke: ${color};
                  }
                }
                &.hasPathColor {
                  path {
                    color: ${color};
                  }
                }
              `,
              hasStroke && 'hasStroke',
              hasPathColor && 'hasPathColor'
            )}
          >
            {resolvedIcon}
          </Text>
        )}
        <div
          className={cx('separator-collapsible', marginSize('x-small', 'left'))}
        />
        <Text
          className={cx(
            'label-collapsible-info',
            css`
              text-align: left;

              &:hover {
                text-decoration: underline;
              }
            `
          )}
          color={
            selected === label
              ? getColor('brand-base')
              : getColor('neutral-dark')
          }
        >
          {label}
          <Icon.Help
            className={cx('help-icon', marginSize('xx-small', 'left'))}
            color={getColor('accent-base')}
          />
        </Text>

        <div
          className={cx('separator-collapsible', marginSize('x-small', 'left'))}
        />
        <Icon.ArrowDown
          className={cx(
            'collapsible-icon',
            css`
              transform: rotate(0deg);
              &.visible {
                transform: rotate(180deg);
              }
            `,
            disclosure.visible && 'visible'
          )}
          color={getColor('neutral-dark')}
        />
      </Disclosure>
      <DisclosureContent
        {...disclosure}
        className={cx(
          'collapsible-content',
          css`
            margin: ${getSpaceSize('x-small')};
            padding-bottom: ${getSpaceSize('x-small')};
            transition: opacity 250ms ease-out;
            opacity: 0;
            &[data-enter] {
              transition: opacity 250ms ease-in;
              opacity: 1;
              border-bottom: 1px solid ${getColor('brand-base')};
            }
          `
        )}
      >
        {contentText}
      </DisclosureContent>
      {hasSpace && (
        <div
          className={cx(
            'bottom-separator-info',
            marginSize(breakpoint?.isDesktop ? 'x-small' : 'base', 'bottom')
          )}
        />
      )}
    </>
  );
};

CollapsibleInfo.propTypes = {
  icon: oneOfType([string, node]),
  label: string,
  contentText: string,
  selected: string,
  setSelected: func,
  hasSpace: bool,
  hasStroke: bool,
  hasPathColor: bool,
  degree: string,
};

export default CollapsibleInfo;
