import React from 'react';

import {
  cx,
  css,
  ColorNeutralBackground,
  ColorBrandBase,
  ColorNeutralTitle,
  ColorBrandDark,
  px2grid,
  Text,
  marginSize,
  fontSize,
  Icon,
  PrimaryButton,
  getSpaceSize,
  mediaQuery,
} from '@ampli/ui';
import { func, string, number } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import Illustration from './illustration';
import IllustrationMobile from './illustration-mobile';
import PlantVase from './plant-vase';

const MIN_WIDTH_DESKTOP_BANNER = 1100;

const StepItem = ({ index, text }) => (
  <div
    className={cx(
      'step-wrap',
      css`
        display: flex;
        align-items: center;
      `,
      marginSize('base', 'top')
    )}
  >
    <Text
      color={ColorBrandDark}
      className={cx(
        'step-index',
        css`
          ${fontSize('huge')};
          font-weight: bold;
          @media (min-width: ${MIN_WIDTH_DESKTOP_BANNER}px) {
            ${fontSize('xxx-large')};
          }
        `,
        marginSize('x-small', 'right')
      )}
      as="h3"
    >
      {index}
    </Text>
    <Text color={ColorNeutralTitle}>{text}</Text>
  </div>
);

StepItem.propTypes = {
  index: number,
  text: string,
};

const items = [
  'Selecione o seu curso',
  'Matricule-se sem sair de casa',
  'Envie os documentos necessários',
];

const HowToSubscribe = ({ onClick }) => {
  const breakpoint = useBreakpoint(true);

  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width > MIN_WIDTH_DESKTOP_BANNER,
    [breakpoint]
  );

  return (
    <div
      id="subscribe-banner"
      className={cx(
        'subscribe-banner-wrap',
        css`
          background-color: ${ColorNeutralBackground};
          display: flex;
          justify-content: center;
          align-items: center;
          padding: ${getSpaceSize('large')};

          @media (min-width: ${MIN_WIDTH_DESKTOP_BANNER}px) {
            padding-bottom: 0px;
            align-items: flex-start;
          }
        `
      )}
    >
      {isDesktop && (
        <Illustration
          className={cx(
            'illustration-banner',
            css`
              width: ${px2grid(500)};
              height: ${px2grid(385)};
            `
          )}
        />
      )}

      <div
        className={cx(
          'items-subscribe-wrap',
          css`
            margin: 0 ${getSpaceSize('large')};

            @media (max-width: ${MIN_WIDTH_DESKTOP_BANNER}px) {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin: 0;
            }
          `
        )}
      >
        {isDesktop ? (
          <Text
            as="h2"
            className={cx(
              'title-step',
              css`
                ${fontSize('larger')};
              `
            )}
            color={ColorBrandDark}
          >
            {'Como fazer a inscrição?'}
          </Text>
        ) : (
          <div
            className={cx(
              'banner-wrap-mobile',
              css`
                display: flex;
                justify-content: space-between;
              `
            )}
          >
            <IllustrationMobile
              className={cx(
                'illustration-banner-mobile',
                css`
                  width: ${px2grid(180)};
                  height: ${px2grid(100)};
                `
              )}
            />
            <Text
              className={cx(
                'title-step',
                css`
                  ${fontSize('xxx-large')};
                  align-self: center;
                  width: ${px2grid(130)};
                `
              )}
              as="h2"
              color={ColorBrandDark}
            >
              {'Como fazer a inscrição?'}
            </Text>
          </div>
        )}
        {items.map((item, index) => (
          <StepItem text={item} key={`step_item_${index}`} index={index + 1} />
        ))}
        <PrimaryButton
          className={cx(
            'button-subscribe',
            css`
              background-color: transparent;
              color: ${ColorBrandBase};
              font-weight: 400;
              border-color: ${ColorBrandBase};
              margin-top: ${getSpaceSize('large')};
            `
          )}
          color={ColorBrandBase}
          onClick={onClick}
        >
          <Icon.Pen
            className={cx(
              'button-subscribe-icon',
              marginSize('small', 'right')
            )}
          />
          {'Experimente Grátis'}
        </PrimaryButton>
      </div>

      {isDesktop && (
        <PlantVase
          className={cx(
            'vase-illustration',
            css`
              width: ${px2grid(180)};
              height: ${px2grid(190)};
              align-self: flex-end;
            `
          )}
        />
      )}
    </div>
  );
};

HowToSubscribe.propTypes = {
  onClick: func,
};

export default HowToSubscribe;
