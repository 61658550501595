import React from 'react';
import { Link } from 'react-router-dom';

import {
  cx,
  css,
  Text,
  Icon,
  px2grid,
  getSpaceSize,
  fontSize,
  marginSize,
  px2rem,
  mediaQuery,
} from '@ampli/ui';
import CourseDetailCard from './course-detail-card';
import BackgroundBanner from './background-banner';
import { DetailsBreadcrumb } from '../';
import { useBreakpoint } from '@ampli/hooks';

import {
  string,
  node,
  arrayOf,
  number,
  func,
  oneOfType,
  object,
  shape,
  sanitizeHTML,
} from '@ampli/utils';
import { useDetectIdScroll } from '../../hooks';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';
import { PartnerLogo } from '../partner-logo';

const defaultXSmallSizeTag = getSpaceSize('xx-small');

const Tag = ({ children }) => (
  <div
    className={cx(
      'tag-wrap',
      css`
        border-radius: ${px2grid(8)};
        border: 1px solid #fff;
        padding: ${getSpaceSize('xxx-small')};
        padding-right: ${defaultXSmallSizeTag};
        padding-left: ${defaultXSmallSizeTag};
        display: flex;
        align-items: center;
        margin-bottom: ${defaultXSmallSizeTag};
        margin-right: ${defaultXSmallSizeTag};
      `,
      fontSize('x-small')
    )}
  >
    <Icon.Award
      className={cx(
        'award-icon-tag',
        css`
          width: ${px2grid(16)};
          path {
            stroke: white;
          }
        `,
        marginSize('xx-small', 'right')
      )}
    />
    <Text color="white">{children}</Text>
  </div>
);

Tag.propTypes = {
  children: node,
};

const StarSize = px2grid(20);

const RateStars = ({ countStars }) => {
  const stars = React.useMemo(() => [...Array(countStars).keys()], [
    countStars,
  ]);

  if (!stars?.length) return null;

  return stars?.map((star) => (
    <Icon.Star
      className={cx(
        'icon-star',
        css`
          width: ${StarSize};
          height: ${StarSize};
        `,
        star === stars.length - 1 && marginSize('xx-small', 'right')
      )}
      key={`star_${star}`}
    />
  ));
};

RateStars.propTypes = {
  countStars: number,
};

const BannerDetailCourse = ({
  name,
  courseName,
  description,
  tags,
  onClickCard,
  duration,
  courseImage,
  partner,
  price,
  courseDegree,
  hideWhenScrollTo,
}) => {
  const breakpoint = useBreakpoint();
  const { id: detectScrollToId, distance } = hideWhenScrollTo || {};
  const { isReachedPoint: isBottom, position: positionCard } =
    useDetectIdScroll(detectScrollToId, distance) || {};
  const isMobile = React.useMemo(() => breakpoint !== 'desktop', [breakpoint]);

  const courseSearchPagePath = PATHNAME_BY_DEGREE_TYPE[courseDegree].pathname;

  return (
    <BackgroundBanner courseDegree={courseDegree}>
      <div
        className={cx(
          'banner-wrap',
          css`
            display: flex;
            align-items: center;
            flex-direction: column;
            padding-bottom: 50px;
            ${mediaQuery('desktop')} {
              max-width: ${px2grid(1500)};
              padding-top: ${px2grid(20)};
              padding-bottom: ${px2grid(20)};
            }

            ${mediaQuery('tablet')} {
              width: 100%;
            }
          `
        )}
      >
        <div
          className={cx(
            'banner-text-wrap',
            css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              margin-top: ${px2grid(50)};
              text-align: left;

              ${mediaQuery('tablet-max')} {
                width: 100%;
                box-sizing: border-box;
                padding: 0 ${px2grid(24)};
              }

              ${mediaQuery('tablet')} {
                align-items: center;
              }

              ${mediaQuery('desktop')} {
                margin-top: 0;

                --lateral-margin: 10%;
                width: calc(100% - var(--lateral-margin));
                margin-left: var(--lateral-margin);
                padding-right: calc(
                  var(--detail-card-width) + var(--lateral-margin)
                );
              }
            `
          )}
        >
          {!isMobile && (
            <DetailsBreadcrumb
              courseName={courseName}
              courseDegree={courseDegree}
            />
          )}
          <Text
            as="h1"
            color="white"
            className={cx(
              'title-banner',
              css`
                font-weight: bold;
                ${mediaQuery('desktop')} {
                  font-size: ${px2grid(40)};
                  line-height: 1;
                }
              `,
              isMobile && fontSize('larger')
            )}
          >
            {name}
          </Text>
          <div
            className={cx(
              'stars-wrap',
              css`
                display: flex;
                align-items: center;
                margin-top: ${getSpaceSize('small')};
              `
            )}
          >
            <RateStars countStars={5} />
            <Text as="h4" color="white" size={isMobile ? 'small' : null}>
              {'Instituição nota máxima no MEC'}
            </Text>
          </div>
          {!!tags?.length && !isMobile && (
            <div
              className={cx(
                'tags-wrap',
                css`
                  display: flex;
                  margin-top: ${getSpaceSize('small')};
                  margin-bottom: ${getSpaceSize('small')};
                `
              )}
            >
              {tags?.map((tag, index) => (
                <Link
                  to={`${courseSearchPagePath}?${tag.link}`}
                  key={`course_tag_${index}`}
                >
                  <Tag>{tag.name}</Tag>
                </Link>
              ))}
            </div>
          )}
          <div
            className={cx(
              'banner-description',
              css`
                color: white;
                border-left: ${px2rem(3)} solid white;
                padding-left: ${getSpaceSize('x-small')};
                align-self: center;
                text-align: left;
                margin: 0;

                ${mediaQuery('tablet')} {
                  width: ${px2grid(300)};
                }

                ${mediaQuery('desktop')} {
                  margin-top: 0;
                  align-self: flex-start;
                }
                & > p {
                  margin: 0 !important;
                }
              `,
              isMobile && fontSize('small')
            )}
            dangerouslySetInnerHTML={{
              __html: sanitizeHTML(description),
            }}
          />

          {!!tags?.length && isMobile && (
            <div
              className={cx(
                'tags-wrap',
                css`
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: ${getSpaceSize('small')};
                  margin-bottom: ${getSpaceSize('x-large')};
                `
              )}
            >
              {tags?.map((tag, index) => (
                <Link
                  to={`${courseSearchPagePath}?${tag.link}`}
                  key={`course_tag_${index}`}
                >
                  <Tag>{tag.name}</Tag>
                </Link>
              ))}
            </div>
          )}
        </div>
        {partner && <PartnerLogo partner={partner} />}
        {!isMobile && (
          <div
            className={cx(
              'banner-card-space',
              css`
                width: ${px2grid(400)};
              `
            )}
          />
        )}
      </div>
      <CourseDetailCard
        onClick={onClickCard}
        image={courseImage}
        price={price}
        recordedClasses={courseDegree === `GRADUATE`}
        className={cx(
          'detail-card-banner',
          css`
            ${mediaQuery('desktop')} {
              position: fixed;
              right: 8%;
              top: ${px2grid(130)};

              &.isBottom {
                position: absolute;
                ${positionCard &&
                distance &&
                css`
                  top: ${px2grid(positionCard - distance)};
                `}
              }
            }
          `,
          isBottom && 'isBottom'
        )}
        duration={duration}
      />
    </BackgroundBanner>
  );
};

BannerDetailCourse.propTypes = {
  name: string,
  courseName: string,
  description: oneOfType([string, object]),
  tags: arrayOf(object),
  onClickCard: func,
  duration: number,
  courseImage: shape({ formats: arrayOf(string), baseUrl: string }),
  price: number,
  courseDegree: string,
  hideWhenScrollTo: shape({ id: string, distance: number }),
  partner: object,
};

export default BannerDetailCourse;
