import * as React from 'react';

function SvgBannerSplash(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.25}
        d="M1096.54 355.958c-13.22 21.372-38.49 39.427-63.3 57.029-2.01 1.423-4.01 2.844-6.02 4.269-1.37.967-2.73 1.937-4.1 2.902-9.33.05-19.13.1-29.39.145-12.854.059-26.406.116-40.607.173-2.187.006-4.377.018-6.597.024-63.198.234-138.721.408-222.116.519-20.437.025-41.346.048-62.658.066-3.434.002-6.878.007-10.333.007-2.59.002-5.18.004-7.785.009-4.762.002-9.546.007-14.345.007-16.33.009-32.873.013-49.603.016-5.893.002-11.812.004-17.753.004-5.425.002-10.869.002-16.316.002-3.98 0-7.958 0-11.924-.002-2.806.002-5.605 0-8.405 0-24.389-.002-48.407-.011-71.974-.02-12.461-.012-24.789-.018-36.976-.032-31.486-.027-62.046-.063-91.443-.118-4.971-.007-9.914-.013-14.81-.022-3.429-.007-6.845-.014-10.242-.023-5.555-.005-11.06-.018-16.521-.03l-16.543-.034c-30.625-.065-59.664-.14-86.842-.228-4.099-.014-8.149-.028-12.166-.037-1.977-.009-3.948-.016-5.911-.022-10.015-.034-19.753-.068-29.195-.107-7.612-.032-15.033-.063-22.256-.095-2.115-.009-4.219-.02-6.298-.023-11.664-.056-22.789-.111-33.334-.163-8.97-.05-17.522-.099-25.624-.147l-4.965-.034c-.691-.005-1.376-.009-2.06-.011-1.715-.016-3.415-.025-5.09-.037-.087 0-.173 0-.26-.002a116.073 116.073 0 01-3.523-2.469C13.656 406.086 3.24 392.386.647 377.91c-6.297-35.104 34.012-71.185 92.001-82.348 35.575-6.847 78.042-5.825 105.042-20.847 31.89-17.749 24.62-48.739 4.086-71.398-20.542-22.669-51.841-42.373-63.952-66.972-20.859-42.359 30.614-91.773 105.193-100.99C359.699 20.938 465.685 93.268 584.153 85.03c48.533-3.374 90.935-20.004 129.781-37.135 38.846-17.13 77.598-35.668 124.29-44.022C884.916-4.48 942.636.16 971.643 22.88c26.136 20.466 21.839 49.267 5.962 72.989-15.876 23.719-41.746 44.899-57.128 68.725-15.387 23.828-18.445 52.822 8.937 72.74 37.921 27.584 116.496 26.447 154.756 53.871 3.31 2.37 6.19 4.887 8.67 7.517 3.55 3.773 6.27 7.783 8.23 11.938 6.85 14.546 4.37 30.902-4.53 45.298z"
        fill="url(#banner-splash_svg__paint0_linear)"
      />
      <defs>
        <linearGradient
          id="banner-splash_svg__paint0_linear"
          x1={0}
          y1={421.13}
          x2={1115.2}
          y2={29.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3BDBB8" />
          <stop offset={1} stopColor="#01B6D0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgBannerSplash;
