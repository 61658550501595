import React from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import {
  Helmet,
  Header,
  CourseGridList,
  CollapsibleItems,
  CourseInfoCard,
  CourseInfoContent,
  CourseSection,
  CourseWrapInfo,
  HomeBanner,
  SearchBox,
  SocialProof,
  Footer,
  VacationBanner,
  CourseRecommendation,
  UpcomingAlert,
  CTAWhatsapp,
} from '../components';

import { cx, css, getSpaceSize, px2grid, getColor } from '@ampli/ui';
import { useBreakpoint, useScroller } from '@ampli/hooks';
// import { useLeadByNameForm } from '../hooks';
import { findLast, defaultTo } from '@ampli/utils';
import { Modal, useSubscriptionModal } from '@ampli/registration';

import { useFindCoursesByCourseDegree } from '@ampli/services';
import { infoItems, defaultInfoProps } from './mock';
import { CONTACT, SCROLLER_SETTINGS } from '../constants';
import {
  ampli,
  entranceExamUrl,
  recaptchaSiteKey,
  googleAPIKey,
  baseUrl,
} from '../config';

import mockedTestimonialList from '../components/social-proof/testimonialList';

const MIN_MOBILE_WIDTH = 910;

const Home = () => {
  const breakpoint = useBreakpoint(true);
  const { pathname } = useLocation();
  const canonicalUrl = baseUrl + pathname;
  const history = useHistory();
  const subscriptionModal = useSubscriptionModal();

  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width > MIN_MOBILE_WIDTH,
    [breakpoint]
  );

  const search = (searchTerm) => history.push(`/graduacao?nome=${searchTerm}`);

  const {
    courses: undergraduateCourses,
    loading: undergraduateCoursesLoading,
  } = useFindCoursesByCourseDegree(
    'UNDERGRADUATE',
    {
      page: 1,
      perPage: 6,
    },
    undefined,
    { fetchPolicy: 'cache-first' }
  );

  const {
    courses: graduateCourses,
    loading: graduateCoursesLoading,
  } = useFindCoursesByCourseDegree(
    'GRADUATE',
    {
      page: 1,
      perPage: 6,
    },
    undefined,
    { fetchPolicy: 'cache-first' }
  );

  const { ref: homeRef, scroller: homeScroller } = useScroller(
    SCROLLER_SETTINGS
  );
  const {
    ref: socialProofRef,
    isInViewport: isSocialProofViewPort,
  } = useScroller(SCROLLER_SETTINGS);
  const {
    ref: contactRef,
    scroller: contactScroller,
    isInViewport: isContactViewport,
  } = useScroller(SCROLLER_SETTINGS);

  const visibleSection = defaultTo(
    findLast(
      [
        ['sobre nós', isSocialProofViewPort],
        ['contato', isContactViewport],
      ],
      '1'
    ),
    []
  )[0];

  // const { formik, loading: leadLoading } = useLeadByNameForm({
  //   onCompleted: () => alert('Seus dados foram cadastrados com sucesso!'),
  //   onError: () =>
  //     alert(
  //       'Ocorreu um problema ao enviar seus dados, por favor tente novamente.'
  //     ),
  // });

  const handleSubscribeCourseClick = () => {
    subscriptionModal.show();
  };

  return (
    <>
      <Helmet
        title="Conheça os nossos cursos online | Ampli"
        description="Acesse e conheça todos os Cursos Online da Ampli. Cursos de Graduação e Pós-graduação EAD. Turmas novas iniciando toda segunda. Inscreva-se!"
        robots="robots"
        canonical={canonicalUrl}
      />
      <Header
        pageTopScroller={homeScroller}
        contactScroller={contactScroller}
        visibleSection={visibleSection}
        onSubscribeClick={subscriptionModal.show}
      />
      <div ref={homeRef}>
        <HomeBanner />
      </div>

      <SearchBox
        onSearchClick={search}
        className={css`
          margin-top: ${px2grid(-68)};
        `}
      />
      <div
        className={cx(
          'courses-wrap',
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: ${px2grid(30)};
          `
        )}
      >
        {isDesktop ? (
          <div
            className={cx(
              css`
                width: 100%;
              `
            )}
          >
            <CourseSection
              className={cx(
                css`
                  padding-left: ${getSpaceSize('large')};
                `
              )}
            >
              <CourseWrapInfo>
                <CourseInfoCard
                  className={cx(
                    'info-card-wrap',
                    css`
                      margin-bottom: ${getSpaceSize('small')};
                    `
                  )}
                >
                  <CourseInfoContent
                    degree="UNDERGRADUATE"
                    {...defaultInfoProps.UNDERGRADUATE}
                  />
                </CourseInfoCard>
                <CollapsibleItems
                  degree="UNDERGRADUATE"
                  items={infoItems.UNDERGRADUATE}
                />
              </CourseWrapInfo>
              <CourseGridList
                items={undergraduateCourses?.length ? undergraduateCourses : []}
                loading={undergraduateCoursesLoading}
                degreeType="UNDERGRADUATE"
              />
            </CourseSection>
            <CourseSection
              className={cx(
                css`
                  padding-bottom: ${getSpaceSize('xxx-large')};
                  padding-left: ${getSpaceSize('large')};
                  background: ${getColor('neutral-background')};
                `
              )}
            >
              <CourseWrapInfo>
                <CourseInfoCard
                  className={cx(
                    'info-card-wrap',
                    css`
                      margin-bottom: ${getSpaceSize('small')};
                    `
                  )}
                >
                  <CourseInfoContent
                    degree="GRADUATE"
                    {...defaultInfoProps.GRADUATE}
                  />
                </CourseInfoCard>
                <CollapsibleItems
                  degree="GRADUATE"
                  items={infoItems.GRADUATE}
                />
              </CourseWrapInfo>
              <CourseGridList
                items={graduateCourses?.length ? graduateCourses : []}
                loading={graduateCoursesLoading}
                degreeType="GRADUATE"
              />
            </CourseSection>
          </div>
        ) : (
          <>
            <CourseInfoCard
              className={cx(
                'course-card-wrap',
                css`
                  width: 100%;
                  margin-top: ${getSpaceSize('x-large')};
                `
              )}
            >
              <CourseInfoContent
                degree="UNDERGRADUATE"
                {...defaultInfoProps.UNDERGRADUATE}
              />
              <CourseGridList
                className={cx(
                  'grid-list-wrap',
                  css`
                    margin: ${getSpaceSize('small')} 0;
                    margin-bottom: ${getSpaceSize('x-large')};
                  `
                )}
                items={undergraduateCourses?.length ? undergraduateCourses : []}
                loading={undergraduateCoursesLoading}
                onSubscribeClick={handleSubscribeCourseClick}
                degreeType="UNDERGRADUATE"
              />
              <CollapsibleItems
                degree="UNDERGRADUATE"
                items={infoItems.UNDERGRADUATE}
              />
            </CourseInfoCard>
            <CourseInfoCard
              className={cx(
                'course-card-wrap',
                css`
                  width: 100%;
                  margin-top: ${getSpaceSize('x-large')};
                `
              )}
            >
              <CourseInfoContent
                degree="GRADUATE"
                {...defaultInfoProps.GRADUATE}
              />
              <CourseGridList
                className={cx(
                  'grid-list-wrap',
                  css`
                    margin: ${getSpaceSize('small')} 0;
                    margin-bottom: ${getSpaceSize('x-large')};
                  `
                )}
                items={graduateCourses?.length ? graduateCourses : []}
                loading={graduateCoursesLoading}
                onSubscribeClick={handleSubscribeCourseClick}
                degreeType="GRADUATE"
              />
              <CollapsibleItems degree="GRADUATE" items={infoItems.GRADUATE} />
            </CourseInfoCard>
          </>
        )}
      </div>

      <div ref={socialProofRef}>
        <SocialProof testimonials={mockedTestimonialList} />
      </div>

      <UpcomingAlert alertText="Em breve teremos cursos de Supletivo para atender todas as necessidades a qualquer momento da sua carreira." />
      <VacationBanner />
      <CourseRecommendation />
      <div ref={contactRef}>
        {/* <ContactBanner
          values={formik?.values}
          onChange={formik?.setFieldValue}
          onSubmitContact={formik?.handleSubmit}
          loading={leadLoading}
        /> */}
        <CTAWhatsapp onClick={() => window?.open(CONTACT.WHATSAPP, '_blank')} />
      </div>

      <Footer />

      <Modal
        {...subscriptionModal}
        isModal
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </>
  );
};

export default Home;
