import React from 'react';

import { cx, css, px2grid, mediaQuery, Text } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import ProposalIllustration from './proposal-illustration';
import ProposalTitle from './proposal-title';

const description =
  'Nós acreditamos que a educação transforma para melhor a vida das pessoas. Como parte do grupo Cogna Educação, uma das principais organizações educacionais do mundo, queremos transformar a vida acadêmica e profissional das pessoas por meio da educação online. Para isso, desenvolvemos cursos superiores a distância, acessíveis e de qualidade, que se adaptam à vida de cada um de nossos estudantes, combinando tecnologia e experiência do aluno para gerar um ensino cada vez melhor e disponível para todos.';

const AmpliProposal = () => {
  const { isDesktop } = useBreakpoint(true);
  return (
    <div
      className={cx(
        'ampli-proposal-wrap',
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          width: 100%;
        `
      )}
    >
      <ProposalTitle />

      <div
        className={cx(
          'ampli-proposal-content',
          css`
            padding-top: ${px2grid(30)};
            display: flex;
            align-items: center;
          `
        )}
      >
        <div
          className={cx(
            'ampli-proposal-text-wrap',
            css`
              padding: 0 ${px2grid(20)};
              max-width: ${px2grid(470)};
              ${mediaQuery('desktop')} {
                max-width: ${px2grid(520)};
              }
            `
          )}
        >
          <Text
            as="h3"
            className={css`
              margin-bottom: ${px2grid(24)};
            `}
          >
            <strong>{'Educação para todos'}</strong>
          </Text>
          <Text as="p">{description}</Text>
        </div>
        {isDesktop && (
          <ProposalIllustration
            className={cx(
              'proposal-illustration',
              css`
                margin-left: ${px2grid(20)};
                width: ${px2grid(500)};
                height: ${px2grid(500)};
              `
            )}
          />
        )}
      </div>
    </div>
  );
};

export default AmpliProposal;
