import React from 'react';
import BaseHeader from './base-header';
import { useHistory, useLocation } from 'react-router-dom';
import { func } from '@ampli/utils';
import { HEADER_LINKS } from '../../constants';

const Header = ({ pageTopScroller, contactScroller, ...props }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  function navigateTo(itemPath) {
    return () =>
      pathname === itemPath ? pageTopScroller() : history.push(itemPath);
  }

  function openURL(item) {
    return () => window?.open(HEADER_LINKS[item], '_blank');
  }

  const menuItems = [
    { label: 'início', onClick: navigateTo('/') },
    { label: 'cursos', onClick: navigateTo('/cursos') },
    { label: 'contato', onClick: contactScroller },
    {
      label: 'dúvidas frequentes',
      onClick: openURL('FAQ'),
    },
    {
      label: 'blog',
      onClick: openURL('blog'),
    },
  ];

  return <BaseHeader {...props} items={menuItems} />;
};

Header.propTypes = {
  pageTopScroller: func,
  contactScroller: func,
};

export default Header;
