import * as React from 'react';

const SvgClock = (props) => (
  <svg
    width={167}
    height={213}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M54.45 181.102c2.477.835 3.819 3.541 2.98 6.011l-7.37 21.755c-.836 2.472-3.549 3.811-6.024 2.974-2.477-.834-3.818-3.541-2.98-6.011l7.37-21.755c.837-2.47 3.55-3.808 6.025-2.974zM98.4 37.987a5.053 5.053 0 00-4.389-5.64 5.06 5.06 0 00-5.652 4.379 5.04 5.04 0 002.155 4.79l-3.547 28.117 4.607.579 3.547-28.118a5.044 5.044 0 003.28-4.107z"
      fill="#311E69"
    />
    <path
      d="M26.6 61.737c2.595 9.773 16.536 10.089 32.507 5.865 15.97-4.224 27.924-11.387 25.328-21.16-2.595-9.772-15.782-25.264-36.223-19.858-21.67 5.731-24.208 25.381-21.612 35.153z"
      fill="#614CD4"
    />
    <path
      d="M37.519 31.419c-.468 1.795-1.03 5.016-.194 8.16 1.248 4.704 7.556 6.135 15.06 5.054 7.505-1.081 13.668 1.464 19.46-2.35 3.38-2.225 5.007-5.274 5.775-7.43 3.552 3.77 5.877 8.054 6.815 11.588 2.595 9.772-9.358 16.936-25.329 21.16-15.97 4.223-29.911 3.907-32.507-5.865-2.083-7.844-.857-22.052 10.92-30.317z"
      fill="#311E69"
    />
    <path
      d="M58.208 188.73L74.307 52.646l42.972 5.398c31.859 4.002 53.863 37.182 49.064 75.217-4.8 38.037-34.135 64.766-65.994 60.762l-42.141-5.293z"
      fill="#311E69"
    />
    <path
      d="M63.444 144.462l-5.236 44.27 42.143 5.295c29.307 3.682 56.478-18.641 64.378-51.851-10.62 4.128-42.085 14.265-101.285 2.286z"
      fill="#1E1240"
    />
    <path
      d="M123.945 127.933c4.736-37.543-17.251-71.222-49.11-75.224-31.858-4.002-61.524 23.188-66.26 60.731-4.736 37.543 17.252 71.222 49.11 75.224 31.859 4.002 61.525-23.188 66.26-60.731z"
      fill="#FFE6E6"
    />
    <path
      d="M74.218 57.586c29.115 3.658 49.229 34.941 44.839 69.735-4.389 34.795-31.648 60.127-60.76 56.469-29.115-3.657-49.23-34.941-44.84-69.735 4.39-34.795 31.646-60.127 60.761-56.469zm.614-4.874C42.975 48.71 13.31 75.9 8.573 113.442c-4.735 37.543 17.25 71.22 49.11 75.222 31.856 4.002 61.523-23.185 66.258-60.728 4.737-37.542-17.25-71.22-49.109-75.224z"
      fill="#614CD4"
    />
    <path
      d="M119.062 127.318c4.396-34.851-15.68-66.074-44.842-69.737-29.161-3.663-56.365 21.62-60.762 56.471-4.396 34.852 15.68 66.074 44.842 69.737 29.162 3.664 56.366-21.619 60.762-56.471z"
      fill="#FFE6E6"
    />
    <path
      d="M15.716 119.603c4.389-34.794 31.648-60.126 60.76-56.468 17.316 2.174 31.447 14.123 39.134 30.77-6.934-19.5-22.207-33.908-41.392-36.317-29.114-3.66-56.372 21.672-60.76 56.467-1.779 14.101.467 27.623 5.705 38.965-3.588-10.092-4.945-21.55-3.447-33.417z"
      fill="#F4C6C6"
    />
    <path
      d="M71.734 77.577c-.923-.117-1.56-1.081-1.426-2.154l1.377-10.916c.135-1.073.992-1.851 1.915-1.735.923.117 1.56 1.081 1.426 2.155L73.65 75.842c-.135 1.075-.992 1.851-1.915 1.735zM46.23 84.795c-.476-.06-.92-.356-1.192-.856l-4.97-9.118c-.49-.897-.244-2.113.551-2.714.793-.602 1.835-.363 2.322.536l4.971 9.118c.49.896.243 2.113-.552 2.714-.35.267-.751.369-1.13.32zM30.073 109.156a1.5 1.5 0 01-.289-.066l-8.994-3.057c-.885-.3-1.363-1.385-1.07-2.422.294-1.038 1.25-1.636 2.134-1.335l8.995 3.056c.885.301 1.363 1.386 1.07 2.423-.262.925-1.052 1.5-1.846 1.401zM22.01 143.696c-.576-.073-1.094-.492-1.326-1.154-.344-.989.08-2.143.948-2.581l8.807-4.438c.867-.436 1.848.012 2.193.999.345.988-.08 2.142-.947 2.58l-8.808 4.438a1.485 1.485 0 01-.867.156zM43.621 170.875a1.452 1.452 0 01-.556-.19c-.82-.475-1.126-1.648-.683-2.618l4.5-9.855c.442-.97 1.467-1.37 2.286-.893.822.476 1.127 1.649.684 2.618l-4.5 9.856c-.346.757-1.05 1.169-1.73 1.082zM75.798 174.916c-.682-.086-1.26-.656-1.409-1.477l-1.911-10.662c-.189-1.049.399-2.109 1.312-2.366.914-.26 1.807.381 1.995 1.43l1.912 10.662c.188 1.048-.4 2.109-1.313 2.366-.2.056-.397.07-.586.047zM103.483 153.933a1.51 1.51 0 01-.8-.365l-7.43-6.477c-.732-.638-.856-1.861-.278-2.733.579-.873 1.64-1.062 2.372-.425l7.43 6.477c.731.637.856 1.861.277 2.733-.388.583-.994.861-1.571.79zM104.252 118.474c-.795-.099-1.416-.851-1.441-1.814-.026-1.077.707-2.009 1.639-2.082l9.473-.735c.932-.072 1.708.742 1.736 1.821.027 1.077-.706 2.009-1.639 2.083l-9.472.735c-.1.007-.2.005-.296-.008zM94.65 90.878a1.537 1.537 0 01-1.016-.59c-.62-.78-.558-2.017.14-2.765l7.082-7.605c.697-.748 1.763-.724 2.382.056.62.78.558 2.017-.139 2.765l-7.082 7.606c-.388.415-.891.594-1.368.533z"
      fill="#614CD4"
    />
    <path
      d="M76.66 99.545l-2.977-7.058-2.978-7.058-4.637 6.101-4.637 6.104 4.518.567-2.836 22.494 6.19.778 2.838-22.495 4.518.567z"
      fill="#1E1240"
    />
    <path
      d="M33.962 126.675l-4.29 6.348-4.293 6.349 7.655.536 7.657.534-1.997-4.085 30.627-14.905-2.735-5.596L35.96 130.76l-1.997-4.085z"
      fill="#1E1240"
    />
    <path
      d="M53.866 178.574s30.567 1.69 47.947-26.36c17.378-28.05 8.755-60.226-1.053-62.255-9.808-2.03-16.762 39.02-23.385 51.601-8.67 16.466-38.323 36.843-23.509 37.014z"
      fill="#EAEAEA"
    />
    <path
      d="M148.782 74.316l-39.397-9.955s8.998 14.718 26.138 21.233c11.306 4.296 19.316 3.928 23.462 3.178-2.778-5.385-6.214-10.254-10.203-14.457z"
      fill="#1E1240"
    />
    <path
      d="M161.199 78.648c-4.943 8.825-20.618 4.516-37.621-4.966-17.003-9.481-28.898-20.545-23.956-29.37 4.943-8.825 22.935-19.808 44.698-7.673 23.074 12.866 21.821 33.184 16.879 42.009z"
      fill="#745FE8"
    />
    <path
      d="M155.288 44.977c.149 1.934.108 5.314-1.48 8.152-2.379 4.25-9.738 3.577-17.919.017-8.178-3.56-15.611-3.081-21.301-8.78-3.321-3.325-4.508-6.887-4.92-9.278-4.764 2.56-8.258 6.034-10.045 9.225-4.943 8.823 6.95 19.887 23.955 29.37 17.003 9.481 32.68 13.791 37.621 4.966 3.967-7.086 5.553-21.577-5.911-33.672z"
      fill="#614CD4"
    />
    <path
      d="M50.13 29.63a2.013 2.013 0 01-1.735-1.67c-.177-1.077.578-2.088 1.65-2.304l4.163-.836L73.554 3.158a9.467 9.467 0 018.24-3.084l36.139 4.541a9.51 9.51 0 016.999 4.63l14.342 24.681 3.754 1.82c.996.483 1.473 1.675 1.008 2.679a2.013 2.013 0 01-2.705.962l-3.462-1.679a5.291 5.291 0 01-2.272-2.107l-14.145-24.343a5.467 5.467 0 00-4.022-2.66l-36.138-4.54a5.433 5.433 0 00-4.734 1.773L57.817 26.814a6.766 6.766 0 01-3.705 2.122l-3.333.671c-.22.043-.44.049-.65.023z"
      fill="#311E69"
    />
    <path
      d="M146.884 39.541c.573-1.023-.392-2.65-2.155-3.632-1.762-.983-3.656-.95-4.229.073s.392 2.649 2.155 3.632c1.762.983 3.656.95 4.229-.073zM49.087 29.882c1.655-.438 2.753-1.711 2.452-2.844-.3-1.133-1.886-1.697-3.541-1.259-1.655.438-2.753 1.711-2.452 2.844.3 1.133 1.886 1.697 3.541 1.259z"
      fill="#A6A4FF"
    />
    <path
      d="M109.991 181.102c-2.477.835-3.818 3.541-2.98 6.011l7.371 21.755c.836 2.472 3.549 3.811 6.024 2.974 2.477-.834 3.818-3.541 2.98-6.011l-7.371-21.755c-.836-2.47-3.547-3.808-6.024-2.974z"
      fill="#614CD4"
    />
  </svg>
);

export default SvgClock;
