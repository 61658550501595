import React from 'react';

import { cx, css, px2grid, mediaQuery } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { node, string } from '@ampli/utils';

const BANNER_IMAGE_COLOR_FILTERS = {
  UNDERGRADUATE: '',
  GRADUATE: css`
    filter: hue-rotate(340deg);
  `,
};

const BackgroundBanner = ({ courseDegree, children }) => {
  const breakpoint = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoint !== 'desktop', [breakpoint]);

  return (
    <div
      className={cx(
        'banner-wrapper',
        css`
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: url(${require(`../../assets/${
              isMobile ? 'bg-banner-course-mobile.jpg' : 'bg-banner-course.jpg'
            }`)});
            background-position: 50% 0%;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
            ${BANNER_IMAGE_COLOR_FILTERS[courseDegree]}
          }

          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;

          ${mediaQuery('mobile')} {
            overflow: hidden;
          }

          ${mediaQuery('desktop')} {
            min-height: ${px2grid(450)};
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            background-position: 100% 100%;
          }
        `
      )}
    >
      {children}
    </div>
  );
};

BackgroundBanner.propTypes = {
  courseDegree: string,
  children: node,
};

export default BackgroundBanner;
