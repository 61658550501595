import React from 'react';

import {
  cx,
  css,
  getSpaceSize,
  px2grid,
  Dialog,
  DialogBackdrop,
  DialogSection,
  DialogCloseButton,
  ColorNeutralLighter,
  px2rem,
} from '@ampli/ui';
import { object, node } from '@ampli/utils';

import MecInfo from './mec-info';

const FilterListModal = ({ dialog, children }) => (
  <>
    <DialogBackdrop {...dialog} />
    <Dialog
      {...dialog}
      aria-label="modal-filter-list"
      className={cx(
        'dialog-filter',
        css`
          background-color: white;
          border: 1px solid ${ColorNeutralLighter};
          border-radius: ${px2rem(8)};
          padding: 0 ${getSpaceSize('large')} ${getSpaceSize('large')};
          overflow-y: auto;
        `
      )}
    >
      <div
        className={cx(
          'dialog-header-wrap',
          css`
            display: flex;
            justify-content: space-between;
          `
        )}
      >
        <DialogCloseButton
          className={cx(
            'modal-close-button',
            css`
              margin-right: ${getSpaceSize('large')};
              margin-top: ${px2grid(20)};
            `
          )}
          onClick={() => dialog.hide()}
        />
        <MecInfo />
      </div>
      <>{children}</>
    </Dialog>
  </>
);

FilterListModal.propTypes = {
  dialog: object,
  children: node,
};

export default FilterListModal;
