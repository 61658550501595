import React from 'react';

import {
  parseFilters,
  createMetaTitle,
  createMetaDescription,
  isUsingMoreThanTwoFilters,
} from '../lib/meta-text-filter';

const useMetaTextFilter = (
  filtersValues = {},
  filterDataList = [],
  courseDegree
) => {
  const filters = React.useMemo(
    () => parseFilters(filtersValues, filterDataList),
    [filtersValues, filterDataList]
  );

  const metaTitle = React.useMemo(
    () => createMetaTitle(filters, courseDegree),
    [filters, courseDegree]
  );
  const metaDescription = React.useMemo(
    () => createMetaDescription(filters, courseDegree),
    [filters, courseDegree]
  );

  const doNotIndex = React.useMemo(
    () => isUsingMoreThanTwoFilters(filtersValues),
    [filtersValues]
  );

  return { metaTitle, metaDescription, doNotIndex };
};

export default useMetaTextFilter;
