import React from 'react';

import { useBreakpoint } from '@ampli/hooks';
import { string, func } from '@ampli/utils';

import VacationBannerDesktop from './vacation-banner-desktop';
import VacationBannerMobile from './vacation-banner-mobile';

export const MIN_WIDTH_MOBILE = 950;

const VacationBanner = (props) => {
  const breakpoint = useBreakpoint(true);

  if (
    !breakpoint?.isDesktop ||
    breakpoint?.currentSize?.width < MIN_WIDTH_MOBILE
  )
    return <VacationBannerMobile {...props} />;

  return <VacationBannerDesktop {...props} />;
};

VacationBanner.propTypes = {
  className: string,
  onProsClick: func,
};

export default VacationBanner;
