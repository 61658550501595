import React, { forwardRef, useState } from 'react';
import BannerBackground from './banner-background';
import CourseDescription from './course-description';

import { css, cx, px2grid, px2rem, mediaQuery, Flex } from '@ampli/ui';
import { string, node, func } from '@ampli/utils';

import bannerImage from '../../assets/banner-garoto-estudando-pelo-celular.webp';

const BannerContent = forwardRef(({ children, className, ...props }, ref) => (
  <Flex alignItems="center" justifyContent="flex-start" direction="row">
    <div
      {...props}
      ref={ref}
      className={cx(
        'banner-content',
        css`
          --award-icon-and-margin-total-width: calc(
            ${px2rem(24)} + ${px2rem(20)}
          );

          margin: 0 auto;
          width: calc(100% - ${px2grid(28)});
          max-width: calc(
            ${px2grid(1000)} + var(--award-icon-and-margin-total-width) * 2
          );
          height: ${px2rem(604)};

          ${mediaQuery('mobile')} {
            align-items: flex-end;
            padding-bottom: ${px2grid(120)};
          }

          ${mediaQuery('tablet')} {
            height: ${px2rem(623)};
          }

          ${mediaQuery('desktop')} {
            height: ${px2rem(707)};
            padding-bottom: ${px2grid(104)};
          }
        `,
        className
      )}
    >
      <div>
        <Flex
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
        >
          <div>{children}</div>
        </Flex>
      </div>
    </div>
  </Flex>
));
BannerContent.propTypes = {
  children: node.isRequired,
  className: string,
};
BannerContent.displayName = 'BannerContent';

const HomeBanner = ({ ...props }) => {
  const [selectedDescription, setSelectedDescription] = useState(
    'UNDERGRADUATE'
  );

  return (
    <BannerBackground {...props} image={bannerImage}>
      <BannerContent>
        <CourseDescription
          icon="Award"
          title="Graduação"
          viewMoreUrl="/graduacao"
          active={selectedDescription === 'UNDERGRADUATE'}
          onClickTitle={() => setSelectedDescription('UNDERGRADUATE')}
        >
          Cursos 100% online, com tempo de duração entre 1 ano e meio e 3 anos.
        </CourseDescription>
        <CourseDescription
          icon="HatGraduation"
          title="Pós-Graduação"
          viewMoreUrl="/pos-graduacao"
          active={selectedDescription === 'GRADUATE'}
          onClickTitle={() => setSelectedDescription('GRADUATE')}
          className={css`
            margin-top: ${px2grid(16)};

            ${mediaQuery('desktop')} {
              margin-top: ${px2grid(40)};
            }
          `}
        >
          Cursos de MBA Online e Especialização, com tempo de duração entre 5 meses e 9 meses
        </CourseDescription>
      </BannerContent>
    </BannerBackground>
  );
};

HomeBanner.propTypes = {
  onCallToActionClick: func,
};

HomeBanner.displayName = 'HomeBanner';

export default HomeBanner;
