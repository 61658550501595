import React from 'react';

import {
  cx,
  css,
  getSpaceSize,
  px2rem,
  px2grid,
  Text,
  ColorNeutralBackground,
  ColorNeutralDark,
  ColorNeutralLighter,
  ColorNeutralTitle,
  ColorBrandBase,
} from '@ampli/ui';
import { object, func } from '@ampli/utils';

const UnitItem = ({ unit, onClickClass }) => (
  <div
    className={cx(
      'wrap-unit-item',
      css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        // &:not(:last-child) {
        //   & > div {
        //     border-bottom: 1px solid ${ColorNeutralLighter};
        //   }
        // }

        & > div {
          margin-top: ${px2grid(24)};
          width: 90%;
        }
      `
    )}
  >
    <div>
      <Text
        color={ColorNeutralTitle}
        className={cx(
          'unit-title',
          css`
            text-decoration: underline;
          `
        )}
      >
        {unit?.title || ''}
      </Text>
      {/* <div>
        {!!unit?.classes?.length &&
          unit?.classes.map((item, index) => (
            <button
              onClick={() =>
                !item?.disabled && onClickClass && onClickClass(item)
              }
              className={cx(
                'class-button',
                css`
                  background-color: ${ColorBrandBase};
                  color: #fff;
                  padding: ${px2rem(5)} ${px2rem(16)};
                  margin: ${getSpaceSize('small')} 0;
                  border-radius: ${px2rem(8)};
                  border: none;
                  outline: none;
                  &.disabled {
                    background-color: ${ColorNeutralBackground};
                    color: ${ColorNeutralDark};
                    cursor: default;
                  }
                  &.hasSpace {
                    margin-right: ${getSpaceSize('x-small')};
                  }
                `,
                item?.disabled && 'disabled',
                index !== unit?.classes?.length - 1 && 'hasSpace'
              )}
              key={`${unit?.title?.replace(/\s/g, '_')}_unit_${index}`}
            >
              {item?.title || ''}
            </button>
          ))}
      </div> */}
    </div>
  </div>
);

UnitItem.propTypes = {
  unit: object,
  onClickClass: func,
};

export default UnitItem;
