import React from 'react';

import {
  css,
  cx,
  px2rem,
  Button,
  ColorNeutralDark,
  ColorNeutralLighter,
} from '@ampli/ui';

import { string, bool, node } from '@ampli/utils';

const ButtonMenu = ({
  hasBorder = true,
  hasLetterSpacing = true,
  color,
  children,
  ...props
}) => (
  <Button
    {...props}
    className={cx(
      'menu-mobile-item',
      css`
        color: ${color || ColorNeutralDark};
        font-weight: 400;
        &.has-border {
          border-bottom: 1px solid ${ColorNeutralLighter};
          border-radius: 0px;
        }
        &.has-letter-spacing {
          letter-spacing: ${px2rem(5)};
        }
      `,
      hasBorder && 'has-border',
      hasLetterSpacing && 'has-letter-spacing'
    )}
  >
    {children}
  </Button>
);

ButtonMenu.propTypes = {
  hasBorder: bool,
  hasLetterSpacing: bool,
  color: string,
  children: node,
};

export default ButtonMenu;
