import React from 'react';

import { cx, css, Loader, px2grid, marginSize, mediaQuery } from '@ampli/ui';
import {
  arrayOf,
  object,
  bool,
  func,
  number,
  string,
  oneOf,
} from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import { CourseCard } from '../course-card';
import { Paginator } from '../paginator';
import EmptyState from './empty-state';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

const CourseList = ({ items, degreeType }) => {
  const { isMobile } = useBreakpoint(true);

  return (
    !!items?.length &&
    items?.map((item, index) => (
      <React.Fragment key={`course_card_${index}`}>
        <CourseCard {...item} noMargin={isMobile} degreeType={degreeType} />
        {isMobile && Boolean(index % 2 === 0) && (
          <div
            className={cx('course-card-space', marginSize('small', 'right'))}
          />
        )}
      </React.Fragment>
    ))
  );
};

const CourseListWrap = React.forwardRef(
  (
    {
      items,
      backupItems,
      loading,
      onEmptyStateSubmit,
      searchText,
      degreeType,
      leadLoading,
      ...props
    },
    ref
  ) => (
    <div
      ref={ref}
      className={cx(
        'list-container',
        css`
          display: flex;
          flex: 1;
          flex-direction: column;
          ${mediaQuery('tablet')} {
            max-width: ${px2grid(760)};
          }
        `
      )}
    >
      {!loading && !items?.length && (
        <EmptyState
          onSubmit={onEmptyStateSubmit}
          searchText={searchText}
          leadLoading={leadLoading}
        />
      )}
      <div
        className={cx(
          'course-list-wrap',
          css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `
        )}
      >
        <div
          className={cx(
            'course-list-content',
            css`
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              width: 100%;
              ${mediaQuery('desktop')} {
                justify-content: flex-start;
              }
            `
          )}
        >
          {loading && <Loader />}
          {!loading && (
            <CourseList
              items={items?.length ? items : backupItems}
              degreeType={degreeType}
            />
          )}
        </div>
        {!loading && (
          <div
            className={cx(
              'paginator-container',
              css`
                align-self: center;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
              `
            )}
          >
            <Paginator {...props} />
          </div>
        )}
      </div>
    </div>
  )
);

CourseListWrap.displayName = 'CourseListWrap';

CourseListWrap.propTypes = {
  items: arrayOf(object),
  backupItems: arrayOf(object),
  loading: bool,
  currentPage: number,
  itemsLength: number,
  onChangePage: func,
  limitPerPage: number,
  searchText: string,
  onEmptyStateSubmit: func,
  leadLoading: bool,
  degreeType: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)),
};

export default CourseListWrap;
