import * as React from 'react';

function SvgClockMobile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M36.222 120.476a3.161 3.161 0 011.983 3.999l-4.903 14.472a3.17 3.17 0 01-4.008 1.978 3.16 3.16 0 01-1.982-3.998l4.903-14.473a3.173 3.173 0 014.007-1.978zM65.46 25.27a3.362 3.362 0 00-2.92-3.752 3.366 3.366 0 00-3.76 2.913 3.353 3.353 0 001.433 3.186l-2.36 18.705 3.065.386 2.36-18.705a3.356 3.356 0 002.181-2.733z"
        fill="#311E69"
      />
      <path
        d="M17.695 41.07c1.727 6.501 11 6.711 21.625 3.901 10.624-2.81 18.576-7.575 16.85-14.076-1.727-6.5-10.5-16.807-24.098-13.21-14.416 3.812-16.104 16.884-14.377 23.385z"
        fill="#614CD4"
      />
      <path
        d="M24.959 20.901c-.31 1.194-.685 3.337-.129 5.428.83 3.13 5.027 4.082 10.02 3.363 4.991-.72 9.091.974 12.944-1.563 2.248-1.48 3.33-3.51 3.842-4.943 2.363 2.508 3.91 5.358 4.534 7.709 1.726 6.5-6.226 11.266-16.85 14.076-10.624 2.81-19.898 2.6-21.625-3.902-1.386-5.218-.57-14.67 7.264-20.168z"
        fill="#311E69"
      />
      <path
        d="M38.722 125.55l10.71-90.528 28.587 3.591c21.193 2.663 35.831 24.735 32.639 50.037-3.193 25.304-22.708 43.085-43.902 40.422l-28.034-3.522z"
        fill="#311E69"
      />
      <path
        d="M42.206 96.102l-3.484 29.45 28.035 3.522c19.496 2.45 37.571-12.4 42.827-34.493-7.065 2.746-27.997 9.49-67.378 1.52z"
        fill="#1E1240"
      />
      <path
        d="M82.453 85.106c3.15-24.975-11.476-47.38-32.67-50.041C28.59 32.402 8.855 50.49 5.704 75.465c-3.15 24.975 11.477 47.38 32.67 50.042 21.194 2.662 40.929-15.426 44.08-40.4z"
        fill="#FFE6E6"
      />
      <path
        d="M49.373 38.308C68.74 40.742 82.123 61.553 79.2 84.7c-2.92 23.147-21.053 39.999-40.42 37.565-19.368-2.433-32.75-23.244-29.828-46.39 2.92-23.147 21.052-39.999 40.42-37.566zm.408-3.242C28.59 32.404 8.854 50.491 5.703 75.466c-3.15 24.975 11.476 47.379 32.67 50.041 21.192 2.662 40.927-15.424 44.078-40.399C85.6 60.133 70.975 37.73 49.78 35.066z"
        fill="#614CD4"
      />
      <path
        d="M79.205 84.698c2.924-23.185-10.431-43.955-29.83-46.392-19.4-2.437-37.497 14.382-40.422 37.567-2.924 23.184 10.431 43.954 29.83 46.391 19.4 2.437 37.497-14.382 40.422-37.566z"
        fill="#FFE6E6"
      />
      <path
        d="M10.455 79.565C13.375 56.418 31.508 39.567 50.875 42c11.52 1.447 20.92 9.395 26.033 20.47-4.612-12.973-14.773-22.557-27.535-24.16-19.368-2.435-37.5 14.417-40.42 37.564-1.183 9.38.31 18.376 3.795 25.921-2.387-6.713-3.29-14.336-2.293-22.23z"
        fill="#F4C6C6"
      />
      <path
        d="M47.72 51.607c-.614-.078-1.037-.72-.948-1.433l.916-7.261c.09-.714.66-1.232 1.274-1.155.614.078 1.038.72.949 1.433l-.916 7.262c-.09.715-.66 1.231-1.275 1.154zM30.754 56.408c-.317-.04-.612-.236-.793-.569l-3.306-6.065c-.326-.597-.162-1.406.367-1.806.527-.4 1.22-.241 1.545.356l3.306 6.066c.326.597.162 1.406-.367 1.806-.233.177-.5.245-.752.212zM20.006 72.614a.96.96 0 01-.192-.043l-5.983-2.034c-.59-.2-.907-.921-.712-1.611.196-.69.83-1.088 1.42-.888l5.983 2.033c.589.2.907.922.712 1.612-.174.615-.7.998-1.228.931zM14.643 95.592c-.384-.049-.728-.328-.882-.768-.23-.657.052-1.425.63-1.716l5.86-2.953c.575-.29 1.228.008 1.458.664.229.658-.053 1.426-.63 1.717l-5.86 2.952a.99.99 0 01-.576.104zM29.019 113.672a.97.97 0 01-.37-.126c-.546-.316-.75-1.097-.455-1.742l2.994-6.556c.294-.645.976-.911 1.521-.594.546.317.75 1.097.455 1.742l-2.994 6.556c-.23.504-.698.778-1.151.72zM50.424 116.36c-.453-.057-.838-.436-.937-.982l-1.272-7.093c-.125-.698.266-1.403.874-1.574.607-.173 1.201.254 1.327.952l1.271 7.092c.126.698-.265 1.403-.873 1.574a.985.985 0 01-.39.031zM68.841 102.402a1.005 1.005 0 01-.532-.243l-4.943-4.309c-.486-.424-.57-1.238-.184-1.818.384-.58 1.091-.707 1.577-.283l4.943 4.309c.487.424.57 1.238.184 1.818-.258.388-.661.573-1.044.526zM69.353 78.813c-.529-.066-.942-.566-.959-1.207-.017-.716.47-1.336 1.09-1.385l6.302-.489c.62-.047 1.136.494 1.155 1.212.018.716-.47 1.337-1.09 1.385l-6.301.49a.941.941 0 01-.197-.006zM62.965 60.456a1.022 1.022 0 01-.676-.393c-.412-.52-.37-1.342.093-1.84l4.712-5.059c.463-.497 1.172-.481 1.585.038.412.519.37 1.342-.093 1.84L63.874 60.1c-.258.277-.592.395-.91.355z"
        fill="#614CD4"
      />
      <path
        d="M50.997 66.221l-1.98-4.695-1.98-4.695-3.086 4.058-3.085 4.06 3.006.378-1.887 14.964 4.118.518 1.888-14.965 3.006.377z"
        fill="#1E1240"
      />
      <path
        d="M22.593 84.27l-2.854 4.222-2.856 4.224 5.092.356 5.094.355-1.328-2.717 20.374-9.916-1.82-3.722-20.374 9.914-1.328-2.717z"
        fill="#1E1240"
      />
      <path
        d="M35.834 118.794s20.334 1.125 31.896-17.535c11.56-18.66 5.824-40.065-.7-41.415-6.525-1.35-11.151 25.958-15.557 34.327-5.768 10.954-25.494 24.51-15.64 24.623z"
        fill="#EAEAEA"
      />
      <path
        d="M98.975 49.438l-26.208-6.623s5.986 9.791 17.388 14.125c7.521 2.859 12.85 2.614 15.608 2.115-1.848-3.583-4.134-6.821-6.788-9.617z"
        fill="#1E1240"
      />
      <path
        d="M107.236 52.32c-3.288 5.87-13.716 3.004-25.027-3.304-11.311-6.307-19.224-13.667-15.936-19.538 3.288-5.87 15.257-13.177 29.734-5.104 15.35 8.559 14.517 22.075 11.229 27.945z"
        fill="#745FE8"
      />
      <path
        d="M103.304 29.92c.099 1.287.071 3.535-.985 5.423-1.583 2.828-6.478 2.38-11.92.012-5.441-2.369-10.386-2.05-14.171-5.841-2.209-2.212-2.998-4.582-3.273-6.172-3.169 1.703-5.493 4.014-6.682 6.137-3.288 5.87 4.623 13.23 15.936 19.538 11.31 6.307 21.74 9.174 25.027 3.303 2.638-4.714 3.694-14.353-3.932-22.4z"
        fill="#614CD4"
      />
      <path
        d="M33.348 19.71a1.339 1.339 0 01-1.154-1.11c-.118-.716.385-1.389 1.098-1.533l2.769-.556L48.93 2.1A6.298 6.298 0 0154.413.049l24.04 3.021a6.327 6.327 0 014.657 3.08l9.54 16.42 2.497 1.21c.663.321.98 1.114.67 1.781a1.339 1.339 0 01-1.798.64l-2.303-1.116a3.516 3.516 0 01-1.512-1.402L80.794 7.49a3.637 3.637 0 00-2.675-1.77L54.079 2.7a3.614 3.614 0 00-3.15 1.18L38.462 17.837a4.502 4.502 0 01-2.465 1.411l-2.217.447a1.367 1.367 0 01-.432.015z"
        fill="#311E69"
      />
      <path
        d="M97.713 26.304c.38-.68-.26-1.762-1.433-2.416-1.173-.654-2.433-.632-2.814.048-.38.68.26 1.763 1.433 2.417 1.173.653 2.433.632 2.814-.049zM32.655 19.878c1.1-.29 1.831-1.138 1.63-1.892-.2-.753-1.254-1.128-2.355-.837-1.101.291-1.831 1.139-1.631 1.892.2.754 1.255 1.129 2.356.837z"
        fill="#A6A4FF"
      />
      <path
        d="M73.17 120.476a3.161 3.161 0 00-1.982 3.999l4.903 14.472a3.17 3.17 0 004.008 1.978 3.16 3.16 0 001.982-3.998l-4.903-14.473a3.173 3.173 0 00-4.007-1.978z"
        fill="#614CD4"
      />
    </svg>
  );
}

export default SvgClockMobile;
