import React from 'react';

import { useHistory } from 'react-router-dom';
import {
  cx,
  css,
  getSpaceSize,
  IconButton,
  ColorBrandBase,
  Loader,
  px2grid,
} from '@ampli/ui';
import { CourseCard } from '../course-card';

import { string, object, arrayOf, func, bool } from '@ampli/utils';

const CourseGridList = ({ items, loading, degreeType, ...props }) => {
  const history = useHistory();
  return (
    <div
      className={cx(
        'course-grid-wrap',
        css`
          display: flex;
          flex-direction: column;
        `
      )}
      {...props}
    >
      <div
        className={cx(
          'course-grid-list',
          css`
            display: flex;
            flex-direction: row;
            padding-top: 0;
            overflow: scroll;

            @media (min-width: 900px) {
              flex-wrap: wrap;
              max-width: ${px2grid(580)};
              overflow: auto;
            }
            &.loading {
              flex: 1;
            }
          `,
          loading && 'loading'
        )}
      >
        {loading && (
          <div
            className={cx(
              'loader-courses-wrap',
              css`
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
              `
            )}
          >
            <Loader />
          </div>
        )}
        {!loading &&
          !!items?.length &&
          items.map((item, index) => (
            <CourseCard
              key={`course_card_${index}`}
              {...(item || {})}
              urlCode={item.urlCode}
              degreeType={degreeType}
            />
          ))}
      </div>
      <div
        className={cx(
          'footer-button-grid',
          css`
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: ${getSpaceSize('small')};
          `
        )}
      >
        <IconButton
          color={ColorBrandBase}
          icon="ChevronRight"
          iconPosition="right"
          className={css`
            font-weight: 500;
          `}
          onClick={() =>
            degreeType == 'UNDERGRADUATE'
              ? history.push('/graduacao')
              : history.push('/pos-graduacao')
          }
          underline
        >
          {degreeType == 'UNDERGRADUATE'
            ? 'Ver mais cursos de graduação'
            : 'Ver mais cursos de pós-graduação'}
        </IconButton>
      </div>
    </div>
  );
};

CourseGridList.propTypes = {
  items: arrayOf(object),
  className: string,
  onSubscribeClick: func,
  loading: bool,
  degreeType: string,
};

export default CourseGridList;
