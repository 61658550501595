import React from 'react';

import {
  cx,
  css,
  Text,
  getSpaceSize,
  fontSize,
  marginSize,
  boxShadow,
  Tooltip,
  TooltipReference,
  useTooltipState,
  px2grid,
  GradientLinearBrand,
  ColorNeutralTitle,
  ColorNeutralDark,
} from '@ampli/ui';
import { string, node } from '@ampli/utils';
import { BENEFITS_TOOLTIP_TEXTS } from '../../constants';

const TooltipCard = ({ type }) => (
  <div
    className={cx(
      'benefits-tooltip-wrap',
      css`
        background-color: white;
        width: ${px2grid(450)};
        border-radius: 0 0 ${px2grid(16)} ${px2grid(16)};
        ${boxShadow()};
      `
    )}
  >
    <div
      className={cx(
        'benefits-tooltip-header',
        css`
          border-radius: ${px2grid(16)} ${px2grid(16)} 0 0;
          background: ${GradientLinearBrand};
        `
      )}
    >
      <div
        className={cx(
          'tooltip-header-vector',
          css`
            border-top-left-radius: ${px2grid(16)};
            height: ${px2grid(30)};
            width: ${px2grid(170)};
            background: url(${require('../../assets/header-vector.png')});
            background-position: 100% 0%;
            background-repeat: no-repeat;
            background-size: cover;
          `
        )}
      />
    </div>
    <div
      className={cx(
        'benefits-tooltip-content',
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: ${getSpaceSize('large')};
        `
      )}
    >
      <Text
        as="h2"
        className={css`
          font-weight: bold;
          ${fontSize('x-large')};
          ${marginSize('x-small', 'bottom')};
        `}
        color={ColorNeutralTitle}
      >
        {type}
      </Text>
      <Text as="h3" color={ColorNeutralDark}>
        {BENEFITS_TOOLTIP_TEXTS[type]}
      </Text>
    </div>
  </div>
);

const TooltipWrapper = ({ children, type, ...props }) => {
  const tooltip = useTooltipState();
  return (
    <>
      <TooltipReference {...tooltip} ref={children.ref} {...children.props}>
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      <Tooltip {...tooltip} {...props}>
        <TooltipCard type={type} />
      </Tooltip>
    </>
  );
};

TooltipWrapper.propTypes = {
  type: string,
  children: node,
};

export default TooltipWrapper;
