import React, { useState, useEffect } from 'react';

import {
  cx,
  css,
  ColorSemanticSuccess,
  px2rem,
  boxShadow,
  Text,
  fontSize,
  mediaQuery,
  PrimaryButton,
  Icon,
  SizeFontXSmall,
  getSpaceSize,
  px2grid,
} from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import LabelTypeCourse from './label-type-course';
import { TooltipDegree } from '../tooltip-degree';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

import { string, number, arrayOf, shape, bool, oneOf } from '@ampli/utils';

const MIN_CANDIDATE_COUNT = 40;

function resolveCourseUrl(degreeType, courseUrlCode) {
  return (
    degreeType &&
    courseUrlCode &&
    `${PATHNAME_BY_DEGREE_TYPE[degreeType].pathname}/${courseUrlCode}`
  );
}

const CourseCard = ({
  name,
  courseType,
  fieldOfStudies,
  candidateCount,
  image,
  degreeType,
  urlCode,
  noMargin,
  ...props
}) => {
  const fieldOfStudy = React.useMemo(() => {
    if (!fieldOfStudies || !fieldOfStudies.length) return '';

    return fieldOfStudies[0].name;
  }, [fieldOfStudies]);

  const [courseUrl, setCourseUrl] = useState(
    resolveCourseUrl(degreeType, urlCode)
  );

  useEffect(() => {
    setCourseUrl(resolveCourseUrl(degreeType, urlCode));
  }, [degreeType, urlCode]);

  const { isDesktop } = useBreakpoint(true);

  return (
    <div
      className={cx(
        'course-card-wrap',
        css`
          background-color: white;
          border-radius: ${px2rem(16)};
          padding: ${getSpaceSize('xx-small')} ${getSpaceSize('small')};
          width: ${px2grid(165)};
          margin-right: ${getSpaceSize('small')};
          margin-bottom: ${getSpaceSize('large')};
          ${boxShadow('card')};
          display: flex;
          flex-direction: column;
          align-items: center;

          &.noMargin {
            margin-right: 0;
          }
        `,
        noMargin && 'noMargin',
        props.className
      )}
    >
      <div
        className={cx(
          'content-wrap-course',
          css`
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: ${getSpaceSize('base')};
          `
        )}
      >
        <LabelTypeCourse label={fieldOfStudy} />
        <a
          href={courseUrl}
          className={css`
            &:hover {
              text-decoration: none;
            }
          `}
        >
          <div
            className={cx(
              'img-course',
              css`
                background: url(${require('../../assets/image-background-card.png')})
                    center center no-repeat,
                  url(${image?.baseUrl && image?.formats?.includes('thumbnail')
                      ? `${image?.baseUrl}/thumbnail`
                      : require('../../assets/image-mock-card.webp')})
                    center center no-repeat;
                height: ${px2grid(200)};
                width: ${px2grid(150)};
                border-radius: ${px2rem(8)};
                display: flex;
                align-items: flex-end;
                padding-bottom: ${px2grid(6)};
                cursor: pointer;
                ${mediaQuery('mobile')} {
                  background: url(${require('../../assets/image-background-card.png')})
                      center center no-repeat,
                    url(${image?.baseUrl
                        ? `${image?.baseUrl}${
                            image?.formats?.includes('mobile') ? '/mobile' : ''
                          }`
                        : require('../../assets/image-mock-card.webp')})
                      center center no-repeat;
                }
              `
            )}
          >
            <div
              className={cx(
                'wrap-text-card',
                css`
                  margin-left: ${getSpaceSize('xx-small')};
                  display: flex;
                  flex-direction: column;
                `
              )}
            >
              <div>
                <Text
                  color="white"
                  className={cx(
                    'text-course-degree',
                    fontSize('x-small'),
                    css`
                      margin-right: ${getSpaceSize('xx-small')};
                      font-weight: normal;
                    `
                  )}
                >
                  {courseType?.name}
                </Text>
                {isDesktop && (
                  <TooltipDegree degree={courseType?.code}>
                    <Text>
                      <Icon.InfoCircleBold color="white" />
                    </Text>
                  </TooltipDegree>
                )}
              </div>
              <Text
                color="white"
                as="h2"
                className={cx(
                  'text-course',
                  fontSize(
                    name?.replace(/ /g, '').length > 15 ? 'base' : 'large'
                  ),
                  css`
                    margin-bottom: ${getSpaceSize('small')};
                  `
                )}
              >
                {name}
              </Text>
            </div>
          </div>
        </a>
        <PrimaryButton
          as="a"
          href={courseUrl}
          className={cx(
            'subscribe-button-card',
            fontSize('x-small'),
            css`
              display: flex;
              justify-content: center;
              align-items: center;
              height: ${px2grid(32)};
              width: ${px2grid(140)};
              position: absolute;
              top: ${px2grid(180)};
            `
          )}
        >
          {'Saiba mais'}
        </PrimaryButton>
      </div>

      <div
        className={cx(
          'count-candidate-wrap',
          css`
            display: flex;
            justify-content: center;
            align-items: center;
            &.hasCandidates {
              justify-content: space-around;
            }
          `,
          candidateCount >= MIN_CANDIDATE_COUNT && 'hasCandidates'
        )}
      >
        {candidateCount >= MIN_CANDIDATE_COUNT ? (
          <Icon.CheckCircle
            width={px2rem(SizeFontXSmall)}
            color={ColorSemanticSuccess}
          />
        ) : (
          <Icon.StarOutline
            className={cx(
              'card-star-icon',
              css`
                margin-right: ${getSpaceSize('xxx-small')};
                height: ${px2grid(11)};
                width: ${px2grid(11)};
              `
            )}
          />
        )}
        <Text
          size="xx-small"
          className={cx(
            'subscriber-count-message',
            css`
              margin-left: ${px2grid(4)};
            `
          )}
        >
          {candidateCount >= MIN_CANDIDATE_COUNT
            ? `${candidateCount} inscritos`
            : 'Lançamento'}
        </Text>
      </div>
    </div>
  );
};

CourseCard.propTypes = {
  name: string,
  courseType: shape({ code: string, name: string }),
  fieldOfStudies: arrayOf(shape({ name: string })),
  urlCode: string,
  candidateCount: number,
  degreeType: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)),
  image: shape({ formats: arrayOf(string), baseUrl: string }),
  noMargin: bool,
  className: string,
};

export default CourseCard;
