import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Helmet,
  Header,
  BannerDetailCourse,
  CourseContent,
  ContentLoader,
  DetailsContentWrapper,
  SocialProof,
  NearbySpots,
  AmpliBenefits,
  TabCourse,
  CourseCurriculumList,
  SubscriptionProcess,
  Footer,
} from '../components';
import { cx, css, px2grid, mediaQuery, getSpaceSize } from '@ampli/ui';
import { GET_COURSE_BY_URL_CODE } from '@ampli/services';
import { object } from '@ampli/utils';
import { useScroller } from '@ampli/hooks';
import {
  Modal,
  SubscriptionSection,
  useSubscriptionModal,
} from '@ampli/registration';
import { useNearbySpots, useCourseInformation } from '../hooks';
import { SCROLLER_SETTINGS } from '../constants';
import {
  ampli,
  recaptchaSiteKey,
  googleAPIKey,
  entranceExamUrl,
  baseUrl,
} from '../config';

const DEGREE_TYPE = 'UNDERGRADUATE';

const CourseDetailsUndergraduate = ({ course }) => {
  const { pathname } = useLocation();
  const canonicalUrl = baseUrl + pathname;
  const subscription = useSubscriptionModal();
  const { nearbySpots, currentLocation } = useNearbySpots(googleAPIKey);
  const {
    duration,
    descriptions,
    testimonials,
    tags,
    tabs,
    classesBySemester,
    price,
  } = useCourseInformation(course);

  const { ref: footerRef, scroller: footerScroller } = useScroller(
    SCROLLER_SETTINGS
  );

  return (
    <div>
      <Helmet
        title={`Graduação em ${course.name} EaD | Ampli`}
        description={`Graduação em ${course.name} à distância. Consulte a descrição do curso, grade curricular, perfil do profissional e mercado de trabalho. Faça sua matrícula!`}
        robots="robots"
        canonical={canonicalUrl}
      />
      <Header
        contactScroller={footerScroller}
        onSubscribeClick={subscription.show}
      />
      <BannerDetailCourse
        tags={tags}
        name={`Graduação em ${course.name}`}
        courseName={course.name}
        onClickCard={subscription.show}
        duration={duration}
        description={descriptions['resumo'] || course.summary}
        courseImage={course?.image}
        courseDegree={DEGREE_TYPE}
        hideWhenScrollTo={{ id: 'nearby-spots', distance: 600 }}
        price={price}
      />
      <DetailsContentWrapper>
        <ContentLoader
          icon="User"
          title={`Como é o curso de ${course.name || ''}?`}
          content={descriptions['como-e-o-curso']}
        />
        <ContentLoader
          icon="BookOpen"
          title={`Qual é o perfil do profissional de ${course.name || ''}?`}
          content={descriptions['perfil-do-profissional']}
        />
        {!!testimonials.length && (
          <SocialProof
            desktopItems={1}
            testimonials={testimonials}
            className={css`
              padding-top: ${px2grid(24)} !important;

              ${mediaQuery('desktop')} {
                padding-top: 0 !important;
                margin-bottom: ${px2grid(84)};
              }
            `}
          />
        )}
        {!!tabs.length && (
          <CourseContent
            icon="List"
            title={`Grade curricular de ${course.name || ''}`}
          >
            <TabCourse tabs={tabs}>
              {(semester) => (
                <CourseCurriculumList
                  items={classesBySemester[semester?.label] || []}
                />
              )}
            </TabCourse>
          </CourseContent>
        )}
        <SubscriptionProcess
          onCallToActionClick={subscription.show}
          degreeType={DEGREE_TYPE}
        />
        <ContentLoader
          icon="Folder"
          title={`Como está o mercado de trabalho em ${course.name || ''}?`}
          content={descriptions['mercado-de-trabalho']}
        />
        <ContentLoader
          icon="Money"
          title={`Quanto ganha em média um profissional de ${
            course.name || ''
          }?`}
          content={descriptions['faixa-salarial']}
        />
        <NearbySpots currentLocation={currentLocation} spotList={nearbySpots} />
      </DetailsContentWrapper>
      <SubscriptionSection
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        courseName={course?.name}
        selectedCourseCode={course.urlCode}
        selectedCourseDegree={DEGREE_TYPE}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
        coursePrice={price}
        className={css`
          margin-bottom: ${px2grid(40)};
        `}
      />
      <div
        className={cx(
          'ampli-benefits-container',
          css`
            margin: ${getSpaceSize('xxx-large')} 0;
          `
        )}
      >
        <AmpliBenefits />
      </div>
      <Footer ref={footerRef} />

      <Modal
        {...subscription}
        selectedCourseCode={course.urlCode}
        selectedCourseDegree={DEGREE_TYPE}
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </div>
  );
};

CourseDetailsUndergraduate.getInitialProps = async ({ match, client }) => {
  try {
    const { data } = await client.query({
      query: GET_COURSE_BY_URL_CODE,
      variables: {
        urlCode: match.params.urlCode,
      },
      fetchPolicy: 'no-cache',
    });
    return {
      course: data.data,
    };
  } catch (error) {
    console.error(error);
    return { statusCode: 404 };
  }
};

CourseDetailsUndergraduate.propTypes = {
  course: object,
};

export default CourseDetailsUndergraduate;
