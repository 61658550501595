import React from 'react';
import { useLocation } from 'react-router-dom';
import { GET_COURSE_BY_URL_CODE } from '@ampli/services';
import {
  Helmet,
  Header,
  BannerDetailCourse,
  CourseContent,
  ContentLoader,
  DetailsContentWrapper,
  SocialProof,
  AmpliBenefits,
  TabCourse,
  CourseCurriculumList,
  SubscriptionProcess,
  Footer,
} from '../components';
import { cx, css, px2grid, mediaQuery, getSpaceSize } from '@ampli/ui';
import { object } from '@ampli/utils';
import { useScroller } from '@ampli/hooks';
import {
  Modal,
  SubscriptionSection,
  useSubscriptionModal,
} from '@ampli/registration';
import { useCourseInformation } from '../hooks';
import { SCROLLER_SETTINGS } from '../constants';
import {
  ampli,
  recaptchaSiteKey,
  googleAPIKey,
  entranceExamUrl,
  baseUrl,
} from '../config';

const DEGREE_TYPE = 'GRADUATE';

const CourseDetailsGraduate = ({ course }) => {
  const { pathname } = useLocation();
  const canonicalUrl = baseUrl + pathname;
  const subscription = useSubscriptionModal();
  const {
    duration,
    descriptions,
    testimonials,
    tags,
    tabs,
    classesBySemester,
    price,
  } = useCourseInformation(course);

  const { ref: footerRef, scroller: footerScroller } = useScroller(
    SCROLLER_SETTINGS
  );

  return (
    <div>
      <Helmet
        title={`${course?.courseType?.name} em ${course.name} EaD | Ampli`}
        description={`${course?.courseType?.name} em ${course.name} a distância. Consulte a descrição do curso, público alvo e programação. Primeira mensalidade grátis. Matricule-se já!`}
        robots="robots"
        canonical={canonicalUrl}
      />
      <Header
        contactScroller={footerScroller}
        onSubscribeClick={subscription.show}
      />
      <BannerDetailCourse
        tags={tags}
        name={`Pós-graduação em ${course.name}`}
        courseName={course.name}
        onClickCard={subscription.show}
        duration={duration}
        description={descriptions['resumo'] || course.summary}
        courseImage={course?.image}
        courseDegree={DEGREE_TYPE}
        hideWhenScrollTo={{ id: 'como-funciona-ead', distance: 500 }}
        price={price}
        partner={course.partner}
      />
      <DetailsContentWrapper>
        <ContentLoader
          icon="BookOpen"
          title={`Como é ${course?.courseType?.name === 'MBA' ? 'o' : 'a'} ${
            course?.courseType?.name
          } em ${course?.name}?`}
          content={descriptions['como-e-o-curso']}
        />
        <ContentLoader
          icon="User"
          title={`Público alvo`}
          content={descriptions['publico-alvo']}
        />
        {!!testimonials.length && (
          <SocialProof
            desktopItems={1}
            testimonials={testimonials}
            className={css`
              padding-top: ${px2grid(24)} !important;

              ${mediaQuery('desktop')} {
                padding-top: 0 !important;
                margin-bottom: ${px2grid(84)};
              }
            `}
          />
        )}
        {!!tabs.length && (
          <CourseContent
            icon="List"
            title={`Grade curricular de ${course.name || ''}`}
          >
            <TabCourse tabs={tabs}>
              {(semester) => (
                <CourseCurriculumList
                  items={classesBySemester[semester?.label] || []}
                />
              )}
            </TabCourse>
          </CourseContent>
        )}
        <SubscriptionProcess
          id="subscription-process"
          degreeType={DEGREE_TYPE}
          onCallToActionClick={subscription.show}
        />
        <ContentLoader
          icon="Notes"
          title={`Como é o método de avaliação?`}
          content={`Ao final de cada disciplina, será aplicada uma prova para avaliar os conhecimentos adquiridos. As provas podem ser realizadas através do nosso aplicativo (disponível para Android e iOS), ou pelo computador, no Ambiente Virtual de Aprendizagem (AVA). Para obter o diploma de conclusão de curso, não será necessário a apresentação de TCC ou monografia.`}
        />
        <ContentLoader
          id="como-funciona-ead"
          icon="PlayCircle"
          title={`Como funciona o ensino a distância na Ampli?`}
          content={`A pós-graduação online da Ampli é uma formação acadêmica e profissional destinada aos alunos que já concluíram um curso de graduação. As aulas são ministradas 100% a distância, com turmas novas iniciando toda segunda-feira. O acesso às disciplinas do curso de pós-graduação é realizado por meio do <a href="https://ava.ampli.com.br/login">Ambiente Virtual de Aprendizagem (AVA)</a>, onde você também encontrará suporte online e acesso à biblioteca virtual.`}
        />
        {course.partner &&
          course.partner?.name &&
          course.partner?.description && (
            <ContentLoader
              icon="HatGraduation"
              title={`Quem é a ${course.partner.name}?`}
              content={course.partner.description}
            />
          )}
      </DetailsContentWrapper>
      <SubscriptionSection
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        courseName={course?.name}
        selectedCourseCode={course.urlCode}
        selectedCourseDegree={DEGREE_TYPE}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
        coursePrice={price}
        className={css`
          margin-bottom: ${px2grid(40)};
        `}
      />
      <div
        className={cx(
          'ampli-benefits-container',
          css`
            margin: ${getSpaceSize('xxx-large')} 0;
          `
        )}
      >
        <AmpliBenefits />
      </div>
      <Footer ref={footerRef} />
      <Modal
        {...subscription}
        selectedCourseCode={course.urlCode}
        selectedCourseDegree={DEGREE_TYPE}
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </div>
  );
};

CourseDetailsGraduate.getInitialProps = async ({ match, client }) => {
  try {
    const { data } = await client.query({
      query: GET_COURSE_BY_URL_CODE,
      variables: {
        urlCode: match.params.urlCode,
      },
      fetchPolicy: 'no-cache',
    });
    return {
      course: data.data,
    };
  } catch (error) {
    console.error(error);
    return { statusCode: 404 };
  }
};

CourseDetailsGraduate.propTypes = {
  course: object,
};

export default CourseDetailsGraduate;
