import React from 'react';

import { cx, css, px2grid, ColorBrandLight, mediaQuery } from '@ampli/ui';
import { node } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import Banner from './banner';
import { MIN_WIDTH_MOBILE } from './vacation-banner';

const BannerWrap = ({ children, ...props }) => {
  const breakpoint = useBreakpoint(true);

  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width > MIN_WIDTH_MOBILE,
    [breakpoint]
  );

  return (
    <div
      className={cx(
        'vacation-banner',
        css`
          background-color: ${ColorBrandLight};
          height: ${px2grid(500)};
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          position: relative;
          margin-bottom: ${px2grid(30)};
          @media (max-width: ${MIN_WIDTH_MOBILE}px) {
            background: url(${require('../../assets/bg-mobile-ferias.webp')});
            background-position: 50% 0%;
            background-repeat: no-repeat;
            background-size: cover;
            align-items: flex-start;
            height: auto;
          }
        `
      )}
      {...props}
    >
      {isDesktop && (
        <Banner
          className={cx(
            'banner-illustration',
            css`
              position: absolute;
              width: 100%;
              height: 100%;

              ${mediaQuery('desktop')} {
                max-width: ${px2grid(1100)};
              }
            `
          )}
        />
      )}

      {children}
    </div>
  );
};

BannerWrap.propTypes = {
  children: node,
};

export default BannerWrap;
