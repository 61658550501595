import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState } from 'react';
import { useDialogState } from '@ampli/ui';

var useSubscriptionModal = function useSubscriptionModal() {
  var _useState = useState({}),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];

  var dialog = useDialogState();

  var show = function show(newState) {
    setState(newState);
    dialog.show();
  };

  return {
    state: state,
    show: show,
    dialog: dialog
  };
};

export default useSubscriptionModal;