import * as React from 'react';

function SvgParachute(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={176}
      height="146pt"
      viewBox="0 0 132 221"
      {...props}
    >
      <path
        d="M80.266 138.277l-1.844-.261 6.14-72.645a.928.928 0 011.008-.84.92.92 0 01.852.992l-4.836 57.227 15.121-72.863a.93.93 0 011.098-.719c.507.102.832.59.73 1.086zm0 0M60.523 147.96L23.965 63.157a.915.915 0 01.492-1.207.933.933 0 011.223.485L57.7 136.71l-5.66-66.945a.93.93 0 01.85-.996.92.92 0 011.008.84zm0 0M84.422 143.258a.924.924 0 01-.898-1.176l26.038-89.484a.937.937 0 011.157-.63c.492.141.781.65.636 1.137L85.316 142.59a.935.935 0 01-.894.668zm0 0"
        fill="#311e69"
      />
      <path
        d="M62.137 146.016a.927.927 0 01-.735-.36L2.344 70.258a.914.914 0 01.164-1.293.94.94 0 011.308.168l59.059 75.394a.921.921 0 01-.164 1.293.953.953 0 01-.574.196zm0 0"
        fill="#311e69"
      />
      <path
        d="M80.969 135.695s12.754.227 14.386-3.11c2.688-5.487-4.93-22.608 1.04-23.155 5.968-.547 13.003 21.191 8.59 29.543-4.415 8.343-20.958 12.91-20.958 12.91zm0 0"
        fill="#ff9791"
      />
      <path
        d="M84.738 148.898c.164-.847 1.965-1.433 2.227-2.148 2.12-5.852 6.152-1.047 7.797-2.828 1.64-1.785 2.765-3.356 4.5-5.203 1.73-1.848 4.968-4.473 4.504-8.09-.465-3.625-3.567-5.973-3.246-8.106.316-2.132.335-3.55-1.59-6.78-1.926-3.231-2.29-4.825-2.012-5.79a.919.919 0 01.32-.46c5.758 1.39 11.957 21.519 7.75 29.476-4.414 8.347-20.96 12.91-20.96 12.91zm0 0"
        fill="#d95f76"
      />
      <path
        d="M120.098 203.477c.574-1.235-1.114-5.836 4.332-5.278.863.09-.106 2.465.699 3.36.348.386 1.21.578 2.402.859 1.914.602 4.285 1.398 4.461 1.98.293.973-10.097 2.649-11.988 1.973-.984-.355-.535-1.539.094-2.894zm0 0"
        fill="#1e1240"
      />
      <path
        d="M83.254 161.508s30.465-3.992 36.84 2.605c6.035 6.246 10.472 36.703 1.8 37.832-7.476.97-10.69-24.93-14-26.77-3.925-2.183-31.515 11.227-35.058-7.386-1.95-10.242 10.418-6.281 10.418-6.281zm0 0"
        fill="#ff9791"
      />
      <path
        d="M72.836 167.79c-.215-1.134-.254-2.095-.156-2.903 1.375-1.485 2.363-2.477 2.363-2.477s9.07 4.828 13.914 5.453c4.844.621 15.781-2.09 21.012-.226 5.23 1.86 7.672 8.414 8.441 11.605.766 3.196 5.793 9.067 8.305 8.926.254 7.21-1.035 13.289-4.82 13.777-7.477.973-10.692-24.93-14-26.77-3.926-2.183-31.516 11.227-35.06-7.386zm0 0"
        fill="#d95f76"
      />
      <path
        d="M83.262 147.824l3.199-6.59s4.437 3.938 5.535 7.711c-4.324 1.926-7.969 2.934-7.969 2.934zm0 0M107.293 174.988c-1.77-.304-5.574.672-10.047 1.563l-4.281-7.406s12.316-.247 14.328 5.843zm0 0"
        fill="#311e69"
      />
      <path
        d="M1.395 67.54c.574 3.413 5.05 2.315 9.703 4.003 23.375 8.484 81.226-1.375 92.93-17 1.464-1.953 8.417-3.027 8.058-5.172C108.266 26.668 90.684-6.16 47.07.996-1.602 8.988-2.426 44.832 1.395 67.54zm0 0"
        fill="#614cd4"
      />
      <path
        d="M47.07 1C9.621 7.148 10.336 42.738 14.156 65.445c.574 3.414 4.004 2.485 7.68 4.336 8.766 4.414 23.406 4.996 37.277 2.781-18.023 2.899-36.91 3.012-48.015-1.019-4.653-1.688-9.13-.59-9.703-4.004C-2.425 44.832-1.602 8.99 47.07 1zm0 0"
        fill="#7e71ff"
      />
      <path
        d="M112.086 49.375c.36 2.145-6.594 3.215-8.059 5.172-6.054 8.082-24.465 14.625-44.062 17.879 15-2.531 28.93-8.336 33.305-16.114 1.058-1.886 6.414-2.695 6.054-4.843C95.504 28.766 80.63-4.504 47.07 1.004c43.614-7.16 61.196 25.664 65.016 48.371zm0 0"
        fill="#7e71ff"
      />
      <path
        d="M36.2 61.828c.573 3.414 2.198 2.781 4.179 4.91 9.941 10.692 31.375 6.809 34.305-7.375.367-1.773 2.957-2.133 2.593-4.277C73.457 32.379 63.257-1.656 47.07 1 29.012 3.965 32.38 39.121 36.2 61.828zm0 0"
        fill="#7e71ff"
      />
      <path
        d="M59.598 75.777c30.566-5.007 54.062-16.82 52.484-26.386-1.578-9.563-27.64-13.258-58.207-8.254-30.57 5.008-54.066 16.82-52.488 26.386 1.582 9.563 27.64 13.262 58.21 8.254zm0 0"
        fill="#311e69"
      />
      <path
        d="M90.195 215.676c.985-.95 1.07-5.844 5.957-3.403.778.387-.988 2.266-.562 3.383.183.489.922.973 1.933 1.653 1.57 1.242 3.497 2.82 3.45 3.425-.078 1.008-10.391-1.09-11.907-2.39-.796-.68.051-1.625 1.13-2.668zm0 0"
        fill="#1e1240"
      />
      <path
        d="M64.805 132.934c6.629-1.961 23.73 2.394 22.242 12.824-.39 2.726-2.746 5.722-3.48 8.851-.446 1.907 1.617 5.7 2.003 7.836.485 2.645-1.504 1.563-5.652 2.102 8.867 2.476 22.965 7.48 25.566 14.836 2.883 8.152-4.89 37.828-13.316 35.515-7.266-1.992-.344-28.09-2.66-31.062-2.746-3.524-29.903 4.578-30.707-11.457 0-.008-9.7-34.79 6.004-39.445zm0 0"
        fill="#ff9791"
      />
      <path
        d="M72.879 127.594l-1.57 6.488c.074.918 3.8 2.492 5.156 1.207.398-.379-.23-5.969-.23-5.969zm0 0"
        fill="#ff6161"
      />
      <path
        d="M76.41 131.078c.09.992.176 2.2.184 3.07-.653-.218-3.114-1.152-2.973-2.746.11-1.238 1.738-.761 2.79-.324zm0 0"
        fill="#311e69"
      />
      <path
        d="M74.082 125.133l-1.113 3.664a2.33 2.33 0 001.746 2.758c.992.238 1.633.226 2.89.047 1.047-.153 1.563-1.165 1.528-2.524-.043-1.766-.594-3.383-.934-4.23-.785-1.934-3.215-2.051-4.117.285zm0 0"
        fill="#ff6161"
      />
      <path
        d="M77.785 127.305s.95-.133 1.242-.075c.293.063.477 2.672.157 3.856-.266.965-.891 1.644-1.739 1.789-.847.145-2.785-.258-3.304-.797-.895-.922-1.114-2.129-.977-2.91.14-.781.547-1.5.613-1.797.067-.3.23-.535.606-.52.375.016.637.88 1.277.743.64-.137.746-.403 2.125-.29zm0 0"
        fill="#d3d3d3"
      />
      <path
        d="M76.547 127.715s.875.191 2.098.012c0 0 .054.793-.426 1.078-.477.285-1.36-.153-1.672-1.09zm0 0"
        fill="#fff"
      />
      <path
        d="M73.164 129.168c.137-.781.547-1.5.613-1.797.067-.3.227-.535.602-.52a.392.392 0 01.223.106c.168.477.07 1.273.12 1.86.063.714.77 2.054 1.41 2.113.641.058 1.235.293 1.9.023.472-.191 1.015-.598 1.296-.82-.027.36-.074.691-.144.953-.266.965-.891 1.644-1.739 1.789-.847.145-2.785-.258-3.304-.797-.895-.918-1.118-2.129-.977-2.91zm0 0"
        fill="#b5b5b5"
      />
      <path
        d="M74.172 126.938a3.596 3.596 0 01-.082-.704c-.016-.453-.137-1.117-.805-.77-.972.5-.773 2.895.36 3.052.835.113.668-1 .527-1.578zm0 0"
        fill="#ff6161"
      />
      <path
        d="M79.133 124.672a.585.585 0 01-.524.766l-3.054.218c-1.446.399-1.11 2.5-.961 3.055.144.555-.399 1.703-1.793.496-1.39-1.207-1.735-3.242-1.16-4.937.793-2.332 4.683-2.758 6.011-1.81.813.587 1.258 1.552 1.48 2.212zm0 0"
        fill="#311e69"
      />
      <path
        d="M58.8 172.375c0-.004-8.816-31.617 3.97-38.598-.977 3.856-2.208 9.344-1.926 11.567.453 3.586.097 17.59 1.246 22.64 1.152 5.047 11.289 10.075 18.289 9.953 6.996-.12 14.219-3.921 16.933 1.247 2.72 5.168.852 22.539.864 27.43.004 1.445.277 2.777.66 3.937-2.027 3.156-4.316 4.988-6.664 4.347-7.266-1.996-.344-28.093-2.66-31.062-2.75-3.524-29.907 4.578-30.711-11.461zm0 0"
        fill="#d95f76"
      />
      <path
        d="M55.844 152.012c-.047-4.27.406-8.512 1.765-11.953l4.145-1.965c-.004 0 2.61 11.734-5.91 13.918zm0 0"
        fill="#311e69"
      />
      <path
        d="M64.805 132.934s-22.41 1.074-23.817-2.243c-.687-1.617-1.082-21.101-5.113-19.843-4.027 1.254-8.52 22.707-.95 29.144 7.571 6.438 19.516 9.36 24.247 6.778 4.73-2.59 5.633-13.836 5.633-13.836zm0 0"
        fill="#ff9791"
      />
      <path
        d="M35.879 110.848c.082-.028.164-.04.242-.051.059 2.004.164 7.96-.469 10.805-.765 3.445-3.086 9.523-.347 12.554 2.738 3.028-.586 7.031 9.168 6.79 9.757-.247 11.886-1.008 15.027.156 1.45.535 1.598 2.394 1.344 4.242-.496.594-1.051 1.086-1.672 1.426-4.73 2.582-16.672-.34-24.242-6.778-7.575-6.437-3.082-27.89.949-29.144zm0 0"
        fill="#d95f76"
      />
      <path
        d="M37.402 113.152s.957-2.511-.27-3.539c-1.226-1.027-2.573-.898-2.573-.898s-.735-.985-1.27-.434c-.535.551-1.117.332-.805 2.012.313 1.68 2.278 2.3 2.63 2.809.343.507 1.632 1.039 2.288.05zm0 0M97.035 110.973s.977-1.754.403-3.668c-.606-2.016-1.778-2.825-2.243-2.22-.465.599-1.898 2.478-1.898 2.478s-.899.488-.68 1.535c.219 1.043 2.426 3.515 3.113 3.27.684-.247 1.368-.794 1.305-1.395zm0 0"
        fill="#ff6161"
      />
      <path
        d="M56.445 160.258c3.903.57 12.395 1.531 16.957-.14 3.758-1.376 7.614-3.134 10.141-4.345.117.926.48 2.028.875 3.122-2.574 1.222-6.242 2.87-9.898 4.21-.329.122-.668.227-1.024.325-4.96 1.37-12.387.683-16.57.117a111.98 111.98 0 01-.48-3.29zm0 0"
        fill="#311e69"
      />
      <path
        d="M84.023 165.773l-19.07-1.953 7.973-2.203 9.758 2.867zm0 0"
        fill="#311e69"
      />
      <path
        d="M80.336 159.574l1.95 5.547 1.737.652.395-6.882zm0 0M68.371 132.48s.52 18.715-12.504 20.614c0 0-.027.48.098 2.152 0 0 6.273.445 10.422-5.207 4.148-5.656 3.894-17.496 3.894-17.496s-1.023-.32-1.91-.063zm0 0M82.652 135.715s1.496 15.898 1.399 17.351c-.094 1.45-.543 2.106-.543 2.106s-3.793-15.047-3.848-20.156c-.004 0 2.633.117 2.992.699zm0 0M35.227 111.707a.942.942 0 01-.743-.36l-2.996-3.882a.91.91 0 01.176-1.29.936.936 0 011.305.173l2.996 3.879a.915.915 0 01-.176 1.293.92.92 0 01-.562.187zm0 0M94.43 108.852a.802.802 0 01-.254-.04.915.915 0 01-.64-1.136l1.042-3.594a.938.938 0 011.156-.629c.493.14.782.649.637 1.137l-1.043 3.594a.936.936 0 01-.898.668zm0 0"
        fill="#311e69"
      />
    </svg>
  );
}

export default SvgParachute;
