import React, { useState } from 'react';
import { cx, css, px2grid, Flex, Text, TextButton } from '@ampli/ui';
import { string, bool, func } from '@ampli/utils';

import { IconSelector } from '../icon-selector';

const LinkItem = ({
  icon,
  text,
  selected = false,
  onClick,
  className,
  ...props
}) => {
  const color = selected ? 'black' : 'neutral-dark';

  return (
    <Flex alignItems="center" justifyContent="center" direction="column">
      <TextButton
        {...props}
        onClick={onClick}
        className={cx(
          'link-item',
          css`
            margin: 0 ${px2grid(12)};

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          `,
          className
        )}
      >
        <IconSelector
          className={css`
            width: ${px2grid(24)};
            height: ${px2grid(24)};
            margin-bottom: ${px2grid(8)};
          `}
          icon={icon}
          color={color}
        />
        <Text size="x-small" color={color}>
          {text}
        </Text>
      </TextButton>
    </Flex>
  );
};

LinkItem.propTypes = {
  icon: string.isRequired,
  text: string.isRequired,
  selected: bool,
  onClick: func,
  className: string,
};

LinkItem.displayName = 'LinkItem';

const CourseLinks = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const links = [
    { icon: 'BookOpen', text: 'Sobre o curso' },
    { icon: 'Calculator', text: 'Comparativo' },
    { icon: 'PersonDocument', text: 'Mercado de trabalho' },
    { icon: 'SpeechBalloon', text: 'Dúvidas' },
  ];

  return (
    <Flex
      alignItems="flex-start"
      justifyContent="space-between"
      direction="row"
    >
      <div
        {...props}
        className={css`
          padding: ${px2grid(40)} 0;
          text-align: center;
        `}
      >
        {links.map((linkProps, index) => (
          <LinkItem
            {...linkProps}
            key={index}
            onClick={() => setSelectedIndex(index)}
            selected={selectedIndex === index}
          />
        ))}
      </div>
    </Flex>
  );
};

CourseLinks.displayName = 'CourseLinks';

export default CourseLinks;
