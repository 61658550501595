import React from 'react';

import { Tooltip, TooltipReference, useTooltipState } from '@ampli/ui';
import { DegreeCard } from '../degree-card';
import { string, node } from '@ampli/utils';

const CustomTooltip = ({ children, degree, ...props }) => {
  const tooltip = useTooltipState();
  return (
    <>
      <TooltipReference {...tooltip} ref={children.ref} {...children.props}>
        {(referenceProps) => React.cloneElement(children, referenceProps)}
      </TooltipReference>
      <Tooltip {...tooltip} {...props}>
        <DegreeCard degree={degree} />
      </Tooltip>
    </>
  );
};

CustomTooltip.propTypes = {
  degree: string,
  children: node,
};

export default CustomTooltip;
