import React from 'react';

import { cx, css, px2grid, getSpaceSize, Text, mediaQuery } from '@ampli/ui';
import { useBreakpoint } from '@ampli/hooks';
import { number } from '@ampli/utils';
import CountStudentsIllustration from './count-student-illustration';
import { MIN_WIDTH_DESKTOP } from './count-students';

const CountStudentsTitleDesktop = ({ count }) => (
  <Text
    as="h2"
    className={cx(
      'count-students-title-wrap',
      css`
        display: flex;
        flex-direction: column;
        width: fit-content;
      `
    )}
  >
    <Text size="larger" color="brand-base">
      <strong>{'Atualmente a Ampli conta com '}</strong>
    </Text>
    <div
      className={css`
        display: flex;
      `}
    >
      <Text
        size={px2grid(72)}
        className={css`
          margin-right: ${getSpaceSize('small')};
          font-weight: 100;
        `}
        color="brand-base"
      >
        {'+ de '}
      </Text>
      <Text
        size={px2grid(150)}
        color="semantic-alert"
        className={css`
          font-weight: 200;
        `}
      >{`${count || 1000} alunos`}</Text>
    </div>
    <Text
      className={css`
        align-self: flex-end;
      `}
      size="larger"
      color="brand-base"
    >
      <strong>{' espalhados por todo Brasil.'}</strong>
    </Text>
  </Text>
);

const CountStudentsTitleMobile = ({ count }) => (
  <Text
    as="h2"
    className={css`
      ${mediaQuery('mobile')} {
        width: 100%;
        max-width: ${px2grid(320)};
      }
    `}
  >
    <Text size="small" color="brand-base">
      <strong>{'Atualmente a ampli conta com mais de '}</strong>
    </Text>
    <div
      className={cx(
        'count-students-title-wrap',
        css`
          display: flex;
          margin-top: ${getSpaceSize('x-small')};
        `
      )}
    >
      <div
        className={cx(
          'count-students-title-content',
          css`
            display: flex;
            flex-direction: column;
            max-width: ${px2grid(130)};
            margin-left: ${getSpaceSize('xx-small')};
            margin-right: ${px2grid(30)};
          `
        )}
      >
        <Text
          size={px2grid(48)}
          className={css`
            font-weight: 200;
            margin-bottom: ${getSpaceSize('x-small')};
          `}
          color="semantic-alert"
        >{`${count || 1000} alunos`}</Text>
        <Text size="small" color="brand-base">
          <strong>{' espalhados por todo Brasil.'}</strong>
        </Text>
      </div>
      <CountStudentsIllustration
        className={cx(
          'count-students-illustration',
          css`
            width: ${px2grid(150)};
            height: ${px2grid(150)};
          `
        )}
      />
    </div>
  </Text>
);

const CountStudentsTitle = (props) => {
  const breakpoint = useBreakpoint(true);
  const isDesktop = React.useMemo(
    () =>
      breakpoint?.isDesktop &&
      breakpoint?.currentSize?.width >= MIN_WIDTH_DESKTOP,
    [breakpoint]
  );

  if (isDesktop) return <CountStudentsTitleDesktop {...props} />;

  return <CountStudentsTitleMobile {...props} />;
};

CountStudentsTitle.propTypes = {
  count: number,
};

export default CountStudentsTitle;
