export const infoItems = {
  GRADUATE: [
    {
      label: 'Forme mais rápido',
      contentText:
        'Aqui você emenda um módulo no outro e utiliza seu período de férias para adiantar disciplinas.',
      icon: 'Clock',
      hasStroke: true,
    },
    {
      label: 'Novas turmas toda segunda-feira',
      contentText:
        'Assim que a matrícula é efetivada, as aulas ficam liberadas para você na segunda-feira seguinte. Aqui, você não precisa aguardar um semestre para começar a estudar.',
      icon: 'CalendarSimple',
    },
    {
      label: 'Diploma igual ao do presencial',
      contentText:
        'Somos nota máxima no MEC! Nós temos conceito 5 no “CI-EaD” que é o Conceito Institucional referente ao ensino a distância.',
      icon: 'CertificateHorizontal',
      hasPathColor: true,
    },
  ],
  UNDERGRADUATE: [
    {
      label: 'Forme mais rápido',
      contentText:
        'Aqui você emenda um módulo no outro e utiliza seu período de férias para adiantar disciplinas. Assim, você consegue se formar a partir de 1 ano e meio, dependendo do curso escolhido.',
      icon: 'Clock',
      hasStroke: true,
    },
    {
      label: 'Novas turmas toda segunda-feira',
      contentText:
        'Assim que a matrícula é efetivada, as aulas ficam liberadas para você na segunda-feira seguinte. Aqui, você não precisa aguardar um semestre para começar a estudar.',
      icon: 'CalendarSimple',
    },
    {
      label: 'O vestibular é online e gratuito',
      contentText:
        'O nosso vestibular é uma redação de 100-250 palavras. Você também pode utilizar a sua nota do ENEM para ingressar. O primeiro mês de aula é por nossa conta!',
      icon: 'SmartPhone',
    },
    {
      label: 'Pague menos',
      contentText:
        'A nossa mensalidade é a mesma para todos os cursos, em todas as cidades. São mensalidades fixas de R$149,99, reajustadas anualmente apenas pela inflação.',
      icon: 'Money',
    },
    {
      label: 'Diploma igual ao do presencial',
      contentText:
        'Somos nota máxima no MEC! Nós temos conceito 5 no “CI-EaD” que é o Conceito Institucional referente ao ensino a distância.',
      icon: 'CertificateHorizontal',
      hasPathColor: true,
    },
  ],
};

export const defaultInfoProps = {
  UNDERGRADUATE: {
    title: 'Graduação',
    subTitle:
      'Ensino superior de qualidade, em um modelo moderno e tecnológico',
    priceLabel: 'Valor fixo para todos os cursos de Graduação:',
    price: '149,99',
  },
  GRADUATE: {
    title: 'Pós-Graduação',
    subTitle:
      'Cursos de Especialização e MBA com foco em crescimento profissional',
    priceLabel: 'Valor fixo para todos os cursos a partir de:',
    price: '210,00',
  },
};
