import React from 'react';

import {
  cx,
  css,
  Text,
  px2grid,
  getSpaceSize,
  ColorNeutralLighter,
  ColorNeutralDark,
  ColorBrandLight,
  ColorNeutralTitle,
  marginSize,
} from '@ampli/ui';
import { func, string, arrayOf, shape, oneOfType, number } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

const GroupButtons = ({ label, items, value, onChange, extraInfo, name }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  return (
    <div
      className={cx(
        'group-buttons-wrap',
        css`
          display: flex;
          flex-direction: column;
        `
      )}
    >
      <Text color={ColorNeutralTitle} size={px2grid(16)}>
        {label}

        {extraInfo && !isDesktop && (
          <Text
            className={cx('extra-info-text', marginSize('x-small', 'left'))}
            color={ColorNeutralDark}
            size="x-small"
          >
            {extraInfo}
          </Text>
        )}
      </Text>

      {extraInfo && isDesktop && (
        <Text
          className={cx('extra-info-text', marginSize('x-small', 'top'))}
          color={ColorNeutralDark}
          size="x-small"
        >
          {extraInfo}
        </Text>
      )}

      <div
        className={cx(
          'group-buttons-content',
          css`
            display: flex;
            flex-wrap: wrap;
          `
        )}
      >
        {!!items?.length &&
          items?.map((item, index) => (
            <button
              onClick={() =>
                onChange &&
                onChange(name, value === item?.value ? null : item?.value)
              }
              key={`group_button_item_${index}`}
              className={cx(
                'group-button-item',
                css`
                  background-color: transparent;
                  border: 1px solid ${ColorNeutralLighter};
                  border-radius: ${px2grid(8)};
                  padding: ${getSpaceSize('xx-small')};
                  outline: none;
                  margin-right: ${getSpaceSize('xx-small')};
                  margin-top: ${getSpaceSize('small')};

                  &.selected {
                    border: 1px solid transparent;
                    background-color: ${ColorBrandLight};
                    color: white;
                  }
                `,
                value === item?.value && 'selected'
              )}
            >
              <Text color={value === item?.value ? 'white' : ColorNeutralDark}>
                {item?.label}
              </Text>
            </button>
          ))}
      </div>
    </div>
  );
};

GroupButtons.propTypes = {
  onChange: func,
  value: oneOfType([number, string]),
  label: string,
  extraInfo: string,
  items: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string]),
    })
  ),
  name: string,
};

export default GroupButtons;
