import {
  COURSE_DURATION_VALUES,
  COURSE_DEGREE_DURATION_TYPES,
  COURSE_FILTERS_TO_URL_PARAMS,
} from '../constants';
import { parseDurationFromISO8601Format, invert } from '@ampli/utils';

const normalizeValue = (value) =>
  value
    ? value
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : '';

const normalizeArrayString = (array) =>
  Array.isArray(array) && array?.length
    ? array.map((item) => normalizeValue(item))
    : [];

export const translateParamNames = (params = {}) => {
  const paramNameDictionary = invert(COURSE_FILTERS_TO_URL_PARAMS);
  let translatedParams = {};

  for (const param in params) {
    const translatedParamName = paramNameDictionary[param] || param;
    translatedParams[translatedParamName] = params[param];
  }

  return translatedParams;
};

export const onFilterCourse = (
  courses = [],
  filterValues = {},
  degreeType,
  paginate = false
) => {
  if (!courses.length) return [];

  let resultCourses = [...courses];
  if (filterValues?.fieldOfStudy?.length) {
    resultCourses = resultCourses.filter((course) =>
      normalizeArrayString(
        course.fieldOfStudies?.map((item) => item.code)
      ).find((fieldOfStudy) =>
        normalizeArrayString(filterValues?.fieldOfStudy).includes(fieldOfStudy)
      )
    );
  }

  if (filterValues?.courseType?.length) {
    resultCourses = resultCourses.filter((course) =>
      filterValues?.courseType.includes(course.courseType?.code)
    );
  }

  if (filterValues?.courseDuration) {
    const degreeDurationType = COURSE_DEGREE_DURATION_TYPES[degreeType];
    resultCourses = resultCourses.filter(
      (course) =>
        COURSE_DURATION_VALUES[filterValues?.courseDuration] >=
        parseDurationFromISO8601Format(course.duration, degreeDurationType)
    );
  }

  resultCourses = resultCourses.filter((item) =>
    normalizeValue(item?.name).includes(
      normalizeValue(filterValues?.name || '')
    )
  );

  const countPages = Math.ceil(resultCourses?.length / 16);
  if (paginate && filterValues?.page > countPages) return [];

  return resultCourses;
};
