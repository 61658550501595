import React from 'react';

import {
  cx,
  css,
  px2grid,
  getSpaceSize,
  fontSize,
  Loader,
  Icon,
  ColorNeutralDark,
  mediaQuery,
} from '@ampli/ui';
import { object, arrayOf, bool, string, oneOf } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import SimpleCourseCard from '../course-card/simple-course-card';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

const ScrollButton = ({ direction, ...props }) => (
  <div
    className={cx(
      'scroll-button-mobile',
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${getSpaceSize('xx-small')};
        cursor: pointer;
      `
    )}
    {...props}
  >
    <Icon.ChevronRight
      className={cx(
        'scroll-button-icon',
        css`
          &.left {
            transform: rotate(180deg);
          }
        `,
        direction === 'left' && 'left'
      )}
    />
  </div>
);

ScrollButton.propTypes = {
  direction: string,
};

const CourseSuggestions = ({ items, degreeType, loading }) => {
  const wrapRef = React.useRef(null);
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  const handleScroll = (direction = 'right') => () => {
    if (wrapRef?.current) {
      if (direction === 'left') {
        wrapRef.current.scrollLeft -= 200;
        return;
      }
      wrapRef.current.scrollLeft += 200;
    }
  };

  return (
    <div>
      <h2
        className={cx(
          'suggestions-title',
          css`
            padding-bottom: ${getSpaceSize('small')};
            margin-left: ${getSpaceSize('small')};
            margin-bottom: ${getSpaceSize('x-large')};
            width: fit-content;
            border-bottom: 1px solid ${ColorNeutralDark};
            color: ${ColorNeutralDark};
            font-weight: normal;
            ${mediaQuery('desktop')} {
              margin-left: 0;
            }
          `,
          fontSize('x-large')
        )}
      >
        {'Cursos mais populares:'}
      </h2>
      <div
        className={cx(
          'suggestions-list-wrap',
          css`
            display: flex;
            &.loading {
              justify-content: center;
              align-items: center;
              margin-bottom: ${getSpaceSize('small')};
            }
          `,
          loading && 'loading'
        )}
      >
        {!loading && !isDesktop && (
          <ScrollButton onClick={handleScroll('left')} direction="left" />
        )}
        <div
          ref={wrapRef}
          className={cx(
            'suggestions-list-content',
            css`
              display: flex;
              flex-direction: row;
              padding-left: ${px2grid(6)};
              overflow: auto;
              scroll-behavior: smooth;
            `
          )}
        >
          {!loading &&
            !!items?.length &&
            items.map((item, index) => (
              <div key={`suggestion-course_${index}`}>
                <SimpleCourseCard
                  degreeType={degreeType}
                  name={item.name}
                  urlCode={item.urlCode}
                  courseType={item.courseType}
                  image={item.image}
                />
                <div
                  className={cx(
                    'suggestion-card-space',
                    css`
                      margin-right: ${px2grid(8)};
                    `
                  )}
                />
              </div>
            ))}

          {loading && (
            <div>
              <Loader />
            </div>
          )}
        </div>
        {!loading && !isDesktop && (
          <ScrollButton onClick={handleScroll('right')} direction="right" />
        )}
      </div>
    </div>
  );
};

CourseSuggestions.propTypes = {
  items: arrayOf(object),
  degreeType: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)),
  loading: bool,
};

export default CourseSuggestions;
