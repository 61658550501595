import React from 'react';
import { cx } from '@ampli/ui';
import { string } from '@ampli/utils';

import DegreeCard from './degree-card';
import styles from './styles';

const CardWrapper = ({ className, ...props }) => (
  <div className={cx('card-wrapper', styles.cardWrapper, className)} {...props}>
    <DegreeCard
      illustration="Parachute"
      title="Supletivo"
      link="https://ofertas.ampli.com.br/eja?utm_source=Site&utm_medium=Home&utm_campaign=Home&utm_content=367b96ed-fb37-461e-a83b-511f293d44c4&sellerId=367b96ed-fb37-461e-a83b-511f293d44c4"
      openLinkInNewTab
    >
      Educação para Jovens e Adultos, do ensino fundamental ao ensino médio.
    </DegreeCard>
    <DegreeCard illustration="RunningMan" title="Graduação" link="/graduacao">
      Cursos bacharelados, licenciaturas e tecnólogos a distância.
    </DegreeCard>
    <DegreeCard
      illustration="RunningLady"
      title="Pós"
      subtitle="Graduação"
      link="/pos-graduacao"
    >
      Cursos de especialização e MBA online.
    </DegreeCard>
  </div>
);

CardWrapper.propTypes = {
  className: string,
};

export default CardWrapper;
