import React from 'react';
import { string } from '@ampli/utils';

const InverseSeparator = ({ color, ...props }) => (
  <svg
    width="83"
    height="22"
    viewBox="0 0 83 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M81.957 0.953676C69.8839 14.7584 38.0012 33.6326 0.814824 8.70831"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
      strokeDasharray="3 10"
    />
  </svg>
);

InverseSeparator.propTypes = {
  color: string,
};

export default InverseSeparator;
