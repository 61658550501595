import { useState, useMemo } from 'react';
import { parseDurationFromISO8601Format } from '@ampli/utils';
import { useGetDefaultCoursePriceByCourseId } from '@ampli/services';

function parseCourseDescriptions(courseDescriptions) {
  return courseDescriptions
    ? courseDescriptions.reduce(
        (descriptions, item) => (
          (descriptions[item.typeCode] = item.description), descriptions
        ),
        {}
      )
    : {};
}

function parseTestimonials(courseName, testimonials) {
  return testimonials
    ? testimonials.map((testimonial) => ({
        imgUrl: testimonial.profileImage,
        name: testimonial.name,
        course: `Estudante do curso de ${courseName}`,
        testimonial: testimonial.description,
      }))
    : [];
}

function generateCourseTags(course) {
  const fieldOfStudies = course?.fieldOfStudies?.length
    ? course.fieldOfStudies.map((field) => {
        return { name: field.name, link: `area=${field.code}` };
      })
    : [];
  const courseType = course?.courseType?.name
    ? [
        {
          name: course.courseType.name,
          link: `tipo=${course.courseType.code}`,
        },
      ]
    : [];

  return [...fieldOfStudies, ...courseType];
}

function parseCourseSubjectsBySemester(subjects, courseDuration) {
  const SEMESTER_LENGTH = 6;
  const courseDurationInSemesters = Math.ceil(
    parseDurationFromISO8601Format(courseDuration, 'months') / SEMESTER_LENGTH
  );

  function calculateSemestersLength(subjects, courseDuration) {
    if (subjects && courseDuration) {
      const baseAmmountBySemester = Math.floor(
        subjects.length / courseDuration
      );
      let divisionLeftover = subjects.length % courseDuration;
      let lengthBySemester = {};
      for (
        let semesterCount = 0;
        semesterCount < courseDuration;
        semesterCount++
      ) {
        lengthBySemester[semesterCount] = divisionLeftover
          ? baseAmmountBySemester + 1
          : baseAmmountBySemester;
        if (divisionLeftover) divisionLeftover--;
      }
      return lengthBySemester;
    } else return null;
  }

  function divideSubjectsBySemester(subjects, semesterDurationList) {
    let subjectsBackup = [...subjects];
    let semesters = [];
    for (const semesterLength in semesterDurationList) {
      semesters.push(
        subjectsBackup.splice(0, semesterDurationList[semesterLength])
      );
    }
    return semesters;
  }

  if (subjects?.length) {
    const semestersLength = calculateSemestersLength(
      subjects,
      courseDurationInSemesters
    );
    return divideSubjectsBySemester(subjects, semestersLength);
  } else return [];
}

function generateSubjectListTabs(semesters) {
  return semesters?.length
    ? semesters.map((semester, index) => ({ label: `${index + 1} Semestre` }))
    : [];
}

function parseClassesBySemester(semesters, labels) {
  function parseSubject(subject) {
    const { name, learningUnits } = subject.subject;
    return {
      label: name,
      units: learningUnits?.map((unit, index) => parseUnit(unit, index)) || [],
    };
  }

  function parseUnit(learningUnit, index) {
    const title = learningUnit.learningUnit.title;
    const sections =
      (learningUnit.learningUnit.currentLearningUnitVersion || {}).sections ||
      [];
    return {
      title: `Unidade ${index + 1} - ${title}`,
      classes: sections.map((section, index) => parseSection(index)),
    };
  }

  function parseSection(index) {
    return {
      title: `Aula ${index + 1}${!index ? ' (veja de graça)' : ''}`,
      disabled: index > 0,
    };
  }

  function generateClassBySemesterMap() {
    return semesters?.length && labels?.length
      ? semesters.reduce(
          (classesBySemester, semester, index) => (
            (classesBySemester[labels[index].label] = semester.map((subject) =>
              parseSubject(subject)
            )),
            classesBySemester
          ),
          {}
        )
      : {};
  }

  return generateClassBySemesterMap();
}

export function useCourseInformation(course) {
  const [duration, setDuration] = useState(null);
  const [descriptions, setDescriptions] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [tags, setTags] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [classesBySemester, setClassesBySemester] = useState({});

  useMemo(() => {
    setDuration(parseDurationFromISO8601Format(course?.duration, 'months'));
    setDescriptions(parseCourseDescriptions(course?.descriptions));
    setTestimonials(parseTestimonials(course?.name, course?.testimonials));
    setTags(generateCourseTags(course));
    setSubjects(
      parseCourseSubjectsBySemester(course?.subjects, course?.duration)
    );
  }, [course]);

  useMemo(() => {
    setTabs(generateSubjectListTabs(subjects));
  }, [subjects]);

  useMemo(() => {
    setClassesBySemester(parseClassesBySemester(subjects, tabs));
  }, [subjects, tabs]);

  const {
    defaultCoursePrice: { firstInstalmentPrice: price } = {},
  } = useGetDefaultCoursePriceByCourseId(course?.id);

  return {
    duration,
    descriptions,
    testimonials,
    tags,
    tabs,
    classesBySemester,
    price,
  };
}
