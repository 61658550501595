import * as React from "react";

function SvgSubscribeAtHome(props) {
  return (
    <svg
      width={136}
      height={114}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.447 64.83H1.904v-3.48l8.28-8.825c1.136-1.242 1.974-2.326 2.513-3.252.551-.925.826-1.804.826-2.636 0-1.137-.287-2.028-.86-2.672-.575-.656-1.395-.985-2.462-.985-1.148 0-2.056.399-2.724 1.196-.657.785-.985 1.822-.985 3.111H1.395c0-1.559.369-2.982 1.107-4.271.75-1.29 1.805-2.297 3.164-3.024 1.36-.738 2.9-1.107 4.623-1.107 2.637 0 4.682.633 6.135 1.898 1.465 1.266 2.197 3.053 2.197 5.361 0 1.266-.328 2.555-.984 3.868-.657 1.312-1.782 2.841-3.375 4.588l-5.819 6.134h11.004v4.096z"
        fill="#009A98"
      />
      <path
        d="M23.021 108.705c-.826 4.943 22.312 5.392 39.415 5.027 17.103-.364 49.454-.22 52.182-5.027 2.728-4.808-26.504-6.216-45.798-5.704-19.294.512-45.019 1.041-45.798 5.704z"
        fill="#E1E8F5"
      />
      <path
        d="M96.46 57.995l-44.036 50.207h-3.24a.383.383 0 01-.29-.636l43.547-49.57h4.019v-.001z"
        fill="#009A98"
      />
      <path
        d="M48.798 57.995l44.036 50.207h3.24c.33 0 .508-.389.29-.636l-43.547-49.57h-4.02v-.001z"
        fill="#009A98"
      />
      <path
        d="M43.13 54.44h58.999a1.78 1.78 0 011.781 1.777 1.78 1.78 0 01-1.781 1.778h-59a1.777 1.777 0 110-3.554z"
        fill="#5787CE"
      />
      <path
        d="M59.173 49.77h26.914a.794.794 0 00.794-.792V32.13a.794.794 0 00-.794-.793H59.173a.794.794 0 00-.794.793v16.848c0 .437.356.792.794.792z"
        fill="#4A4A4A"
      />
      <path
        d="M85.69 46.888V32.923a.396.396 0 00-.397-.396H59.968a.396.396 0 00-.397.396v13.964c0 .219.178.396.397.396h25.325a.394.394 0 00.397-.395z"
        fill="#F5F7FB"
      />
      <path
        d="M68.997 53.607h7.265l-.794-3.837h-5.876l-.595 3.837z"
        fill="#E9E6E6"
      />
      <path
        d="M67.582 54.423h10.097v-.222a.595.595 0 00-.596-.594h-8.906a.595.595 0 00-.595.594v.222z"
        fill="#EBF1FF"
      />
      <path
        d="M65.961 40.88a3.153 3.153 0 002.612-3.617 3.157 3.157 0 00-3.624-2.606 3.153 3.153 0 00-2.611 3.616 3.157 3.157 0 003.623 2.606z"
        fill="#0096FF"
      />
      <path
        d="M65.45 34.62v3.152h-3.157a3.154 3.154 0 013.158-3.152z"
        fill="#C7F0FF"
      />
      <path
        d="M67.684 40a3.15 3.15 0 01-2.233.924 3.154 3.154 0 01-3.158-3.152h3.158L67.684 40z"
        fill="#009A98"
      />
      <path
        d="M74.694 35.942h-4.282a.407.407 0 110-.814h4.282a.407.407 0 110 .814zM81.077 35.942h-4.282a.407.407 0 110-.814h4.282a.407.407 0 110 .814zM72.793 38.11h-2.38a.407.407 0 110-.814h2.38a.407.407 0 110 .814zM82.538 38.11h-7.646a.407.407 0 110-.814h7.646a.407.407 0 110 .814zM76.755 40.414h-6.343a.407.407 0 110-.814h6.343a.407.407 0 110 .814z"
        fill="#A19F9F"
      />
      <path
        d="M62.492 45.465h20.256c.11 0 .2-.09.2-.199v-2.8c0-.11-.09-.2-.2-.2H62.492c-.11 0-.199.09-.199.2v2.8c0 .11.09.199.199.199z"
        fill="#fff"
      />
      <path
        d="M89.4 98.213s-4.483-5.114-3.327-9.264c1.135-4.076 3.512-5.74 1.932-9.181-1.04-2.265-1.458-5.45.434-6.384 1.885-.932 6.32 2.796 6.32 7.45 0 4.652-3.17 9.696-2.176 11.928.995 2.233 2.13 5.687 3.701 6.978 1.57 1.29-6.884-1.527-6.884-1.527z"
        fill="#F17786"
      />
      <path
        d="M93.16 98.028s-3.351-5.913-1.377-9.744c1.939-3.763 4.604-4.912 3.755-8.6-.558-2.429-.323-5.632 1.72-6.165 2.035-.53 5.622 4.017 4.678 8.573-.945 4.557-5.075 8.854-4.552 11.242.522 2.387.933 5.999 2.209 7.581 1.275 1.581-6.433-2.887-6.433-2.887z"
        fill="#FAD2D7"
      />
      <path
        d="M90.716 98.427a.238.238 0 01-.191-.094c-1.057-1.392-1.638-4.025-1.553-7.044.085-3.02.826-5.97 1.983-7.89 2.156-3.574-.951-6.18-1.085-6.288a.24.24 0 11.304-.371c.036.03 3.565 2.972 1.192 6.908-2.356 3.907-2.6 11.576-.459 14.396a.24.24 0 01-.191.383z"
        fill="#FFE3E3"
      />
      <path
        d="M89.715 112.876h7.52l3.883-16.232h-14.86l3.457 16.232z"
        fill="#009A98"
      />
      <path
        d="M95.378 98.017a.238.238 0 01-.214-.13c-.797-1.555-.91-4.249-.3-7.207.61-2.96 1.854-5.736 3.327-7.424 2.752-3.153.141-6.252.028-6.382a.24.24 0 01.364-.313c.03.034 2.992 3.546-.03 7.01-3.001 3.438-4.579 10.947-2.962 14.095a.24.24 0 01-.104.323.226.226 0 01-.11.028z"
        fill="#FFE3E3"
      />
      <path
        d="M91.962 96.644s5.29 3.722 3.05 7.033c-2.24 3.311-5.297 9.199-5.297 9.199h7.52l3.881-16.232h-9.154z"
        fill="#009A98"
      />
      <path
        d="M117.939 103.62s-8.895 2.919-8.895 3.41v1.243h10.079l.665-.809.462.809h2.743s.279-.627.222-2.148c-.058-1.521-.982-1.926-.982-1.926l-3.004-1.541-1.29.962zM129.329 99.33s-.976 3.116-1.888 3.431c-.912.315-3.777.535-3.777 1.102v1.154h6.609l3.996-4.901-.314-.945.818.167L136 97.502s-.787-.991-1.762-1.322c-.976-.331-2.057-.158-2.057-.158l-2.852 3.307z"
        fill="#444"
      />
      <path
        d="M115.382 68.467l-.048.206a9.513 9.513 0 00-.217 2.84l2.416 32.241s.708 1.422 2.47 1.254c1.762-.168 2.084-.884 2.084-.884s.35-12.635-.217-17.61c-.566-4.976-.251-9.07.063-10.58.315-1.511 2.266-11.022 2.266-11.022l-4.595-1.07-4.222 4.625z"
        fill="#8E9294"
      />
      <path
        d="M125.828 66.763s-2.472 6.235-3.265 8.225c-.792 1.99-.503 3.463 1.637 7.053s8.371 14.674 8.371 14.674.189 1.092-.86 2.058c-.74.683-2.382.555-2.382.555l-8.234-11.788s-9.503-34.206-9.314-34.52c.189-.315 6.494 1.855 6.494 1.855l7.553 11.888z"
        fill="#8E9294"
      />
      <path
        d="M110.248 53.89s5.936-1.336 5.673 1.603c-.262 2.94-1.048 15.77-1.048 15.77s.514-1.944 6.053-3.077c5.539-1.134 4.77-.882 4.77-.882s2.877-8.281 3.003-10.044c.126-1.763 0-5.133-.787-6.109-.787-.977-7.396-1.26-7.396-1.26l-4.595-.22-5.673 4.219z"
        fill="#8E9294"
      />
      <path
        d="M105.474 45.436s-.691.725-1.368.614c-.493-.08-1.764-.46-2.421-.661a.267.267 0 00-.328.344.904.904 0 00.326.474c.408.284.66.441.283.615-.378.173-1.118.598-1.92.141-.802-.456-.561-.924-.561-.924s-1.47 1.638-.147 4.073c.843 1.555 2.182 1.658 3.525.63 1.343-1.029 1.49-1.449 2.287-1.869a7.568 7.568 0 011.402-.583l1.221-1.557-.776-.945-1.523-.352z"
        fill="#B89397"
      />
      <path
        d="M103.76 50.055c.057-.007-.107 1.297-.107 1.297s-.602.249-1.037.52c-.434.272-.252.797-.998 1.368a2.18 2.18 0 00-.203.177l-1.43-.205a.556.556 0 00-.226-.574c-.174-.127-.562.059-1.05.392a15.7 15.7 0 00-.67.499c-1.168.917-2.555 2.2-3.04 2.3-.619.129.343-1.165 1.567-2.521.154-.172.314-.345.477-.514.23-.245.464-.486.69-.716 1.657-1.652 2.463-1.495 3.207-1.746.744-.252 1.149-.856 1.149-.856s1.614.584 1.671.58z"
        fill="#B89397"
      />
      <path
        d="M101.415 53.416c-.201.206-.27.394-.317.569-.061.241-.079.464-.323.686-.472.434-1.048.434-1.41.203-.237-.151-.808-.262-1.17-.323-.147-.025-.214-.2-.117-.312.162-.188.402-.416.651-.486.425-.118.537.159.853.006l.04-.02a.783.783 0 00.363-.527l1.43.204z"
        fill="#B89397"
      />
      <path
        d="M92.507 21.17s.08.287.354.25c.273-.036.27-.317.27-.317l-.624.068z"
        fill="url(#subscribe-at-home_svg__paint0_linear)"
      />
      <path
        d="M120.274 35.81s-1.66 1.317-4.245 3.737-11.477 12.37-11.477 12.37-1.178-.587-1.821-1.45c-.644-.863-.924-1.561-.924-1.561s1.407-1.016 2.753-2.93c1.346-1.913 8.81-12.417 11.286-14.439 2.475-2.022 5.33 2.467 5.33 2.467l-.902 1.806z"
        fill="#B89397"
      />
      <path
        d="M113.352 27.697s-.985 1.15-2.16 4.13c-1.176 2.981-2.142 3.444-1.469 4.745.673 1.302 2.435 1.763 1.994 3.107-.441 1.344-.106 5.668-.609 6.97-.504 1.301-1.93 5.962-1.93 5.962s3.441 5.5 10.238 3.527c6.798-1.974 9.651-5.29 9.651-5.29s-3.566-5.165-3.86-8.145c-.294-2.981.881-7.306-.21-9.574-1.091-2.267-2.748-5.468-4.07-6.507-1.322-1.04-2.863-1.448-2.863-1.448l-2.109.576-2.603 1.947z"
        fill="#009A98"
      />
      <path
        d="M113.475 23.25s.151 2.805.046 3.876c-.105 1.07-2.392 3.926-.797 3.506 1.594-.42 3.713-2.73 5.056-2.855 1.343-.126 1.637-.694 1.637-.694s-.441-1.616-.106-2.645c.336-1.028-.859-2.058-.859-2.058l-2.329-1.553-3.294.168.646 2.256z"
        fill="#B89397"
      />
      <path
        d="M112.367 13.082s-1.196 1.48-2.078 4.534c-.495 1.716-.582 3.333-.564 4.42a1.48 1.48 0 001.489 1.458c1.347-.005 3.412-.247 5.024-1.47 2.611-1.985 3.619-8.755 3.619-8.755l-3.557-1.26-2.486.566-1.447.507zM118.997 29.739a1.96 1.96 0 00-2.13.963c-.508.918-1.015 2.369-.471 3.98.923 2.73 2.433 3.863-.042 5.248-2.476 1.386-10.784 4.618-10.784 4.618s-.336 1.764.252 2.772c.588 1.008 1.385 1.553 1.385 1.553s13.882-4.778 15.609-7.18c1.284-1.786.607-5.682-.294-8.354-.854-2.533-2.39-3.355-3.525-3.6z"
        fill="#B89397"
      />
      <path
        d="M117.308 14.688c.157-2.33-4.941-1.606-4.941-1.606s.02-4.548 4.362-5.208c14.73-3.758 12.678 18.244 3.401 17.007-2.154-.287-3.027-.924-4.02-2.783-.992-1.86 1.04-5.08 1.198-7.41z"
        fill="#444"
      />
      <path
        d="M103.231 24.592h-17.74c-.291 0-.527-.201-.527-.45V4.116c0-.248.236-.45.526-.45h17.741c.291 0 .527.202.527.45v20.027c0 .248-.236.45-.527.45z"
        fill="#E3ECFF"
      />
      <path
        d="M95.428 4.902a1.96 1.96 0 10-.903-3.817 1.96 1.96 0 10.903 3.817z"
        fill="#D6D8E0"
      />
      <path
        d="M95.166 3.835a.864.864 0 10-.398-1.683.864.864 0 10.398 1.683z"
        fill="#C0BEBC"
      />
      <path
        d="M98.04 5.988h-5.848c-.543 0-1.033-.364-1.242-.923l-.484-1.297c-.136-.366.105-.769.46-.769h8.379c.355 0 .596.403.46.769l-.484 1.297c-.208.56-.698.923-1.242.923z"
        fill="#D6D8E0"
      />
      <path
        d="M88.183 10.352a.285.285 0 01-.2-.083l-.75-.748a.285.285 0 01.402-.401l.52.518 1.007-1.322a.285.285 0 01.453.344l-1.205 1.58a.285.285 0 01-.207.112h-.02zM99.56 9.635H91a.357.357 0 110-.714h8.56a.357.357 0 110 .714zM88.183 14.138a.285.285 0 01-.2-.083l-.75-.748a.285.285 0 01.402-.401l.52.518 1.007-1.322a.285.285 0 01.453.344l-1.205 1.581a.286.286 0 01-.207.111h-.02zM96.947 13.421h-6.052c-.14 0-.252-.16-.252-.357 0-.197.113-.357.252-.357h6.052c.14 0 .252.16.252.357 0 .197-.113.357-.252.357zM88.183 17.925a.285.285 0 01-.2-.084l-.75-.748a.285.285 0 01.402-.401l.52.518 1.007-1.322a.285.285 0 01.453.344l-1.205 1.581a.286.286 0 01-.207.112h-.02zM99.56 17.208H91a.357.357 0 110-.714h8.56a.357.357 0 110 .714zM88.183 21.71a.285.285 0 01-.2-.083l-.75-.747a.285.285 0 01.402-.401l.52.518 1.007-1.323a.285.285 0 01.453.344l-1.205 1.581a.285.285 0 01-.207.112h-.02zM100.61 20.994h-9.567c-.222 0-.4-.16-.4-.357 0-.197.178-.357.4-.357h9.567c.221 0 .4.16.4.357 0 .197-.179.357-.4.357z"
        fill="#C0BEBC"
      />
      <defs>
        <linearGradient
          id="subscribe-at-home_svg__paint0_linear"
          x1={92.507}
          y1={21.263}
          x2={93.131}
          y2={21.263}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6A7A" />
          <stop offset={1} stopColor="#FF3F45" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgSubscribeAtHome;
