import React from 'react';
import { string } from '@ampli/utils';

const Separator = ({ color, ...props }) => (
  <svg
    width="83"
    height="22"
    viewBox="0 0 83 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M81.7983 20.9728C69.7252 7.1681 37.8425 -11.7061 0.656133 13.2182"
      stroke={color}
      strokeWidth="2"
      strokeLinejoin="round"
      strokeDasharray="3 10"
    />
  </svg>
);

Separator.propTypes = {
  color: string,
};

export default Separator;
