import React from 'react';
import { Link } from 'react-router-dom';
import { cx, css, px2grid, Breadcrumb, Text, mediaQuery } from '@ampli/ui';
import { string, oneOf } from '@ampli/utils';
import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

const DetailsBreadcrumb = ({
  courseName,
  color = 'white',
  courseDegree,
  className,
  ...props
}) => (
  <Breadcrumb
    {...props}
    color={color}
    className={cx(
      'details-breadcrumb',
      css`
        margin-bottom: ${px2grid(68)} !important;

        & > li {
          padding-right: ${px2grid(4)} !important;

          & > a {
            text-decoration: none;
          }
        }

        ${mediaQuery('mobile')} {
          margin-top: ${px2grid(12)};
        }
        ${mediaQuery('tablet')} {
          margin-top: ${px2grid(12)};
        }
      `,
      className
    )}
    shadow={false}
    showOnlyLatestOnMobile={false}
  >
    <Link rel="canonical" to="/">
      {'Página Inicial >'}
    </Link>
    <Link rel="canonical" to="/cursos">
      {'Todos os Cursos >'}
    </Link>
    <Link to={PATHNAME_BY_DEGREE_TYPE[courseDegree].pathname}>
      {`${PATHNAME_BY_DEGREE_TYPE[courseDegree].label} >`}
    </Link>
    <Text>{courseName}</Text>
  </Breadcrumb>
);

DetailsBreadcrumb.propTypes = {
  courseName: string,
  color: string,
  courseDegree: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)).isRequired,
  className: string,
};

export default DetailsBreadcrumb;
