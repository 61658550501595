import Parachute from './parachute';
import RunningLady from './running-lady';
import RunningMan from './running-man';

export { default as Parachute } from './parachute';
export { default as RunningLady } from './running-lady';
export { default as RunningMan } from './running-man';

export default {
  Parachute,
  RunningLady,
  RunningMan,
};
