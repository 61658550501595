import React from 'react';

import {
  cx,
  css,
  getSpaceSize,
  Text,
  px2grid,
  ColorNeutralLighter,
  mediaQuery,
} from '@ampli/ui';
import { bool } from '@ampli/utils';

import { MecBadge } from '../mec-badge';

const MecInfo = ({ hasBottomBorder }) => (
  <div
    className={cx(
      'mec-info-wrap',
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: ${getSpaceSize('small')} 0;
        &.bottomBorder {
          border-bottom: 1px solid ${ColorNeutralLighter};
          margin-bottom: ${getSpaceSize('small')};
          width: 100%;
        }
        ${mediaQuery('desktop')} {
          padding: ${getSpaceSize('small')};
        }
      `,
      hasBottomBorder && 'bottomBorder'
    )}
  >
    <MecBadge
      className={cx(
        'mec-badge-icon',
        css`
          width: ${px2grid(30)};
          height: ${px2grid(40)};
        `
      )}
    />
    <div
      className={cx(
        'mec-info-text',
        css`
          display: flex;
          flex-direction: column;
          margin-left: ${getSpaceSize('small')};
        `
      )}
    >
      <Text size="small" color="text-warn">
        {'Instituição '}
        <strong>{'Nota'}</strong>
      </Text>
      <Text size="small" color="text-warn">
        <strong>{'Máxima'}</strong>
        {' no MEC'}
      </Text>
    </div>
  </div>
);

MecInfo.propTypes = {
  hasBottomBorder: bool,
};

export default MecInfo;
