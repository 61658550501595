import React, { useState } from 'react';

import {
  css,
  cx,
  getColor,
  Flex,
  FlexItem,
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  DialogFooter,
  px2rem,
  px2grid,
  mediaQuery,
} from '@ampli/ui';
import {
  shape,
  bool,
  func,
  string,
  node,
  oneOfType,
  isFunction,
} from '@ampli/utils';

import { OnlineTest } from '../assets/illustrations';

import {
  CtaButton,
  Paragraph,
  IllustrationWrap,
  DialogTitle,
} from './components';

const ExplanationList = (props) => (
  <dl
    {...props}
    className={cx(
      'explanation-list',
      css`
        padding: 0;
      `,
      props.className
    )}
  />
);
ExplanationList.propTypes = {
  className: string,
};

const ExplanationItem = ({ children, ...props }) => {
  const [collapsed, toggle] = useState(true);

  return (
    <div
      {...props}
      className={cx(
        'explanation-item',
        css`
          background: white;
          border-bottom: 1px solid ${getColor('neutral-lighter')};
          display: flex;
          flex-direction: column;
        `,
        props.className
      )}
    >
      {isFunction(children) ? children(collapsed, toggle) : children}
    </div>
  );
};
ExplanationItem.propTypes = {
  className: string,
  children: oneOfType([func, node]).isRequired,
};

const ExplanationQuestion = ({ collapsed, ...props }) => (
  <dt
    {...props}
    className={cx(
      'explanation-question',
      collapsed && 'collapsed',
      css`
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8L15 1' stroke='%238E9294' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: calc(100% - ${px2grid(16)}) ${px2rem(16)};
        font-size: ${px2rem(16)};
        font-weight: bold;
        padding: ${px2grid(16)} ${px2grid(16)} ${px2grid(16)} ${px2grid(16)};

        color: ${getColor('neutral-dark')};
        cursor: pointer;

        &.collapsed {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='9' viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 8L15 1' stroke='%238E9294' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          color: ${getColor('neutral-dark')};
          &:hover {
            padding-bottom: ${px2grid(16)};
            padding-top: ${px2grid(16)};
          }
        }

        &:hover {
          background-color: white;
        }

        ${mediaQuery('desktop')} {
          background-position: calc(100% - ${px2grid(16)}) ${px2rem(16)};
          font-size: ${px2rem(16)};
          font-weight: bold;
          padding: ${px2grid(16)} ${px2grid(16)} ${px2grid(16)} ${px2grid(16)};

          &.collapsed {
            padding-bottom: ${px2grid(16)};
            padding-top: ${px2grid(16)};
          }
        }
      `,
      props.className
    )}
  />
);
ExplanationQuestion.propTypes = {
  className: string,
  collapsed: bool,
};

const ExplanationAnswer = ({ collapsed, ...props }) => (
  <dd
    {...props}
    className={cx(
      'explanation-answer',
      collapsed && 'collapsed',
      css`
        color: ${getColor('neutral-dark')};
        height: auto;
        margin: 0;
        overflow: hidden;

        &.collapsed {
          height: 0;
          padding: 0;
        }

        ${mediaQuery('desktop')} {
          padding: 0 ${px2grid(16)} ${px2grid(24)};
          font-weight: 200;
        }

        & > p:last-child {
          margin-bottom: 0;
        }

        a {
          color: ${getColor('neutral-dark')};
          text-decoration: underline;
        }
      `,
      props.className
    )}
  />
);
ExplanationAnswer.propTypes = {
  className: string,
  collapsed: bool,
};

const TestAndEdictModal = ({ dialog, onCallToActionClick }) => {
  const handleCallToActionClick = () => {
    dialog.hide();
    onCallToActionClick();
  };
  return (
    <>
      <DialogBackdrop {...dialog} />
      <DialogAlert {...dialog} aria-labelledby="modalTitle">
        <DialogHeader>
          <IllustrationWrap Illustration={OnlineTest} />
          <DialogCloseButton onClick={dialog.hide} />
        </DialogHeader>
        <DialogTitle id="modalTitle">Como é a prova e o edital?</DialogTitle>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph>
              A prova é uma <strong>pequena redação</strong>, entre 100 e 250
              palavras, para responder a uma pergunta específica, e pode ser
              feita no celular mesmo. Nossos alunos levam{' '}
              <strong>em média de 5 a 10 minutos</strong> pra fazê-la.
            </Paragraph>
            <Paragraph>
              Sua redação será corrigida com base em{' '}
              <strong>quatro critérios</strong>:
            </Paragraph>
            <ExplanationList>
              <ExplanationItem>
                {(collapsed, toggle) => [
                  <ExplanationQuestion
                    key={0}
                    collapsed={collapsed}
                    onClick={() => toggle(!collapsed)}
                  >
                    Expressão escrita formal
                  </ExplanationQuestion>,
                  <ExplanationAnswer key={1} collapsed={collapsed}>
                    <p>
                      Avalia o seu domínio do registro formal da Língua
                      Portuguesa na modalidade escrita, ou seja, se o seu texto
                      apresentou um vocabulário diverso e culto; respeitou a
                      norma ortográfica; e manteve a concordância nominal e
                      verbal.
                    </p>
                  </ExplanationAnswer>,
                ]}
              </ExplanationItem>
              <ExplanationItem>
                {(collapsed, toggle) => [
                  <ExplanationQuestion
                    key={0}
                    collapsed={collapsed}
                    onClick={() => toggle(!collapsed)}
                  >
                    Desenvolvimento do Tema
                  </ExplanationQuestion>,
                  <ExplanationAnswer key={1} collapsed={collapsed}>
                    <p>
                      Avalia a sua capacidade de desenvolver o tema da redação
                      respeitando os elementos do relato de experiência, ou
                      seja, se o seu texto teve uma narrativa clara; demonstrou
                      seus conhecimentos sociais e culturais; e introduziu
                      pessoas, lugares e tempos de forma lógica.
                    </p>
                  </ExplanationAnswer>,
                ]}
              </ExplanationItem>
              <ExplanationItem>
                {(collapsed, toggle) => [
                  <ExplanationQuestion
                    key={0}
                    collapsed={collapsed}
                    onClick={() => toggle(!collapsed)}
                  >
                    Estrutura
                  </ExplanationQuestion>,
                  <ExplanationAnswer key={1} collapsed={collapsed}>
                    <p>
                      Avalia a estruturação interna do relato de experiência, ou
                      seja, se o seu texto utilizou informações e fatos
                      relevantes para a narrativa; organizou as informações e
                      fatos em uma sequência lógica; e possuiu um começo, um
                      meio e um fim claros e relacionados entre si.
                    </p>
                  </ExplanationAnswer>,
                ]}
              </ExplanationItem>
              <ExplanationItem>
                {(collapsed, toggle) => [
                  <ExplanationQuestion
                    key={0}
                    collapsed={collapsed}
                    onClick={() => toggle(!collapsed)}
                  >
                    Recursos linguísticos
                  </ExplanationQuestion>,
                  <ExplanationAnswer key={1} collapsed={collapsed}>
                    <p>
                      Avalia a sua utilização de mecanismos linguísticos para
                      dar coesão às diferentes partes do relato, ou seja, se o
                      seu texto fez bom uso de marcadores temporais, deixando
                      claro quando cada coisa aconteceu; apresentou uma
                      diversidade de recursos coesivos, deixando a relação entre
                      os fatos mais compreensível; e demonstrou domínio da
                      pontuação, tornando a leitura do texto mais dinâmica.
                    </p>
                  </ExplanationAnswer>,
                ]}
              </ExplanationItem>
            </ExplanationList>
          </DialogSection>
        </FlexItem>
        <Flex
          justifyContent="center"
          matches={{ 'tablet-min': { justifyContent: 'flex-end' } }}
        >
          <DialogFooter>
            <CtaButton fluid onClick={handleCallToActionClick}>
              Inscreva-se de graça
            </CtaButton>
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};
TestAndEdictModal.propTypes = {
  dialog: shape({ visible: bool, hide: func }).isRequired,
  onCallToActionClick: func,
};

export default TestAndEdictModal;
