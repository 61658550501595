import React from 'react';

import { cx, css, getSpaceSize } from '@ampli/ui';

import { node, string } from '@ampli/utils';

const CourseSection = ({ children, ...props }) => (
  <div
    className={cx(
      'course-section',
      css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        justify-content: center;
        padding: ${getSpaceSize('xxx-large')} 0;
      `,
      props.className
    )}
  >
    {children}
  </div>
);

CourseSection.propTypes = {
  children: node,
  className: string,
};

export default CourseSection;
