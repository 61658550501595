import { isEmpty } from '@ampli/utils';
const allowedFilters = ['fieldOfStudy', 'courseDuration', 'courseType'];

export const parseFilters = (filtersValues = {}, filtersDataList = []) => {
  const filtersDataObject = filtersDataList.reduce(
    (memo, cur) => ({ ...memo, [cur.name]: cur.items }),
    {}
  );

  return allowedFilters.reduce((memo, cur) => {
    const data = filtersValues[cur];

    if (!data || (Array.isArray(data) && (!data?.length || data.length > 2)))
      return memo;

    const filterData = filtersDataObject[cur].filter((item) =>
      Array.isArray(data) ? data.includes(item?.value) : data === item?.value
    );
    return {
      ...memo,
      [cur]: `${filterData[0]?.label}${
        filterData[1] ? ` e ${filterData[1]?.label}` : ''
      }`,
    };
  }, {});
};

export const isUsingMoreThanTwoFilters = (filters = {}) => {
  if (!filters || !Object.keys(filters).length) return false;

  return !!Object.keys(filters).find(
    (key) =>
      filters[key] && Array.isArray(filters[key]) && filters[key].length > 2
  );
};

export const defaultTitle = {
  UNDERGRADUATE: 'Cursos de Graduação EaD | Ampli',
  GRADUATE: 'Cursos de Pós-graduação EAD | Ampli',
};

const filterMetaInformation = {
  UNDERGRADUATE: {
    title: 'Cursos a distância',
    courseType: 'graduação',
    brandText:
      ' da Ampli. São diversas opções, com polos EAD espalhados por todo o Brasil.',
  },
  GRADUATE: {
    title: 'Pós-graduação a distância',
    courseType: 'pós-graduação',
    brandText: ' da Ampli. Saiba mais.',
  },
};

export const createMetaTitle = (
  usedFilters = {},
  courseDegree = 'UNDERGRADUATE'
) => {
  const courseType = `${filterMetaInformation[courseDegree].title}`,
    courseDuration = usedFilters['courseDuration']
      ? ` em ${usedFilters['courseDuration']}`
      : '',
    fieldOfStudy = usedFilters['fieldOfStudy']
      ? ` na Área de ${usedFilters['fieldOfStudy']}`
      : '',
    brandText = ' | Ampli';

  return !isEmpty(usedFilters)
    ? courseType + courseDuration + fieldOfStudy + brandText
    : defaultTitle[courseDegree];
};

export const defaultDescription = {
  UNDERGRADUATE:
    'Confira os cursos de graduação a distância da Ampli. São diversas opções, com polos EAD espalhados por todo o Brasil.',
  GRADUATE:
    'Cursos de pós-graduação a distância. Especialização e MBA online em diversas áreas. Cursos de 5 meses até 9 meses de duração. Faça a sua matrícula!',
};

export const createMetaDescription = (
  usedFilters = {},
  courseDegree = 'UNDERGRADUATE'
) => {
  const courseType = `Confira os cursos de ${filterMetaInformation[courseDegree].courseType} a distância`,
    courseDuration = usedFilters['courseDuration']
      ? ` com até ${usedFilters['courseDuration']} de duração`
      : '',
    fieldOfStudy = usedFilters['fieldOfStudy']
      ? ` da Área de ${usedFilters['fieldOfStudy']}`
      : '',
    brandText = filterMetaInformation[courseDegree].brandText;

  return !isEmpty(usedFilters)
    ? courseType + courseDuration + fieldOfStudy + brandText
    : defaultDescription[courseDegree];
};
