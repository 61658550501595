import * as React from 'react';

function SvgProposalIllustration(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 559 470"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#proposal-illustration_svg__clip0)">
        <path
          d="M256.278 26.153s-23.151-3.238-23.424 19.97c-.272 23.197 6.106 41.795 29.994 41.795 8.962 0 21.658 2.543 30.105-20.079 9.92-26.605-12.555-56.21-36.675-41.686z"
          fill="#311E69"
        />
        <path
          d="M233.813 126.882s-29.176 20.753-48.734 68.202c-19.559 47.448-26.543 69.454 8.729 89.393 35.272 19.939 90.738-84.009 91.293-112.829.565-28.821-51.288-44.766-51.288-44.766z"
          fill="#AD263D"
        />
        <path
          d="M231.805 323.431c16.924-18.836 31.548-33.689 35.282-51.869 9.739-47.409-68.707-126.46-28.591-147.899 43.688-23.347 90.001-32.04 133.7 7.183 51.035 45.809 20.991 160.923 41.367 195.873-36.271 6.438-126.131 18.916-181.758-3.288z"
          fill="#614CD4"
        />
        <path
          d="M372.196 130.846c-20.507-18.399-41.59-26.258-62.702-26.993 8.628 11.574 25.472 35.457 30.74 52.167 7.196 22.811 24.372 97.927 28.762 117.191 1.575 6.895.283 31.404-2.028 60.026a653.73 653.73 0 0046.595-6.518c-20.376-34.95 9.668-150.074-41.367-195.873z"
          fill="#311E69"
        />
        <path
          d="M202.871 271.572c55.365 25.591 117.34 33.907 177.489 22.969 55.063-10.015 117.956-16.999 143.257-3.189 49.138 26.814 45.162 107.265 0 142.335-45.162 35.07-274.252 38.159-352.496 20.624-78.244-17.524-220.734-106.232-153.682-188.75 38.915-47.886 122.296-23.168 185.432 6.011z"
          fill="#FF8F8F"
        />
        <path
          d="M184.826 379.393c-29.6-.397-73.51-30.042-100.678-48.153-27.168-18.111-35.958-10.054-67.91-27.589-6.741-3.696-10.9-7.779-13.301-11.912-23.242 74.233 98.176 146.895 168.184 162.582 10.94 2.454 24.827 4.501 40.742 6.11 9.042-14.524 15.784-33.927 28.742-55.277 27.824-45.809 119.198-73.914 119.198-73.914-41.539-3.319-115.847 48.938-174.977 48.153z"
          fill="#D95F76"
        />
        <path
          d="M195.554 429.494c-1.736-8.524-13.957-17.544-41.206-14.017 0 0-25.492 1.47-40.166 13.63-14.664 12.16-12.918 20.068-3.139 25.572 9.779 5.504 52.741.686 66.355-1.719 13.625-2.424 19.892-14.951 18.156-23.466z"
          fill="#AD263D"
        />
        <path
          d="M337.439 117.315c9.183-17.933 123.466 38.159 160.595 108.696 37.128 70.536-.323 95.87-156.165 89.661l-9.87-23.208 104.231-42.193s-37.401-33.351-51.682-45.709c-40.519-35.06-53.881-74.034-47.109-87.247z"
          fill="#FF6161"
        />
        <path
          d="M240.615 405.154s56.142-50.886 106.875-62.748c50.733-11.862 61.814-15.468 61.814-15.468s-17.812-8.762-52.388-2.583c-55.234 9.885-103.645 49.853-116.301 80.799z"
          fill="#311E69"
        />
        <path
          d="M282.568 75.082l12.494 36.967c2.028 6.09-8.165 26.596-20.467 21.936-3.603-1.361-2.553-10.948-1.907-18.141l-2.947-34.205 12.827-6.557z"
          fill="#FF6161"
        />
        <path
          d="M271.497 101.965c12.454-7.64 11.152-20.177 11.152-20.177l-12.484 4.749 1.332 15.428z"
          fill="#311E69"
        />
        <path
          d="M274.737 52.977l6.882 13.541c5.46 10.73 1.756 24.241-9.032 29.834-9.961 5.166-22.092 2.375-27.915-10.262-5.167-11.237-.02-23.953 1.322-30.192 3.038-14.227 19.67-17.912 28.743-2.92z"
          fill="#FF6161"
        />
        <path
          d="M246.933 71.962c-.596-1.192-.989-2.463-1.312-3.755-.616-2.454-2.099-5.96-5.359-3.278-4.733 3.914-.737 16.82 5.682 16.322 4.753-.367 2.472-6.268.989-9.289z"
          fill="#FF6161"
        />
        <path
          d="M267.228 77.417s-5.813 2.344-14.482 2.563c0 0 3.017 5.692 6.671 7.093 3.643 1.41 6.782-2.831 7.811-9.656z"
          fill="#fff"
        />
        <path
          d="M269.801 92.11c-4.299 1.49-12.554 2.225-16.571.616-3.562-1.42-7.872-6.21-8.8-7.272.091.208.161.417.252.626 5.813 12.637 17.954 15.428 27.915 10.262 6.651-3.447 10.597-9.905 11.374-16.859-4.148 7.322-10.304 11.286-14.17 12.627zM363.658 117.603c3.734 8.544 10.889 20.018 24.311 29.089 24.575 16.601 29.964 8.335 49.139 33.112 19.175 24.777 46.736 87.893 37.149 101.464-9.588 13.571-55.133 2.424-82.099 15.339-11.99 5.742-21.305 13.223-27.965 19.76 137.03 2.891 169.123-23.316 133.841-90.346-27.249-51.77-96.057-95.761-134.376-108.418z"
          fill="#AD263D"
        />
        <path
          d="M240.989 53.444s16.268 9.359 24.392 10.511l-2.119-6.418s15.501 7.302 19.417 6.418c3.916-.884 4.996-20.416-6.641-24.757-11.626-4.342-40.549-3.259-35.049 14.246z"
          fill="#311E69"
        />
        <path
          d="M278.763 66.796c.091-1.33-.03-2.652-.222-3.964-.373-2.503-.343-6.308 3.704-5.027 5.884 1.858 7.145 15.3 1.009 17.227-4.561 1.42-4.713-4.888-4.491-8.236z"
          fill="#FF6161"
        />
        <path
          d="M263.161 23.868s-4.178-17.366 10.526-22.661c14.704-5.295 24.958 32.566 7.014 31.076-17.954-1.5-17.54-8.415-17.54-8.415zM274.232 188.865H95.945c-2.765 0-5.015 2.205-5.015 4.937l28.762 119.476c0 9.924 8.165 17.962 18.246 17.962H308.01l-28.762-137.438c0-2.722-2.241-4.937-5.016-4.937z"
          fill="#311E69"
        />
        <path
          d="M119.692 309.522v3.756c0 9.924 8.165 17.962 18.247 17.962h242.26c8.598 0 15.572-6.865 15.572-15.33v-6.388H119.692z"
          fill="#311E69"
        />
        <path
          d="M90.95 193.574c0 .079-.02.149-.02.228l28.762 119.476c0 9.924 8.165 17.962 18.246 17.962H308.01l-9.244-44.19c-26.391-5.901-73.995-17.763-107.662-33.033-33.98-15.429-86.701-44.289-100.154-60.443z"
          fill="#1E1240"
        />
        <path
          d="M190.195 261.875c1.231 6.07 6.378 10.173 11.505 9.17 5.127-1.003 8.276-6.746 7.044-12.816-1.231-6.07-6.378-10.173-11.504-9.17-5.117 1.014-8.276 6.746-7.045 12.816z"
          fill="#fff"
        />
        <path
          d="M341.596 296.597s-11.474-7.411-17.348-5.434c-5.863 1.987-16.42 11.852-13.957 12.269 2.462.418 12.998-6.527 12.998-6.527s-9.446 10.551-10.031 14.624c-.575 4.073 6.368 5.593 9.84 4.66 3.471-.934 20.981-2.365 25.351-6.875 4.39-4.491-6.853-12.717-6.853-12.717z"
          fill="#FF6161"
        />
        <path
          d="M544.407 309.641c-1.998 39.074-53.549 36.511-69.545 48.512-16.783 12.587-28.762 30.678-84.692 33.033-55.931 2.364-93.483 8.653-126.575 16.521-23.121 5.494-30.145 36.322-32.285 54.403 100.083 7.332 255.915-.159 292.307-28.423 37.341-28.99 46.484-88.966 20.79-124.046z"
          fill="#D95F76"
        />
        <path
          d="M257.378 431.63c-11.525-2.583-26.239-1.549-26.239-1.549s-3.805-.577-9.699-.984c-14.936-1.033-43.264-.984-56.798 12.329-18.862 18.568 12.05 33.003 41.953 26.814 2.422-.497 4.683-.984 6.873-1.45 20.981-4.501 31.648-7.342 39.924-5.256 9.153 2.315 15.511-27.331 3.986-29.904z"
          fill="#FF6161"
        />
        <path
          d="M207.2 443.105c-21.576-6.686-24.766 0-27.278 9.259-2.16 7.948 1.614 14.525 2.906 16.651 7.307 1.063 15.572.924 23.777-.765 2.422-.497 4.683-.984 6.873-1.451 20.981-4.5 31.648-7.341 39.924-5.255 3.25.824 6.136-2.404 8.003-7.004-18.045-3.13-41.832-7.61-54.205-11.435z"
          fill="#AD263D"
        />
      </g>
      <defs>
        <clipPath id="proposal-illustration_svg__clip0">
          <path fill="#fff" transform="translate(0 .7)" d="M0 0h559v469H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgProposalIllustration;
