import React from 'react';

import {
  Flex,
  FlexItem,
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  DialogFooter,
} from '@ampli/ui';
import { shape, bool, func } from '@ampli/utils';

import { SubscribeAtHome } from '../assets/illustrations';

import {
  CtaButton,
  Paragraph,
  DialogTitle,
  IllustrationWrap,
} from './components';

const RegistrationModal = ({ dialog, onCallToActionClick }) => {
  const handleCallToActionClick = () => {
    dialog.hide();
    onCallToActionClick();
  };
  return (
    <>
      <DialogBackdrop {...dialog} />
      <DialogAlert {...dialog} aria-labelledby="modalTitle">
        <DialogHeader>
          <IllustrationWrap Illustration={SubscribeAtHome} />
          <DialogCloseButton onClick={dialog.hide} />
        </DialogHeader>
        <DialogTitle id="modalTitle">Como é a matrícula</DialogTitle>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph>
              O processo de matrícula todo é feito através de nossa plataforma
              online e é composto de três etapas:
            </Paragraph>
            <Paragraph>
              <ol>
                <li>
                  Preenchimento de dados complementares, como: RG, endereço,
                  estado civil, dentre outros;
                </li>
                <li>Aceite dos termos do contrato;</li>
                <li>
                  Escolha da forma de pagamento para as mensalidades seguintes
                </li>
              </ol>
            </Paragraph>
            <Paragraph>
              Após a escolha do método de pagamento, a sua matrícula estará
              efetuada e suas aulas iniciam na segunda-feira seguinte!
            </Paragraph>
          </DialogSection>
        </FlexItem>
        <Flex
          justifyContent="center"
          matches={{ 'tablet-min': { justifyContent: 'flex-end' } }}
        >
          <DialogFooter>
            <CtaButton fluid onClick={handleCallToActionClick}>
              Inscreva-se de graça
            </CtaButton>
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};
RegistrationModal.propTypes = {
  dialog: shape({ visible: bool, hide: func }).isRequired,
  onCallToActionClick: func,
};

export default RegistrationModal;
