import React from 'react';

import {
  cx,
  css,
  px2grid,
  getSpaceSize,
  IconButton,
  Text,
  Button,
  ColorNeutralDark,
  ColorBrandBase,
} from '@ampli/ui';
import { func, number } from '@ampli/utils';

const COUNT_INDEX_PAGES = 4;

const PageButton = ({ children, ...props }) => (
  <button
    className={cx(
      'page-button',
      css`
        border: none;
        outline: none;
        background-color: transparent;
        padding: ${getSpaceSize('small')};
      `
    )}
    {...props}
  >
    {children}
  </button>
);

const Paginator = ({
  itemsLength,
  onChangePage,
  limitPerPage,
  currentPage,
}) => {
  const countPages = React.useMemo(
    () => Math.ceil(itemsLength / limitPerPage),
    [itemsLength, limitPerPage]
  );

  const pages = React.useMemo(() => {
    if (!countPages || !currentPage) return [];
    const pages = Array.from(Array(countPages).keys()).map((item) => item + 1);
    if (countPages <= COUNT_INDEX_PAGES) return pages;

    if (countPages > COUNT_INDEX_PAGES) {
      if (currentPage === countPages)
        return pages
          .slice(currentPage - COUNT_INDEX_PAGES)
          .splice(0, COUNT_INDEX_PAGES);

      if (currentPage === 1) return pages.slice(0).splice(0, COUNT_INDEX_PAGES);

      return pages
        .slice(currentPage > 3 ? currentPage - 3 : 0)
        .splice(0, COUNT_INDEX_PAGES);
    }
  }, [countPages, currentPage]);

  const onChange = (nextPage) =>
    onChangePage && onChangePage(nextPage, nextPage === pages.length);

  React.useEffect(() => {
    if (countPages && countPages < currentPage) onChange(1);
  }, [currentPage]);

  return (
    <div
      className={cx(
        'paginator-wrap',
        css`
          display: flex;
          align-items: center;
        `
      )}
    >
      <IconButton
        className={cx(
          'icon-left',
          css`
            svg {
              width: ${px2grid(20)};
              height: ${px2grid(20)};
            }
          `
        )}
        disabled={currentPage === 1}
        icon="ChevronLeft"
        color={ColorBrandBase}
        onClick={() => onChange(1)}
      />

      {pages.map((item, index) => (
        <PageButton
          onClick={() => item !== currentPage && onChange(item)}
          key={`page_${item}_${index}`}
        >
          <Text
            color={item === currentPage ? ColorBrandBase : ColorNeutralDark}
          >
            {item}
          </Text>
        </PageButton>
      ))}

      {pages[pages.length - 1] < countPages && (
        <>
          <Text>{'...'}</Text>
          <PageButton onClick={() => onChange(countPages)}>
            <Text color={ColorNeutralDark}>{countPages}</Text>
          </PageButton>
        </>
      )}
      <IconButton
        className={cx(
          'icon-right',
          css`
            transform: rotate(180deg);
            svg {
              width: ${px2grid(20)};
              height: ${px2grid(20)};
            }
          `
        )}
        disabled={currentPage === countPages || countPages <= 1}
        color={ColorBrandBase}
        icon="ChevronLeft"
        onClick={() => onChange(currentPage + 1)}
      />
    </div>
  );
};

Paginator.defaultValues = {
  currentPage: 1,
};

Paginator.propTypes = {
  currentPage: number,
  itemsLength: number,
  onChangePage: func,
  limitPerPage: number,
};

export default Paginator;
