import * as React from 'react';

function SvgIllustrationMobile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M98.787 41.439l40.249-.655c.911-.005 1.642.511 1.793 1.578.09.639-.609 1.913-1.618 2.007l-37.295 4.105-3.13-7.035z"
        fill="#68564E"
      />
      <path
        d="M139.896 42.083l10.324 52.554c.165 1.27-2.319 1.736-2.557.792l-14.772-52.755 7.005-.59z"
        fill="#68564E"
      />
      <path
        d="M142.9 62.848c0 1.5-1.187 3.368-2.686 3.422l-128.419.172v-4.083l131.105.489z"
        fill="#68564E"
      />
      <path
        d="M22.628 36.755l7.33 19.331-6.82 38.22a1.653 1.653 0 003.21.765L37.508 57.86a4.432 4.432 0 00-.5-3.643L25.166 35.487a1.426 1.426 0 00-2.538 1.268z"
        fill="#7F6D65"
      />
      <path
        d="M59.527 62.847c0 1.5-1.187 2.732-2.686 2.785l-45.047 1.615v-4.4h47.733z"
        fill="#7F6D65"
      />
      <path
        d="M15.86 54.293h105.266L109.914 28.47a4.765 4.765 0 00-4.333-2.783H4.116c-2.5 0-4.163 2.588-3.122 4.863l8.433 19.516a7.007 7.007 0 006.433 4.227z"
        fill="#B8D2D9"
      />
      <path
        d="M21.865 54.294h102.643L113.296 28.47a4.768 4.768 0 00-4.334-2.784H9.539c-2.502 0-4.163 2.588-3.122 4.863l8.202 18.981a7.895 7.895 0 007.246 4.763z"
        fill="#D8E7EB"
      />
      <path
        d="M29.974 63.825h117.543a4.78 4.78 0 004.766-4.766 4.78 4.78 0 00-4.766-4.766H29.974c-2.62 0-2.62 9.532 0 9.532z"
        fill="#D8E7EB"
      />
      <path
        d="M12.772 63.825h46.103a4.78 4.78 0 004.766-4.766 4.78 4.78 0 00-4.766-4.766H12.772c-2.621 0-2.621 9.532 0 9.532z"
        fill="#B8D2D9"
      />
      <path
        d="M1.857 36.755l7.331 19.331-6.821 38.22a1.653 1.653 0 003.21.765L16.739 57.86a4.431 4.431 0 00-.5-3.643L4.395 35.487a1.426 1.426 0 00-2.538 1.268z"
        fill="#7F6D65"
      />
      <path
        d="M105.463 38.842s-21.75-5.924-26.135-.919c-4.386 5.006 2.935 13.054 13.718 15.548 10.782 2.494 25.426-2.656 22.528-7.243-2.896-4.586-10.111-7.386-10.111-7.386z"
        fill="#311E69"
      />
      <path
        d="M82.841 39.15c.953-.102 1.915.061 2.804.423 4.848 1.976 27.941 11.647 27.437 16.474-.563 5.392-31.675-2.682-33.615-9.288-.537-1.825-1.088-7.139 3.374-7.61z"
        fill="#614CD4"
      />
      <path
        d="M112.452 57.046c-1.102-1.244-4.627-1.865-6.973-2.753-3.752-1.42-13.078-2.591-18.63-5.819-2.946-1.712-5.667-4.134-7.57-6.026-.354 1.614-.082 3.399.186 4.312 1.793 6.105 28.508 13.464 32.987 10.286z"
        fill="#311E69"
      />
      <path
        d="M55.836 30.26s-17.325 9.57-8.957 17.668c9.505 9.2 22.005-14.662 22.914-13.609.874 1.014 3.227 9.139 7.738 16.415 1.007 1.625 4.54.876 4.68-.837.428-5.281-2.789-19.463-4.13-23.462-2.32-6.909-10.461-6.278-22.245 3.826z"
        fill="#AD263D"
      />
      <path
        d="M60.795 26.411a60.133 60.133 0 00-4.96 3.85S38.512 39.83 46.88 47.929c7.546 7.303 16.979-6.229 21.001-11.592-2.094-4.327-5.318-8.058-7.085-9.926z"
        fill="#1E1240"
      />
      <path
        d="M39.982 38.999s15.55 1.411 23.764 5.119c8.215 3.708 9.514 16.477 10.205 19.037.691 2.56 2.01 20.126.23 25.626-.982 3.032-3.565 2.885-4.853.207-1.29-2.678-8.67-27.207-11.3-30.585-1.637-2.103-19.53-1.661-25.955-6.189-6.425-4.526-3.43-10.937-3.43-10.937l11.339-2.278z"
        fill="#FF6161"
      />
      <path
        d="M73.255 90.404c-.337-1.392-.797-2.87-1.209-4.18-1.288-4.105-6.357-26.314-9.656-31.063-3.298-4.747-4.586-2.333-14.056-5.118-7.078-2.08-15.723-6.507-19.707-8.729-.228.523-2.697 6.572 3.446 10.9 6.425 4.528 24.318 4.086 25.955 6.189 2.63 3.378 10.01 27.907 11.3 30.585.985 2.044 2.722 2.611 3.927 1.416zM53.266 12.096s11.646 18.549 9.447 21.66c-2.2 3.112-7.576 1.824-9.131-.59-1.556-2.414-7.677-20.48-.316-21.07z"
        fill="#AD263D"
      />
      <path
        d="M58.952 22.024c-2.672-5.127-5.686-9.928-5.686-9.928-5.615.45-3.384 11.07-1.32 17.154 1.816-.778 5.56-2.86 7.006-7.226z"
        fill="#FFD0CF"
      />
      <path
        d="M52.15 32.672c-.94-4.28-.768-8.25-.68-8.67 1.66-7.903 4.775-10.364-.53-14.231-3.109-2.266-15.943-4.224-20.777 1.527-3.89 4.629-3.12 17.35-3.202 25.182-.016 1.574-.013 8.783 1.054 9.442 7.915 4.887 17.484-2.777 24.135-13.25z"
        fill="#fff"
      />
      <path
        d="M51.997 56.869c.584-2.497 2.972-11.513 7.533-14.298-8.404-2.56-19.548-3.572-19.548-3.572l-11.337 2.277s-2.997 6.41 3.43 10.937c4.28 3.019 13.66 3.829 19.922 4.656z"
        fill="#1E1240"
      />
      <path
        d="M81.146 49.434s3.407 3.084 4.865 3.43c1.761.417 1.194.907 1.854 1.39 1.39 1.02.008 1.298-.418 1.303-.426.005-3.192.259-3.915-.219-1.595-1.054-4.928.836-5.028-2.447-.099-3.283 1.426-3.892 2.642-3.457zM73.09 87.665s4.11 5.46 5.552 6.006c1.442.547-.067 1.322-1.305 1.77-.791.285-2.244.641-3.806-.942-1.383-1.4-5.025-1.098-3.857-4.27 1.168-3.172 2.422-3.447 3.416-2.564zM123.388 88.953s-4.11 5.459-5.552 6.006c-1.442.547.067 1.322 1.305 1.77.791.284 2.245.641 3.806-.942 1.383-1.4 5.025-1.099 3.857-4.27-1.168-3.171-2.422-3.447-3.416-2.564z"
        fill="#444"
      />
      <path
        d="M7.711 41.439l40.25-.655c.91-.005 1.642.511 1.792 1.578.09.639-.608 1.913-1.617 2.007l-37.295 4.105-3.13-7.035z"
        fill="#7F6D65"
      />
      <path
        d="M48.821 42.083l10.324 52.554c.165 1.27-2.319 1.736-2.557.792L41.816 42.674l7.005-.59z"
        fill="#7F6D65"
      />
      <path
        d="M78.5 52.306s-2.175-2.942-2.384-4.066c-.208-1.125 4.958-2.05 6.157-1.286l.273 4.682s-3.224 1.111-4.046.67z"
        fill="#444"
      />
      <path
        d="M50.494 24.072l.017.08.04.182c.02.08.038.158.056.237l.04.17a119.712 119.712 0 00.133.546l.056.219.05.197.052.198.052.196c.02.076.04.151.062.225.028.106.058.211.088.315l.051.183.06.207.048.164.063.213.043.143c.04.137.083.272.123.406l.038.12c.023.074.045.147.069.22l.04.128.07.217.037.11c.054.167.108.33.163.49 0 .004.002.007.003.01.294.866.584 1.639.848 2.278.28-.733.047-2.564-.138-4.05-.242-1.933.958-11.117.958-11.117-1.653 1.121-2.6 4.696-3.122 7.713z"
        fill="#311E69"
      />
      <path
        d="M67.346 83.431c.935 2.79 1.653 4.878 1.98 5.557 1.29 2.678 3.872 2.825 4.854-.206.445-1.378.697-3.513.813-5.98-4.654-1.128-7.646.628-7.647.63z"
        fill="#444"
      />
      <path
        d="M46.529 3.16s1.581-1.06.563-2.363c-1.516-1.941-4.234.176-4.636.958-.6 1.164-.374 3.28-.374 3.28s3.886-.476 4.447-1.875z"
        fill="#311E69"
      />
      <path
        d="M42.083 5.036s-.226-2.117.374-3.281l.002-.001c.822.559 2.6 1.306 3.818 1.789-1.03 1.104-4.194 1.493-4.194 1.493z"
        fill="#614CD4"
      />
      <path
        d="M42.822 3.772l-2.269 3.869c-.126.584 2.69 2.86 3.766 2.31.316-.16.355-.737.227-1.398l-.831-3.625-.893-1.156z"
        fill="#FF6161"
      />
      <path
        d="M43.883 5.657c-.622-.248-1.468-.598-1.468-.598s-.157 1.04.584 2c.391.507.907.818 1.427.965l-.543-2.367z"
        fill="#311E69"
      />
      <path
        d="M43.944 2.574L42.569 4.65a1.52 1.52 0 00.556 2.045c.567.333.963.443 1.772.563.674.1 1.18-.433 1.414-1.289.305-1.109.27-2.218.218-2.809-.118-1.35-1.592-1.874-2.586-.586z"
        fill="#FF6161"
      />
      <path
        d="M45.332 6.465c-.69-.347-1.547.607-2.556-.797-.19-.262-.188-.767-.176-1.063l-.03.045a1.52 1.52 0 00.556 2.045c.567.333.963.443 1.772.563.41.06.76-.115 1.022-.454-.137-.015-.378-.234-.588-.339z"
        fill="#AD263D"
      />
      <path
        d="M43.19 6.05a.077.077 0 01-.04-.01c-.036-.022-.37-.243-.512-1.302-.11-.84.144-1.587.452-1.893.13-.127.27-.185.409-.164.06.008.24.034.205 1.364a.08.08 0 11-.16-.005c.013-.507-.014-1.099-.082-1.202-.104-.008-.198.06-.258.12-.274.271-.509.979-.405 1.758.13.981.43 1.181.434 1.184.038.023.05.073.028.11a.086.086 0 01-.07.04z"
        fill="#fff"
      />
      <path
        d="M35.87 16.375s.586 4.695-.888 10.408c-1.475 5.713-1.395 8.127-1.18 8.744.214.617-2.268-.427-2.494-1.931-.755-5.016.242-9.067 1.233-11.48.991-2.415 3.33-5.74 3.33-5.74z"
        fill="#311E69"
      />
      <path
        d="M41.192 37.76l20.744-2.358 12.258 1.824-20.108 2.284-13.139-1.587a.316.316 0 01.245-.164z"
        fill="#614CD4"
      />
      <path
        d="M54.086 39.511l-.503 1.68-.085-.013c-1.005-.155-10.58-1.678-12.225-1.94a.318.318 0 01-.266-.279l-.097-.845a.316.316 0 01.038-.19l13.138 1.587zM54.714 38.899l-10.637-1.027 11.478-1.304-.84 2.33z"
        fill="#311E69"
      />
      <path
        d="M54.085 39.511l20.11-2.284 3.193-16.802a.569.569 0 00-.624-.672L57.84 21.902a.57.57 0 00-.495.459l-3.26 17.15z"
        fill="#614CD4"
      />
      <path
        d="M65.193 29.168c-.136.743.265 1.439.897 1.555.632.116 1.255-.393 1.39-1.136.136-.744-.265-1.439-.897-1.555-.632-.116-1.254.393-1.39 1.136zM74.193 37.227l-.802 1.318a1.201 1.201 0 01-.89.569l-18.234 2.072a3.333 3.333 0 01-.684.006l.503-1.68 20.107-2.285z"
        fill="#311E69"
      />
      <path
        d="M50.94 9.77c-.611-.446-1.599-.88-2.826-1.244.127 2.058.474 8.317.22 12.147-.298 4.53-2.076 13.131-.221 17.552a52.728 52.728 0 004.037-5.553c-.94-4.28-.768-8.25-.68-8.67 1.66-7.905 4.775-10.364-.53-14.233z"
        fill="#FFD0CF"
      />
      <path
        d="M33.055 11.141c6.416 1.698-2.346 20.963-.167 23.51 2.179 2.549 17.164-3.034 18.017.697.84 3.676-21.374 12.929-25.633 3.867-3.34-7.106 1.448-29.75 7.783-28.074z"
        fill="#FF6161"
      />
      <path
        d="M25.018 24.238c1.668.547 5.923 1.782 8.897 1.26.14-.612.28-1.232.417-1.855l-8.782-1.814a45.95 45.95 0 00-.532 2.409z"
        fill="#311E69"
      />
      <path
        d="M34.552 25.106c1.377-6.058 2.636-13.09-1.496-14.125-3.774-.945-6.322 5.302-7.822 12.205 1.105.82 4.108 2.158 9.318 1.92z"
        fill="#fff"
      />
      <path
        d="M50.126 37.04c-1.728.03-4.465 1.158-6.712 1.491-3.583.531-7.199 2.897-13.299 1.716-3.544-.685-5.184-4.794-5.936-8.117-.028 2.877.306 5.412 1.094 7.086 3.696 7.867 20.922 1.931 24.853-2.177z"
        fill="#AD263D"
      />
      <path
        d="M49.419 34.693s2.109-.155 2.936.141 2.045 3.014 1.655 3.077c-.39.063-.906-1.125-.906-1.125s.422 1.546.203 1.687c-.219.14-.36.063-.36.063s.047.375-.343.296c-.39-.078-.953-1.577-.953-1.577s.164 1.14-.047 1.203c-.138.04-.281-.468-.281-.468l-.376-.843s-2.23.594-2.537-.398c-.248-.81.103-1.868 1.009-2.056z"
        fill="#FF6161"
      />
      <path
        d="M30.552 19.514a237.045 237.045 0 00-1.613 5.17c1.463.327 3.326.527 5.613.423 1.377-6.057 2.636-13.09-1.496-14.125a3.075 3.075 0 00-.536-.086c-.492 2.575-1.32 6.663-1.968 8.618z"
        fill="#FFD0CF"
      />
      <path
        d="M108.253 29.166s-10.179 5.487-16.213 6.749c-6.035 1.261-.253 2.79 6.13 2.589 6.382-.201 12.417-2.374 12.899-4.104.483-1.729.162-5.922-2.816-5.234z"
        fill="#AD263D"
      />
      <path
        d="M126.156 35.936c-3.139-8.496-5.35-14.384-10.701-17.87-4.023-2.62-6.618-1.42-8.336-.08-2.773 2.159-3.993 7.122-1.262 9.656 4.751 4.407 2.137 8.852.711 10.138-3.568 3.223 22.424 5.169 20.753.725-.327-.872-.838-1.682-1.165-2.57z"
        fill="#FF6161"
      />
      <path
        d="M126.156 35.935c-3.138-8.496-5.349-14.383-10.701-17.87-1.413-.92-2.648-1.368-3.732-1.505-.072 1.966.112 5.264 1.76 8.063 2.535 4.305 7.041 10.33 6.678 14.6-.058.68-.149 1.304-.254 1.871 4.433-.079 8.072-.837 7.414-2.589-.327-.872-.838-1.682-1.165-2.57z"
        fill="#AD263D"
      />
      <path
        d="M104.661 38.633s.901.045 1.684-.595c0 0 4.226 2.16 7.563 2.59 3.337.432-1.168 2.489-3.794 2.325-2.626-.164-5.453-4.32-5.453-4.32z"
        fill="#311E69"
      />
      <path
        d="M127.321 38.505s-3.912.667-7.598 1.147c-2.912.38-5.682.52-6.272.98-.595.462-19.76 9.219-21.048 20.324-1.167 10.064 22.59 28.2 29.891 30.096 1.55.403 2.914-.74 3.117-1.046 2.596-3.903-19.149-27.646-19.149-27.646s17.209-5.656 20.095-8.164c4.747-3.79.964-15.69.964-15.69z"
        fill="#614CD4"
      />
      <path
        d="M117.891 20.016c-.6.172-1.881.51-2.854.57-1.246.077-2.782-2.955-2.776-3.933-2.28-.488-3.931.388-5.143 1.332a6.671 6.671 0 00-1.256 1.305c-.242.656-.396 1.357-.613 2.047-.17.536-.605 1.146-.897 1.897-.149 1.643.278 3.27 1.504 4.408.549.51.999 1.02 1.364 1.525 2.111-.54 11.336-2.896 14.59-3.713-1.119-2.119-2.368-3.929-3.919-5.438zM104.994 14.168s-.642-1.827.182-2.787c.822-.96 2.681-.931 3.861.25 1.181 1.183.83 3.869.065 4.181-.765.313-4.195-.302-4.108-1.644z"
        fill="#311E69"
      />
      <path
        d="M108.869 14.177l1.172 3.265c.19.538-.659 2.383-1.747 1.994-.318-.114-.247-.968-.205-1.61l-.329-3.037 1.109-.612z"
        fill="#FF6161"
      />
      <path
        d="M107.867 15.783l.142 1.312c.659-.472.801-1.946.801-1.946s-.477.325-.943.634z"
        fill="#311E69"
      />
      <path
        d="M108.194 13.144l.285.49c.604.882.446 2.117-.423 2.739-.803.574-1.889.473-2.547-.573-.585-.93-.291-2.113-.251-2.678.093-1.29 2.936.022 2.936.022z"
        fill="#FF6161"
      />
      <path
        d="M107.763 16.032c-.355.183-1.063.346-1.431.252-.326-.083-.758-.453-.852-.537.01.017.019.036.03.052.658 1.046 1.745 1.146 2.547.573.536-.383.8-1 .784-1.623-.272.696-.758 1.119-1.078 1.283z"
        fill="#AD263D"
      />
      <path
        d="M106.86 11.287s-.933-.132-.349-1.376c.584-1.243 1.845-.923 2.584-.396.74.527.544 2.595-.186 2.573-.731-.02-2.049-.801-2.049-.801z"
        fill="#311E69"
      />
      <path
        d="M117.89 20.016c-.6.172-1.88.51-2.853.57-1.247.077-2.782-2.955-2.776-3.933a6.226 6.226 0 00-1.22-.14c.162 2.025.731 7.45 2.32 9.558.371.493.721.91 1.043 1.261 2.879-.733 5.836-1.485 7.405-1.88-1.119-2.117-2.367-3.927-3.919-5.436z"
        fill="#1E1240"
      />
      <path
        d="M106.667 39.111l-12.562-2.426-7.575.531 12.177 2.352 8.102-.344a.198.198 0 00-.142-.113z"
        fill="#FFCACA"
      />
      <path
        d="M98.705 39.568l.227 1.05.053-.004c.621-.047 6.544-.522 7.561-.604a.197.197 0 00.176-.158l.099-.512a.195.195 0 00-.013-.118l-8.103.346z"
        fill="#F9B2B2"
      />
      <path d="M98.351 39.163l6.547-.12-6.95-1.343.403 1.463z" fill="#FFE6E6" />
      <path
        d="M98.705 39.568L86.53 37.216l-1.152-10.417a.35.35 0 01.413-.382l11.46 2.214c.15.028.264.152.28.304l1.175 10.633z"
        fill="#fff"
      />
      <path
        d="M92.412 32.72c.048.461-.231.867-.623.907-.391.04-.747-.3-.795-.76-.048-.461.231-.868.622-.907.392-.04.747.3.796.76z"
        fill="#311E69"
      />
      <path
        d="M86.529 37.216l.428.843a.737.737 0 00.517.39l11.042 2.133c.137.027.278.038.417.036l-.227-1.05-12.177-2.352z"
        fill="#FFCACA"
      />
      <path
        d="M126.156 35.935s-1.247 2.3-4.037 4.233c-2.789 1.931-10.779 3.586-15.06 1.421-1.362-.689 1.596-.618 4.193-1.42 5.561-1.72 14.904-4.233 14.904-4.233z"
        fill="#311E69"
      />
      <path
        d="M113.088 17.301c1.118-1.565 17.087 9.856 13.389 17.905-3.37 7.33-21.741 8.41-21.816 4.563-.05-2.532 13.377-3.95 16.064-7.139 3.091-3.67-10.137-11.828-7.637-15.329z"
        fill="#FF6161"
      />
      <path
        d="M126.477 35.205c3.311-7.205-9.136-17.111-12.585-17.986 1.843 1.512 5.061 4.306 6.59 6.558 2.212 3.259 5.31 6.27 4.344 9.211-.965 2.94-4.78 5.213-6.996 5.928-2.216.715-6.963.96-9.578 1.24-1.681.18-2.797-.085-3.539-.07 1.068 3.46 18.492 2.24 21.764-4.881z"
        fill="#AD263D"
      />
      <path
        d="M104.81 38.694c-.715-.317-1.544.05-2.367-.175-.821-.226-.849.369-.291.71.22.133.435.176.628.195-.393.44-2.232 1.076-2.232 1.076s.329.382 1.55.48c1.221.097 2.401.235 2.954-.323.348-.35.473-1.646-.242-1.963z"
        fill="#FF6161"
      />
      <path
        d="M127.321 38.505s-1.171.199-2.834.458c.579 2.631.927 6.401-.894 8.957-3.058 4.292-11.414 6.373-17.164 8.959-4.463 2.005-7.353 2.682-1.531 9.925 5.822 7.242 14.833 16.094 16.604 18.83a996.094 996.094 0 003.227 4.952c.359-.228.605-.465.681-.58 2.595-3.904-19.149-27.647-19.149-27.647s17.209-5.657 20.095-8.164c4.748-3.79.965-15.69.965-15.69z"
        fill="#311E69"
      />
      <path
        d="M46.527 4.75s-.296.131-1.127-.07c-.996-.24-1.815-.842-1.815-.842s-.83 1.186-.579 2.099c.095.344 1.368 1.332 2.763 1.08.526-.096.839-1.417.758-2.268z"
        fill="#fff"
      />
      <path
        d="M45.77 7.016c.27-.05.483-.423.617-.89-.425.032-1.203.036-1.962-.245-.752-.279-1.168-.703-1.362-.954-.099.328-.147.686-.057 1.011.096.345 1.368 1.332 2.763 1.078z"
        fill="#E8E8E8"
      />
      <path
        d="M43.516 3.513c.009-.154.04-.305.082-.453.078-.283.127-.721-.35-.63-.692.134-1.078 1.505-.41 1.812.495.227.657-.341.678-.729z"
        fill="#FF6161"
      />
      <path
        d="M108.478 15.953a.056.056 0 01-.049-.026.059.059 0 01.016-.084c.079-.054.233-.55.286-1.122.046-.497-.031-1.208-.195-1.349-.027-.022-.047-.02-.062-.017-.18.046-.255.575-.261.899 0 .033-.024.064-.062.06-.033-.002-.061-.03-.059-.063.001-.095.024-.93.351-1.013a.184.184 0 01.171.043c.239.205.273 1.06.237 1.45-.047.506-.194 1.112-.338 1.212a.076.076 0 01-.035.01z"
        fill="#fff"
      />
      <path
        d="M108.318 13.624c.03-.115.042-.232.049-.35.01-.225.077-.557.403-.378.474.26.356 1.455-.205 1.522-.416.05-.322-.505-.247-.794z"
        fill="#FF6161"
      />
      <path
        d="M105.182 14.822s.31.056 1.147-.091c.57-.1 1.879-.554 1.879-.554s.458 1.428.36 1.73c-.097.302-2.132.809-2.615.657-.253-.08-.48-.355-.612-.705-.121-.319-.159-.628-.159-1.037z"
        fill="#7BDBD2"
      />
      <path
        d="M108.518 15.323c.053.257.082.483.05.584-.097.302-2.132.809-2.615.657-.224-.07-.427-.296-.563-.59a8.44 8.44 0 00.836-.066 9.88 9.88 0 002.292-.585z"
        fill="#47BFB1"
      />
      <path
        d="M106.815 14.65c-.281-.108-.497-.389-.544-.758-.081-.627.396-.742.78-.835.384-.092.825-.169.905.457 0 0 .074.349-.131.736a.862.862 0 01-.565.428.735.735 0 01-.445-.028zm.916-1.238a.272.272 0 00-.265-.177c-.103.007-.229.031-.383.068-.484.117-.679.219-.637.546.053.412.401.663.781.582.34-.072.689-.528.504-1.02zM105.212 15.06a.734.734 0 01-.329-.208 1.248 1.248 0 01-.307-.682c-.037-.273.016-.468.162-.58.117-.092.279-.119.463-.142.183-.024.345-.04.485.018.169.072.275.246.312.519.075.55-.181 1.04-.573 1.091a.518.518 0 01-.213-.016zm.407-1.367c-.105-.03-.241-.014-.384.004-.16.021-.311.046-.404.117-.061.049-.116.123-.086.336a.923.923 0 00.23.508c.122.13.269.191.416.171.298-.038.496-.407.439-.82-.029-.214-.101-.271-.174-.302-.012-.006-.025-.01-.037-.014z"
        fill="#fff"
      />
      <path
        d="M105.971 13.975l-.149-.148c.054-.126.229-.316.514-.131l-.054.226c-.225-.146-.31.05-.311.053z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgIllustrationMobile;
