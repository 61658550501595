import React, { forwardRef } from 'react';

import {
  css,
  cx,
  px2grid,
  mediaQuery,
  fontSize,
  Flex,
  IconButton,
  GradientLinearBrand,
  getSpaceSize,
} from '@ampli/ui';
import { string, node, func } from '@ampli/utils';
import { useBreakpoint } from '@ampli/hooks';

import { PersonStudyingFromLaptop } from './assets';
import HeaderText from '../cta-header-text/header-text';

const HeaderWrapper = forwardRef(({ children, className, ...props }, ref) => (
  <Flex alignItems="center" justifyContent="center" direction="row">
    <div
      {...props}
      ref={ref}
      className={cx(
        'card-list-wrapper',
        css`
          padding: 0 ${px2grid(16)};
          margin: 0 auto;
        `,
        className
      )}
    >
      {children}
    </div>
  </Flex>
));
HeaderWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

const Text = forwardRef(({ children, className, ...props }, ref) => (
  <Flex alignItems="center" justifyContent="center" direction="row">
    <div
      className={cx(
        'card-list-wrapper',
        css`
          width: 100%;
          ${mediaQuery('desktop')} {
            width: ${px2grid(280)};
          }
        `,
        className
      )}
    >
      <HeaderText {...props} ref={ref}>
        {children}
      </HeaderText>
    </div>
  </Flex>
));
HeaderWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

const StudyingIcon = ({ className, ...props }) => (
  <PersonStudyingFromLaptop
    {...props}
    className={cx(
      'studying-icon',
      css`
        margin-right: ${px2grid(12)};
      `,
      className
    )}
  />
);

StudyingIcon.propTypes = {
  className: node,
};

const CTAWhatsapp = ({ buttonText, onClick, ...props }) => {
  const breakpoint = useBreakpoint();
  const isDesktop = React.useMemo(() => breakpoint === 'desktop', [breakpoint]);

  return (
    <div
      className={cx(
        'cta-whatsapp-wrap',
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          margin: ${getSpaceSize('huge')} 0;

          ${mediaQuery('desktop')} {
            margin-bottom: 0;
          }
        `
      )}
    >
      {isDesktop && (
        <StudyingIcon
          className={cx(
            'cta-person-illustration',
            css`
              width: ${px2grid(200)};
              height: ${px2grid(330)};
            `
          )}
        />
      )}
      <div
        className={cx(
          'cta-whatsapp-content',
          css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `
        )}
        {...props}
      >
        <HeaderWrapper>
          {!isDesktop && <StudyingIcon />}
          <Text as="h2" size={isDesktop ? 'larger' : 'xx-large'}>
            Ainda tem dúvidas ou não encontrou o que procurava?
          </Text>
        </HeaderWrapper>
        <IconButton
          icon="WhatsappOutline"
          iconSize="xx-large"
          onClick={onClick}
          className={cx(
            'whatsapp-button',
            css`
              background: ${GradientLinearBrand};
              height: ${px2grid(50)};
              width: ${px2grid(290)};
              border-radius: ${px2grid(50)};
              display: flex;
              justify-content: center;
              align-items: center;
            `,
            fontSize('small')
          )}
          color="white"
        >
          {buttonText || 'Mande mensagem no whatsapp :)'}
        </IconButton>
      </div>
    </div>
  );
};

CTAWhatsapp.displayName = 'CTAWhatsapp';

CTAWhatsapp.propTypes = {
  buttonText: string,
  onClick: func,
};

export default CTAWhatsapp;
