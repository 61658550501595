import * as React from 'react';

function SvgCoins(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.363 7.304c4.802 0 8.695-1.038 8.695-2.318 0-1.281-3.893-2.32-8.695-2.32-4.803 0-8.696 1.039-8.696 2.32 0 1.28 3.893 2.318 8.696 2.318z"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.058 6.097v3.478c0 1.275-3.942 2.319-8.695 2.319-4.754 0-8.696-1.044-8.696-2.32V6.098"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.058 10.686v3.478c0 1.276-3.942 2.32-8.695 2.32-4.754 0-8.696-1.044-8.696-2.32v-3.478"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.638 20.155c4.802 0 8.696-1.039 8.696-2.32 0-1.28-3.893-2.318-8.696-2.318-4.802 0-8.696 1.038-8.696 2.319 0 1.28 3.894 2.319 8.696 2.319z"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.334 18.947v3.478c0 1.276-3.942 2.319-8.696 2.319s-8.696-1.043-8.696-2.319v-3.478"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.334 23.536v3.479c0 1.275-3.942 2.318-8.696 2.318s-8.696-1.043-8.696-2.318v-3.479M2.667 15.42v3.478c0 1.276 3.942 2.32 8.696 2.32h.58"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.667 20.01v3.478c0 1.275 3.942 2.319 8.696 2.319h.58"
        stroke="#01B6D0"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCoins;
