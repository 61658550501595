import React from 'react';

import { cx, css, px2grid } from '@ampli/ui';

import { node, string } from '@ampli/utils';

const CourseWrapInfo = ({ children, ...props }) => (
  <div
    className={cx(
      'wrap-info',
      css`
        width: ${px2grid(360)};
        margin-right: ${px2grid(80)};
      `,
      props.className
    )}
  >
    {children}
  </div>
);

CourseWrapInfo.propTypes = {
  children: node,
  className: string,
};

export default CourseWrapInfo;
