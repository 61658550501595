import React from 'react';
import {
  Carousel as _Carousel,
  css,
  cx,
  mediaQuery,
  getColor,
} from '@ampli/ui';
import { string, node, object } from '@ampli/utils';

const Carousel = ({ responsive, children, className, ...props }) => (
  <_Carousel
    {...props}
    arrowColor={getColor('brand-base')}
    responsive={responsive || { desktop: { items: 2 } }}
    className={cx(
      'carousel-container',
      css`
        &.react-multi-carousel-list {
          ${mediaQuery('desktop')} {
            width: 100%;
            left: 0;

            .react-multiple-carousel__arrow {
              &.react-multiple-carousel__arrow--left {
                left: 0;
              }
              &.react-multiple-carousel__arrow--right {
                right: 0;
              }
            }
          }
        }
      `,
      className
    )}
  >
    {children}
  </_Carousel>
);

Carousel.propTypes = {
  responsive: object,
  children: node.isRequired,
  className: string,
};

export default Carousel;
