import * as React from 'react';

const SvgHourglass = (props) => (
  <svg
    width={241}
    height={390}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M126.257 358.024c-12.541-1.156-27.228-1.821-42.933-1.821-15.706 0-30.396.665-42.934 1.821H0v18.768c0 7.045 37.303 12.76 83.324 12.76 46.02 0 83.319-5.711 83.319-12.76v-18.768h-40.386z"
      fill="#774135"
    />
    <path
      d="M83.324 370.784c46.018 0 83.323-5.713 83.323-12.76 0-7.048-37.305-12.761-83.323-12.761C37.305 345.263 0 350.976 0 358.024c0 7.047 37.305 12.76 83.324 12.76z"
      fill="#A05F4D"
    />
    <path
      d="M122.789 235.237c1.789-1.472 5.001-2.896 8.843-4.66 15.457-7.111 25.37-22.56 25.37-39.575v-43.83H9.642v43.83c0 17.015 9.913 32.464 25.37 39.575 3.841 1.764 7.054 3.188 8.842 4.66 5.614 4.62 5.683 16.699 5.683 16.699s-.069 12.079-5.683 16.699c-1.788 1.472-5.005 2.896-8.842 4.66-15.457 7.11-25.37 22.56-25.37 39.575v43.83c0 4.774 32.987 8.643 73.678 8.643 40.69 0 73.678-3.869 73.678-8.643v-43.826c0-17.015-9.913-32.465-25.37-39.575-3.842-1.764-7.054-3.188-8.843-4.66-5.613-4.62-5.682-16.699-5.682-16.699s.073-12.083 5.686-16.703z"
      fill="#FFD5D4"
    />
    <path
      d="M122.789 235.237c1.789-1.472 5.001-2.896 8.843-4.66 15.457-7.111 25.37-22.56 25.37-39.575v-43.83H9.642v43.83c0 17.015 9.913 32.464 25.37 39.575 3.841 1.764 7.054 3.188 8.842 4.66 5.614 4.62 5.683 16.699 5.683 16.699s-.069 12.079-5.683 16.699c-1.788 1.472-5 2.896-8.842 4.66-15.457 7.11-25.37 22.56-25.37 39.575v43.83c0 4.774 32.987 8.643 73.678 8.643 40.69 0 73.678-3.869 73.678-8.643v-43.826c0-17.015-9.913-32.465-25.37-39.575-3.842-1.764-7.054-3.188-8.843-4.66-5.613-4.62-5.682-16.699-5.682-16.699s.073-12.083 5.686-16.703zm-9.726 16.674v.049c.008 1.432.28 14.14 7.155 19.798 2.064 1.699 5.074 3.082 8.891 4.83l.831.382a39.48 39.48 0 0116.768 14.581 39.432 39.432 0 016.25 21.319v42.337c-1.703.763-6.96 2.49-21.679 3.991-13.268 1.355-30.299 2.101-47.955 2.101s-34.688-.746-47.955-2.101c-14.72-1.505-19.976-3.228-21.68-3.991v-42.333a39.408 39.408 0 016.25-21.319 39.456 39.456 0 0116.769-14.581l.831-.381c3.813-1.753 6.826-3.132 8.891-4.831 6.875-5.662 7.147-18.37 7.155-19.798v-.049c-.008-1.431-.28-14.139-7.155-19.797-2.065-1.7-5.074-3.079-8.89-4.831l-.832-.381a39.485 39.485 0 01-16.768-14.582 39.427 39.427 0 01-6.25-21.318V151.22h139.268v39.786a39.411 39.411 0 01-6.25 21.318 39.463 39.463 0 01-16.768 14.582l-.831.381c-3.817 1.752-6.827 3.131-8.891 4.831-6.875 5.658-7.147 18.366-7.155 19.793z"
      fill="#fff"
    />
    <path
      d="M147.279 190.996c0 6.482-1.85 12.797-5.35 18.257a33.794 33.794 0 01-14.358 12.488l-.828.381c-4.023 1.846-7.499 3.44-10.132 5.61-4.579 3.768-33.288 28.526-33.288 28.526S54.614 231.5 50.035 227.732c-2.632-2.166-6.108-3.76-10.132-5.61l-.827-.381a33.795 33.795 0 01-14.359-12.488 33.759 33.759 0 01-5.35-18.257v-7.374H147.28v7.374zM141.929 317.399a33.794 33.794 0 00-14.358-12.488l-.828-.382c-4.023-1.845-43.42-17.68-43.42-17.68s-39.396 15.835-43.42 17.68l-.827.382a33.795 33.795 0 00-14.359 12.488 33.759 33.759 0 00-5.35 18.257v7.374c0 4.774 28.636 8.643 63.956 8.643 35.324 0 63.956-3.869 63.956-8.643v-7.374c0-6.482-1.85-12.797-5.35-18.257z"
      fill="#FF8B85"
    />
    <path
      d="M157.002 356.704v-43.83c0-17.015-9.913-32.465-25.371-39.575-3.841-1.764-7.053-3.188-8.842-4.66-5.613-4.62-5.682-16.699-5.682-16.699s.069-12.079 5.682-16.699c1.789-1.472 5.001-2.896 8.842-4.66 15.458-7.111 25.371-22.56 25.371-39.575v-43.834h-33.811c1.703 14.89 2.283 31.868-1.047 45.099-7.593 30.153-24.255 37.993-25.967 58.574-1.715 20.58 19.356 54.039 22.54 68.421 2.685 12.128-11 30.664-11.394 45.615 28.904-1.172 49.679-4.393 49.679-8.177z"
      fill="#E5C4C3"
    />
    <path
      d="M83.323 192.269c35.322 0 63.956-3.87 63.956-8.643 0-4.774-28.634-8.644-63.956-8.644s-63.956 3.87-63.956 8.644c0 4.773 28.634 8.643 63.956 8.643z"
      fill="#FFA8A6"
    />
    <path
      d="M16.14 156.768s11.685 3.184 45.983 4.409c0 0-7.017 46 0 56.59 12.983 19.599-16.942 15.734-35.523-2.206-14.208-13.718-10.46-58.793-10.46-58.793z"
      fill="url(#hourglass_svg__paint0_linear)"
      fillOpacity={0.06}
    />
    <path
      d="M157.002 147.172h-37.097l-62.45 8.972s33.27 7.057 70.753 5.403c12.545-.552 21.899-1.274 28.794-1.984v-12.391z"
      fill="#311E69"
    />
    <path
      d="M63.94 274.049c-.97-4.381-6.409-5.878-9.593-2.718-1.412 1.399-3.314 2.827-5.881 4.133-10.104 5.143-21.68 10.842-25.541 20.211-3.858 9.37-7.902 38.951-4.778 41.157 3.123 2.207 29.763 5.512 38.95 2.754 7.277-2.182 11.438-44.783 6.843-65.537z"
      fill="url(#hourglass_svg__paint1_linear)"
      fillOpacity={0.06}
    />
    <path
      d="M126.257 125.239c-12.541-1.156-27.228-1.821-42.933-1.821-15.706 0-30.396.665-42.934 1.821H0v18.767c0 7.046 37.303 12.761 83.324 12.761 46.02 0 83.319-5.711 83.319-12.761v-18.767h-40.386z"
      fill="#774135"
    />
    <path
      d="M83.324 138c46.018 0 83.323-5.713 83.323-12.76 0-7.048-37.305-12.761-83.323-12.761C37.305 112.479 0 118.192 0 125.24 0 132.287 37.305 138 83.324 138z"
      fill="#A05F4D"
    />
    <path
      d="M147.529 58.815s56.504-2.901 58.213 4.602c1.708 7.504-39.914 12.163-51.004 10.608-11.091-1.549-7.209-15.21-7.209-15.21z"
      fill="#AD263D"
    />
    <path
      d="M225.957 195.361s.265 7.755 1.688 9.64c-.432.713-1.239 1.472-2.745 1.743-1.719.313-5.072.491-8.002.337.324-3.124-.379-8.512-.532-10.224l9.591-1.496zM226.347 211.811c5.243-.469 11.014-1.843 13.864-2.588.121.299.226.63.299 1.026.341 1.865-18.595 5.236-22.105 4.022-.943-.327-1.513-1.242-1.767-2.425 2.55.188 6.025.296 9.709-.035z"
      fill="#fff"
    />
    <path
      d="M226.347 211.813c-3.684.33-7.16.222-9.706.034-.24-1.116-.192-2.473.108-3.795.066-.285.111-.619.146-.971 2.929.153 6.283-.024 8.001-.337 1.507-.275 2.314-1.033 2.745-1.743.031.042.063.087.097.122.658.72 2.258 1.054 4.467 1.544 3.232.981 6.788-.459 8.002 2.561-2.846.741-8.617 2.115-13.86 2.585zM154.244 84.024s35.321 3.204 53.978 11.626c18.657 8.419 21.611 37.422 23.18 43.236 1.572 5.813 8.683 43.764 4.641 56.257-2.23 6.884-17.548 9.949-20.477 3.868-2.929-6.084-14.357-63.246-20.331-70.917-3.718-4.776-44.794-.741-59.388-11.024-14.593-10.281-10.746-23.417-10.746-23.417l29.143-9.63z"
      fill="#1E1240"
    />
    <path
      d="M152.522 198.88s-2.543 7.33-1.896 9.602c-.661.508-1.684.926-3.19.64-1.715-.327-4.909-1.367-7.587-2.564 1.422-2.797 2.71-8.078 3.183-9.73l9.49 2.052zM146.967 214.371c5.061 1.447 10.941 2.24 13.866 2.574.004.324-.013.668-.09 1.065-.355 1.864-19.235-1.806-22.07-4.203-.762-.643-.967-1.704-.776-2.898 2.307 1.093 5.511 2.443 9.07 3.462z"
      fill="#fff"
    />
    <path
      d="M146.968 214.372c-3.556-1.02-6.76-2.369-9.07-3.462.181-1.13.714-2.376 1.469-3.503.163-.244.327-.536.487-.852 2.678 1.2 5.872 2.24 7.587 2.564 1.506.285 2.529-.133 3.19-.64.014.052.028.104.045.149.355.908 1.729 1.795 3.615 3.048 2.665 2.077 6.498 2.014 6.544 5.27-2.93-.334-8.809-1.127-13.867-2.574z"
      fill="#1E1240"
    />
    <path
      d="M129.37 81.253s24.589 5.973 43.246 14.392c18.657 8.419 22.439 23.051 17.266 45.142-1.375 5.862-17.464 45.257-21.51 57.75-2.23 6.884-24.794 6.923-27.724.838-2.929-6.084 23.086-66.492 18.978-71.289-3.935-4.599-38.052-3.583-52.646-13.863-14.594-10.281-6.76-23.344-6.76-23.344l29.15-9.626z"
      fill="#311E69"
    />
    <path
      d="M99.392 92.523c-4.018-40.664 10.91-72.639 16.688-76.928 15.259-11.324 48.378.295 52.017 14.705 3.607 14.288-29.341 22.258-12.274 49.525 1.66 2.651 3.197 11.334-19.565 16.782-2.651.637-8.736 1.928-16.006 1.928-14.264 0-20.606-3.448-20.86-6.012z"
      fill="#FFE6E6"
    />
    <path
      d="M149.227 23.809s-6.356 27.563-6.742 33.96c-.386 6.398 1.402 18.119 1.402 18.119l3.41 1.708 1.93-2.56s-1.412-13.739-1.207-18.706c.209-4.968 3.225-32.83 3.225-32.83l-2.018.309zM159.821 7.51s3.962-1.53 2.386-4.866c-2.345-4.965-9.295-1.691-10.555-.188-1.875 2.24-2.424 6.954-2.424 6.954s8.686.866 10.593-1.9z"
      fill="#311E69"
    />
    <path
      d="M151.457 7.028l-6.833 7.302c-.56 1.21 4.446 7.539 7.063 6.87.765-.194 1.137-1.429 1.179-2.928l-.031-8.294-1.378-2.95z"
      fill="#FF6161"
    />
    <path
      d="M152.839 11.643c-1.232-.845-2.902-2.02-2.902-2.02s-.849 2.184.289 4.633a6.089 6.089 0 002.63 2.8l-.017-5.413z"
      fill="#311E69"
    />
    <path
      d="M154.48 4.965l-4.008 3.844a3.392 3.392 0 00.206 4.721c1.068 1.002 1.878 1.437 3.579 2.094 1.416.55 2.78-.365 3.712-2.111 1.207-2.265 1.674-4.69 1.851-6.005.404-2.992-2.543-4.856-5.34-2.543z"
      fill="#FF6161"
    />
    <path
      d="M155.398 9.768S157.851 11 158.3 11.4c0 0-2.675 1.19-2.902-1.632z"
      fill="#fff"
    />
    <path
      d="M155.593 14.113c-1.329-1.093-3.663.56-5.173-2.989-.282-.66-.031-1.76.139-2.4l-.087.083a3.392 3.392 0 00.206 4.721c1.068 1.002 1.878 1.437 3.579 2.095.863.334 1.708.121 2.446-.487-.285-.098-.703-.69-1.11-1.023z"
      fill="#AD263D"
    />
    <path
      d="M153.603 6.916c.094-.33.237-.644.4-.946.306-.578.63-1.507-.452-1.541-1.573-.05-3.082 2.748-1.781 3.74.967.737 1.597-.418 1.833-1.253z"
      fill="#FF6161"
    />
    <path
      d="M154.209 6.184a1.98 1.98 0 00.807 2.682c.056.028.108.056.164.08.282.122.577.177.873.167l.421-.018a1.73 1.73 0 001.273-.64c.091-.02.171-.024.223.004a.286.286 0 01.115.129c-.164.452-.167.939.003 1.318l.143.32a1.337 1.337 0 00.682.692c.758.327 1.729-.125 2.226-1.047.143-.264.199-.556.153-.828-.045-.288-.198-.515-.424-.636l-1.287-.693c-.393-.208-.908-.041-1.235.376a1.102 1.102 0 00-.133-.083c-.062-.032-.114-.067-.184-.084a1.173 1.173 0 00-.007-.62 1.173 1.173 0 00-.56-.688l-1.68-.905a1.16 1.16 0 00-1.573.474zm4.342 2.292c.191-.355.557-.546.817-.435.011.004.021.01.028.014l1.287.693c.119.062.199.19.227.358a.932.932 0 01-.112.58c-.417.777-1.224 1.16-1.791.85a.942.942 0 01-.425-.466l-.142-.32c-.16-.359-.119-.846.111-1.274zm-4.001-2.111a.772.772 0 011.047-.314l1.681.905c.181.097.313.26.372.46a.77.77 0 01-.059.587 1.35 1.35 0 01-1.134.706l-.421.018a1.627 1.627 0 01-.825-.192 1.606 1.606 0 01-.661-2.17z"
      fill="#fff"
    />
    <path
      d="M106.984 114.223c-14.594-10.28-6.76-23.343-6.76-23.343l20.547-6.787c1.896 11.041 5.406 22.88 11.403 24.477 11.237 2.995 31.001-4.091 34.264 7.841 3.263 11.933-.306 37.103-5.608 48.099-3.848 7.984-7.768 27.786-9.908 39.166-4.853-.442-9.111-1.886-10.273-4.304-2.929-6.084 23.086-66.492 18.977-71.289-3.931-4.592-38.048-3.576-52.642-13.86z"
      fill="#1E1240"
    />
    <path
      d="M99.392 92.525c-4.018-40.665 10.91-72.64 16.688-76.929 4.179-3.1 9.693-4.477 15.575-4.55-3.576 3.555-11.191 12.072-13.63 22.22-3.228 13.425-7.563 28.76-3.507 37.662 3.618 7.946 12.465 18.71 9.553 27.493a64.128 64.128 0 01-3.819.119c-14.264-.004-20.606-3.451-20.86-6.015z"
      fill="#FFCACA"
    />
    <path
      d="M109.763 66.356c3.635 4.195 11.466 9.974 18.921 10.534 7.459.56 19.11-.445 19.11-.445s3.332.424 6.693 1.099c.407.748.849 1.506 1.336 2.282.546.873 1.078 2.397.737 4.248-4.633 1.18-17.857 3.896-33.185 1.203-19.109-3.357-20.643-12.026-21.812-17.881-1.583-7.935 2.703-19.033 2.703-19.033s1.861 13.797 5.497 17.993zM180.854 36.62h35.582c.925 0 1.555.748 1.409 1.673l-7.723 49.167c-.146.925-1.013 1.673-1.935 1.673h-35.585c-.926 0-1.555-.748-1.409-1.673l7.727-49.167c.142-.925 1.008-1.673 1.934-1.673z"
      fill="#311E69"
    />
    <path
      d="M181.765 36.62h35.582c.925 0 1.555.748 1.409 1.673l-7.723 49.167c-.146.926-1.013 1.674-1.935 1.674h-35.585c-.925 0-1.555-.748-1.409-1.674l7.727-49.167c.146-.925 1.012-1.673 1.934-1.673z"
      fill="#614CD4"
    />
    <path
      d="M195.594 54.871c-.706-1.42-.243-3.308 1.033-4.213 1.277-.908 2.888-.49 3.594.929.706 1.42.244 3.308-1.033 4.213-1.28.904-2.888.49-3.594-.929z"
      fill="#988DFF"
    />
    <path
      d="M120.669 62.209c3.973 6.992 56.769 4.195 55.791 12.444-.977 8.248-45.302 13.564-65.577 3.218-16.636-8.489-4.056-49.08 4.613-58.31 8.67-9.23 18.609-1.353 16.362 10.618-1.994 10.603-14.793 25.684-11.189 32.03z"
      fill="#FF6161"
    />
    <path
      d="M110.879 77.868c-12.322-6.29-8.614-30.186-2.338-45.908.126 1.208.16 2.603.522 3.82 1.12 3.775-.421 5.313-1.538 17.687-1.12 12.374 1.121 13.634 4.335 18.108 3.215 4.473 5.455 5.033 17.756 4.866 12.305-.167 10.486 2.964 20.929 1.844 8.802-.942 16.247-6.356 18.365-8.032.296.062.588.125.87.19 1.44.335 2.894.724 4.237 1.35 1.11.519 2.606 1.423 2.436 2.857-.975 8.252-45.299 13.564-65.574 3.218z"
      fill="#AD263D"
    />
    <path
      d="M105.324 41.67l16.462 4.247 2.804 1.04c-1.52 2.95-2.901 5.79-3.736 8.311-2.922-.083-12.072-.63-17.502-4.077.452-3.18 1.137-6.401 1.972-9.522z"
      fill="#311E69"
    />
    <path
      d="M131.854 30.179c-1.29 6.881-5.851 16.25-8.808 23.291-4.29-.449-13.133-2.261-19.176-5.413 2.247-12.05 7.4-23.997 11.627-28.492 8.669-9.233 18.605-1.357 16.357 10.614z"
      fill="#FFE6E6"
    />
    <path
      d="M115.497 19.56c2.261-2.406 4.606-3.638 6.801-3.951-1.921 1.948-5.671 6.318-7.111 11.967-1.973 7.737.17 8.948-1.134 13.331-.894 3-3.281 6.892-4.905 9.376-1.865-.647-3.671-1.388-5.278-2.227 2.247-12.05 7.4-23.997 11.627-28.495z"
      fill="#FFCACA"
    />
    <path
      d="M169.864 72.545s3.729-4.972 4.161-6.325c.431-1.35-.334-3.566 1.29-2.54 1.625 1.027.049 4.001 0 4.972-.048.974 5.01-.682 6.036-1.76 1.026-1.082 1.868-3.104 2.54-1.75.671 1.35-.519 4.97-.303 5.73.216.754-2.647 5.722-5.458 6.453-2.811.734-7.028 2.077-7.998 0-.971-2.077-.578-4.366-.268-4.78z"
      fill="#FF6161"
    />
    <path
      d="M152.543 21.642s-.16 3.26-2.223 3.514c-2.067.254-3.11-4.157-1.548-4.568 1.562-.414 2.737-1.252 3.771 1.054z"
      fill="#311E69"
    />
    <path
      d="M151.245 18.435c-2.021-.348-2.387-2.415-3.145-3.232-.759-.814-1.319-3.183-1.319-3.183s-2.376 1.485-2.755 2.115c-.379.63.059 2.115.786 3.423.727 1.308 3.086 4.571 3.086 4.571s3.176-1.019 3.378-.668c.202.348 1.583 1.34 1.583 1.34s1.44-3.785.007-6.52c-.004 0-1.148 2.234-1.621 2.154z"
      fill="#FFCACA"
    />
    <defs>
      <linearGradient
        id="hourglass_svg__paint0_linear"
        x1={35.67}
        y1={151.183}
        x2={49.927}
        y2={241.908}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4D4D4D" />
        <stop offset={1} />
      </linearGradient>
      <linearGradient
        id="hourglass_svg__paint1_linear"
        x1={51.092}
        y1={270.646}
        x2={37.312}
        y2={336.607}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4D4D4D" />
        <stop offset={1} />
      </linearGradient>
    </defs>
  </svg>
);

export default SvgHourglass;
