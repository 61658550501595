import React from 'react';
import { cx, css, px2grid, mediaQuery } from '@ampli/ui';
import { string, node } from '@ampli/utils';

const DetailsContentWrapper = ({ children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      'details-content-wrapper',
      css`
        padding: ${px2grid(24)};

        ${mediaQuery('desktop')} {
          --lateral-margin: 9%;

          width: calc(100% - var(--lateral-margin));
          max-width: ${px2grid(1500)};
          margin-left: var(--lateral-margin);
          padding: 0;
          padding-top: ${px2grid(40)};
          padding-right: calc(var(--detail-card-width) + var(--lateral-margin));
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

DetailsContentWrapper.propTypes = {
  children: node.isRequired,
  className: string,
};

export default DetailsContentWrapper;
