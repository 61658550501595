import React from 'react';
import { useHistory } from 'react-router-dom';
import { useFindListFieldOfStudy } from '@ampli/services';
import { omit } from '@ampli/utils';

import {
  COURSES_LIST_FILTERS_BY_DEGREE_TYPE,
  COURSE_FILTERS_TO_URL_PARAMS as filtersTranslate,
} from '../constants';
import { useQueryParams } from './use-query-params';
import { onFilterCourse } from '../lib/filter-courses';
import { filterQueryStringIgnoredParams } from '../lib/query-string-manipulation';

export const initialFilters = {
  name: '',
  courseDuration: 0,
  fieldOfStudy: [],
  courseType: [],
  page: 1,
};

const parseParamValue = (value) => (isNaN(value) ? value : Number(value));

const GET_QUERY_PARAM_BY_KEY = (key) =>
  new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`, 'g');

const GET_QUERY_VALUE_BY_KEY = (key) => new RegExp(`[?&]${key}=`, 'g');

const allowedQueryParams = Object.values(filtersTranslate);

export const loadFilters = (queryString) => {
  const reversedTranslate = Object.keys(filtersTranslate).reduce(
    (memo, cur) => ({ ...memo, [filtersTranslate[cur]]: cur }),
    {}
  );

  return allowedQueryParams.reduce((memo, key) => {
    const values = queryString.match(GET_QUERY_PARAM_BY_KEY(key));
    const regexValue = GET_QUERY_VALUE_BY_KEY(key);
    let parsedValues = '';
    if (values?.length)
      parsedValues =
        values?.length > 1
          ? values.map((item) => parseParamValue(item.replace(regexValue, '')))
          : parseParamValue(values[0].replace(regexValue, ''));

    if (parsedValues) {
      if (Array.isArray(initialFilters[reversedTranslate[key]])) {
        return {
          ...memo,
          [reversedTranslate[key]]: Array.isArray(parsedValues)
            ? parsedValues
            : [parsedValues],
        };
      }
      return { ...memo, [reversedTranslate[key]]: parsedValues };
    }

    return { ...memo };
  }, {});
};

let initialPageLoad = true;

const useCourseFilter = (courses = [], degreeType = 'UNDERGRADUATE') => {
  const COURSES_LIST_FILTERS = COURSES_LIST_FILTERS_BY_DEGREE_TYPE[degreeType];

  const history = useHistory();
  const { queryString, parseQueryObject } = useQueryParams();
  const { fieldsOfStudy, loading } = useFindListFieldOfStudy();
  const [filterValues, setFilterValues] = React.useState({
    ...initialFilters,
    ...(loadFilters(queryString) || {}),
  });

  const queryStringIgnoredParams = filterQueryStringIgnoredParams(
    queryString,
    allowedQueryParams
  );

  const onChangeFilter = (key, value) => {
    if (initialPageLoad) initialPageLoad = false;
    const page = key !== 'page' ? { page: 1 } : {};
    setFilterValues((prevState) => ({ ...prevState, ...page, [key]: value }));
  };

  const clearFilters = () => setFilterValues(initialFilters);

  const filteredCourses = React.useMemo(
    () => onFilterCourse(courses, filterValues, degreeType),
    [courses, filterValues, degreeType]
  );

  const filtersList = React.useMemo(() => {
    if (!fieldsOfStudy?.length) return COURSES_LIST_FILTERS;

    return COURSES_LIST_FILTERS.reduce((memo, cur) => {
      if (cur.name === 'fieldOfStudy') {
        return [
          ...memo,
          {
            ...cur,
            items: fieldsOfStudy.map((item) => ({
              label: item.name,
              value: item.code,
            })),
          },
        ];
      }
      return [...memo, cur];
    }, []);
  }, [fieldsOfStudy]);

  React.useEffect(() => {
    let values = filterValues;
    if (values?.page <= 1) values = omit(filterValues, ['page']);

    if (!initialPageLoad)
      history.replace({
        path: 'cursos',
        search: parseQueryObject(
          { ...values, ...queryStringIgnoredParams },
          filtersTranslate
        ),
      });
  }, [filterValues]);

  return {
    filterValues,
    onChangeFilter,
    filteredCourses,
    filtersList,
    loadingFilters: loading,
    clearFilters,
  };
};

export default useCourseFilter;
