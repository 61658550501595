import * as React from 'react';

function SvgHourglassMobile(props) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M59.33 167.185c-5.894-.544-12.795-.856-20.175-.856-7.38 0-14.284.312-20.175.856H0v8.819C0 179.314 17.53 182 39.154 182c21.626 0 39.153-2.684 39.153-5.996v-8.819H59.33z"
        fill="#774135"
      />
      <path
        d="M39.154 173.181c21.625 0 39.155-2.685 39.155-5.997 0-3.311-17.53-5.996-39.154-5.996C17.53 161.188 0 163.873 0 167.184c0 3.312 17.53 5.997 39.154 5.997z"
        fill="#A05F4D"
      />
      <path
        d="M57.7 109.486c.84-.692 2.35-1.361 4.155-2.19a20.472 20.472 0 0011.922-18.597V68.103H4.53v20.596a20.472 20.472 0 0011.921 18.597c1.805.829 3.315 1.498 4.155 2.19 2.638 2.171 2.67 7.847 2.67 7.847s-.032 5.676-2.67 7.846c-.84.692-2.351 1.361-4.155 2.19a20.473 20.473 0 00-11.921 18.597v20.596c0 2.243 15.5 4.062 34.622 4.062 19.12 0 34.622-1.819 34.622-4.062v-20.594a20.473 20.473 0 00-11.922-18.597c-1.805-.829-3.315-1.498-4.155-2.19-2.638-2.171-2.67-7.847-2.67-7.847s.034-5.677 2.672-7.848z"
        fill="#FFD5D4"
      />
      <path
        d="M57.7 109.486c.84-.692 2.35-1.361 4.155-2.19a20.472 20.472 0 0011.922-18.597V68.103H4.53v20.596a20.472 20.472 0 0011.921 18.597c1.805.829 3.315 1.498 4.155 2.19 2.638 2.171 2.67 7.847 2.67 7.847s-.032 5.676-2.67 7.846c-.84.692-2.35 1.361-4.155 2.19a20.473 20.473 0 00-11.921 18.597v20.596c0 2.243 15.5 4.062 34.622 4.062 19.12 0 34.622-1.819 34.622-4.062v-20.594a20.473 20.473 0 00-11.922-18.597c-1.805-.829-3.315-1.498-4.155-2.19-2.638-2.171-2.67-7.847-2.67-7.847s.034-5.677 2.672-7.848zm-4.57 7.835v.023c.003.673.13 6.644 3.361 9.303.97.799 2.385 1.449 4.178 2.27l.391.179a18.557 18.557 0 017.88 6.852 18.53 18.53 0 012.936 10.018v19.895c-.8.358-3.27 1.17-10.187 1.875-6.234.637-14.238.987-22.535.987-8.296 0-16.3-.35-22.534-.987-6.917-.707-9.387-1.517-10.187-1.875v-19.893c0-3.558 1.016-7.024 2.937-10.018a18.544 18.544 0 017.88-6.852l.39-.179c1.791-.823 3.208-1.471 4.178-2.27 3.23-2.661 3.358-8.632 3.362-9.303v-.023c-.004-.673-.132-6.644-3.362-9.303-.97-.799-2.385-1.447-4.178-2.27l-.39-.179a18.556 18.556 0 01-7.88-6.852A18.528 18.528 0 016.433 88.7V70.005h65.443v18.696c0 3.558-1.016 7.024-2.937 10.018a18.543 18.543 0 01-7.879 6.852l-.39.179c-1.794.823-3.209 1.471-4.179 2.27-3.23 2.659-3.358 8.63-3.362 9.301z"
        fill="#fff"
      />
      <path
        d="M69.208 88.697c0 3.046-.87 6.013-2.514 8.579a15.88 15.88 0 01-6.747 5.868l-.389.179c-1.89.867-3.524 1.617-4.761 2.636-2.152 1.771-15.642 13.405-15.642 13.405s-13.491-11.634-15.643-13.405c-1.237-1.018-2.87-1.767-4.761-2.636l-.389-.179a15.88 15.88 0 01-6.747-5.868 15.862 15.862 0 01-2.514-8.58v-3.464h60.107v3.465zM66.694 148.094a15.881 15.881 0 00-6.747-5.868l-.389-.179c-1.89-.868-20.403-8.308-20.403-8.308s-18.513 7.44-20.404 8.308l-.389.179a15.881 15.881 0 00-6.747 5.868 15.866 15.866 0 00-2.514 8.579v3.465c0 2.244 13.456 4.062 30.054 4.062 16.599 0 30.053-1.818 30.053-4.062v-3.465c0-3.046-.87-6.013-2.514-8.579z"
        fill="#FF8B85"
      />
      <path
        d="M73.777 166.564v-20.596a20.473 20.473 0 00-11.922-18.597c-1.805-.829-3.315-1.498-4.155-2.19-2.638-2.171-2.67-7.847-2.67-7.847s.032-5.676 2.67-7.846c.84-.692 2.35-1.361 4.155-2.19a20.473 20.473 0 0011.922-18.597V68.103H57.888c.8 6.997 1.073 14.975-.491 21.193-3.568 14.169-11.398 17.853-12.202 27.524-.807 9.671 9.095 25.393 10.591 32.152 1.262 5.699-5.169 14.409-5.354 21.434 13.582-.55 23.345-2.064 23.345-3.842z"
        fill="#E5C4C3"
      />
      <path
        d="M39.155 89.295c16.598 0 30.053-1.819 30.053-4.062 0-2.243-13.455-4.061-30.053-4.061-16.599 0-30.054 1.818-30.054 4.061s13.455 4.062 30.054 4.062z"
        fill="#FFA8A6"
      />
      <path
        d="M7.584 72.612s5.491 1.497 21.608 2.072c0 0-3.297 21.616 0 26.593 6.101 9.209-7.961 7.393-16.693-1.037-6.676-6.446-4.915-27.627-4.915-27.627z"
        fill="url(#hourglass-mobile_svg__paint0_linear)"
        fillOpacity={0.06}
      />
      <path
        d="M73.777 68.103H56.345l-29.347 4.216s15.635 3.316 33.248 2.539c5.895-.26 10.29-.599 13.53-.932v-5.823z"
        fill="#311E69"
      />
      <path
        d="M30.046 127.724c-.456-2.059-3.012-2.762-4.508-1.277-.663.657-1.557 1.328-2.763 1.942-4.748 2.417-10.188 5.095-12.002 9.498-1.813 4.402-3.713 18.303-2.246 19.339 1.468 1.037 13.986 2.591 18.304 1.295 3.419-1.026 5.374-21.044 3.215-30.797z"
        fill="url(#hourglass-mobile_svg__paint1_linear)"
        fillOpacity={0.06}
      />
      <path
        d="M59.33 57.797c-5.894-.543-12.795-.856-20.175-.856-7.38 0-14.284.313-20.175.856H0v8.819c0 3.31 17.53 5.996 39.154 5.996 21.626 0 39.153-2.683 39.153-5.996v-8.82H59.33z"
        fill="#774135"
      />
      <path
        d="M39.154 63.793c21.625 0 39.155-2.684 39.155-5.996 0-3.312-17.53-5.996-39.154-5.996C17.53 51.8 0 54.485 0 57.797c0 3.312 17.53 5.996 39.154 5.996z"
        fill="#A05F4D"
      />
      <path
        d="M69.132 27.638s26.552-1.364 27.355 2.162c.803 3.527-18.756 5.716-23.967 4.985-5.212-.728-3.388-7.147-3.388-7.147z"
        fill="#AD263D"
      />
      <path
        d="M105.986 91.802s.125 3.644.793 4.53c-.203.335-.582.692-1.29.82-.807.146-2.383.23-3.76.158.152-1.468-.178-4-.25-4.805l4.507-.703zM106.17 99.532c2.463-.22 5.175-.866 6.514-1.216.057.14.106.296.141.482.16.876-8.738 2.46-10.388 1.89-.443-.154-.711-.584-.83-1.14a28.54 28.54 0 004.563-.016z"
        fill="#fff"
      />
      <path
        d="M106.169 99.533a28.515 28.515 0 01-4.561.016c-.113-.525-.09-1.162.051-1.783a3.83 3.83 0 00.069-.456c1.376.072 2.952-.012 3.76-.159.707-.13 1.087-.486 1.289-.819.015.02.03.04.046.057.309.339 1.061.496 2.099.726 1.519.461 3.19-.216 3.76 1.203-1.337.349-4.049.994-6.513 1.215zM72.287 39.483s16.598 1.506 25.365 5.464c8.767 3.956 10.155 17.585 10.893 20.316.739 2.732 4.08 20.566 2.18 26.436-1.047 3.235-8.245 4.676-9.622 1.818-1.376-2.86-6.746-29.72-9.553-33.324-1.748-2.245-21.05-.349-27.907-5.181-6.858-4.83-5.05-11.004-5.05-11.004l13.694-4.525z"
        fill="#1E1240"
      />
      <path
        d="M71.478 93.456s-1.195 3.444-.89 4.512c-.311.238-.792.434-1.5.3-.806-.153-2.306-.642-3.565-1.204.668-1.315 1.273-3.796 1.496-4.573l4.46.965zM68.868 100.735c2.378.68 5.14 1.053 6.516 1.209.001.152-.007.314-.043.501-.166.876-9.038-.849-10.37-1.975-.359-.303-.455-.801-.365-1.362a28.491 28.491 0 004.262 1.627z"
        fill="#fff"
      />
      <path
        d="M68.868 100.736a28.635 28.635 0 01-4.261-1.627c.085-.531.335-1.117.69-1.646.076-.115.153-.252.228-.4 1.26.563 2.76 1.052 3.566 1.204.708.134 1.188-.062 1.499-.3a.704.704 0 00.021.07c.167.426.813.843 1.699 1.432 1.252.976 3.053.946 3.075 2.476-1.377-.157-4.14-.529-6.517-1.209z"
        fill="#1E1240"
      />
      <path
        d="M60.599 38.181s11.554 2.807 20.322 6.763c8.767 3.956 10.544 10.832 8.113 21.213-.646 2.755-8.207 21.267-10.108 27.137-1.048 3.235-11.65 3.253-13.027.394-1.377-2.86 10.848-31.245 8.917-33.5-1.849-2.16-17.88-1.683-24.738-6.514-6.858-4.83-3.177-10.97-3.177-10.97L60.6 38.182z"
        fill="#311E69"
      />
      <path
        d="M46.512 43.477C44.624 24.37 51.64 9.343 54.354 7.327c7.17-5.32 22.733.14 24.443 6.91 1.695 6.715-13.788 10.46-5.767 23.273.78 1.246 1.502 5.326-9.194 7.886-1.246.3-4.105.906-7.522.906-6.702 0-9.682-1.62-9.802-2.825z"
        fill="#FFE6E6"
      />
      <path
        d="M69.93 11.188s-2.987 12.952-3.168 15.958c-.181 3.007.659 8.514.659 8.514l1.602.803.907-1.203s-.664-6.456-.567-8.79c.098-2.335 1.515-15.428 1.515-15.428l-.948.146zM74.908 3.53s1.862-.72 1.121-2.288c-1.101-2.333-4.368-.794-4.96-.088-.88 1.053-1.139 3.268-1.139 3.268s4.082.407 4.978-.893z"
        fill="#311E69"
      />
      <path
        d="M70.978 3.302l-3.21 3.432c-.264.569 2.089 3.542 3.318 3.229.36-.092.535-.672.554-1.377l-.014-3.897-.648-1.387z"
        fill="#FF6161"
      />
      <path
        d="M71.627 5.472c-.578-.398-1.363-.95-1.363-.95s-.399 1.026.136 2.177c.282.61.738 1.046 1.235 1.316l-.008-2.543z"
        fill="#311E69"
      />
      <path
        d="M72.399 2.333l-1.883 1.806a1.594 1.594 0 00.096 2.219c.502.47.883.675 1.682.984.666.258 1.306-.172 1.744-.992.568-1.065.787-2.204.87-2.822.19-1.406-1.195-2.282-2.51-1.195z"
        fill="#FF6161"
      />
      <path
        d="M72.83 4.59s1.153.579 1.364.767c0 0-1.257.559-1.364-.767z"
        fill="#fff"
      />
      <path
        d="M72.922 6.632c-.625-.514-1.721.263-2.431-1.405-.132-.31-.015-.827.065-1.128l-.04.04a1.594 1.594 0 00.096 2.218c.502.47.883.675 1.682.984.406.157.803.057 1.15-.229-.135-.046-.33-.323-.522-.48z"
        fill="#AD263D"
      />
      <path
        d="M71.987 3.25c.044-.156.111-.303.188-.445.144-.272.296-.708-.213-.724-.739-.023-1.448 1.291-.837 1.757.455.347.75-.196.862-.589z"
        fill="#FF6161"
      />
      <path
        d="M72.271 2.906a.93.93 0 00.38 1.26l.076.038a.947.947 0 00.41.078l.199-.008a.813.813 0 00.598-.3c.042-.01.08-.012.105 0 .027.016.044.042.053.061a.843.843 0 00.002.62l.067.15a.628.628 0 00.32.326c.357.153.813-.06 1.047-.492a.613.613 0 00.072-.39.398.398 0 00-.2-.299l-.605-.325c-.184-.098-.426-.02-.58.177a.518.518 0 00-.062-.04c-.03-.014-.054-.03-.087-.039a.55.55 0 00-.003-.29.551.551 0 00-.263-.325l-.79-.425a.545.545 0 00-.739.223zm2.04 1.077c.09-.167.262-.257.385-.204.005.001.01.005.013.006l.605.326c.055.029.093.09.106.168a.437.437 0 01-.052.273c-.197.365-.576.544-.842.399a.443.443 0 01-.2-.22l-.067-.15c-.075-.168-.055-.397.052-.598zm-1.88-.992a.363.363 0 01.493-.147l.79.425a.36.36 0 01.174.215.361.361 0 01-.028.277.634.634 0 01-.533.332l-.197.008a.754.754 0 01-.698-1.11z"
        fill="#fff"
      />
      <path
        d="M50.08 53.674c-6.859-4.83-3.177-10.969-3.177-10.969l9.655-3.19c.89 5.19 2.54 10.752 5.358 11.503 5.28 1.407 14.568-1.923 16.101 3.684 1.534 5.608-.144 17.435-2.635 22.603-1.808 3.751-3.65 13.056-4.656 18.404-2.28-.208-4.281-.886-4.827-2.022-1.377-2.86 10.848-31.245 8.917-33.5-1.847-2.158-17.879-1.68-24.737-6.513z"
        fill="#1E1240"
      />
      <path
        d="M46.512 43.478C44.624 24.37 51.64 9.344 54.354 7.328c1.963-1.456 4.554-2.103 7.319-2.138-1.68 1.671-5.26 5.673-6.405 10.442-1.517 6.308-3.554 13.514-1.648 17.698 1.7 3.733 5.857 8.791 4.49 12.919-.576.034-1.176.056-1.796.056-6.702-.002-9.682-1.622-9.802-2.827z"
        fill="#FFCACA"
      />
      <path
        d="M51.385 31.181c1.709 1.972 5.388 4.687 8.892 4.95 3.505.263 8.98-.21 8.98-.21s1.566.2 3.145.517c.19.352.398.708.627 1.073.257.41.507 1.126.347 1.996-2.178.554-8.391 1.83-15.594.566-8.98-1.578-9.7-5.652-10.25-8.403-.744-3.729 1.27-8.944 1.27-8.944s.875 6.483 2.583 8.455zM84.792 17.209h16.72c.435 0 .731.351.662.786L98.545 41.1c-.069.435-.476.786-.909.786H80.914c-.435 0-.73-.351-.662-.786l3.63-23.104a.952.952 0 01.91-.786z"
        fill="#311E69"
      />
      <path
        d="M85.22 17.208h16.72c.435 0 .731.352.662.787l-3.629 23.104c-.068.434-.475.786-.909.786H81.342c-.434 0-.73-.352-.662-.786l3.631-23.104c.069-.435.476-.787.909-.787z"
        fill="#614CD4"
      />
      <path
        d="M91.718 25.785c-.332-.667-.114-1.555.486-1.98.6-.427 1.357-.23 1.689.437.331.667.114 1.554-.486 1.98-.602.424-1.357.23-1.689-.437z"
        fill="#988DFF"
      />
      <path
        d="M56.51 29.232c1.867 3.286 26.676 1.972 26.217 5.848-.46 3.876-21.288 6.374-30.815 1.512-7.818-3.989-1.906-23.063 2.168-27.4 4.073-4.337 8.744-.636 7.688 4.99-.937 4.982-6.951 12.068-5.258 15.05z"
        fill="#FF6161"
      />
      <path
        d="M51.91 36.59c-5.79-2.955-4.048-14.184-1.098-21.571.058.567.075 1.222.245 1.795.526 1.773-.198 2.496-.723 8.31-.526 5.816.527 6.407 2.037 8.51 1.51 2.102 2.563 2.365 8.344 2.287 5.782-.079 4.927 1.392 9.835.866 4.136-.443 7.634-2.987 8.63-3.775l.408.09c.677.157 1.36.34 1.991.635.522.243 1.225.668 1.145 1.342-.458 3.877-21.287 6.374-30.814 1.512z"
        fill="#AD263D"
      />
      <path
        d="M49.3 19.581l7.735 1.996 1.318.489c-.715 1.386-1.364 2.72-1.756 3.905-1.373-.039-5.673-.296-8.224-1.916.212-1.494.534-3.008.927-4.474z"
        fill="#311E69"
      />
      <path
        d="M61.766 14.181c-.606 3.234-2.75 7.636-4.14 10.945-2.015-.21-6.17-1.062-9.01-2.543 1.056-5.663 3.477-11.277 5.464-13.39 4.073-4.338 8.742-.637 7.686 4.989z"
        fill="#FFE6E6"
      />
      <path
        d="M54.08 9.192c1.062-1.132 2.164-1.71 3.195-1.858-.902.916-2.664 2.97-3.341 5.624-.927 3.636.08 4.205-.533 6.264-.42 1.41-1.542 3.239-2.305 4.406a18.611 18.611 0 01-2.48-1.046c1.056-5.663 3.477-11.277 5.464-13.39z"
        fill="#FFCACA"
      />
      <path
        d="M79.627 34.09s1.753-2.336 1.956-2.972c.202-.634-.157-1.676.606-1.194.764.483.023 1.88 0 2.336-.023.458 2.354-.32 2.837-.827.482-.508.877-1.458 1.193-.822.315.634-.244 2.336-.142 2.692.101.355-1.244 2.69-2.565 3.033-1.321.345-3.302.976-3.759 0-.456-.976-.271-2.052-.126-2.246z"
        fill="#FF6161"
      />
      <path
        d="M71.488 10.17s-.075 1.531-1.045 1.65c-.97.12-1.461-1.953-.727-2.146.734-.194 1.287-.588 1.772.496z"
        fill="#311E69"
      />
      <path
        d="M70.878 8.662c-.95-.163-1.121-1.134-1.478-1.518-.356-.383-.62-1.496-.62-1.496s-1.116.698-1.294.994c-.178.296.028.994.37 1.608.341.615 1.45 2.149 1.45 2.149s1.492-.48 1.587-.314c.095.163.744.63.744.63s.677-1.78.003-3.064c-.002 0-.54 1.049-.762 1.011z"
        fill="#FFCACA"
      />
      <defs>
        <linearGradient
          id="hourglass-mobile_svg__paint0_linear"
          x1={16.762}
          y1={69.988}
          x2={23.461}
          y2={112.621}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D4D4D" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="hourglass-mobile_svg__paint1_linear"
          x1={24.009}
          y1={126.125}
          x2={17.533}
          y2={157.121}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D4D4D" />
          <stop offset={1} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgHourglassMobile;
