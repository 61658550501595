import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useFormik } from '@ampli/utils';
import { useCreateLead } from '@ampli/services';

var useLeadForm = function useLeadForm(_ref) {
  var _onSubmit = _ref.onSubmit,
      onError = _ref.onError,
      onCompleted = _ref.onCompleted;

  var _useCreateLead = useCreateLead({
    onError: onError,
    onCompleted: onCompleted
  }),
      _useCreateLead2 = _slicedToArray(_useCreateLead, 3),
      createLead = _useCreateLead2[0],
      _useCreateLead2$ = _useCreateLead2[1],
      data = _useCreateLead2$.data,
      loading = _useCreateLead2$.loading,
      validationSchema = _useCreateLead2[2];

  var formik = useFormik({
    initialValues: {
      phone: '',
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: function onSubmit(values) {
      createLead({
        variables: {
          lead: values
        }
      });
      _onSubmit && _onSubmit(values);
    }
  });
  return {
    formik: formik,
    createLead: createLead,
    data: data,
    loading: loading
  };
};

export default useLeadForm;