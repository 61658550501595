import * as React from 'react';

function SvgParaQuemQuerLecionar(props) {
  return (
    <svg
      width="100%"
      viewBox="0 0 100 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.176 119.673c-.325 1.943-.655 4.472-.601 4.563.044.074 1.036.344 1.821.687.354.267.703.613.33.731-.637.202-4.401.29-4.77-.267a1.572 1.572 0 00-.449-.448c.377-.536-1.573-5.153-.996-5.102.002 0 4.816-1.068 4.665-.164z"
        fill="#FF6161"
      />
      <path
        d="M14.956 125.387c.369.557 4.133.469 4.77.267.371-.119.024-.464-.33-.731 1.18.516 2.634 1.183 2.693 1.543.1.6-6.75.737-7.903.182-.603-.291-.207-.957.325-1.707.167.112.322.26.445.446z"
        fill="#311E69"
      />
      <path
        d="M18.11 34.005s-.186-.907-1.313-1.33c-1.127-.421-4.207-.012-4.927 1.473-.72 1.485.371 4.184-.004 5.357s2.963 1.855 5.118 1.68c2.153-.175 4.331-.845 4.405-1.808.075-.962.93-6.27-3.278-5.372z"
        fill="#1E1240"
      />
      <path
        d="M18.436 37.62c.017-.116.017-.233.008-.349-.016-.222.015-.554.388-.415.544.202.56 1.386-.035 1.514-.44.094-.407-.46-.361-.75z"
        fill="#FF6161"
      />
      <path
        d="M19.438 43.51c-.865 1.895-.434 8.338 8.892 12.87 9.324 4.533 19.29-2.589 19.73-6.169.438-3.58-8.156 1.105-12.922 2.207-3.558.823-9.352-6.425-11.483-8.466-1.643-1.571-3.651-1.68-4.217-.442z"
        fill="#AD263D"
      />
      <path
        d="M32.112 124.236c.014-3.269 8.66-31.151.535-58.064-.027-.092-.06-.184-.087-.276-3.314 6.445-11.044 6.631-11.044 6.631 1.446 1.43 5.17 24.327 5.17 24.327s-4.276 7.401 2.172 26.22c.001.002 1.26 2.595 3.254 1.162z"
        fill="#FF6161"
      />
      <path
        d="M17.575 124.236c.014-3.27 10.37-30.7 2.246-57.613-.028-.092-7.461 3.2-7.49 3.109-3.315 6.445-.283 27.122-.283 27.122s-4.276 7.401 2.171 26.22c.002.001 1.361 2.594 3.356 1.162zM32.687 119.064c-.325 1.944-.629 5.081-.575 5.172.044.073 1.036.344 1.822.687.353.267.702.613.329.731-.637.202-4.401.29-4.77-.267a1.572 1.572 0 00-.449-.448c.377-.536-1.573-5.153-.996-5.102.002 0 4.79-1.676 4.64-.773z"
        fill="#FF6161"
      />
      <path
        d="M29.495 125.387c.369.557 4.133.469 4.77.267.371-.119.024-.464-.33-.731 1.18.516 2.634 1.184 2.693 1.543.1.601-6.75.738-7.903.182-.603-.29-.206-.956.323-1.707.167.112.322.26.447.446z"
        fill="#311E69"
      />
      <path
        d="M31.41 95.277c.582 3.868-1.215 13.501-1.398 16.599-.139 2.342 1.454 8.123.192 11.165-.228.549-1.083.57-1.347.035-6.445-18.819-2.169-26.222-2.169-26.222s-.633-3.887-1.476-8.556c1.692-.125 5.695 3.63 6.199 6.979zM14.221 123.076c-6.447-18.819-2.17-26.222-2.17-26.222S10.35 85.26 10.887 76.729c2.053 2.57 4.268 6.135 4.942 10.153 1.354 8.072 1.681 8.128.756 14.044-.817 5.231-.34 18.925-1.775 22.947-.377-.399-.59-.797-.59-.797z"
        fill="#AD263D"
      />
      <path
        d="M28.327 56.38a23.922 23.922 0 01-3.686-2.195l.414-3.93s6.175 4.52 6.57 7.258c-1.095-.232-2.2-.6-3.298-1.133zM36.598 92.563c-.73-6.57.242-15.228-3.546-26.667-.694-2.095-1.58-4-2.5-5.72-9.374 2.893-17.446 1.758-19.851 1.303-4.62 12.246-.282 28.282-.512 33.367-.046 1.03.05 2.425 1.054 2.903 9.794 4.666 19.224 2.378 24.567-.018 1.766-.794.986-3.387.788-5.168z"
        fill="#311E69"
      />
      <path
        d="M30.552 60.175c-1.873-3.495-3.9-6.209-4.794-8.09-.899-1.899.178-5.736-1.534-7.748-1.183-1.39-3.897-2.243-5.6-2.506-3.395-.525-6.623 1.856-6.738 1.972-1.08 1.088-.786 2.033-.026 3.074.825 1.131.865 2.46 1.635 4.136 1.02 2.219-.657 5.896-1.756 8.083a24.588 24.588 0 00-1.04 2.38c2.407.456 10.48 1.593 19.853-1.301z"
        fill="#D8E7EB"
      />
      <path
        d="M15.797 39.054l-.042 3.172c-.004.521 1.409 1.864 2.315 1.202.266-.194-.083-.912-.333-1.453l-.684-2.724-1.256-.197z"
        fill="#FF6161"
      />
      <path
        d="M17.46 40.87c-.748-.21-1.5-.94-1.5-.94l1.192-.29.308 1.23z"
        fill="#311E69"
      />
      <path
        d="M15.136 38.074l.399-.732 2.514-1.092c.304.485 1.143 1.415 1.004 2.488-.155 1.209-1.173 1.761-2.224 1.592-1.14-.183-1.872-1.215-1.693-2.256z"
        fill="#FF6161"
      />
      <path
        d="M16.759 38.608s.595.043 1.387-.166c0 0-.105.556-.395.769-.29.213-.699-.06-.992-.603z"
        fill="#fff"
      />
      <path
        d="M13.497 51.014c-.605-1.313-.764-2.41-1.2-3.37l1.484-1.59s1.337 3.551.008 6.142a3.592 3.592 0 00-.292-1.182z"
        fill="#311E69"
      />
      <path
        d="M16.958 39.904c.432.012 1.202-.145 1.518-.385.28-.213.53-.725.583-.839l-.004.06c-.155 1.209-1.173 1.76-2.224 1.591a2.043 2.043 0 01-1.524-1.11c.587.502 1.262.672 1.65.683z"
        fill="#AD263D"
      />
      <path
        d="M17.196 42.019s-1.802-.03-2.504 1.037c-.703 1.066 2.974 2.445 4.276 1.544 1.304-.902 1.25-3.033-1.772-2.581zM12.574 44.268C9.741 42.805-.47 56.851.016 62.7c.468 5.638 12.483 11.798 14.382 8.765 1.79-2.858-6.213-7.217-6.618-10.519-.51-4.171 9.392-14.302 4.794-16.677z"
        fill="#FF6161"
      />
      <path
        d="M14.763 37.547a.16.16 0 00.208.024c.085-.062.149-.222.61-.044.003.018.01.036.017.053.155.32.516.51.897.474.381-.034.692-.287.776-.628a.27.27 0 00.006-.079.621.621 0 01.218-.016c.004.025.01.05.022.074.155.32.516.51.897.474.38-.034.692-.287.776-.628a.267.267 0 00-.074-.25.35.35 0 00-.285-.101l-1.036.093a.367.367 0 00-.193.08.943.943 0 00-.488.043.356.356 0 00-.202-.041l-1.038.093a.349.349 0 00-.212.096c-.371-.143-.622-.088-.873.094a.127.127 0 00-.026.19zm3.024-.25c.008-.024.02-.033.034-.035l1.035-.094a.042.042 0 01.036.013.55.55 0 01-.51.431.595.595 0 01-.595-.316zm-1.919.175c.008-.024.02-.033.034-.035l1.036-.094a.042.042 0 01.035.013.55.55 0 01-.51.431.598.598 0 01-.595-.316z"
        fill="#fff"
      />
      <path
        d="M15.404 37.9a1.428 1.428 0 01-.095-.337c-.04-.218-.155-.536-.482-.323-.479.31-.199 1.467.414 1.467.453 0 .28-.532.163-.806zM13.653 71c.088-.26.209-.505.361-.74.302-.466.897-1.266 1.49-1.376.848-.157 2.822.264 2.556.468-.265.203-2.027.11-2.027.11s1.89.564 2.252.98c.36.414-.363 1.03-.848 1.147-.484.119-3.048.74-3.897.521-.58-.151-.051-.611.113-1.11z"
        fill="#FF6161"
      />
      <path
        d="M11.015 44.501c-.137 1.579-.728 4.058-2.854 6.59-3.526 4.201-5.554 7.04-5.067 9.731.486 2.69 1.5 2.58 3 4.57 1.5 1.99 3.19 4.019 4.96 4.609.94.312 2.417.823 3.394 1.37-.018.03-.03.063-.05.095C12.499 74.499.486 68.339.018 62.701c-.42-5.04 7.094-16.149 10.997-18.2z"
        fill="#AD263D"
      />
      <path
        d="M25.756 52.086c-.9-1.898.178-5.736-1.534-7.748-1.08-1.268-3.431-2.084-5.128-2.417 1.352 1.44 4.203 4.716 3.655 6.24-.71 1.985-4.532.51-4.964 2.094-.433 1.584 3.403 2.109 5.143 5.786.877 1.85 1.685 3.861 2.272 5.404a42.081 42.081 0 005.35-1.272c-1.872-3.493-3.898-6.207-4.794-8.087z"
        fill="#C3DCE3"
      />
      <path
        d="M33.052 65.896c-.695-2.095-1.58-4-2.5-5.72a42.132 42.132 0 01-5.525 1.3c.905 2.226 1.772 4.563 2.213 6.3 1.155 4.552 1.004 26.87 1.129 29.536.038.803.093 1.741.153 2.662a31.639 31.639 0 007.286-2.243c1.766-.792.986-3.385.788-5.166-.728-6.573.244-15.23-3.544-26.669z"
        fill="#1E1240"
      />
      <path
        d="M73.865 4.592l-9.89 17.676-9.945 17.65c-3.31 5.886-6.69 11.739-10.033 17.61-.145.256-.438.38-.653.28-.214-.102-.27-.39-.129-.645 3.298-5.89 6.556-11.801 9.886-17.678l9.973-17.639L73.101 4.235c3.38-5.852 4.02-5.552.764.357z"
        fill="#8C6244"
      />
      <path
        d="M45.838 48.866s1.004-1.792 2.005-1.805c1-.012 1.208.313 1.853.706.645.393 1.16.63.526.805-.635.175-1.651-.471-1.794-.199-.143.27-.687 1.7-1.453 1.709-.766.009-1.841-.836-1.137-1.216z"
        fill="#AD263D"
      />
      <path
        d="M48.44 47.958c.112-.215.467-.482.884-.51.416-.03 1.86.364 1.813.598-.047.233-.514.525-.514.525s-1.155 1.942-1.54 2.218c-.384.278-1.805-.21-1.38-.482.426-.272 1.267-1.912 1.267-1.912s-.75-.01-.53-.437z"
        fill="#AD263D"
      />
    </svg>
  );
}

export default SvgParaQuemQuerLecionar;
