import React from 'react';

import { Text } from '@ampli/ui';
import { string, node } from '@ampli/utils';

const HeaderText = ({ children, className, ...props }) => (
  <Text size="xx-large" {...props} color="neutral-dark" marginSize="base">
    {children}
  </Text>
);

HeaderText.propTypes = {
  children: node.isRequired,
  className: string,
};

export default HeaderText;
