import React from 'react';
import { string, node, sanitizeHTML } from '@ampli/utils';
import {
  Carousel as _Carousel,
  css,
  cx,
  px2rem,
  px2grid,
  mediaQuery,
  getColor,
  fontSize,
  Text,
  Paragraph,
  RichText,
} from '@ampli/ui';

const TestimonialName = ({ color, ...props }) => (
  <Paragraph
    {...props}
    className={cx(
      'item-title',
      css`
        text-align: center;
        color: ${getColor(color)};
        ${fontSize('small')}
        font-weight: bold;
        margin-bottom: 0;
      `,
      props.className
    )}
  />
);

TestimonialName.propTypes = {
  className: string,
  color: string,
};

const CourseName = ({ color, ...props }) => (
  <Paragraph
    {...props}
    className={cx(
      'item-title',
      css`
        text-align: center;
        color: ${getColor(color)};
        ${fontSize('small')}
        margin-bottom: ${px2rem(16)};
      `,
      props.className
    )}
  />
);

CourseName.propTypes = {
  className: string,
  color: string,
};

const StudentImage = ({ children, ...props }) => (
  <div
    {...props}
    className={cx(
      'student-image',
      css`
        margin-top: ${px2grid(40)};
        margin-bottom: ${px2grid(8)};
        img {
          border-radius: 50%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          height: ${px2grid(60)};
          width: ${px2grid(60)};
        }
      `,
      props.className
    )}
  >
    {children}
  </div>
);

StudentImage.propTypes = {
  className: string,
  children: node.isRequired,
};

const TestimonialItem = ({ children, ...props }) => (
  <Text
    {...props}
    className={cx(
      'testimonial-item',
      css`
        ${fontSize('base')}
        font-weight: lighter;
        display: block;

        ${mediaQuery('desktop')} {
          width: ${px2grid(352)};
          padding: 0;
          display: inline;
        }
      `,
      props.className
    )}
  >
    <RichText>{children}</RichText>
  </Text>
);

TestimonialItem.propTypes = {
  className: string,
  children: node.isRequired,
};

const StudentTestimonial = ({ imgUrl, name, course, testimonial }) => {
  return (
    <div
      className={css`
        padding-right: ${px2grid(20)};
        padding-left: ${px2grid(40)};

        ${mediaQuery('desktop')} {
          padding: 0;
        }
      `}
    >
      <StudentImage>
        <img alt="Estudante" src={imgUrl} width={100} height={100} />
      </StudentImage>
      <TestimonialName color={getColor('neutral-text')}>{name}</TestimonialName>
      <CourseName color={getColor('neutral-text')}>
        Estudante do curso de {course}
      </CourseName>
      <TestimonialItem>{testimonial}</TestimonialItem>
    </div>
  );
};

StudentTestimonial.propTypes = {
  imgUrl: string,
  name: string,
  course: string,
  testimonial: string,
};

export default StudentTestimonial;
