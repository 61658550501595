import React from 'react';
import { useLocation } from 'react-router-dom';

import { useCountEnrolledCandidates } from '@ampli/services';
import { Modal, useSubscriptionModal } from '@ampli/registration';
import { useScroller } from '@ampli/hooks';
import {
  Helmet,
  Header,
  Footer,
  BannerHomeCourse,
  AmpliProposal,
  WhyAmpli,
  CountStudents,
  BannerMobileApp,
  HomeTestimonials,
  CTAWhatsapp,
  DegreeCards,
} from '../components';
import { css, px2grid, mediaQuery } from '@ampli/ui';

import { SCROLLER_SETTINGS, CONTACT } from '../constants';
import {
  ampli,
  entranceExamUrl,
  recaptchaSiteKey,
  googleAPIKey,
  baseUrl,
} from '../config';

import mockedTestimonialList from '../components/social-proof/testimonialList';

const Home = () => {
  const { pathname } = useLocation();
  const canonicalUrl = baseUrl + pathname;
  const subscriptionModal = useSubscriptionModal();

  const { ref: homeRef, scroller: homeScroller } = useScroller(
    SCROLLER_SETTINGS
  );
  const {
    ref: ampliProposalRef,
    scroller: ampliProposalScroller,
  } = useScroller(SCROLLER_SETTINGS);
  const { ref: contactRef, scroller: contactScroller } = useScroller(
    SCROLLER_SETTINGS
  );

  const { data: enrolledCandidatesCount } = useCountEnrolledCandidates();

  const defaultComponentBottomMargin = ({
    desktop = 150,
    mobile = 80,
  } = {}) => css`
    margin-bottom: ${px2grid(mobile)};

    ${mediaQuery('desktop')} {
      margin-bottom: ${px2grid(desktop)};
    }
  `;

  return (
    <div ref={homeRef}>
      <Helmet
        title="Faculdade Ampli: Cursos Superiores a Distância"
        description="Graduação EAD, Especialização e MBA online. Estude pelo computador ou pelo APP Ampli. Educação a distância de qualidade ao alcance de todos. Matricule-se!"
        robots="robots"
        canonical={canonicalUrl}
      />
      <Header
        pageTopScroller={homeScroller}
        contactScroller={contactScroller}
        onSubscribeClick={subscriptionModal.show}
      />
      <BannerHomeCourse
        CTARedirectionURL="/graduacao"
        onClickScroll={ampliProposalScroller}
      />
      <div
        ref={ampliProposalRef}
        className={css`
          scroll-margin-top: ${px2grid(80)};

          ${defaultComponentBottomMargin()}
        `}
      >
        <AmpliProposal />
      </div>
      <WhyAmpli
        className={css`
          ${defaultComponentBottomMargin()}
        `}
      />
      <DegreeCards
        className={css`
          ${defaultComponentBottomMargin()}
        `}
      />
      <HomeTestimonials
        testimonials={mockedTestimonialList}
        className={css`
          ${defaultComponentBottomMargin({ desktop: 100 })}
        `}
      />
      <CountStudents
        count={enrolledCandidatesCount}
        redirectURL="/cursos"
        className={css`
          ${defaultComponentBottomMargin()}
        `}
      />
      <BannerMobileApp
        onClick={subscriptionModal.show}
        className={css`
          ${defaultComponentBottomMargin({ desktop: 100 })}
        `}
      />
      <div ref={contactRef}>
        <CTAWhatsapp onClick={() => window?.open(CONTACT.WHATSAPP, '_blank')} />
      </div>
      <Footer />

      <Modal
        {...subscriptionModal}
        isModal
        ampli={ampli}
        entranceExamUrl={entranceExamUrl}
        formSettings={{ recaptchaSiteKey, googleAPIKey }}
      />
    </div>
  );
};

export default Home;
