import React from 'react';
import { Link } from 'react-router-dom';

import {
  css,
  cx,
  px2grid,
  getColor,
  fontSize,
  px2rem,
  mediaQuery,
  Flex,
  Icon,
  Text,
  Button,
} from '@ampli/ui';
import { string, node, bool, func } from '@ampli/utils';

const DescriptionIcon = ({ icon, className, ...props }) => {
  const SelectedIcon = Icon[icon];
  return (
    <div
      {...props}
      className={cx(
        'description-icon',
        css`
          filter: brightness(0) invert(1);
          margin-right: ${px2grid(20)};
          margin-top: ${px2rem(2)};

          width: ${px2grid(24)};
          height: ${px2grid(24)};

          svg {
            width: ${px2grid(24)};
            height: ${px2grid(24)};
          }

          ${mediaQuery('tablet-min')} {
            margin-top: ${px2rem(4)};
          }
        `,
        className
      )}
    >
      {icon && <SelectedIcon />}
    </div>
  );
};

DescriptionIcon.propTypes = {
  icon: string,
  className: string,
};

const Title = ({ children, active, onClick, className, ...props }) => (
  <Button
    onClick={onClick}
    className={cx(
      'title-button',
      css`
        padding: 0 !important;
        text-align: left !important;
      `,
      className
    )}
  >
    <Text
      {...props}
      as="h1"
      color="text-white"
      size="xxx-large"
      className={cx(
        'title-text',
        css`
          width: 100%;
          font-weight: 300;

          &.active {
            padding-bottom: ${px2grid(8)};
            border-bottom: ${px2rem(2)} solid ${getColor('accent-base')};
          }

          ${mediaQuery('desktop')} {
            ${fontSize('xxx-large')}
            max-width: ${px2grid(370)};
            margin-top: 0;
          }
        `,
        active && 'active'
      )}
    >
      {children}
    </Text>
  </Button>
);

Title.propTypes = {
  className: string,
  active: bool,
  onClick: func,
  children: node.isRequired,
};

const Description = ({ children, className, ...props }) => (
  <Text
    {...props}
    as="h3"
    color="text-white"
    size="small"
    className={cx(
      'description',
      css`
        margin: ${px2grid(8)} 0;
        max-width: ${px2grid(200)};
        font-weight: 300;

        ${mediaQuery('tablet-min')} {
          max-width: ${px2grid(225)};
          ${fontSize('medium')}
        }
      `,
      className
    )}
  >
    {children}
  </Text>
);

Description.propTypes = {
  className: string,
  children: node.isRequired,
};

const ViewLink = ({ to, children, className, ...props }) => (
  <Link {...props} to={to}>
    <Text
      color="text-white"
      size="medium"
      className={cx(
        'view-link',
        css`
          text-decoration: underline;
          font-weight: 200;
        `,
        className
      )}
    >
      {children} &gt;
    </Text>
  </Link>
);

ViewLink.propTypes = {
  to: string,
  children: node.isRequired,
  className: string,
};

const CourseDescription = ({
  icon,
  title,
  color,
  active,
  onClickTitle,
  viewMoreUrl,
  className,
  children,
  ...props
}) => (
  <Flex alignItems="flex-start" justifyContent="flex-start" direction="row">
    <div className={cx('course-description', className)}>
      <DescriptionIcon icon={icon} />
      <Flex
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="column"
      >
        <div
          {...props}
          className={cx(
            'course-description-content',
            css`
              color: ${getColor(color || 'text-white')};
              width: ${px2grid(225)};
            `
          )}
        >
          <Title active={active} onClick={onClickTitle}>
            {title}
          </Title>
          {active && (
            <>
              <Description>{children}</Description>
              <ViewLink rel="canonical" to={viewMoreUrl}>
                Ver todos
              </ViewLink>
            </>
          )}
        </div>
      </Flex>
    </div>
  </Flex>
);

CourseDescription.propTypes = {
  icon: string,
  active: bool,
  onClickTitle: func,
  title: string,
  color: string,
  viewMoreUrl: string,
  className: string,
  children: node.isRequired,
};

export default CourseDescription;
