import React, { useState } from 'react';

import {
  cx,
  css,
  ColorNeutralDark,
  px2rem,
  boxShadow,
  Button,
  Text,
  fontSize,
  mediaQuery,
  getSpaceSize,
  px2grid,
} from '@ampli/ui';
import LabelTypeCourse from './label-type-course';
import { string, arrayOf, shape, oneOf } from '@ampli/utils';

import { PATHNAME_BY_DEGREE_TYPE } from '../../constants';

function resolveCourseUrl(degreeType, courseUrlCode) {
  return (
    degreeType &&
    courseUrlCode &&
    `${PATHNAME_BY_DEGREE_TYPE[degreeType].pathname}/${courseUrlCode}`
  );
}

const SimpleCourseCard = ({
  name,
  courseType,
  image,
  degreeType,
  urlCode,
  ...props
}) => {
  const [courseUrl] = useState(resolveCourseUrl(degreeType, urlCode));

  return (
    <div
      className={cx(
        'course-card-wrap',
        css`
          background-color: white;
          border-radius: ${px2rem(8)};
          padding: ${getSpaceSize('small')};
          padding-bottom: 0;
          width: ${px2grid(165)};
          margin-right: ${getSpaceSize('small')};
          margin-bottom: ${getSpaceSize('small')};
          ${boxShadow('card')};
          display: flex;
          flex-direction: column;
          align-items: center;

          &.noMargin {
            margin-right: 0;
          }
        `,
        props.className
      )}
      {...props}
    >
      <div
        className={cx(
          'content-wrap-course',
          css`
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
          `
        )}
      >
        <LabelTypeCourse label={courseType?.name} />
        <a
          href={courseUrl}
          className={css`
            &:hover {
              text-decoration: none;
            }
          `}
        >
          <div
            className={cx(
              'img-course',
              css`
                background: url(${require('../../assets/image-background-card.png')})
                    center center no-repeat,
                  url(${image?.baseUrl
                      ? `${image?.baseUrl}/${
                          image?.formats?.includes('mobile')
                            ? 'mobile'
                            : 'thumbnail'
                        }`
                      : require('../../assets/image-mock-card.webp')})
                    center center no-repeat;
                height: ${px2grid(200)};
                width: ${px2grid(150)};
                border-radius: ${px2rem(8)};
                display: flex;
                align-items: flex-end;
                cursor: pointer;
                ${mediaQuery('desktop')} {
                  background: url(${require('../../assets/image-background-card.png')})
                      center center no-repeat,
                    url(${image?.baseUrl &&
                      image?.formats?.includes('thumbnail')
                        ? `${image?.baseUrl}/thumbnail`
                        : require('../../assets/image-mock-card.webp')})
                      center center no-repeat;
                }
              `
            )}
          >
            <Text
              color="white"
              as="h3"
              className={cx(
                'text-course',
                fontSize('large'),
                css`
                  margin-left: ${getSpaceSize('xx-small')};
                  margin-bottom: ${getSpaceSize('small')};
                  max-width: 90%;
                `
              )}
            >
              {name}
            </Text>
          </div>
        </a>
      </div>

      <Button
        as="a"
        className={cx(
          'button-more-card',
          fontSize('x-small'),
          css`
            color: ${ColorNeutralDark};
            font-weight: 500;
          `
        )}
        size="micro"
        href={courseUrl}
        fluid
      >
        {'Saber mais >'}
      </Button>
    </div>
  );
};

SimpleCourseCard.propTypes = {
  name: string,
  courseType: shape({ code: string, name: string }),
  image: shape({ formats: arrayOf(string), baseUrl: string }),
  degreeType: oneOf(Object.keys(PATHNAME_BY_DEGREE_TYPE)),
  urlCode: string,
  className: string,
};

export default SimpleCourseCard;
