import React from 'react';

import { RichText } from '@ampli/ui';
import { string } from '@ampli/utils';

import CourseContent from './course-content';

const ContentLoader = ({ icon, title, content, ...props }) => {
  return content ? (
    <CourseContent icon={icon} title={title} {...props}>
      <RichText>{content}</RichText>
    </CourseContent>
  ) : (
    <div />
  );
};

ContentLoader.propTypes = {
  content: string,
};

ContentLoader.displayName = 'ContentLoader';

export default ContentLoader;
