import * as React from 'react';

function SvgCountStudentIllustration(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 267 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M79.703 41.076s21.767 36.752 15.02 49.856c-6.745 13.115-28.812 1.463-26.109-22.672 2.68-24.135 11.089-27.184 11.089-27.184z"
        fill="#AD263D"
      />
      <path
        d="M99.188 49.524S74.862 84.68 80.744 93.88c5.882 9.202 23.473-16.85 25.578-31.607 2.105-14.756-7.134-12.749-7.134-12.749z"
        fill="#FF6161"
      />
      <path
        d="M8.386 97.45c.277-.432 41.95-4.456 51.278-7.416-.011.044-.011.077-.011.077 39.203 105.254-3.401 178.357-20.294 179.854-19.231 1.707 3.09-68.281 4.486-73.491 4.763-17.894 7.034-32.106-2.304-48.071-6.88-11.751-1.263 119.755-26.431 116.895-15.94-1.819-17.78-28.747-1.584-74.445 3.833-10.843-23.983-64.755-5.14-93.403z"
        fill="#FF8F8F"
      />
      <path
        d="M33.222 135.144c10.224-5.909-16.617-17.771-30.441-23.259-1.186 6.264-1.23 13.015-.61 19.867 6.614 3.359 20.926 9.246 31.05 3.392zM87.668 56.43c-4.01-8.669-7.965-15.343-7.965-15.343s-8.397 3.05-11.089 27.184c-.908 8.093.986 14.778 4.132 19.6 3.19-4.356 12.33-17.748 14.922-31.44z"
        fill="#311E69"
      />
      <path
        d="M41.153 147.893c-1.75-2.228-8.452-5.288-11.897 4.18-3.922 10.798.61 26.175-1.839 42.039-2.448 15.854.631 10.466-5.816 34.013-5.417 19.811-9.748 29.445-7.045 37.084.177.022.355.066.543.088 24.68 2.805 19.773-123.601 26.054-117.404zM59.653 90.111s0-.033.01-.077c-3.112.986-7.3 2.14-12.373 3.126-.543 14.712-.388 37.383 4.741 49.345 7.987 18.648 15.475 41.629 12.097 51.607-3.379 9.967-15.808 30.089-18.566 46.773-1.706 10.289-4.73 21.031-3.9 28.503 18.08-7.228 55.521-78.513 17.99-179.277z"
        fill="#D95F76"
      />
      <path
        d="M59.032 12.94s3.49-2.584 2.46-5.92c-1.03-3.338-5.351-7.64-9.184-6.01-1.595.677-2.537 1.586-3.09 2.406-.798 1.186-1.718 2.273-2.825 3.182a5.069 5.069 0 00-1.54 2.184c-.975 2.705-.709 9.301-.709 9.301s12.761-.71 14.888-5.144z"
        fill="#311E69"
      />
      <path
        d="M48.342 4.602c3.213 4.413 9.505 5.81 12.562 6.242a8.046 8.046 0 01-1.872 2.095c-2.127 4.435-14.877 5.144-14.877 5.144s-.266-6.596.71-9.301a4.978 4.978 0 011.539-2.184c.72-.588 1.351-1.264 1.938-1.996z"
        fill="#1E1240"
      />
      <path
        d="M1.152 117.594C.91 91.73-1.717 53.593 9.24 35.223c7.09-11.896 24.802-11.707 35.957-10.144 9.173 1.286 29.068 6.829 34.152 15.41 4.509 7.594 1.042 14.711-1.329 20.864-2.725 7.062-4.918 13.315-6.79 25.377-2.515 16.097.587 41.551.21 42.394-11.432 12.261-63.474 7.638-70.287-11.53z"
        fill="#614CD4"
      />
      <path
        d="M46.836 14.136l-8.22 12.107c-.531 1.873 8.131 9.9 11.765 8.337 1.063-.455 1.318-2.328 1.04-4.501l-1.927-11.985-2.658-3.958z"
        fill="#FF6161"
      />
      <path
        d="M49.882 20.5c-1.972-.942-4.652-2.262-4.652-2.262s-.731 3.349 1.473 6.641a8.879 8.879 0 004.442 3.448L49.882 20.5z"
        fill="#311E69"
      />
      <path
        d="M50.735 10.478l-4.918 6.474a4.96 4.96 0 001.373 6.785c1.773 1.209 3.035 1.652 5.65 2.218 2.171.476 3.932-1.164 4.885-3.892 1.23-3.547 1.352-7.161 1.318-9.102-.1-4.445-4.785-6.474-8.308-2.483z"
        fill="#FF6161"
      />
      <path
        d="M53.26 17.307s1.574 1.031 4.122 1.574c0 0-.488 1.608-1.64 1.83-1.152.21-2.559-1.309-2.481-3.404z"
        fill="#fff"
      />
      <path
        d="M54.424 23.45c-2.171-1.276-5.173 1.64-8.153-3.15-.554-.897-.443-2.538-.343-3.503l-.111.145a4.96 4.96 0 001.373 6.784c1.773 1.209 3.035 1.652 5.65 2.218 1.33.288 2.492-.211 3.423-1.264-.443-.078-1.174-.843-1.84-1.23z"
        fill="#AD263D"
      />
      <path
        d="M49.915 13.494c.056-.5.2-.987.366-1.464.31-.909.565-2.317-.997-2.128-2.282.288-3.833 4.667-1.728 5.809 1.562.854 2.204-.965 2.36-2.217z"
        fill="#FF6161"
      />
      <path
        d="M59.099 19.569a1.13 1.13 0 01-.322-.078l-.42-.155c-.61-.222-.986-.898-1.03-1.84l-.078-1.597c-.023-.377.055-.809.21-1.22.177-.476.443-.886.742-1.174.377-.355.809-.477 1.186-.333l2.226.82c.41.156.665.544.731 1.12.056.466-.022 1.009-.221 1.541-.665 1.863-1.928 3.038-3.024 2.916zm-.011-5.532c-.122-.011-.51.31-.754.964-.11.3-.166.61-.155.865l.078 1.596c.022.533.188.932.432 1.02l.42.156c.555.2 1.607-.732 2.183-2.284.144-.4.21-.798.166-1.12-.033-.255-.11-.355-.144-.366l-2.227-.831zM52.862 17.23a1.85 1.85 0 01-.487-.122l-.599-.222c-1.506-.554-2.138-2.616-1.406-4.6.454-1.22 1.562-1.897 2.514-1.542l3.202 1.186c.432.156.764.544.908 1.043.133.454.1.986-.078 1.485-.155.432-.432.82-.764 1.075l-1.351 1.076c-.62.488-1.308.698-1.939.62zm-.443-5.654c-.443-.056-.964.388-1.207 1.03-.554 1.497-.167 3.038.875 3.426l.598.222c.465.166 1.019.044 1.529-.355l1.35-1.075c.2-.155.367-.4.477-.676.111-.31.133-.643.067-.92-.033-.134-.133-.355-.344-.433l-3.201-1.186c-.044-.011-.089-.022-.144-.033z"
        fill="#311E69"
      />
      <path
        d="M57.935 15.101a.455.455 0 01-.343-.222c-.332-.587-.809-.377-.864-.354a.46.46 0 01-.61-.211.462.462 0 01.211-.61c.443-.21 1.451-.343 2.05.72a.462.462 0 01-.167.622.419.419 0 01-.277.055z"
        fill="#311E69"
      />
      <path
        d="M115.993 115.166V98.049H101.082v17.117a6.961 6.961 0 01-2.77 5.555l-1.716 1.286a6.516 6.516 0 00-2.603 5.221v10.865H123.104v-10.865c0-2.051-.963-3.98-2.603-5.221l-1.717-1.286a6.936 6.936 0 01-2.791-5.555z"
        fill="#614CD4"
      />
      <path
        d="M115.993 98.05H101.082v14.267c3.778-.731 10.247-2.128 14.911-3.913V98.049z"
        fill="#311E69"
      />
      <path d="M236.028 88.349h-36.234v47.117h36.234V88.349z" fill="#B1A9FF" />
      <path d="M212.865 88.349h-72.469v47.117h72.469V88.349z" fill="#B1A9FF" />
      <path
        d="M152.471 104.834l-.056-9.501h2.881c1.761 0 3.179 2.095 3.179 4.756s-1.407 4.756-3.168 4.756h-2.836v-.011z"
        fill="#AD263D"
      />
      <path
        d="M152.438 104.834c1.761 0 3.19-2.128 3.19-4.756 0-2.627-1.429-4.756-3.19-4.756-1.762 0-3.191 2.129-3.191 4.756 0 2.628 1.44 4.756 3.191 4.756z"
        fill="#FF6161"
      />
      <path
        d="M165.62 102.716l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.639 0 1.474-.786 2.638-1.761 2.638h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M165.609 102.716c.974 0 1.761-1.175 1.761-2.638 0-1.464-.787-2.639-1.761-2.639-.975 0-1.762 1.175-1.762 2.639 0 1.463.776 2.638 1.762 2.638z"
        fill="#FF6161"
      />
      <path
        d="M173.784 102.716l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.639 0 1.474-.786 2.638-1.761 2.638h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M173.773 102.716c.973 0 1.761-1.181 1.761-2.638 0-1.457-.788-2.639-1.761-2.639-.973 0-1.762 1.182-1.762 2.639 0 1.457.789 2.638 1.762 2.638z"
        fill="#FF6161"
      />
      <path
        d="M181.959 102.716l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.639 0 1.474-.786 2.638-1.761 2.638h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M181.937 102.716c.975 0 1.761-1.175 1.761-2.638 0-1.464-.786-2.639-1.761-2.639-.975 0-1.761 1.175-1.761 2.639 0 1.463.786 2.638 1.761 2.638z"
        fill="#FF6161"
      />
      <path
        d="M190.123 102.716l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.639 0 1.474-.786 2.638-1.761 2.638h-1.562v-.011z"
        fill="#FF9D47"
      />
      <path
        d="M190.112 102.716c.975 0 1.761-1.175 1.761-2.638 0-1.464-.786-2.639-1.761-2.639-.975 0-1.761 1.175-1.761 2.639 0 1.463.786 2.638 1.761 2.638z"
        fill="#FFDA64"
      />
      <path
        d="M198.298 102.716l-.033-5.266h1.595c.975 0 1.762 1.164 1.762 2.639 0 1.474-.787 2.638-1.762 2.638h-1.562v-.011z"
        fill="#FF9D47"
      />
      <path
        d="M198.276 102.716c.973 0 1.761-1.181 1.761-2.638 0-1.457-.788-2.639-1.761-2.639-.973 0-1.761 1.182-1.761 2.639 0 1.457.788 2.638 1.761 2.638z"
        fill="#FFDA64"
      />
      <path
        d="M165.62 114.845l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.638 0 1.475-.786 2.639-1.761 2.639h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M165.609 114.845c.974 0 1.761-1.175 1.761-2.639 0-1.452-.787-2.638-1.761-2.638-.975 0-1.762 1.175-1.762 2.638-.011 1.464.776 2.639 1.762 2.639z"
        fill="#FF6161"
      />
      <path
        d="M173.784 114.845l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.639 0 1.474-.786 2.638-1.761 2.638h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M173.773 114.856c.973 0 1.761-1.181 1.761-2.638 0-1.458-.788-2.639-1.761-2.639-.973 0-1.762 1.181-1.762 2.639 0 1.457.789 2.638 1.762 2.638z"
        fill="#FF6161"
      />
      <path
        d="M181.959 114.845l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.638 0 1.475-.786 2.639-1.761 2.639h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M181.937 114.845c.975 0 1.761-1.175 1.761-2.639 0-1.452-.786-2.638-1.761-2.638-.975 0-1.761 1.175-1.761 2.638 0 1.464.786 2.639 1.761 2.639z"
        fill="#FF6161"
      />
      <path
        d="M190.123 114.845l-.033-5.266h1.595c.975 0 1.761 1.164 1.761 2.638 0 1.475-.786 2.639-1.761 2.639h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M190.112 114.845c.975 0 1.761-1.175 1.761-2.639 0-1.452-.786-2.638-1.761-2.638-.975 0-1.761 1.175-1.761 2.638-.011 1.464.786 2.639 1.761 2.639z"
        fill="#FF6161"
      />
      <path
        d="M198.298 114.845l-.033-5.266h1.595c.975 0 1.762 1.164 1.762 2.639 0 1.474-.787 2.638-1.762 2.638h-1.562v-.011z"
        fill="#AD263D"
      />
      <path
        d="M198.276 114.856c.973 0 1.761-1.181 1.761-2.638 0-1.458-.788-2.639-1.761-2.639-.973 0-1.761 1.181-1.761 2.639 0 1.457.788 2.638 1.761 2.638z"
        fill="#FF6161"
      />
      <path
        d="M209.608 103.914l9.483 30.665h-20.372l-9.017-30.665h19.906z"
        fill="#614CD4"
      />
      <path
        d="M209.608 103.914l5.163 16.685c-2.127-.577-5.251-1.541-7.345-2.683-3.423-1.863-3.423-2.184-6.059-1.552-2.648.621-4.398 2.184-5.927-.466-.376-.643-1.329-1.009-2.559-1.175l-3.179-10.809h19.906z"
        fill="#311E69"
      />
      <path
        d="M156.592 105.477h88.165c4.409 0 8.718-3.614 9.56-8.027l11.909-61.085c.841-4.413-2.072-8.027-6.481-8.027h-88.166c-4.408 0-8.718 3.614-9.559 8.027L150.111 97.45c-.853 4.413 2.061 8.027 6.481 8.027z"
        fill="#311E69"
      />
      <path
        d="M152.548 105.477h88.166c4.409 0 8.718-3.614 9.56-8.027l11.908-61.085c.842-4.413-2.071-8.027-6.48-8.027h-88.166c-4.409 0-8.718 3.614-9.56 8.027L146.068 97.45c-.853 4.413 2.06 8.027 6.48 8.027z"
        fill="#614CD4"
      />
      <path
        d="M163.936 34.447c.055-.266.343-.51.609-.51h91.977a.42.42 0 01.409.51l-11.243 57.948c-.056.266-.344.51-.609.51h-91.977a.42.42 0 01-.41-.51l11.244-57.948z"
        fill="#311E69"
      />
      <path
        d="M238.532 86.486s-.776-7.162-7.777-9.335c-7.001-2.184-13.68 3.426-20.061 1.397-6.381-2.018-12.905-30.798-26.431-30.798-15.863 0-20.061 38.736-20.061 38.736h74.33z"
        fill="#B1A9FF"
      />
      <path
        d="M167.78 86.486s2.016-16.03 11.044-16.807c9.017-.776 13.371 4.668 18.5 3.115 5.128-1.552 8.706-15.72 18.344-13.38 9.637 2.338 15.863 21.784 15.863 27.072H167.78zM220.088 44.435h26.94l.488-1.862h-26.941l-.487 1.862zM218.227 51.553h26.94l.488-1.863h-26.941l-.487 1.863z"
        fill="#B1A9FF"
      />
      <path
        d="M197.597 99.6c.119-1.478-.749-2.753-1.938-2.849-1.189-.096-2.249 1.024-2.368 2.5-.119 1.478.748 2.753 1.937 2.849 1.189.096 2.25-1.024 2.369-2.5z"
        fill="#311E69"
      />
      <path
        d="M149.114 137.627c-.886 0-1.861-.066-2.946-.21-11.665-1.564-24.914-40.133-25.479-41.774a.975.975 0 01.61-1.241.973.973 0 011.24.61c3.7 10.853 15.188 39.289 23.883 40.464 7.533 1.009 9.117-1.973 11.122-5.764 1.019-1.918 2.072-3.903 3.977-5.466 5.661-4.634 12.961-3.692 13.271-3.647a.994.994 0 01.842 1.108c-.078.532-.576.92-1.108.843-.066-.011-6.779-.854-11.764 3.226-1.606 1.319-2.559 3.126-3.49 4.867-1.816 3.437-3.7 6.984-10.158 6.984z"
        fill="#614CD4"
      />
      <path
        d="M78.019 61.342c2.382-6.153 5.838-13.27 1.33-20.864C75.802 34.502 65.068 30 55.774 27.373c1.374 3.404 2.437 7.251 2.393 10.943-.11 10.144-4.331 8.957-4.918 26.396-.587 17.44 4.43 20.466.853 38.004-.377 1.863-28.724 6.841-28.902 8.725-.985 10.288 23.928 9.789 22.909 13.925-1.319 5.354-.853 6.164-1.407 10.543 10.878-.078 20.526-2.295 24.714-6.796.376-.843-2.714-26.297-.2-42.394 1.895-12.062 4.077-18.315 6.802-25.377z"
        fill="#311E69"
      />
      <path
        d="M158.153 137.627c-.886 0-1.872-.066-2.946-.21-11.576-1.553-32.125-42.306-33-44.035a.988.988 0 01.432-1.32.986.986 0 011.318.433c5.794 11.552 22.742 41.795 31.505 42.97 7.532 1.009 9.116-1.973 11.121-5.765 1.02-1.917 2.072-3.902 3.977-5.465 5.661-4.634 12.961-3.692 13.271-3.648a.995.995 0 01.842 1.109c-.078.532-.587.92-1.108.843-.066-.011-6.779-.854-11.764 3.226-1.606 1.319-2.559 3.126-3.489 4.867-1.817 3.448-3.7 6.995-10.159 6.995z"
        fill="#278976"
      />
      <path
        d="M166.229 137.627c-.886 0-1.861-.066-2.947-.211-11.487-1.541-39.79-42.183-40.997-43.912a.979.979 0 111.606-1.12c8.031 11.585 30.817 41.906 39.646 43.092 7.533 1.009 9.117-1.973 11.122-5.765 1.019-1.917 2.071-3.902 3.977-5.465 5.66-4.634 12.96-3.692 13.271-3.647a.993.993 0 01.841 1.108c-.077.532-.576.92-1.107.843-.067-.011-6.78-.854-11.765 3.226-1.606 1.319-2.558 3.126-3.489 4.867-1.817 3.437-3.7 6.984-10.158 6.984z"
        fill="#FF6161"
      />
      <path
        d="M262.326 133.337H35.448v14.966h226.878v-14.966z"
        fill="#311E69"
      />
      <path
        d="M41.441 141.33h-5.993v149.244h5.993V141.33zM256.324 290.577h5.993V141.333h-5.993v149.244z"
        fill="#311E69"
      />
      <path
        d="M257.364 218.746l4.231-5.211-64.593-79.345-4.242 5.211 64.604 79.345zM40.4 218.746l-4.232-5.211 64.593-79.345 4.243 5.211L40.4 218.746zM116.546 62.606s-13.88-3.015-22.941 2.616c-9.05 5.644-15.497 28.792-9.05 35.643 6.436 6.851 26.154 4.823 36.622-3.026 10.457-7.85 10.856-34.024-4.631-35.233z"
        fill="#311E69"
      />
      <path
        d="M121.443 64.058c.465 7.628-2.238 16.02-7.422 19.911-8.585 6.442-23.385 8.947-31.992 5.865-.332 4.645.377 8.747 2.526 11.031 6.436 6.851 26.154 4.823 36.622-3.027 9.194-6.906 10.612-27.981.266-33.78z"
        fill="#1E1240"
      />
      <path
        d="M102.722 70.41c-3.058 1.143-5.838-2.128-4.265-4.977 1.44-2.605 3.379-5.133 5.893-6.474 4.553-2.44 11.51-1.453 14.955 1.507 1.972 1.686 1.13 4.923-1.396 5.51-3.667.843-9.671 2.373-15.187 4.435zM91.09 78.992c-.963 2.228-2.924 3.514-4.375 2.882-1.451-.632-1.85-2.949-.886-5.166.963-2.228 2.924-3.515 4.375-2.883 1.451.632 1.85 2.95.886 5.167zM103.198 84.557c-.964 2.228-2.924 3.514-4.375 2.882-1.452-.631-1.85-2.948-.887-5.166.964-2.228 2.925-3.514 4.376-2.882 1.451.62 1.85 2.938.886 5.166zM119.183 99.191c-6.89 1.619-13.304 2.372-19.397 1.641-.952-.111-1.761-.71-1.783-1.674v-.011c-.111-3.47-1.407-7.705-8.286-10.266-3.312-1.242-5.871-.72-7.621.144-.477 4.989.188 9.423 2.448 11.84 2.614 2.783 7.433 4.102 12.927 4.157v-.011c7.211.078 15.586-2.017 21.712-5.82z"
        fill="#fff"
      />
      <path
        d="M96.984 64.712s-4.531 2.162-5.583 3.725c-1.053 1.564-2.338 3.825-.986 3.304 1.362-.532 2.115-4.146 4.154-3.47 2.038.676 2.658 3.392 3.434 3.016.786-.377-1.917-5.111-1.02-6.575zM102.644 71.819s12.075-1.818 15.165-3.925c3.091-2.117 3.468-2.871 4.753-.754 1.285 2.118 3.622 6.264 3.323 8.304-.299 2.04-9.283.222-13.504-.676-4.231-.92-9.737-2.95-9.737-2.95zM93.694 95.765c-1.817-2.45-7.245-4.978-9.86-4.567-.841.133-1.44.476-1.883.898 0 .853.045 1.674.133 2.45a.645.645 0 00.1-.278c.011-.166.133-1.618 1.828-1.873 1.96-.3 7.067 1.885 8.707 4.102.742 1.009 1.252 4.579 1.54 8.348.398.044.808.077 1.218.111-.266-3.692-.786-7.85-1.783-9.19z"
        fill="#614CD4"
      />
      <path
        d="M101.791 82.894c-.387 0-.764-.189-.997-.532-3.101-4.545-.764-12.75-.664-13.104a1.2 1.2 0 011.495-.82c.643.188 1.008.853.82 1.496-.598 2.062-1.695 8.07.343 11.064a1.198 1.198 0 01-.321 1.674 1.2 1.2 0 01-.676.222zM89.44 76.852c-.477 0-.92-.277-1.119-.743-.255-.62.033-1.32.654-1.574 4.21-1.74 10.08-8.071 10.136-8.138a1.207 1.207 0 111.772 1.641c-.255.277-6.292 6.785-10.989 8.725a1.274 1.274 0 01-.454.089z"
        fill="#fff"
      />
      <path
        d="M15.409 33.904c14.822.953 4.808 46.096 16.018 56.13 9.062 8.104 44.044 4.745 51.311 14.035 7.267 9.29-52.618 21.918-69.29 6.219C-5.25 92.661-3.323 32.695 15.41 33.903z"
        fill="#FF6161"
      />
      <path
        d="M82.738 104.069c-7.267-9.29-42.25-5.931-51.31-14.035-5.65-5.056-5.905-19.025-6.67-31.763-4.508 5.898-11.32 15.82-10.169 21.973 1.64 8.78 5.24 13.936 12.263 16.342 7.023 2.416 5.35 2.46 14.279 3.503 8.928 1.042 15.675 2.505 20.56 3.658 3.378.799 10.79 5.122 15.01 7.705 5.262-2.328 7.931-4.966 6.037-7.383z"
        fill="#AD263D"
      />
      <path
        d="M78.595 105.743s-1.362-2.894-.61-5.189c.754-2.294-.044-5.166-.531-5.687-.487-.52.698-1.607 2.05-.532.974.765 2.049 2.472 1.905 3.714-.144 1.253 5.273-1.663 8.84-1.33 3.567.332.432 1.596.432 1.596s3.3-1.109 4.087.288c.787 1.408-3.71 3.969-3.71 3.969s2.536-1.153 2.503-.144c-.022 1.009-11.909 8.337-14.966 3.315z"
        fill="#FF6161"
      />
      <path
        d="M1.983 85.255c5.66-.82 16.273-2.76 16.572-5.809.288-2.905-10.989-3.537-17.857-3.647a91.577 91.577 0 001.285 9.456z"
        fill="#311E69"
      />
      <path
        d="M107.374 45.478s3.213 2.017 3.623 4.257c.41 2.24-.034 5.532-3.025 5.3-2.99-.222-7.277-2.218-5.594-4.047 1.684-1.819 4.996-5.51 4.996-5.51z"
        fill="#FF6161"
      />
      <path
        d="M106.2 60.222l.299 5.156-.676.11.743 2.218h.631l.654-2.34-.687-.055.044-5-1.008-.089z"
        fill="#614CD4"
      />
      <path
        d="M107.662 45.766s-2.968-1.408-4.121.898c-1.152 2.306.355 13.614 2.061 14.611 1.473.854 2.06.189 2.559-.676.476-.853 1.949-14.057-.499-14.833z"
        fill="#1E1240"
      />
      <path
        d="M100.119 48.105s3.212-6.197 5.527-6.13c2.315.077 2.094 3.591 3.844 4.179 1.75.598 1.761 1.796 0 1.94-1.761.144-3.179-2.395-3.622-1.719-.443.677-1.994 5.078-3.944 4.114-1.95-.965-1.805-2.384-1.805-2.384z"
        fill="#FF6161"
      />
      <path
        d="M101.492 46.675s-4.807 1.718-4.73 4.479c.078 2.76 4.331 5.155 6.048 5.077 1.717-.077 2.161-.743 3.878 0s.952-3.115-.754-3.093c-1.706.022-2.902-1.153-2.902-1.64 0-.488-.775-5.134-1.54-4.823z"
        fill="#FF6161"
      />
      <path
        d="M26.187 76.497c-2.437-16.962.687-44.8-10.28-45.51-11.93-.775-17.535 26.209-14.843 48.326 4.796 1.375 14.334 2.794 25.123-2.816z"
        fill="#614CD4"
      />
      <path
        d="M10.69 268.546c.543-1.408.82-2.395.986-3.293.354-1.94 1.407-3.68 3.002-4.833 2.448-1.741 5.838-3.714 7.145-2.406 1.451 1.452-.61 6.663.709 8.647.576.865 11.277 6.707 11.454 7.905.3 1.995-20.35 2.439-23.828.587-1.827-.964-.531-3.836.532-6.607zM39.68 268.546c.543-1.408.82-2.395.986-3.293.354-1.94 1.407-3.68 3.002-4.833 2.448-1.741 5.837-3.714 7.145-2.406 1.45 1.452-.61 6.663.698 8.647.576.865 11.276 6.707 11.454 7.905.299 1.995-20.35 2.439-23.828.587-1.817-.964-.52-3.836.543-6.607zM15.908 30.977c-1.806-.122-3.468.41-4.974 1.452 2.913 4.634 6.27 11.585 4.486 16.995-2.947 8.925-5.395 8.747-3.921 18.282.587 3.78 1.816 8.359 3.101 12.527 3.556-.488 7.478-1.607 11.598-3.747-2.448-16.962.676-44.8-10.29-45.51z"
        fill="#311E69"
      />
    </svg>
  );
}

export default SvgCountStudentIllustration;
