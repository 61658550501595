import React from 'react';

import {
  Flex,
  FlexItem,
  DialogBackdrop,
  DialogAlert,
  DialogHeader,
  DialogCloseButton,
  DialogSection,
  DialogFooter,
} from '@ampli/ui';
import { shape, bool, func, string } from '@ampli/utils';

import { NecessaryDocuments } from '../assets/illustrations';

import {
  CtaButton,
  Paragraph,
  DialogTitle,
  IllustrationWrap,
} from './components';

const DocumentModal = ({
  dialog,
  onCallToActionClick,
  requiredDegreeLevel,
}) => {
  const handleCallToActionClick = () => {
    dialog.hide();
    onCallToActionClick && onCallToActionClick();
  };
  return (
    <>
      <DialogBackdrop {...dialog} />
      <DialogAlert {...dialog} aria-labelledby="modalTitle">
        <DialogHeader>
          <IllustrationWrap Illustration={NecessaryDocuments} />
          <DialogCloseButton onClick={dialog.hide} />
        </DialogHeader>
        <DialogTitle id="modalTitle">
          Quais são os documentos necessários ?
        </DialogTitle>
        <FlexItem flex={1}>
          <DialogSection>
            <Paragraph>
              Você deverá enviar fotos dos seguintes documentos:
            </Paragraph>
            <Paragraph>
              <ul>
                <li>RG ou CNH</li>
                <li>CPF</li>
                {requiredDegreeLevel && (
                  <li>
                    Certificado de conclusão do{' '}
                    {requiredDegreeLevel.toLowerCase()}
                  </li>
                )}
              </ul>
            </Paragraph>
            <Paragraph>
              Caso tenha perdido ou não tenha acesso fácil a eles, não tem
              problema, pois você terá até 3 meses, após a confirmação de sua
              matrícula, para enviá-los.
            </Paragraph>
          </DialogSection>
        </FlexItem>
        <Flex
          justifyContent="center"
          matches={{ 'tablet-min': { justifyContent: 'flex-end' } }}
        >
          <DialogFooter>
            <CtaButton fluid onClick={handleCallToActionClick}>
              Inscreva-se de graça
            </CtaButton>
          </DialogFooter>
        </Flex>
      </DialogAlert>
    </>
  );
};
DocumentModal.propTypes = {
  dialog: shape({ visible: bool, hide: func }).isRequired,
  onCallToActionClick: func,
  requiredDegreeLevel: string,
};

export default DocumentModal;
