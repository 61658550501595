import React from 'react';
import { css, cx, mediaQuery } from '@ampli/ui';
import { string, node } from '@ampli/utils';

const BannerBackground = ({ image, children, className, ...props }) => (
  <div
    {...props}
    className={cx(
      'banner-background',
      css`
        background-image: url('${image}');
        width: 100%;
        height: 100%;
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: cover;

        ${mediaQuery('desktop')} {
          background-position: 100% 0%;
        }
      `,
      className
    )}
  >
    {children}
  </div>
);

BannerBackground.propTypes = {
  image: string,
  children: node.isRequired,
  className: string,
};

export default BannerBackground;
