import * as React from "react";

function SvgOnlineTest(props) {
  return (
    <svg
      width={141}
      height={130}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M124.122 14.194c0 .407-.056.796-.159 1.161h-33.84c0-2.497 1.668-4.523 3.727-4.523.72 0 1.393.249 1.962.677.607-2.488 2.504-4.304 4.751-4.304.835 0 1.622.251 2.313.694.947-3.23 3.49-5.544 6.478-5.544 2.785 0 5.184 2.009 6.264 4.898.199-.03.404-.046.612-.046 1.987 0 3.702 1.42 4.491 3.469.15-.03.306-.044.464-.044 1.622 0 2.937 1.595 2.937 3.562z"
        fill="#E3ECFF"
      />
      <path
        d="M14.244 75.83h-5.08V56.248L3.1 58.128v-4.13L13.7 50.2h.544V75.83z"
        fill="#009A98"
      />
      <path
        d="M107.522 48.724c.188 1.13.041 2.26-.176 3.39-.117.564-.271 1.13-.446 1.694-.186.565-.363 1.13-.687 1.695-.617 1.13-1.731 2.26-2.421 3.39-.698 1.13-.785 2.26-.574 3.389.105.564.318 1.13.581 1.694.271.565.601 1.13.907 1.695.31.564.6 1.13.791 1.694.196.565.293 1.13.283 1.694a4.611 4.611 0 01-.096.848c-.066.282-.123.564-.284.847-.3.564-.897 1.13-1.702 1.694-1.65 1.13-3.929 2.26-4.71 3.39-.428.564-.482 1.13-.411 1.694.068.565.282 1.13.651 1.695.182.282.395.564.653.847.13.141.246.282.388.424l.437.424c.568.564 1.205 1.13 1.792 1.694.595.565 1.077 1.13 1.471 1.695.381.564.623 1.13.746 1.694.258 1.13-.137 2.26-.905 3.39-.73 1.13-1.572 2.26-1.657 3.39-.069.565.058 1.129.235 1.694.174.564.389 1.13.65 1.694.522 1.13 1.235 2.26 2.199 3.39a17.534 17.534 0 003.867 3.389 4.086 4.086 0 004.465 0 17.519 17.519 0 003.868-3.39 14.712 14.712 0 002.198-3.39c.262-.564.476-1.129.65-1.694.178-.564.304-1.13.236-1.694-.085-1.13-.928-2.26-1.658-3.39-.768-1.13-1.162-2.26-.905-3.39.124-.564.364-1.129.746-1.694.395-.565.876-1.13 1.471-1.694.587-.564 1.225-1.13 1.792-1.694l.437-.424c.142-.142.258-.283.389-.424.259-.283.47-.565.652-.847.369-.565.582-1.13.651-1.695.071-.564.017-1.13-.41-1.694-.782-1.13-3.06-2.26-4.71-3.39-.805-.565-1.403-1.13-1.703-1.694-.161-.283-.216-.565-.284-.847a4.61 4.61 0 01-.095-.848 4.9 4.9 0 01.282-1.694c.191-.564.482-1.13.791-1.694.306-.565.636-1.13.907-1.695.263-.564.476-1.13.582-1.694.209-1.13.122-2.26-.575-3.39-.69-1.13-1.804-2.26-2.421-3.39-.323-.564-.499-1.13-.687-1.694a17.937 17.937 0 01-.445-1.694c-.217-1.13-.364-2.26-.177-3.39a3.866 3.866 0 10-7.629 0z"
        fill="#B1CBF2"
      />
      <path
        d="M110.834 79.563l-4.459-4.46a.502.502 0 01.709-.709l3.75 3.751V63.014l-3.409-3.41a.5.5 0 11.708-.709l2.701 2.702v-5.56a.501.501 0 011.002 0v7.61l3.606-3.606a.501.501 0 01.709.709l-4.315 4.315v15.084l5.127-5.129a.502.502 0 01.709.71l-5.836 5.836v14.596l4.269-4.27a.502.502 0 01.709.71l-4.978 4.978v26.762a.501.501 0 01-1.002 0V95.826l-4.029-4.03a.5.5 0 11.708-.708l3.321 3.32V79.562z"
        fill="#E3F7FF"
      />
      <path
        d="M126.056 58.997c.163.984.035 1.968-.155 2.952-.102.492-.235.984-.388 1.476-.163.492-.316.984-.598 1.476-.537.984-1.508 1.968-2.108 2.952-.607.984-.683 1.968-.501 2.951.093.493.277.984.507 1.477.235.492.523.983.79 1.476.269.492.522.984.689 1.476.17.492.254.984.246 1.476a4.107 4.107 0 01-.083.738c-.058.246-.106.492-.246.738-.261.492-.781.983-1.483 1.476-1.436.984-3.421 1.968-4.101 2.951-.373.493-.419.984-.359 1.477.06.492.247.984.568 1.476.158.246.342.492.568.738.113.122.214.246.338.368l.381.37c.494.492 1.049.983 1.56 1.475.519.493.938.984 1.281 1.477.333.492.542.984.65 1.476.224.984-.12 1.968-.788 2.952-.636.983-1.37 1.967-1.443 2.951-.059.492.05.984.205 1.476.152.493.338.984.566 1.477a12.83 12.83 0 001.915 2.951 15.253 15.253 0 003.368 2.952 3.562 3.562 0 003.888 0 15.276 15.276 0 003.368-2.952 12.896 12.896 0 001.915-2.951c.227-.493.413-.984.565-1.477.155-.492.265-.984.206-1.476-.074-.984-.808-1.968-1.443-2.951-.67-.984-1.012-1.968-.788-2.952a4.24 4.24 0 01.649-1.476 9.65 9.65 0 011.282-1.477c.511-.492 1.066-.983 1.56-1.476l.381-.369c.123-.122.224-.246.338-.368.225-.247.41-.493.568-.738.321-.492.507-.984.567-1.476.062-.493.014-.984-.358-1.477-.681-.983-2.665-1.967-4.102-2.951-.701-.493-1.221-.984-1.483-1.476-.139-.247-.188-.493-.246-.738a4.034 4.034 0 01-.082-.738 4.26 4.26 0 01.246-1.476c.167-.492.419-.984.689-1.476.267-.493.553-.984.79-1.476.228-.493.414-.984.506-1.477.183-.984.107-1.967-.5-2.951-.6-.984-1.571-1.968-2.108-2.952-.282-.492-.436-.984-.599-1.476a16.07 16.07 0 01-.388-1.476c-.189-.984-.317-1.968-.154-2.952a3.367 3.367 0 00-6.643-1.1c-.065.371-.059.75-.003 1.1z"
        fill="#C7F0FF"
      />
      <path
        d="M128.941 85.852l-3.884-3.884a.437.437 0 01.617-.617l3.266 3.266V71.441l-2.969-2.97a.437.437 0 01.617-.617l2.352 2.353v-4.842a.436.436 0 11.872 0v6.628l3.141-3.14a.436.436 0 11.617.616l-3.759 3.758v13.135l4.466-4.466a.436.436 0 01.617.617l-5.083 5.083v12.712l3.718-3.719a.436.436 0 11.617.617l-4.335 4.335v23.307a.436.436 0 11-.872 0v-24.834l-3.509-3.509a.437.437 0 01.617-.617l2.891 2.892V85.852h.003z"
        fill="#E3F7FF"
      />
      <path
        d="M106.701 127.28h28.323l4.529-18.976h-36.885l4.033 18.976z"
        fill="#009A98"
      />
      <path
        d="M109.323 108.304s6.171 4.351 3.558 8.221c-2.614 3.87-6.18 10.755-6.18 10.755h28.323l4.528-18.976h-30.229z"
        fill="#009A98"
      />
      <path
        d="M53.66 4.432l-32.015-.345c-.72-.008-1.285-.868-1.262-1.919.022-1.053.625-1.898 1.345-1.89l32.015.345c.72.008 1.285.868 1.263 1.919-.023 1.053-.626 1.898-1.345 1.89zM53.546 11.815l-32.015-.345c-.72-.008-1.285-.868-1.263-1.919.023-1.052.626-1.898 1.345-1.89l32.016.346c.72.007 1.285.867 1.262 1.918-.022 1.053-.625 1.898-1.345 1.89zM53.433 19.199l-32.015-.346c-.721-.008-1.286-.867-1.263-1.918.023-1.053.626-1.898 1.345-1.89l32.016.345c.72.008 1.285.868 1.262 1.918-.023 1.053-.625 1.898-1.345 1.89zM53.319 26.582l-32.016-.346c-.72-.008-1.285-.867-1.262-1.918.023-1.053.625-1.898 1.345-1.89l32.015.345c.721.008 1.286.868 1.263 1.919-.023 1.052-.626 1.898-1.345 1.89z"
        fill="#CCF0F6"
      />
      <path
        d="M73.45 124.82s-9.583 3.143-9.583 3.671v1.339h10.86l.715-.871.498.871h2.955s.301-.675.239-2.313c-.062-1.638-1.058-2.074-1.058-2.074l-3.235-1.66-1.39 1.037zM85.723 120.199s-1.052 3.357-2.035 3.696c-.983.339-4.069.576-4.069 1.186v1.243h7.12l4.306-5.278-.34-1.017.882.18 1.322-1.977s-.848-1.068-1.898-1.424c-1.052-.357-2.216-.17-2.216-.17l-3.072 3.561z"
        fill="#444"
      />
      <path
        d="M70.695 86.965l-.05.222a10.23 10.23 0 00-.235 3.058l2.603 34.719s.763 1.531 2.661 1.35c1.898-.181 2.245-.952 2.245-.952s.377-13.606-.233-18.963c-.61-5.358-.272-9.766.067-11.393.34-1.627 2.441-11.868 2.441-11.868l-4.95-1.153-4.549 4.98z"
        fill="#8E9294"
      />
      <path
        d="M81.949 85.13s-2.663 6.715-3.517 8.858c-.854 2.142-.543 3.73 1.763 7.595 2.306 3.865 9.019 15.801 9.019 15.801s.203 1.176-.927 2.217c-.797.735-2.566.597-2.566.597l-8.871-12.693s-12.67-36.67-12.466-37.01c.204-.338 14.048 2.284 14.048 2.284l3.517 12.352z"
        fill="#8E9294"
      />
      <path
        d="M65.164 71.269s4.357 2.342 4.074 5.507c-.282 3.165.856 11.705.856 11.705s.607-.597 6.574-1.818c5.967-1.22 5.131-1.037 5.131-1.037s3.107-8.83 3.243-10.728c.136-1.899 0-5.528-.847-6.578-.848-1.052-7.968-1.357-7.968-1.357l-4.95-.238-6.113 4.544z"
        fill="#8E9294"
      />
      <path
        d="M59.953 54.5c.038.039-.886.871-.886.871s-.516-.29-.946-.431c-.43-.14-.656.39-1.46.225a1.515 1.515 0 00-.233-.03l-.721-1.273a.655.655 0 00.23-.6c-.023-.23-.371-.399-.872-.536a11.31 11.31 0 00-.715-.16c-1.277-.24-2.914-.383-3.266-.692-.45-.39.942-.589 2.53-.627.2-.005.406-.007.61-.004.291 0 .583.006.864.015 2.032.083 2.411.832 3.013 1.231.602.398 1.226.272 1.226.272s.589 1.698.626 1.739z"
        fill="#B89397"
      />
      <path
        d="M78.8 56.97s-1.822-.334-4.893-.58c-3.07-.246-14.664.104-14.664.104s-.328-1.357-.163-2.499c.164-1.141.44-1.876.44-1.876s1.48.356 3.494.003c2.013-.354 13.109-2.233 15.862-1.779 2.754.453 1.605 6.003 1.605 6.003l-1.68.624z"
        fill="#B89397"
      />
      <path
        d="M68.508 43.063s-1.061 1.24-2.328 4.449c-1.266 3.21-2.307 3.707-1.582 5.109.724 1.401 2.623 1.898 2.148 3.346-.475 1.447-.113 6.102-.656 7.504-.543 1.402-2.08 6.42-2.08 6.42s3.708 5.923 11.03 3.798c7.325-2.125 10.399-5.696 10.399-5.696s-3.843-5.562-4.16-8.771c-.317-3.21.95-7.867-.225-10.309-1.176-2.44-2.961-5.888-4.385-7.007-1.424-1.12-3.085-1.56-3.085-1.56l-2.272.621-2.804 2.096z"
        fill="#009A98"
      />
      <path
        d="M68.641 38.275s.163 3.02.05 4.173c-.114 1.153-2.578 4.228-.86 3.776 1.718-.453 4.001-2.94 5.448-3.075 1.447-.135 1.763-.746 1.763-.746s-.475-1.74-.113-2.85c.362-1.106-.926-2.215-.926-2.215l-2.509-1.673-3.549.181.696 2.43z"
        fill="#B89397"
      />
      <path
        d="M61.138 32.113s.346 2.019 2.19 4.904c1.036 1.62 2.282 2.84 3.175 3.599a1.594 1.594 0 002.237-.17c.954-1.093 2.227-2.937 2.384-5.11.252-3.524-4.508-9.152-4.508-9.152l-3.549 1.982-1.31 2.415-.62 1.532z"
        fill="#B89397"
      />
      <path
        d="M57.857 60.741a4.174 4.174 0 01-6.162-1.016l-2.53-4.016a4.18 4.18 0 117.082-4.447l2.518 4.024a4.174 4.174 0 01-.908 5.455z"
        fill="#444"
      />
      <path
        d="M54.359 61.36l-5.105-8.171 4.954-3.249 4.753 7.655-4.602 3.765z"
        fill="#ECECF3"
      />
      <path
        d="M59.045 60.994s-.851.662-1.555.436c-.512-.163-1.806-.771-2.474-1.09-.213-.102-.443.082-.404.313.032.195.11.405.271.558.39.367.634.575.204.7-.43.123-1.286.458-2.068-.156s-.45-1.074-.45-1.074-1.827 1.51-.806 4.314c.651 1.791 2.06 2.114 3.655 1.233 1.595-.882 1.818-1.305 2.735-1.625a8.16 8.16 0 011.586-.397l1.55-1.464-.677-1.13-1.567-.618z"
        fill="#B89397"
      />
      <path
        d="M75.957 46.435a2.11 2.11 0 00-2.423.686c-.688.897-1.46 2.36-1.137 4.163.548 3.054 1.977 4.502-.881 5.583-2.86 1.08-12.227 3.198-12.227 3.198s-.64 1.824-.174 2.99c.466 1.168 1.228 1.876 1.228 1.876s15.553-2.873 17.776-5.156c1.653-1.697 1.552-5.955 1.02-8.944-.507-2.834-2.012-3.954-3.182-4.396z"
        fill="#B89397"
      />
      <path
        d="M66.738 32.547c-1.338-1.915-4.818 1.807-4.818 1.807s-2.832-3.558.105-6.762c9.012-12.053 21.202 6.375 13.271 11.156-1.842 1.11-2.914 1.154-4.843.319-1.93-.835-2.378-4.606-3.715-6.52z"
        fill="#444"
      />
    </svg>
  );
}

export default SvgOnlineTest;
